angular.module("rezepteAddDates").component("rezepteAddDates", {
  templateUrl: "static/angular_assets/rezepte/rezepte_add_new/rezepte_add_dates/rezepte_add_dates.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "$anchorScroll",
    "$timeout",
    "MitarbeiterService",
    "RememberService",
    "$stateParams",
    function RezepteAddController(
      $scope,
      $rootScope,
      $state,
      $anchorScroll,
      $timeout,
      MitarbeiterService,
      RememberService,
      $stateParams
    ) {
      var rad = this;

      rad.init = function () {
        // holds errors
        rad.errors = {
          positions: [],
          messages: [],
        };

        // used for anchor scroll to first error
        rad.error_scroll = null;
        $anchorScroll.yOffset = 200;

        // needed for access to date forms
        $scope.forms = {};

        // previously visited state
        rad.fromState = $rootScope.fromState;

        // rezeptHeader object
        rad.rezeptHeader = null;

        // patient object
        rad.patient = null;

        // general object that holds data related to patient/rezeptHeader
        rad.general = null;

        // mitarbeiter object
        rad.mitarbeiter = null;

        // positions object
        rad.positions = null;

        // get related mitarbeiter data first
        rad.getMitarbeiterData();

        // dates object
        rad.dates = {
          original_entries: [],
          daten_entries: [],
          position_cnts: [],
          vorrangig_cnts: [],
          mehrfach_cnts: [],
        };

        // tarife with frequency 1
        rad.tarif_lkp = [
          "19701",
          "29701",
          "59701",
          "89701",
          "33010",
          "33011",
          "54002",
          "12.1",
          "11906",
          "21906",
          "81906",
          "3.1",
          "20553",
        ];

        // info if rezept is predated
        rad.predated = false;

        // set page readiness to false
        rad.isready = false;

        if (rad.fromState == "menu.rezepte-add-summary") {
          rad.rezeptHeader = RememberService.rezeptAddDatesData.rezeptHeader;
          rad.patient = RememberService.rezeptAddDatesData.patient;
          rad.general = RememberService.rezeptAddDatesData.general;
          rad.positions = RememberService.rezeptAddDatesData.positions;
          rad.dates = RememberService.rezeptAddDatesData.dates;
        } else {
          // if no parameters were provided return to rezepte_add_selection
          if (!$stateParams.params) {
            rad.returnToPreviousState();
            return;
          }

          rad.patient = $stateParams.params.patient;
          rad.rezeptHeader = $stateParams.params.rezeptHeader;
          rad.general = $stateParams.params.general;
          rad.positions = $stateParams.params.positions;
        }

        // check if ready to be displayed
        rad.checkifready();
      };

      rad.getMitarbeiterData = function () {
        MitarbeiterService.query()
          .$promise.then(function (data) {
            rad.mitarbeiter = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rad.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      rad.checkifready = function () {
        if (!rad.mitarbeiter) {
          setTimeout(rad.checkifready, 50);
        } else {
          if (rad.fromState != "menu.rezepte-add-summary") {
            // calculate inital groups (set variables etc.)
            rad.calculateGroups();
          }
          rad.isready = true;
          $scope.$apply();
        }
      };

      rad.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      rad.date_to_str_ger = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return (ddChars[1] ? dd : "0" + ddChars[0]) + "." + (mmChars[1] ? mm : "0" + mmChars[0]) + "." + yyyy;
      };

      //functions to sort data (for tables) based on multiple attributes/properties
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          if (a[property] === null) {
            return 0;
          }
          if (b[property] === null) {
            return 0;
          }

          return result * sortOrder;
        };
      }

      rad.dynamicSortMultiple = function () {
        var props = arguments;
        return function (obj1, obj2) {
          var i = 0,
            result = 0,
            numberOfProperties = props.length;
          /* try getting a different result from 0 (equal)
           * as long as we have extra properties to compare
           */
          while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
          }
          return result;
        };
      };

      rad.set_error_scroll = function (loc) {
        if (!rad.error_scroll) {
          rad.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      // ########## DATEN FUNCTIONS ##########
      // #####################################

      // checks whether all array values are equal to 0
      rad.checkifallZero = function (arr) {
        var zero = true;
        for (var z = 0; z < arr.length; z++) {
          if (arr[z].cnt != 0) {
            zero = false;
            break;
          }
        }
        return zero;
      };

      // returns written weekday based on provided date
      rad.getweekday = function (dt) {
        var days = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
        return days[dt.getDay()];
      };

      rad.dateDiffInDays = function (a, b) {
        // Discard the time and time-zone information.
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      };

      // calculates date groups
      rad.calculateGroups = function () {
        var suggest_dates = rad.general.settings_data.date_suggestion;
        var start_date = rad.general.erfassungsdatum;
        var groups = {};
        var week_lkp = {};
        var groups_keys = [];

        // TODO: Remove old logic!
        if (
          rad.rezeptHeader.rezeptversion == "Alt" ||
          ["PPPP", "BWBW", "KURE", "BGBG", "9999"].indexOf(rad.general.diagnose_key) != -1 ||
          ["LOGO"].indexOf(rad.general.heilmittelbereich) != -1
        ) {
          // OLD LOGIC
          // calculate containers
          for (var i = 0; i < rad.positions.length; i++) {
            var c_frq = rad.rezeptHeader.frequenz ? rad.rezeptHeader.frequenz : 1;
            if (rad.tarif_lkp.includes(rad.positions[i].heilmittel.tarif)) {
              c_frq = 1;
            }

            container_data = {
              cnt: parseInt(rad.positions[i].menge),
              date: new Date(start_date),
              frequency: c_frq,
            };
            rad.dates.position_cnts.push(container_data);
          }

          // calculate group-lookup
          for (var pos = 0; pos < rad.dates.position_cnts.length; pos++) {
            while (rad.dates.position_cnts[pos].cnt != 0) {
              var pos_data = rad.dates.position_cnts[pos];
              var date = pos_data.date;
              var week = moment(date).week();
              var week_day = moment(date).isoWeekday();

              // update week_lkp data
              if (week in week_lkp) {
                if (pos in week_lkp[week]) {
                  week_lkp[week][pos] += 1;
                } else {
                  week_lkp[week][pos] = 1;
                }
              } else {
                week_lkp[week] = {};
                week_lkp[week][pos] = 1;
              }

              // CASE 1: this date entry already exists
              if (date in groups) {
                groups[date].push(pos);
              }
              // CASE 2: new date entry has to be created
              else {
                groups_keys.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
                groups[date] = [pos];
              }

              // now calculate next valid date
              if (pos_data.frequency == 1) {
                rad.dates.position_cnts[pos].date.setDate(rad.dates.position_cnts[pos].date.getDate() + 7);
              } else {
                var week_cnt = week_lkp[week][pos];
                var remaining = pos_data.frequency - week_cnt;

                // there is still data missing for this week
                if (remaining != 0) {
                  // remaining dates will fit in this week
                  if (week_day + remaining <= 5) {
                    // find out max next date
                    var margin = 5 - week_day - remaining;
                    var group_found = false;

                    // look for existing groups
                    for (var m = 1; m < margin + 2; m++) {
                      var possible_date = rad.dates.position_cnts[pos].date.getDate() + m;
                      if (possible_date in groups) {
                        group_found = true;
                        // set date to next existing group date
                        rad.dates.position_cnts[pos].date.setDate(rad.dates.position_cnts[pos].date.getDate() + m);
                        break;
                      }
                    }

                    // no group date was found, take earliest valid date
                    if (!group_found) {
                      if (week_day == 6) {
                        rad.dates.position_cnts[pos].date.setDate(rad.dates.position_cnts[pos].date.getDate() + 2);
                      } else {
                        rad.dates.position_cnts[pos].date.setDate(rad.dates.position_cnts[pos].date.getDate() + 1);
                      }
                    }
                  }
                  // remaining dates will not (all) fit in this week
                  else {
                    // try to fit as many as possible
                    var to_fit = 5 - week_day;
                    if (to_fit <= 0) {
                      // look for existing group next week
                      var margin = 5 - pos_data.frequency;
                      var group_found = false;

                      // look for existing group within marigin
                      for (var m = 1; m < margin + 2; m++) {
                        var possible_date = rad.dates.position_cnts[pos].date.getDate() + m;
                        if (possible_date in groups) {
                          group_found = true;
                          // set date to next existing group date
                          rad.dates.position_cnts[pos].date.setDate(rad.dates.position_cnts[pos].date.getDate() + m);
                          break;
                        }
                      }

                      // no group date was found, take earliest valid date
                      if (!group_found) {
                        rad.dates.position_cnts[pos].date.setDate(
                          rad.dates.position_cnts[pos].date.getDate() + (8 - week_day)
                        );
                      }
                    } else {
                      rad.dates.position_cnts[pos].date.setDate(rad.dates.position_cnts[pos].date.getDate() + 1);
                    }
                  }
                }
                // enough dates are assigned to this week, go to next one
                else {
                  var margin = 5 - pos_data.frequency;
                  var group_found = false;

                  // look for existing group within marigin
                  for (var m = 1; m < margin + 2; m++) {
                    var possible_date = rad.dates.position_cnts[pos].date.getDate() + m;
                    if (possible_date in groups) {
                      group_found = true;
                      // set date to next existing group date
                      rad.dates.position_cnts[pos].date.setDate(rad.dates.position_cnts[pos].date.getDate() + m);
                      break;
                    }
                  }

                  // no group date was found, take earliest valid date
                  if (!group_found) {
                    rad.dates.position_cnts[pos].date.setDate(
                      rad.dates.position_cnts[pos].date.getDate() + (8 - week_day)
                    );
                  }
                }
              }

              rad.dates.position_cnts[pos].cnt -= 1;
            }
          }
        } else {
          var curr_date = new Date(start_date);
          var vorr_freq = 0;
          var first_doubletr_pos = {};
          // NEW LOGIC
          // calculate containers

          // check if any vorr tarife are available
          var has_vorr = false;
          for (var i = 0; i < rad.positions.length; i++) {
            entry = rad.positions[i];
            var found = rad.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });
            if (found.vorrangig) {
              has_vorr = true;
            }
          }

          for (var i = 0; i < rad.positions.length; i++) {
            entry = rad.positions[i];

            var frq = rad.rezeptHeader.frequenz;

            if (rad.tarif_lkp.includes(entry.heilmittel.tarif)) {
              frq = 1;
            }

            container_data = {
              pos: i,
              tarif: entry.heilmittel.tarif,
              cnt: parseInt(entry.menge),
              date: new Date(start_date),
              frequency: frq,
              doubletr: false,
            };

            var found = rad.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });

            // vorranging with mehrfach_kz
            if (entry.heilmittel.mehrfach_kz) {
              rad.dates.mehrfach_cnts.push(container_data);
            } else if (found.vorrangig) {
              // check for double treatment entries
              for (var j = 0; j < rad.positions.length; j++) {
                if (rad.positions[j].heilmittel.tarif == entry.heilmittel.tarif && i != j) {
                  if (!(entry.heilmittel.tarif in first_doubletr_pos)) {
                    first_doubletr_pos[entry.heilmittel.tarif] = i;
                  }
                  container_data.doubletr = true;
                  break;
                }
              }

              rad.dates.vorrangig_cnts.push(container_data);
              vorr_freq = Math.max(vorr_freq, container_data.frequency);
            } else if (found.alleinstehend && !has_vorr) {
              rad.dates.vorrangig_cnts.push(container_data);
              vorr_freq = Math.max(vorr_freq, container_data.frequency);
            }
            // ergaenzend or no flag (neither vorrangig, ergaenzend nor alleinstehend)
            else {
              rad.dates.mehrfach_cnts.push(container_data);
            }
          }

          // sort rad.dates.vorrangig_cnts by cnt (desc)
          rad.dates.vorrangig_cnts.sort(function (a, b) {
            return b.cnt - a.cnt;
          });

          function is_empty(obj_cnts) {
            for (var i = 0; i < obj_cnts.length; i++) {
              if (obj_cnts[i].cnt != 0) {
                return false;
              }
            }
            return true;
          }

          vorr_pos = 0;
          vorr_used = 0;
          next_week = false;
          while (!is_empty(rad.dates.vorrangig_cnts)) {
            var vd = rad.dates.vorrangig_cnts[vorr_pos];
            var week = moment(curr_date).week();
            var week_day = moment(curr_date).isoWeekday();

            // skip doubletr pos
            if (vd.doubletr) {
              if (vd.pos != first_doubletr_pos[vd.tarif]) {
                vorr_pos = (vorr_pos + 1) % rad.dates.vorrangig_cnts.length;
                continue;
              }
            }

            // if freq has been fulfilled in current week go to next week
            if (vorr_used == vorr_freq && !next_week) {
              curr_date.setDate(curr_date.getDate() + (8 - week_day));
              vorr_used = 0;
              continue;
            }

            if (next_week) {
              next_week = false;
              vorr_used = 0;
            }

            // tarif is empty, go to next tarif
            if (vd.cnt == 0) {
              vorr_pos = (vorr_pos + 1) % rad.dates.vorrangig_cnts.length;
              continue;
            }

            // add vorr.tarife to groups
            // CASE 1: this date entry already exists
            if (curr_date in groups) {
              groups[curr_date].push(vd.pos);
            }
            // CASE 2: new date entry has to be created
            else {
              groups_keys.push(new Date(curr_date.getFullYear(), curr_date.getMonth(), curr_date.getDate()));
              groups[curr_date] = [vd.pos];
            }

            // reduce vorr_cnt
            rad.dates.vorrangig_cnts[vorr_pos].cnt -= 1;

            // reduce double_treatment cnt and add to groups
            if (vd.doubletr) {
              for (var i = 0; i < rad.dates.vorrangig_cnts.length; i++) {
                var dtr = rad.dates.vorrangig_cnts[i];
                if (dtr.doubletr && i != vorr_pos) {
                  if (dtr.pos != first_doubletr_pos[dtr.tarif] && dtr.tarif == vd.tarif) {
                    dtr.cnt -= 1;
                    groups[curr_date].push(dtr.pos);
                  }
                }
              }
            }

            // take care of mehrfach_cnts
            for (var m = 0; m < rad.dates.mehrfach_cnts.length; m++) {
              var md = rad.dates.mehrfach_cnts[m];

              // if nothing left to group go to next tarif
              if (md.cnt == 0) {
                continue;
              }

              // push tarif/pos to group
              groups[curr_date].push(md.pos);
              rad.dates.mehrfach_cnts[m].cnt -= 1;
            }

            // increase date by one day - skip weekend
            if (week_day == 5) {
              curr_date.setDate(curr_date.getDate() + 3);
            } else {
              curr_date.setDate(curr_date.getDate() + 1);
            }

            if (week != moment(curr_date).week()) {
              next_week = true;
            }

            // select next vorr_tarif
            vorr_pos = (vorr_pos + 1) % rad.dates.vorrangig_cnts.length;

            // increase used count
            vorr_used += 1;
          }
        }

        // order/sort groups_keys by ascending date
        groups_keys.sort(function (a, b) {
          return a.valueOf() - b.valueOf();
        });

        // calculate groups
        var j = 1; // running id
        var g = 1; // curr group number

        for (var i = 0; i < groups_keys.length; i++) {
          var sel_date = groups_keys[i];
          var entries = groups[sel_date];

          for (var e = 0; e < entries.length; e++) {
            var entry = entries[e];
            var first = e == 0 ? true : false;
            var last = e == entries.length - 1 ? true : false;

            // change 02.10.2019
            var pos_id = rad.positions[entry].id;

            // Vollprivat logic
            if (rad.general.rezeptart_selected.id == "VPP") {
              var heilmittel = rad.positions[entry].heilmittel;
              var bezeichnung = heilmittel.bezeichnung;
              var available = true;
            } else {
              var diff_1 = rad.dateDiffInDays(rad.general.preislisten.neu.date_from, sel_date);
              var diff_2 = rad.dateDiffInDays(rad.general.preislisten.alt.date_from, sel_date);
              var heilmittel = null;
              var bezeichnung = null;
              var available = false;

              if (
                (rad.general.preistyp == "mix" && diff_1 >= 0) ||
                (rad.general.preistyp == "mix_alt" && diff_2 >= 0)
              ) {
                for (h = 0; h < rad.general.heilmittel_neu.length; h++) {
                  if (rad.general.heilmittel_neu[h].tarif == rad.positions[entry].heilmittel.tarif) {
                    // set updated tarif
                    var heilmittel = rad.general.heilmittel_neu[h];
                    var bezeichnung = rad.general.heilmittel_neu[h].bezeichnung;
                    var available = true;

                    break;
                  }
                }

                // assume no matching tarif was found in tarif table
                if (!heilmittel) {
                  var heilmittel = rad.positions[entry].heilmittel;
                  var bezeichnung = heilmittel.bezeichnung;

                  // Try to find matching tarif once again using the "bezeichnung" field
                  for (h = 0; h < rad.general.heilmittel_neu.length; h++) {
                    if (rad.general.heilmittel_neu[h].bezeichnung == rad.positions[entry].heilmittel.bezeichnung) {
                      // set updated tarif
                      heilmittel = rad.general.heilmittel_neu[h];
                      bezeichnung = rad.general.heilmittel_neu[h].bezeichnung;
                      available = true;
                      break;
                    }
                  }

                }
              } else {
                var heilmittel = rad.positions[entry].heilmittel;
                var bezeichnung = heilmittel.bezeichnung;
                var available = true;
              }
            }

            // set suggested dates if requested
            if (!suggest_dates && g != 1) {
              var datum1 = null;
              var datum2 = null;
              var weekday1 = null;
              var weekday2 = null;
            } else {
              var datum1 = new Date(sel_date.getFullYear(), sel_date.getMonth(), sel_date.getDate());
              var weekday1 = rad.getweekday(sel_date);
              var datum2 = new Date(sel_date.getFullYear(), sel_date.getMonth(), sel_date.getDate());
              var weekday2 = rad.getweekday(sel_date);
            }

            // double treatment flag for new rezepte
            var doubletr = rad.positions[entry].doubletr;

            var entry1 = {
              id: j,
              pos_id: pos_id,
              group: g,
              first: first,
              last: last,
              old_pos: j,
              weekday: weekday1,
              datum: datum1,
              heilmittel: heilmittel,
              bezeichnung: bezeichnung,
              available: available,
              unterbrechung: false,
              unterbrechung_grund: null,
              mitarbeiter: rad.mitarbeiter.filter(function (e) {
                return e.id == rad.patient.mitarbeiter;
              })[0],
              doubletr: doubletr,
            };

            var entry2 = {
              id: j,
              pos_id: pos_id,
              group: g,
              first: first,
              last: last,
              old_pos: j,
              weekday: weekday2,
              datum: datum2,
              heilmittel: heilmittel,
              bezeichnung: bezeichnung,
              available: available,
              unterbrechung: false,
              unterbrechung_grund: null,
              mitarbeiter: rad.mitarbeiter.filter(function (e) {
                return e.id == rad.patient.mitarbeiter;
              })[0],
              doubletr: doubletr,
            };

            rad.dates.daten_entries.push(entry1);
            rad.dates.original_entries.push(entry2);

            j += 1;
          }

          g += 1;
        }

        rad.dates.position_cnts = [];
      };

      rad.updateGroup = function (g, dt, u, b, m) {
        for (i = 0; i < rad.dates.daten_entries.length; i++) {
          var entry = rad.dates.daten_entries[i];
          if (entry.group == g) {
            rad.dates.daten_entries[i].datum = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
            rad.dates.daten_entries[i].unterbrechung = u;
            rad.dates.daten_entries[i].unterbrechung_grund = b;
            rad.dates.daten_entries[i].mitarbeiter = m;
            rad.updateWeekday(rad.dates.daten_entries[i].id);
          }
        }
      };

      rad.datenRefresh = function (data_check) {
        data_check = data_check === undefined ? true : data_check;

        rad.dates.daten_entries.sort(rad.dynamicSortMultiple("datum", "group", "id"));

        curr_grp = 0;
        curr_date = null;

        // update groups
        for (i = 0; i < rad.dates.daten_entries.length; i++) {
          // logic for rezepte where prices could differ (IGNORE FOR VOLLPRIVATPREISE VPP)
          if (rad.general.rezeptart_selected.id != "VPP") {
            if (rad.dates.daten_entries[i].datum) {
              diff_1 = rad.dateDiffInDays(rad.general.preislisten.neu.date_from, rad.dates.daten_entries[i].datum);
              diff_2 = rad.dateDiffInDays(rad.general.preislisten.alt.date_from, rad.dates.daten_entries[i].datum);
              if (
                (rad.general.preistyp == "mix" && diff_1 >= 0) ||
                (rad.general.preistyp == "mix_alt" && diff_2 >= 0)
              ) {
                heilmittel = null;

                // iterate over new heilmittel data to find maching tarif
                for (h = 0; h < rad.general.heilmittel_neu.length; h++) {
                  if (rad.general.heilmittel_neu[h].tarif == rad.dates.daten_entries[i].heilmittel.tarif) {
                    // set updated tarif
                    heilmittel = rad.general.heilmittel_neu[h];
                    available = true;
                    break;
                  }
                }

                if (!heilmittel) {
                  // Try to find matching tarif once again using the "bezeichnung" field
                  for (h = 0; h < rad.general.heilmittel_neu.length; h++) {
                    if (rad.general.heilmittel_neu[h].bezeichnung == rad.dates.daten_entries[i].heilmittel.bezeichnung) {
                      // set updated tarif
                      heilmittel = rad.general.heilmittel_neu[h];
                      available = true;
                      break;
                    }
                  }
                }
              

                // assume no matching tarif was found in tarif table
                if (!heilmittel) {
                  available = false;
                } else {
                  rad.dates.daten_entries[i].heilmittel = heilmittel;
                }
              }
            }
          }

          // UPDATE Groups

          rad.dates.daten_entries[i].old_pos = rad.dates.daten_entries[i].id;
          rad.dates.daten_entries[i].id = i + 1;

          // check for first element
          if (i == 0) {
            // no date provided
            if (rad.dates.daten_entries[i].datum == null || rad.dates.daten_entries[i].datum == "") {
              continue;
            }

            curr_grp = 1;
            rad.dates.daten_entries[i].group = curr_grp;
            curr_date = rad.dates.daten_entries[i].datum;
            rad.dates.daten_entries[i].first = true;
          }
          // check for following elements
          else {
            // no date provided
            if (rad.dates.daten_entries[i].datum == null || rad.dates.daten_entries[i].datum == "") {
              rad.dates.daten_entries[i].weekday = null;
              continue;
            }

            if (rad.dates.daten_entries[i - 1].datum == "" || rad.dates.daten_entries[i - 1].datum == null) {
              continue;
            }

            // different group
            if (rad.dateDiffInDays(rad.dates.daten_entries[i].datum, rad.dates.daten_entries[i - 1].datum) != 0) {
              curr_grp += 1;
              rad.dates.daten_entries[i].group = curr_grp;
              curr_date = rad.dates.daten_entries[i].datum;
              rad.dates.daten_entries[i].first = true;
            }
            // same group
            else {
              rad.dates.daten_entries[i].group = curr_grp;
              rad.dates.daten_entries[i].first = false;
            }
          }

          // set last for last element of each group
          if (i + 1 < rad.dates.daten_entries.length) {
            if (rad.dates.daten_entries[i + 1].datum == null) {
              rad.dates.daten_entries[i].last = true;
            } else {
              if (rad.dateDiffInDays(rad.dates.daten_entries[i].datum, rad.dates.daten_entries[i + 1].datum)) {
                rad.dates.daten_entries[i].last = true;
              } else {
                rad.dates.daten_entries[i].last = false;
              }
            }
          } else {
            rad.dates.daten_entries[i].last = true;
          }
        }

        if (data_check) {
          rad.checkDatenInput(false);
        }
      };

      rad.updateWeekday = function (id) {
        if (rad.dates.daten_entries[id - 1].datum) {
          rad.dates.daten_entries[id - 1].weekday = rad.getweekday(rad.dates.daten_entries[id - 1].datum);
        }
      };

      rad.datenReset = function () {
        // reset current errors
        for (var error in rad.errors) {
          rad.errors[error] = [];
        }

        rad.dates.daten_entries = [];
        for (i = 0; i < rad.dates.original_entries.length; i++) {
          var element = rad.dates.original_entries[i];
          var entry = {
            id: element.id,
            pos_id: element.pos_id,
            first: element.first,
            last: element.last,
            old_pos: element.id,
            group: element.group,
            weekday: element.weekday,
            datum: element.datum,
            heilmittel: element.heilmittel,
            bezeichnung: element.bezeichnung,
            available: element.available,
            unterbrechung: element.unterbrechung,
            unterbrechung_grund: element.unterbrechung_grund,
            mitarbeiter: element.mitarbeiter,
            doubletr: element.doubletr,
          };

          rad.dates.daten_entries.push(entry);
        }
      };

      rad.unterbrechungReset = function (d) {
        d.unterbrechung_grund = null;
      };

      rad.add_week_to_list = function (container, year, week, dateStr, entry) {
        var id = entry.id;
        var pos_id = entry.pos_id;
        var aborted = entry.unterbrechung;

        if (week.toString().length < 2) {
          week = "0" + week;
        }
        var week_id = year + "-" + week;

        // frequency lookup
        var frequency_lkp = {};
        var total_cnt_lkp = {};
        rad.positions.forEach(function (item, index) {
          frequency_lkp[item.id] = rad.rezeptHeader.frequenz;
          if (rad.tarif_lkp.includes(item.heilmittel.tarif)) {
            frequency_lkp[item.id] = 1;
          }

          total_cnt_lkp[item.id] = item.menge;
        });

        if (!(week_id in container)) {
          container[week_id] = {};
          container[week_id][pos_id] = {
            ids: [id],
            dates: [dateStr],
            cnt: 1,
            aborted: [],
            frequency: frequency_lkp[pos_id],
            total_cnt: total_cnt_lkp[pos_id],
            doubletr: entry.doubletr,
            heilmittel: entry.heilmittel,
          };
        } else {
          if (!(pos_id in container[week_id])) {
            container[week_id][pos_id] = {
              ids: [id],
              dates: [dateStr],
              cnt: 1,
              aborted: [],
              frequency: frequency_lkp[pos_id],
              total_cnt: total_cnt_lkp[pos_id],
              doubletr: entry.doubletr,
              heilmittel: entry.heilmittel,
            };
          } else {
            container[week_id][pos_id].ids.push(id);
            container[week_id][pos_id].dates.push(dateStr);
            container[week_id][pos_id].cnt += 1;
          }
        }

        if (aborted) {
          container[week_id][pos_id].aborted.push(id);
        }
      };

      rad.getMaxPositionFrequency = function () {
        var max_freq = 0;

        for (var i = 0; i < rad.positions.length; i++) {
          max_freq = Math.max(max_freq, Number(rad.positions[i].menge));
        }

        return max_freq;
      }

      rad.checkDatenInput = function (data_refresh) {
        data_refresh = data_refresh === undefined ? true : data_refresh;

        // force update first
        if (data_refresh) {
          rad.datenRefresh(false);
        }

        // reset current errors
        for (var error in rad.errors) {
          rad.errors[error] = [];
        }

        // reset predated flag
        rad.predated = false;

        // object to store line numbers that have an issue
        rad.affected_lines = {
          unterbrechungnotallowed: [],
          twoweekscheck: [],
          twentyonedaycheck: [],
          missinggrund: [],
          missingdatum: [],
          invaliddatum: [],
          erstebehandlung: [],
          notavailable: [],
          sundaycheck: [],
          frequency: [],
          vorrangig_cnt: [],
          doubletr_underused: [],
          doubletr_overused: [],
          ergaenzend_overused: [],
          ergaenzend_alone: [],
          none_alone: [],
          bandage_alone: [],
          validity_3_months: [],
          validity_6_months: [],
          validity_16_weeks: [],
          discharge_management: [],
          blanko_28_days_check: [],
          diagnostic_not_first: [],
          analysis_not_first: [],
          zi_min_max: [],
        };

        // reset anchor scroll position
        rad.error_scroll = null;

        // weekday collection used for frequency checks
        rad.weekdays = {};

        // date limits
        lim_past = 1900;
        lim_future = parseInt(new Date().getFullYear()) + 1;
        lim_today = new Date();


        // 3 or 6 months validity period (for all prescriptions other than PP)
        var prescription_validity_rule = rad.getMaxPositionFrequency() <= 6 ? 3 : 6;
        var max_validity_period_date = moment(rad.general.erfassungsdatum).add(prescription_validity_rule, 'months')


        var de = rad.dates.daten_entries;

        // check errors first
        for (var i = 0; i < de.length; i++) {
          var num = i + 1;

          // check if datum is missing
          if (de[i].datum == null || de[i].datum == "") {
            rad.affected_lines.missingdatum.push(num);
            rad.set_error_scroll("datum" + de[i].id);
          }

          // datum was provided
          else {
            // skip further checks if previous datum or first datum is empty
            if (i != 0) {
              if (de[i - 1].datum == null || de[i - 1].datum == "" || de[0].datum == null || de[0].datum == "") {
                continue;
              }
            }

            var dt = moment(de[i].datum);
            var dtStr = dt.format("YYYY-MM-DD");
            var week = dt.isoWeek();
            var year = dt.isoWeekYear();
            var given_year = dt.year();

            rad.add_week_to_list(rad.weekdays, year, week, dtStr, de[i]);

            // check year limits: 1900 < given_year < lim_future (current year)
            if (lim_past > given_year || lim_future < given_year) {
              for (j = 0; j < de.length; j++) {
                if (de[j].old_pos == num) {
                  rad.affected_lines.invaliddatum.push(de[j].id);
                  rad.set_error_scroll("datum" + de[j].id);
                }
              }
            } else {
              // check if a predated date is found
              if (rad.dateDiffInDays(de[i].datum, lim_today) < 0) {
                rad.predated = true;
              }

              // first date logic
              if (rad.dateDiffInDays(de[i].datum, de[0].datum) == 0) {
                // unterbrechung not allowed on first day
                if (de[i].unterbrechung) {
                  rad.affected_lines.unterbrechungnotallowed.push(num);
                  rad.set_error_scroll("datum" + de[i].id);
                } else {
                  // check if erfassungsdatum is the same as given
                  if (de[i].datum < rad.general.erfassungsdatum || de[i].datum > rad.general.erfassungsdatum) {
                    rad.affected_lines.erstebehandlung.push(num);
                    rad.set_error_scroll("datum" + de[i].id);
                  }
                }
              } else if (
                !de[i].unterbrechung &&
                rad.general.ids_key != "PPPP" &&
                rad.general.diagnose_key != "PPPP" &&
                rad.general.heilmittelbereich != "PODO" &&
                !(rad.rezeptHeader.blanko && rad.general.le_typ == "26")
              ) {
                var diffDays = rad.dateDiffInDays(de[i - 1].datum, de[i].datum);
                if (diffDays > 14) {
                  rad.affected_lines.twoweekscheck.push(num);
                  rad.set_error_scroll("datum" + de[i].id);
                }
              } else if (de[i].unterbrechung && !de[i].unterbrechung_grund) {
                rad.affected_lines.missinggrund.push(num);
                rad.set_error_scroll("datum" + de[i].id);
              }

              if (de[i].weekday == "Sonntag") {
                rad.affected_lines.sundaycheck.push(num);
                rad.set_error_scroll("datum" + de[i].id);
              }
            }

            // check if date is within prescription validity period
            if (!["PPPP", "BGBG"].includes(rad.general.diagnose_key) && !rad.rezeptHeader.blanko){
              if (dt.isAfter(max_validity_period_date)) {
                rad.affected_lines["validity_" + prescription_validity_rule + "_months"].push(num);
                rad.set_error_scroll("datum" + de[i].id);
              }
            }

            // check for blanko prescriptions
            if (rad.rezeptHeader.blanko) {
              if (rad.dateDiffInDays(rad.general.rezeptdatum, de[i].datum) > 112) {
                rad.affected_lines.validity_16_weeks.push(num);
                rad.set_error_scroll("datum" + de[i].id);
              }
            }

            // check for discharge management
            if (rad.rezeptHeader.discharge_management) {
              var diffDays = rad.dateDiffInDays(rad.general.rezeptdatum, de[i].datum);
              if (diffDays > 12) {
                rad.affected_lines.discharge_management.push(num);
                rad.set_error_scroll("datum" + de[i].id);
              }
            }

          }

          if (!de[i].available) {
            rad.affected_lines.notavailable.push(num);
            rad.set_error_scroll("datum" + de[i].id);
          }
        }

        // check for week / frequency errors
        if (
          rad.general.settings_data.frequency_check &&
          rad.general.ids_key != "PPPP" &&
          rad.general.diagnose_key != "PPPP"
        ) {
          // iterate and sort over weeks to get keys in ascending order
          var weeks = [];
          for (var key in rad.weekdays) {
            weeks.push(key);
          }
          weeks.sort();

          // new frequency check
          if (rad.rezeptHeader.rezeptversion == "Neu") {
            // check whether to check vorranginge or alleinstehende
            var check_id = "alone";
            for (var i = 0; i < rad.positions.length; i++) {
              var found = rad.general.diagnose_tarif_lkp.find(function (element) {
                return element.tarif == rad.positions[i].heilmittel.tarif;
              });

              if (found.vorrangig) {
                check_id = "vorr";
                break;
              }
            }

            // iterate over all weeks and check frequency based on selected check_id
            for (var i = 0; i < weeks.length; i++) {
              // week to check
              var week = rad.weekdays[weeks[i]];

              var week_freq = rad.rezeptHeader.frequenz;
              var seen_tarife = new Set();
              var ids = [];
              var dates = new Set();
              
              // iterate over all tarife in the given week
              for (var t in week) {
                var tarif = week[t];

                var found = rad.general.diagnose_tarif_lkp.find(function (element) {
                  return element.tarif == tarif.heilmittel.tarif;
                });

                // we are only interested in the tarife matching the selected check_id
                if (
                  (check_id == "alone" && found.alleinstehend) ||
                  (check_id == "vorr" && found.vorrangig && !tarif.heilmittel.mehrfach_kz)
                ) {
                  if (!tarif.doubletr) {
                    ids = ids.concat(tarif.ids);

                    // ... spread-operator doesn't work properly here (not all dates are added)
                    tarif.dates.forEach(function (d) {
                      dates.add(d);
                    });
                    
                  } else {
                    if (!seen_tarife.has(tarif.heilmittel.bezeichnung)) {
                      seen_tarife.add(tarif.heilmittel.bezeichnung);
                      ids = ids.concat(tarif.ids);
                      // ... spread-operator doesn't work properly here (not all dates are added)
                      tarif.dates.forEach(function (d) {
                        dates.add(d);
                      });
                    }
                  }
                }
              }

              // too many vorrangige/alleinstehende for this week
              if (dates.size > week_freq) {
                rad.affected_lines.frequency.push([ids, week_freq.toString()]);
              }
            }
          }
        }

        // https://github.com/Korred/mkav03/issues/91
        if (rad.rezeptHeader.rezeptversion == "Neu") {
          if (
            !["PPPP", "BGBG", "BWBW", "KURE", "9999"].includes(rad.general.diagnose_key) &&
            rad.general.rezeptart_selected.id != "ZA"
          ) {
            var tarif_set = [];
            var lines = [];
            var double_tr_ids = [];

            var vorr_found = false;
            var ergaenzend_ids = [];
            var alleinstehend_ids = [];

            var bandage_ids = [];
            var mld_flag = false;
            var kb_lkp = ["10204", "20204", "80204", "8403a", "19d"];
            var mld_lkp = [
              "10201",
              "20201",
              "10202",
              "20202",
              "10205",
              "20205",
              "80201",
              "80202",
              "80205",
              "8402",
              "8403",
              "19a",
              "19b",
              "19c",
            ];

            var none_ids = [];

            var blanko_diagnostic_entries = []
            var blanko_analysis_entry = null;

            var blanko_zi_lookup = {
              group_entries: [],
              zi_counts: [],
            }

            // check if any vorr tarife are available
            var has_vorr = false;
            for (var i = 0; i < rad.positions.length; i++) {
              entry = rad.positions[i];
              var found = rad.general.diagnose_tarif_lkp.find(function (element) {
                return element.tarif == entry.heilmittel.tarif;
              });
              if (found.vorrangig && !entry.heilmittel.tarif.mehrfach_kz) {
                has_vorr = true;
              }
            }

            for (i = 0; i < rad.dates.daten_entries.length; i++) {
              var entry = rad.dates.daten_entries[i];

              var found = rad.general.diagnose_tarif_lkp.find(function (element) {
                return element.tarif == entry.heilmittel.tarif;
              });

              if (rad.rezeptHeader.blanko){
                if (['20522','20523'].includes(entry.heilmittel.tarif)){
                  blanko_diagnostic_entries.push(entry);
                }

                if ('54003' == entry.heilmittel.tarif){
                  blanko_analysis_entry = entry;
                }

                // Track ZI sums for each group (Timeunits)
                if (rad.general.le_typ == '26'){
                  // Add empty arrays if a new group is found
                  if (entry.group > blanko_zi_lookup.group_entries.length){
                    blanko_zi_lookup.group_entries.push([]);
                    blanko_zi_lookup.zi_counts.push(0);
                  }
                  blanko_zi_lookup.group_entries[entry.group - 1].push(entry.id);

                  if (entry.heilmittel.bezeichnung.includes('1ZI')){
                    blanko_zi_lookup.zi_counts[entry.group - 1] += 1;
                  }
                }
              }

              if (found.vorrangig && !entry.heilmittel.mehrfach_kz) {
                vorr_found = true;

                if (mld_lkp.includes(entry.heilmittel.tarif)) {
                  mld_flag = true;
                }

                if (entry.doubletr) {
                  double_tr_ids.push(entry.id);
                }

                if (!entry.doubletr || (entry.doubletr && !tarif_set.includes(entry.heilmittel.tarif))) {
                  tarif_set.push(entry.heilmittel.tarif);
                }

                lines.push(entry.id);
              }

              if (kb_lkp.includes(entry.heilmittel.tarif)) {
                bandage_ids.push(entry.id);
              }

              if (found.alleinstehend) {
                alleinstehend_ids.push(entry.id);
              }

              if (found.ergaenzend) {
                ergaenzend_ids.push(entry.id);
              }

              if (!found.ergaenzend && !found.alleinstehend && !found.vorrangig) {
                var exclude_tarife = [
                  "19701", // arztbericht
                  "29701", // arztbericht
                  "59701", // arztbericht
                  "89701", // arztbericht
                  "20553", // arztbericht
                  "33010", // erstbefundung
                  "54002", // funktionsanalyse erstvo
                  "54003", // analyse ergo bedarf
                  "20422", // physio diagnostik
                  "20423", // bedarfsdiagnostik
                ];

                if (!exclude_tarife.includes(entry.heilmittel.tarif)) {
                  none_ids.push(entry.id);
                }
              }

              // entry.last == last entry of the day e.g. reset group related data after the last entry is processed
              if (entry.last) {
                
                // ! This will be ignored for blanko with le_typ 26 (ergo)
                if ((tarif_set.length > 1 && !rad.rezeptHeader.blanko) || 
                    (tarif_set.length > 2 && rad.rezeptHeader.blanko && rad.general.le_typ == "22")) {
                      rad.affected_lines.vorrangig_cnt.push(lines);
                }

                if (double_tr_ids.length == 1) {
                  // double_tr entry is missing its partner
                  rad.affected_lines.doubletr_underused.push(double_tr_ids);
                } else if (double_tr_ids.length > 2) {
                  // multiple double_tr entries in on one day
                  rad.affected_lines.doubletr_overused.push(double_tr_ids);
                }

                if (ergaenzend_ids.length > 1) {
                  // only 1 ergaenzend per day
                  rad.affected_lines.ergaenzend_overused.push(ergaenzend_ids);
                }

                if (none_ids.length > 0 && !vorr_found && alleinstehend_ids.length == 0) {
                  // allg tarife that are standing alone
                  rad.affected_lines.none_alone.push(none_ids);
                }

                if (ergaenzend_ids.length == 1 && !vorr_found) {
                  // possible vorraning missing / lone erganzend
                  if (alleinstehend_ids.length == 1) {
                    if (alleinstehend_ids[0] != ergaenzend_ids[0] || has_vorr) {
                      rad.affected_lines.ergaenzend_alone.push(ergaenzend_ids);
                    }
                  } else {
                    rad.affected_lines.ergaenzend_alone.push(ergaenzend_ids);
                  }
                }

                if (!mld_flag && bandage_ids.length > 0) {
                  rad.affected_lines.bandage_alone.push(bandage_ids);
                }

                tarif_set = [];
                lines = [];
                double_tr_ids = [];
                vorr_found = false;
                ergaenzend_ids = [];
                alleinstehend_ids = [];
                none_ids = [];
                bandage_ids = [];
                mld_flag = false;
              }
            }

            if (blanko_diagnostic_entries.length > 0) {

              if (blanko_diagnostic_entries[0].group != 1) {
                rad.affected_lines.diagnostic_not_first.push(blanko_diagnostic_entries[0].id);
              }

              if (blanko_diagnostic_entries.length > 1) {
                // compare pairwise
                for (var i = 0; i < blanko_diagnostic_entries.length - 1; i++) {
                  var entry_1 = blanko_diagnostic_entries[i];
                  var entry_2 = blanko_diagnostic_entries[i + 1];
  
                  if (rad.dateDiffInDays(entry_1.datum, entry_2.datum) < 28) {
                    rad.affected_lines.blanko_28_days_check.push([entry_1.id, entry_2.id]);
                  }
                }
              }
            }

            if (blanko_analysis_entry) {
              if (blanko_analysis_entry.group != 1) {
                rad.affected_lines.analysis_not_first.push(blanko_analysis_entry.id);
              }
            }


            // Check for ZI min/max (2ZI / 12ZI or 30min / 180min)
            if (rad.rezeptHeader.blanko && rad.general.le_typ == "26") {
              var zi_counts = blanko_zi_lookup.zi_counts;
              var group_entries = blanko_zi_lookup.group_entries;

              for (var i = 0; i < zi_counts.length; i++) {
                var zi_count = zi_counts[i];
                var group_entry = group_entries[i];

                if (zi_count < 2 || zi_count > 12) {
                  rad.affected_lines.zi_min_max.push([i+1, group_entry]);
                }
              }
            }

          }
        }

        if (rad.errors.positions.length == 0) {
          rad.errors.positions = {
            datum: [],
            unterbrechung: [],
          };
        }

        // iterate over affacted_lines to check for errors / to create error messages
        for (var type in rad.affected_lines) {
          if (!rad.affected_lines.hasOwnProperty(type)) {
            //The current error is not a direct property of p
            continue;
          }

          if (rad.affected_lines[type].length != 0) {
            if (type == "notavailable") {
              rad.errors.messages.push(
                "Eintrag " +
                  rad.affected_lines[type].join(", ") +
                  ": Für diese Daten stehen keine aktuellen Tarifpreise zur Verfügung!"
              );
              rad.errors.positions.datum = rad.errors.positions.datum.concat(rad.affected_lines[type]);
            }

            if (type == "missingdatum") {
              rad.errors.messages.push(
                "Eintrag " + rad.affected_lines[type].join(", ") + ": Datum wurde nicht angegeben!"
              );
              rad.errors.positions.datum = rad.errors.positions.datum.concat(rad.affected_lines[type]);
            }

            if (type == "unterbrechungnotallowed") {
              rad.errors.messages.push(
                "Eintrag " +
                  rad.affected_lines[type].join(", ") +
                  ": Erster Behandlungstermin darf nicht unterbrochen werden!"
              );
              rad.errors.positions.unterbrechung = rad.errors.positions.unterbrechung.concat(rad.affected_lines[type]);
            }

            if (type == "twoweekscheck") {
              rad.errors.messages.push(
                "Eintrag " + rad.affected_lines[type].join(", ") + ": Datum überschreitet die 14-Tage Frist!"
              );
              rad.errors.positions.datum = rad.errors.positions.datum.concat(rad.affected_lines[type]);
            }

            if (type == "twentyonedaycheck") {
              rad.errors.messages.push(
                "Eintrag " + rad.affected_lines[type].join(", ") + ": Datum überschreitet die 21-Tage Frist!"
              );
              rad.errors.positions.datum = rad.errors.positions.datum.concat(rad.affected_lines[type]);
            }

            if (type == "missinggrund") {
              rad.errors.messages.push(
                "Eintrag " + rad.affected_lines[type].join(", ") + ": Unterbrechungsgrund fehlt!"
              );
              rad.errors.positions.unterbrechung = rad.errors.positions.unterbrechung.concat(rad.affected_lines[type]);
            }

            if (type == "invaliddatum") {
              rad.errors.messages.push(
                "Eintrag " + rad.affected_lines[type].join(", ") + ": Ungültiges Datum angegeben!"
              );
              rad.errors.positions.datum = rad.errors.positions.datum.concat(rad.affected_lines[type]);
            }

            if (type == "sundaycheck") {
              rad.errors.messages.push(
                "Eintrag " + rad.affected_lines[type].join(", ") + ": Behandlung an Sonntagen nicht erlaubt!"
              );
              rad.errors.positions.datum = rad.errors.positions.datum.concat(rad.affected_lines[type]);
            }

            if (type == "erstebehandlung") {
              rad.errors.messages.push(
                "Eintrag " +
                  rad.affected_lines[type].join(", ") +
                  ": Datum entspricht nicht dem angegebenen ersten Behandlungsdatum (" +
                  rad.date_to_str_ger(rad.general.erfassungsdatum) +
                  ")!"
              );
              rad.errors.positions.datum = rad.errors.positions.datum.concat(rad.affected_lines[type]);
            }

            if (type == "frequency") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item[0].sort(function(a, b) { return a - b; });
                var freq = item[1];
                var msg = "";

                if (freq == 1) {
                  msg =
                    "Eintrag " +
                    ids.join(", ") +
                    ": Die angegebene Frequenz von " +
                    freq +
                    "x pro Woche wurde nicht eingehalten!";
                } else {
                  msg =
                    "Eintrag " +
                    ids.join(", ") +
                    ": Die angegebene Frequenz von 1-" +
                    freq +
                    "x pro Woche wurde nicht eingehalten!";
                }

                rad.errors.messages.push(msg);
                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

            if (type == "vorrangig_cnt") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var msg = ": Pro Behandlungstag ist nur ein vorrangiges Heilmittel erlaubt!";

                if (rad.rezeptHeader.blanko && rad.general.le_typ == "22") {
                  msg = ": Pro Behandlungstag sind maximal zwei vorrangige Heilmittel erlaubt!";
                }

                var ids = item;
                rad.errors.messages.push(
                  "Eintrag " + ids.join(", ") + msg
                );
                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

            if (type == "doubletr_underused") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rad.errors.messages.push("Eintrag " + ids.join(", ") + ": Doppelbehandlungen müssen zusammenstehen!");
                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

            if (type == "doubletr_overused") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rad.errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Mehrere Doppelbehandlungen an einem Tag sind nicht erlaubt!"
                );
                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

            if (type == "ergaenzend_overused") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rad.errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Nur ein ergänzendes Heilmittel pro Tag erlaubt!"
                );
                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

            if (type == "ergaenzend_alone") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rad.errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Ergänzende Heilmittel dürfen nicht alleine stehen!"
                );
                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

            if (type == "none_alone") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rad.errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Allgemeine Heilmittel dürfen nicht alleine stehen!"
                );
                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

            if (type == "bandage_alone") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rad.errors.messages.push(
                  "Eintrag " +
                    ids.join(", ") +
                    ": Kompressionsbandage nur in Kombination mit MLD 30, 45 oder 60 möglich!"
                );
                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

            if (type == "validity_3_months") {
              var ids = rad.affected_lines[type];

              rad.errors.messages.push(
                "Eintrag " + ids.join(", ") + ": Gültigkeitszeitraum von 3 Monaten überschritten!"
              );

              rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
            }

            if (type == "validity_6_months") {
              var ids = rad.affected_lines[type];

              rad.errors.messages.push(
                "Eintrag " + ids.join(", ") + ": Gültigkeitszeitraum von 6 Monaten überschritten!"
              );

              rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
            }

            if (type == "validity_16_weeks") {
              var ids = rad.affected_lines[type];

              rad.errors.messages.push(
                "Eintrag " + ids.join(", ") + ": Blankoverordnung Gültigkeitszeitraum von 16 Wochen überschritten!"
              );

              rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
            }

            if (type == "discharge_management") {
              var ids = rad.affected_lines[type];

              rad.errors.messages.push(
                "Eintrag " + ids.join(", ") + ": Abschluss muss innerhalb von 12 Tagen nach Rezeptdatum sein (Entlassmanagement)!"
              );

              rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
            }

            if (type == "blanko_28_days_check") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rad.errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Zwischen Diagnostiken müssen mindestens 28 Tage liegen!"
                );
                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

            if (type == "diagnostic_not_first") {
              var ids = rad.affected_lines[type];

              rad.errors.messages.push(
                "Eintrag " + ids + ": Diagnostik muss am ersten Tag erfolgen!"
              );

              rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
            }

            if (type == "analysis_not_first") {
              var ids = rad.affected_lines[type];

              rad.errors.messages.push(
                "Eintrag " + ids + ": Analyse muss am ersten Tag erfolgen!"
              );

              rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
            }

            if (type == "zi_min_max") {
              var err_container = rad.affected_lines[type];
              err_container.forEach(function (item, index) {
                var group = item[0];
                var ids = item[1];
                rad.errors.messages.push(
                  "Gruppe " + group + ": Mindestens 2 und maximal 12 ZI pro Tag erlaubt!"
                );

                rad.errors.positions.datum = rad.errors.positions.datum.concat(ids);
              });
            }

          }
        }

        // Check if no errors were found
        if (rad.checkErrors()) {
          // no errors were found
          // now create proper rezeptdaten object
          var daten = [];
          for (i = 0; i < de.length; i++) {
            if (rad.general.rezeptart_selected.id == "VPP") {
              var entry = {
                line_nr: de[i].id,
                pos_line_nr: de[i].pos_id,
                datum: rad.date_to_str(de[i].datum),

                tarif_bezeichnung: de[i].heilmittel.bezeichnung,
                tarif_nr: de[i].heilmittel.tarif,
                le_typ: rad.general.le_typ,
                tarif_typ: "vpp",
                tarif_region: rad.general.tarif_region,
                verband: rad.general.verband,
                preis: de[i].heilmittel.preis,
                zuzahlung: "0.00",

                unterbrechung: de[i].unterbrechung,
                unterbrechung_grund: de[i].unterbrechung_grund,
                mitarbeiter: de[i].mitarbeiter.id,
              };
            } else {
              var entry = {
                line_nr: de[i].id,
                pos_line_nr: de[i].pos_id,
                datum: rad.date_to_str(de[i].datum),

                tarif_bezeichnung: de[i].heilmittel.bezeichnung,
                tarif_nr: de[i].heilmittel.tarif,
                le_typ: de[i].heilmittel.le_typ,
                tarif_typ: de[i].heilmittel.tarif_typ,
                tarif_region: de[i].heilmittel.tarif_region,
                verband: de[i].heilmittel.verband,
                preis: de[i].heilmittel.preis,
                zuzahlung: de[i].heilmittel.zuzahlung,

                unterbrechung: de[i].unterbrechung,
                unterbrechung_grund: de[i].unterbrechung_grund,
                mitarbeiter: de[i].mitarbeiter.id,
              };
            }

            daten.push(entry);
          }
          rad.rezeptHeader.daten = daten;

          return true;
        } else {
          return false;
        }
      };

      rad.gotoFinalOverview = function () {
        if (rad.checkDatenInput()) {
          if (rad.predated) {
            rad.rezeptHeader.status = "Vordatiert";
          }

          // save data in case user decides to go back to this view
          RememberService.rezeptAddDatesData = {
            rezeptHeader: rad.rezeptHeader,
            patient: rad.patient,
            general: rad.general,
            positions: rad.positions,
            dates: rad.dates,
          };

          // params passed to the next view
          var params = {
            rezeptHeader: rad.rezeptHeader,
            patient: rad.patient,
            general: rad.general,
            positions: rad.positions,
            dates: rad.dates,
          };

          var stateChainEntry = {
            state: $state.current.name,
            params: {},
          };

          var newParams = Object.assign({}, $stateParams, { params: params });
          if (!newParams.stateChain) {
            newParams.stateChain = [];
          }
          newParams.stateChain.push(stateChainEntry);

          $state.go("menu.rezepte-add-summary", newParams);
        }
      };

      rad.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in rad.errors) {
          if (!rad.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }

          if (typeof rad.errors[error] == "object") {
            for (var e in rad.errors[error]) {
              if (!rad.errors[error].hasOwnProperty(e)) {
                //The current error is not a direct property of p
                continue;
              }

              if (rad.errors[error][e].length != 0) {
                return false;
              }
            }
          } else {
            if (rad.errors[error].length != 0) {
              return false;
            }
          }
        }

        // no errors were found
        return true;
      };

      // COLLAPSIBLES

      rad.toggle_collapsible = function (c) {
        var collapse = angular.element(document.querySelector(c));
        collapse[0].style.height = collapse[0].clientHeight ? 0 : collapse[0].scrollHeight + "px";
        if (collapse.hasClass("is-expanded")) {
          collapse.removeClass("is-expanded");
        } else {
          collapse.addClass("is-expanded");
        }
      };

      rad.init();
    },
  ],
});
