angular.module("settings").component("settings", {
  templateUrl: "static/angular_assets/settings/settings.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "store",
    "jwtHelper",
    "$anchorScroll",
    "SettingsService",
    "VollprivatTarifService",
    "Popeye",
    "MahnungPreviewService",
    "FileSaver",
    "$stateParams",
    function SettingsController(
      $scope,
      $rootScope,
      $state,
      store,
      jwtHelper,
      $anchorScroll,
      SettingsService,
      VollprivatTarifService,
      Popeye,
      MahnungPreviewService,
      FileSaver,
      $stateParams
    ) {
      var sett = this;

      sett.init = function () {
        sett.errors = {
          dokumente: {
            kopfzeile: [],
            fusszeile: [],
            privat_info_text: [],
          },
          vollprivat: {
            tarif: [],
            bezeichnung: [],
            preis: [],
            abkuerzung: [],
            zeiteinheit: [],
          },
          zahlungsziel: {
            zzinit: [],
            zzmahnung: [],
          },
          rezepte: {},
        };

        sett.dsgvo_data_waiting = false;
        sett.dsgvo_delete_waiting = false;

        sett.tarif = new VollprivatTarifService();
        sett.tarif.kunde = jwtHelper.decodeToken(store.get("access_token")).user_id;
        sett.tarif.tarif = "";
        sett.tarif.bezeichnung = "";
        sett.tarif.abkuerzung = "";
        sett.tarif.zeiteinheit = "";
        sett.tarif.preis = "";

        sett.settings_data = null;
        sett.vollprivattarife = null;

        sett.bescheinigung_sizes = ["A4", "A5"];
        sett.quittung_sizes = ["A4", "A6"];

        // modal init
        sett.modal = null;
        sett.rememberchoice = false;
        sett.tarif_to_del = null;

        sett.mahnstufen = [
          { id: 1, name: "Stufe 1" },
          { id: 2, name: "Stufe 2" },
          { id: 3, name: "Stufe 3" },
        ];

        sett.mt_stufe = null;
        sett.mt_text = null;
        sett.mt_geb = null;
        sett.mt_bet = null;

        // used for anchor scroll to first error
        sett.error_scroll = null;
        $anchorScroll.yOffset = 200;

        // not ready to display yet
        sett.isready = false;

        sett.doc_visible = false;
        sett.vpt_visible = true;
        sett.zz_visible = false;

        // previously visited state
        sett.fromState = $rootScope.fromState;

        // get settings data
        sett.getSettingsData();

        // get Vollpreis-Tarif data
        sett.getVollprivatTarif();

        // check if ready to be displayed
        sett.checkifready();
      };

      sett.getMahnungPreview = function () {
        var mahnung = new MahnungPreviewService();
        mahnung.betreff = sett.mt_bet;
        mahnung.text = sett.mt_text;
        mahnung.gebuehr = sett.mt_geb;
        mahnung.stufe = sett.mt_stufe.id;

        mahnung.$pdf().then(
          function (data) {
            FileSaver.saveAs(data.response, data.filename);
          },
          function (error) {
            console.log(error);
          }
        );
      };

      sett.storeMahntext = function () {
        var mid = sett.mt_stufe.id;
        sett.settings_data["mahntext_" + mid] = sett.mt_text;
        sett.settings_data["mahngebuehr_" + mid] = sett.mt_geb;
        sett.settings_data["mahnbetreff_" + mid] = sett.mt_bet;

        sett.mt_stufe = sett.mt_stufe_selected;
        var nid = sett.mt_stufe.id;

        sett.mt_text = sett.settings_data["mahntext_" + nid];
        sett.mt_geb = sett.settings_data["mahngebuehr_" + nid];
        sett.mt_bet = sett.settings_data["mahnbetreff_" + nid];
      };

      sett.initFooTable = function () {
        sett.table.footable();
      };

      sett.add_tarif = function () {
        if (sett.checkVollprivatInput()) {
          sett.tarif.$save().then(
            function (success_response) {
              sett.vollprivattarife = null;
              sett.initFooTable();

              // update Tarif-liste
              sett.getVollprivatTarif();
              // reset Vollpreis entry
              sett.tarif = new VollprivatTarifService();
              sett.tarif.kunde = jwtHelper.decodeToken(store.get("access_token")).user_id;
            },
            function (error) {
              console.log(error);
            }
          );
        }
      };

      sett.checkDokumenteInput = function () {
        for (var error in sett.errors.dokumente) {
          sett.errors.dokumente[error] = [];
        }

        if (sett.settings_data.pdf_header_line.length > 100) {
          sett.errors.dokumente.kopfzeile.push("Kopfzeile ist zu lang! Nur 100 Zeichen sind erlaubt!");
        }

        if (sett.settings_data.pdf_footer_line.length > 100) {
          sett.errors.dokumente.fusszeile.push("Fußzeile ist zu lang! Nur 100 Zeichen sind erlaubt!");
        }

        if (sett.settings_data.privat_info_text.length > 200) {
          sett.errors.dokumente.privat_info_text.push("Zusatztext ist zu lang! Nur 200 Zeichen sind erlaubt!");
        }

        return sett.checkErrors("dokumente");
      };

      sett.checkVollprivatInput = function () {
        for (var error in sett.errors.vollprivat) {
          sett.errors.vollprivat[error] = [];
        }

        if (!sett.tarif.tarif) {
          sett.errors.vollprivat.tarif.push("Es wurde keine Tarif-Nr angegeben!");
        } else {
          for (var i = 0; i < sett.vollprivattarife.length; i++) {
            if (sett.vollprivattarife[i].tarif == sett.tarif.tarif) {
              sett.errors.vollprivat.tarif.push("Dieser Tarif existiert bereits!");
              break;
            }
          }

          if (sett.tarif.tarif.indexOf(";") > -1) {
            sett.errors.vollprivat.tarif.push("Semikolons dürfen nicht verwendet werden!");
          }
        }

        if (!sett.tarif.bezeichnung) {
          sett.errors.vollprivat.bezeichnung.push("Es wurde keine Bezeichnung angegeben!");
        } else {
          if (sett.tarif.bezeichnung.indexOf(";") > -1) {
            sett.errors.vollprivat.bezeichnung.push("Semikolons dürfen nicht verwendet werden!");
          }
        }

        if (!sett.tarif.abkuerzung) {
          sett.errors.vollprivat.abkuerzung.push("Es wurde keine Abkürzung angegeben!");
        } else {
          if (sett.tarif.abkuerzung.length > 7) {
            sett.errors.vollprivat.abkuerzung.push("Abkürzung ist zu lang! Maximal 7 Zeichen sind erlaubt!");
          }

          if (sett.tarif.bezeichnung.indexOf(";") > -1) {
            sett.errors.vollprivat.abkuerzung.push("Semikolons dürfen nicht verwendet werden!");
          }
        }

        if (!sett.tarif.zeiteinheit) {
          sett.errors.vollprivat.zeiteinheit.push("Es wurde keine Zeiteinheit angegeben!");
        } else {
          if (sett.tarif.zeiteinheit.indexOf(",") != -1 || sett.tarif.zeiteinheit.indexOf(".") != -1){
            sett.errors.vollprivat.zeiteinheit.push("Nur ganzzahlige Werte sind erlaubt!");
          }
          if (!isNumber(sett.tarif.zeiteinheit)) {
            sett.errors.vollprivat.zeiteinheit.push("Zeiteinheit ist keine gültige Zahl!");
          } else {
            if (parseFloat(sett.tarif.zeiteinheit) < 0) {
              sett.errors.vollprivat.zeiteinheit.push("Zeiteinheit muss eine positive Zahl sein!");
            }
          }
        }

        if (!sett.tarif.preis) {
          sett.errors.vollprivat.preis.push("Es wurde keine Preis angegeben!");
        } else {
          // replace ',' with '.'
          sett.tarif.preis = sett.tarif.preis.replace(",", ".");
          if (!isNumber(sett.tarif.preis)) {
            sett.errors.vollprivat.preis.push("Preis ist keine gültige Zahl!");
          }
          else {
            if (parseFloat(sett.tarif.preis) < 0) {
              sett.errors.vollprivat.preis.push("Preis muss eine positive Zahl sein!");
            }
          }
        }

        return sett.checkErrors("vollprivat");
      };

      sett.checkZahlungszielInput = function () {
        for (var error in sett.errors.zahlungsziel) {
          sett.errors.zahlungsziel[error] = [];
        }

        if (!sett.settings_data.zahlungsziel_init) {
          sett.errors.zahlungsziel.zzinit.push("Initiales Zahlungsziel fehlt!");
        } else {
          if (!isNumber(sett.settings_data.zahlungsziel_init)) {
            sett.errors.zahlungsziel.zzinit.push("Initiales Zahlungsziel ist keine Zahl!");
          }
        }

        if (!sett.settings_data.zahlungsziel_mahnung) {
          sett.errors.zahlungsziel.zzmahnung.push("Mahnungs Zahlungsziel fehlt!");
        } else {
          if (!isNumber(sett.settings_data.zahlungsziel_mahnung)) {
            sett.errors.zahlungsziel.zzmahnung.push("Mahnungs Zahlungsziel ist keine Zahl!");
          }
        }

        return sett.checkErrors("zahlungsziel");
      };

      sett.editVollprivattarif = function (vpt_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { vptId: vpt_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.vollprivattarife-edit", newParams);
      };

      sett.getSettingsData = function () {
        SettingsService.get()
          .$promise.then(function (data) {
            sett.settings_data = data;

            sett.mt_stufe = sett.mahnstufen[0];
            sett.mt_stufe_selected = sett.mahnstufen[0];
            sett.mt_text = data.mahntext_1;
            sett.mt_geb = data.mahngebuehr_1;
            sett.mt_bet = data.mahnbetreff_1;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      sett.getVollprivatTarif = function () {
        VollprivatTarifService.query()
          .$promise.then(function (data) {
            sett.vollprivattarife = data;
            sett.vollprivattarif_cnt = sett.vollprivattarife.length;
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      sett.checkifready = function () {
        if (!sett.settings_data | !sett.vollprivattarife) {
          setTimeout(sett.checkifready, 50);
        } else {
          sett.isready = true;
          $scope.$apply();

          if (sett.fromState == "menu.vollprivattarife-edit") {
            sett.change_tab("vpt_tab");
          }
        }
      };

      sett.saveDokumenteSettings = function () {
        if (sett.checkDokumenteInput()) {
          sett.dokumente_changes_success = false;

          data = {
            pdf_header_line: sett.settings_data.pdf_header_line,
            pdf_footer_line: sett.settings_data.pdf_footer_line,
            bescheinigung_pdf: sett.settings_data.bescheinigung_pdf,
            quittung_pdf: sett.settings_data.quittung_pdf,
            privat_info_text: sett.settings_data.privat_info_text,
          };

          SettingsService.update({}, data).$promise.then(
            function (success_response) {
              sett.dokumente_changes_success = true;
              sett.settings_data = success_response;
            },
            function (error) {
              console.log(error);
            }
          );
        }
      };

      sett.saveZahlungszielSettings = function () {
        // save Mahn data before checking and pushing
        sett.storeMahntext();

        if (sett.checkZahlungszielInput()) {
          sett.zahlungsziel_changes_success = false;

          data = {
            mahnungen: sett.settings_data.mahnungen,
            zahlungsziel_init: sett.settings_data.zahlungsziel_init,
            zahlungsziel_mahnung: sett.settings_data.zahlungsziel_mahnung,

            mahnbetreff_1: sett.settings_data.mahnbetreff_1,
            mahntext_1: sett.settings_data.mahntext_1,
            mahngebuehr_1: sett.settings_data.mahngebuehr_1,

            mahnbetreff_2: sett.settings_data.mahnbetreff_2,
            mahntext_2: sett.settings_data.mahntext_2,
            mahngebuehr_2: sett.settings_data.mahngebuehr_2,

            mahnbetreff_3: sett.settings_data.mahnbetreff_3,
            mahntext_3: sett.settings_data.mahntext_3,
            mahngebuehr_3: sett.settings_data.mahngebuehr_3,
          };

          SettingsService.update({}, data).$promise.then(
            function (success_response) {
              sett.zahlungsziel_changes_success = true;
              sett.settings_data = success_response;
            },
            function (error) {
              console.log(error);
            }
          );
        }
      };

      sett.saveRezepteSettings = function () {
        sett.rezepte_changes_success = false;

        var data = {
          date_suggestion: sett.settings_data.date_suggestion,
          frequency_check: sett.settings_data.frequency_check,
        };

        SettingsService.update({}, data).$promise.then(
          function (success_response) {
            sett.rezepte_changes_success = true;
            sett.settings_data = success_response;
          },
          function (error) {
            console.log(error);
          }
        );
      };

      sett.saveDsgvoData = function () {
        var data = {
          dsgvo_data: true,
        };

        sett.dsgvo_data_waiting = true;

        SettingsService.update({}, data).$promise.then(
          function (success_response) {
            sett.settings_data = success_response;
            sett.dsgvo_data_waiting = false;
          },
          function (error) {
            console.log(error);
          }
        );
      };

      sett.saveDsgvoDelete = function () {
        var data = {
          dsgvo_delete: true,
        };

        sett.dsgvo_delete_waiting = true;

        SettingsService.update({}, data).$promise.then(
          function (success_response) {
            sett.settings_data = success_response;
            sett.dsgvo_delete_waiting = false;
          },
          function (error) {
            console.log(error);
          }
        );
      };

      sett.checkErrors = function (error_dict) {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in sett.errors[error_dict]) {
          if (!sett.errors[error_dict].hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (sett.errors[error_dict][error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      sett.change_tab = function (tab_id) {
        // remove is active classes
        var doc_tab = angular.element(document.querySelector("#doc_tab"));
        sett.doc_visible = false;

        var vpt_tab = angular.element(document.querySelector("#vpt_tab"));
        sett.vpt_visible = false;

        var zz_tab = angular.element(document.querySelector("#zz_tab"));
        sett.zz_visible = false;

        var rez_tab = angular.element(document.querySelector("#rez_tab"));
        sett.rez_visible = false;

        var dsgvo_tab = angular.element(document.querySelector("#dsgvo_tab"));
        sett.dsgvo_visible = false;

        doc_tab.removeClass("is-active");
        rez_tab.removeClass("is-active");
        vpt_tab.removeClass("is-active");
        zz_tab.removeClass("is-active");
        dsgvo_tab.removeClass("is-active");

        if (tab_id == "doc_tab") {
          doc_tab.addClass("is-active");
          sett.doc_visible = true;
        } else if (tab_id == "vpt_tab") {
          vpt_tab.addClass("is-active");
          sett.vpt_visible = true;
        } else if (tab_id == "zz_tab") {
          zz_tab.addClass("is-active");
          sett.zz_visible = true;
        } else if (tab_id == "rez_tab") {
          rez_tab.addClass("is-active");
          sett.rez_visible = true;
        } else if (tab_id == "dsgvo_tab") {
          dsgvo_tab.addClass("is-active");
          sett.dsgvo_visible = true;
        }
      };

      sett.opendeleteModal = function (tarif_id) {
        if (sett.rememberchoice) {
          sett.deleteTarif(tarif_id);
        } else {
          sett.tarif_to_del = tarif_id;
          sett.modal = Popeye.openModal({
            templateUrl: "static/angular_assets/settings/modals/delete_modal.template.html",
            scope: $scope,
            modalClass: "normal-modal-container",
          });
        }
      };

      sett.closeModal = function () {
        sett.modal.close();
        sett.tarif_to_del = null;
      };

      sett.openTarifInUseModal = function (tarif_id) {
        sett.tarif_to_del = sett.vollprivattarife.find((o) => o.id === tarif_id);

        sett.modal = Popeye.openModal({
          templateUrl: "static/angular_assets/settings/modals/tarif_in_use_modal.template.html",
          scope: $scope,
          click: false,
          keyboard: false,
          modalClass: "extended-modal-container no_close",
        });
      };

      sett.deleteTarif = function (tarif_id) {
        VollprivatTarifService.delete({
          id: tarif_id,
        })
          .$promise.then(function (data) {
            // show changed available Tarife
            sett.tarif_to_del = null;
            sett.vollprivattarife = null;
            sett.initFooTable();

            sett.getVollprivatTarif();
            sett.modal.close();
          })
          .catch(function (error) {
            if (error.data.message) {
              if (error.data.message == "Object in use") {
                sett.modal.close();
                sett.openTarifInUseModal(tarif_id);
              }
            } else {
              console.log(error);
            }
          });
      };

      isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      sett.init();
    },
  ],
});
