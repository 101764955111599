angular.module("mkav03App", [
  "templates",
  "ui.router",
  "ngAnimate",
  "ngResource",
  "angular-storage",
  "angular-jwt",
  "ngCookies",
  "ui.select",
  "angular-loaders",
  "ngSanitize",
  "ngTagsInput",
  "ngFileSaver",
  "pathgather.popeye",
  "zxcvbn",
  "switcher",
  "core",
  "login",
  "register",
  "menu",
  "mainNav",
  "profile",
  "aerzteOverview",
  "aerzteEdit",
  "aerzteView",
  "aerzteAdd",
  "mitarbeiterOverview",
  "mitarbeiterAdd",
  "mitarbeiterView",
  "mitarbeiterEdit",
  "patientenOverview",
  "patientenAdd",
  "patientenView",
  "patientenEdit",
  "rezepteNav",
  "rezepteArchive",
  "termineRezepte",
  "rezepteOverviewNav",
  "rezepteOverviewFilter",
  "rezepteOverviewView",
  "rezepteOverviewRezept",
  "rezepteEdit",
  "offenepostenOverview",
  "offenepostenEdit",
  "kassenbuchOverview",
  "rezepteAddHeader",
  "rezepteAddLines",
  "rezepteAddDates",
  "rezepteAddSummary",
  "rezepteAbrechnen",
  "adminNav",
  "angularFileUpload",
  "abrechnungDownload",
  "abrechnungUpload",
  "resetTestaccount",
  "nachrichtenOverview",
  "nachrichtenView",
  "settings",
  "ui.calendar",
  "vollprivattarifeEdit",
  "colorpicker",
  "statistics",
  "adminMessages",
  "angularTrix",
  "datatables",
]);
