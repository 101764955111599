angular.module("rezepteEdit").component("rezepteEdit", {
  templateUrl: "static/angular_assets/rezepte/rezepte_overview/edit/rezepte_edit.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "store",
    "jwtHelper",
    "$state",
    "$anchorScroll",
    "$timeout",
    "RezepteHeaderService",
    "RezepteDatenService",
    "RezeptePositionenService",
    "AerzteService",
    "PatientenService",
    "HeilmittelService",
    "HeilmittelOldService",
    "PreislistenCheckService",
    "VollprivatTarifService",
    "SettingsService",
    "BefreiungenService",
    "StoreService",
    "DiagnoseService",
    "DiagnoseTarifService",
    "Popeye",
    "$window",
    "$stateParams",
    function RezepteEditController(
      $scope,
      $rootScope,
      store,
      jwtHelper,
      $state,
      $anchorScroll,
      $timeout,
      RezepteHeaderService,
      RezepteDatenService,
      RezeptePositionenService,
      AerzteService,
      PatientenService,
      HeilmittelService,
      HeilmittelOldService,
      PreislistenCheckService,
      VollprivatTarifService,
      SettingsService,
      BefreiungenService,
      StoreService,
      DiagnoseService,
      DiagnoseTarifService,
      Popeye,
      $window,
      $stateParams
    ) {
      var rove = this;

      rove.regexp_text = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+']/g;
      rove.regexp_text_num = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+'0-9]/g;
      rove.regexp_text_all = /[^A-Za-zßöÖäÄüÜéÉèÈ.,:\\\*\-_#@+§&%$€~=| <>°'!?\/()\[\]\{\}\"0-9\n]/g;
      rove.regexp_diagnose = /[^A-Za-zßöÖäÄüÜéÉèÈ.\*\-_#@§&%$€~=| <>!\/()\[\]\{\}0-9\n]/g;

      get_unique = function (arr) {
        return arr
          ? arr.reduce(function (a, b) {
            if (a.indexOf(b) < 0) a.push(b);
            return a;
          }, [])
          : null;
      };

      // convert icd codes to string seperated by ;
      rove.icd_to_str = function (icd) {
        icd_str = "";
        for (i = 0; i < icd.length; i++) {
          icd_str += icd[i].id + ";";
        }
        return icd_str;
      };

      // covert icd string to seperate icd codes
      rove.str_to_icd = function (str) {
        str_arr = str.split(";").filter(Boolean);
        icd_arr = [];
        icd_arr = rove.general.icd10_data.filter(function (icd) {
          return str_arr.includes(icd.id);
        });

        return icd_arr;
      };

      rove.loadItems = function (query) {
        query = query.toUpperCase();

        var matched = rove.general.icd10_data.filter(function (code) {
          return code.id.indexOf(query) != -1;
        });

        return matched;
      };

      rove.dateDiffInDays = function (a, b) {
        // Discard the time and time-zone information.
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      };

      rove.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      rove.updateDoubleTreatmentLookup = function () {
        var seen = [];
        rove.doubleTreatmentLookup = [];

        // Double treatment is only relevant for Physio and Ergo
        if (rove.onlyPhysioErgo()) {

          for (var i = 0; i < rove.positions.length; i++) {
            var pos = rove.positions[i];
  
            if (seen.includes(pos.heilmittel.tarif)) {
              rove.doubleTreatmentLookup.push(pos.heilmittel.tarif);
            } else {
              seen.push(pos.heilmittel.tarif);
            }
          }

        }
      }



      rove.nextStep = function () {
        // show next edit step on screen/step navigation
        if (rove.curr_step == "header" && rove.checkHeaderInput()) {
          // update stepper
          var step_curr = angular.element(document.querySelector("#header-step"));
          var step_next = angular.element(document.querySelector("#positions-step"));
          step_curr.removeClass("is-active");
          step_next.addClass("is-active");

          rove.curr_step = "positions";

          // automatically try to fix errors that were created by newly made changes
          rove.updatePosition();
        } else if (rove.curr_step == "positions" && rove.checkPositionenInput()) {
          
          // Update/Overwrite initial positions data so that it can be used to check for changes
          rove.updateInitPositions();

          // Iterate over all positions and store the information about double treatments in the lookup
          rove.updateDoubleTreatmentLookup();

          // Update UI stepper information
          var step_curr = angular.element(document.querySelector("#positions-step"));
          var step_next = angular.element(document.querySelector("#dates-step"));
          step_curr.removeClass("is-active");
          step_next.addClass("is-active");

          rove.curr_step = "dates";

          // automatically try to fix errors that were created by newly made changes
          rove.updateDaten();
        }
      };

      rove.prevStep = function () {
        // show previous edit step/step navigation
        if (rove.curr_step == "dates") {
          // update stepper
          var step_curr = angular.element(document.querySelector("#dates-step"));
          var step_next = angular.element(document.querySelector("#positions-step"));
          step_curr.removeClass("is-active");
          step_next.addClass("is-active");

          rove.curr_step = "positions";
        } else if (rove.curr_step == "positions") {
          // update stepper
          var step_curr = angular.element(document.querySelector("#positions-step"));
          var step_next = angular.element(document.querySelector("#header-step"));
          step_curr.removeClass("is-active");
          step_next.addClass("is-active");

          rove.curr_step = "header";
        }
      };

      rove.getBefreiungenData = function () {
        BefreiungenService.query({ patient: rove.patient.id })
          .$promise.then(function (data) {
            rove.general.befreiungen = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rove.check_zulassungsbefreiung = function () {
        rove.general.aktive_befreiung = null;

        if (rove.general.rezeptdatum) {
          var rzd = moment(rove.general.rezeptdatum);

          if (rzd.isValid()) {
            for (var i = 0; i < rove.general.befreiungen.length; i++) {
              var b = rove.general.befreiungen[i];
              var zzv = moment(b.befreit_von);
              var zzb = moment(b.befreit_bis);

              if (rzd.isSameOrAfter(zzv) && rzd.isSameOrBefore(zzb)) {
                rove.general.aktive_befreiung =
                  "Aktive Zulassungsbefreiung: " + zzv.format("DD.MM.YYYY") + " - " + zzb.format("DD.MM.YYYY");

                break;
              }
            }
          }
        }
      };

      // ! ##########################################################
      // ! ARZT MODAL RELATED DATA ##################################

      rove.init_arzt_modal_data = function () {
        // new arzt modal
        rove.arzt_modal = null;

        rove.am_data = {};
        rove.am_data.errors = {
          arzt_num: [],
          betriebs_num: [],
          arzt_name: [],
          strasse: [],
          plz: [],
          ort: [],
        };

        rove.am_data.arzt = new AerzteService();

        rove.am_data.arzt.kunde = rove.rezeptHeader.kunde;
        rove.am_data.arzt.arzt_num = "";
        rove.am_data.arzt.betriebs_num = "";
        rove.am_data.arzt.name = "";
        rove.am_data.arzt.strasse = "";
        rove.am_data.arzt.plz = "";
        rove.am_data.arzt.ort = "";
        rove.am_data.arzt.telefon = "";
        rove.am_data.arzt.fax = "";
      };

      rove.checkArztErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in rove.am_data.errors) {
          if (!rove.am_data.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (rove.am_data.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      rove.checkArztInput = function () {
        // reset current errors
        for (var error in rove.am_data.errors) {
          rove.am_data.errors[error] = [];
        }

        // reset anchor scroll position
        rove.am_data.error_scroll = null;

        // arzt_num
        if (!rove.am_data.arzt.arzt_num) {
          rove.am_data.errors.arzt_num.push("Es wurde keine Arzt-Nummer angegeben!");
        } else {
          if (!rove.isNumber(rove.am_data.arzt.arzt_num)) {
            rove.am_data.errors.arzt_num.push("Eingabe ist keine gültige Nummer!");
          }

          if (rove.am_data.arzt.arzt_num.length != 9) {
            rove.am_data.errors.arzt_num.push("Arzt-Nummer muss eine 9-stellige Nummer sein!");
          }
        }

        // betriebs_num
        if (!rove.am_data.arzt.betriebs_num) {
          rove.am_data.errors.betriebs_num.push("Es wurde keine Betriebsnummer angegeben!");
        } else {
          if (!rove.isNumber(rove.am_data.arzt.betriebs_num)) {
            rove.am_data.errors.betriebs_num.push("Eingabe ist keine gültige Nummer!");
          }

          if (rove.am_data.arzt.betriebs_num.length != 9) {
            rove.am_data.errors.betriebs_num.push("Betriebsnummer muss eine 9-stellige Nummer sein!");
          }
          if (rove.am_data.arzt.betriebs_num == "999999999") {
            rove.am_data.errors.betriebs_num.push("Betriebsnummer 999999999 darf nicht verwendet werden!");
          }
        }

        // name
        if (!rove.am_data.arzt.name) {
          rove.am_data.errors.arzt_name.push("Es wurde kein Name angegeben!");
        } else {
          var matches = get_unique(rove.am_data.arzt.name.match(rove.regexp_text));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            rove.am_data.errors.arzt_name.push(err_msg);
          }
          if (rove.am_data.arzt.name.length > 90) {
            rove.am_data.errors.arzt_name.push("Name des Arztes ist zu lang! (> 90 Zeichen)");
          }
        }

        // strasse
        if (rove.am_data.arzt.strasse) {
          var matches = get_unique(rove.am_data.arzt.strasse.match(rove.regexp_text_num));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            rove.am_data.errors.strasse.push(err_msg);
          }
        }

        // plz
        if (rove.am_data.arzt.plz && rove.am_data.arzt.plz.length > 0) {
          //rove.am_data.errors.plz.push("Es wurde keine PLZ angegeben!");
          //rove.set_error_scroll('plz_loc');
          if (!rove.isNumber(rove.am_data.arzt.plz)) {
            rove.am_data.errors.plz.push("Ungültige PLZ! (nur Zahlen sind erlaubt)");
          } else if (rove.am_data.arzt.plz.length != 5) {
            rove.am_data.errors.plz.push("PLZ ist nicht 5 Stellen lang!");
          }
        }

        // ort
        if (rove.am_data.arzt.ort) {
          var matches = get_unique(rove.am_data.arzt.ort.match(rove.regexp_text_num));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            rove.am_data.errors.ort.push(err_msg);
          }
        }

        return rove.checkArztErrors();
      };

      rove.saveArzt = function () {
        if (rove.checkArztInput()) {
          rove.am_data.arzt.$save().then(
            function (success_response) {
              // set newly created arzt_num
              rove.general.arzt_num = rove.am_data.arzt.arzt_num;

              // check num
              rove.check_arzt_num();

              rove.header_changed = true;

              // delete data and close modal
              rove.closeArztModal();
            },
            function (error_response) {
              if (error_response["data"]["non_field_errors"][0] == "ArztExists") {
                rove.am_data.errors.arzt_num.push("Arzt mit dieser Arztnummer exisitert bereits!");
              }
            }
          );
        }
      };

      rove.openNewArztModal = function (information) {
        if (rove.general.arzt_num) {
          rove.am_data.arzt.arzt_num = rove.general.arzt_num;
        }

        rove.arzt_modal = Popeye.openModal({
          templateUrl: "static/angular_assets/rezepte/rezepte_overview/edit/modals/arzt_add_modal.html",
          scope: $scope,
          modalClass: "extended-modal-container",
          click: false,
          keyboard: true,
        });
      };

      rove.closeArztModal = function () {
        rove.arzt_modal.close();
        rove.arzt_modal = null;
        rove.init_arzt_modal_data();
      };

      // ! ##########################################################

      rove.init = function () {
        // ? why is this used here
        // ? replace with: token_data = jwtHelper.decodeToken(store.get('access_token'))
        // token data
        token = store.get("access_token");
        if (token) {
          token_data = jwtHelper.decodeToken(token);
        } else {
          $window.sessionStorage.clear();
          $state.go("login");
        }

        // get provided parameters
        rove.header_id = $stateParams.headerId;

        // if no parameters were provided return to main menu
        if (!rove.header_id) {
          $state.go("menu.nav");
        }

        // previously visited state
        rove.prevState = $rootScope.fromState;

        // information modal
        rove.modal = null;

        rove.awaiting_response = false;
        rove.saving = false;

        // first run flag
        rove.first_run = true;


        rove.tarifWithLockedMenge = [
          { tarif: "19701", menge: "1" },
          { tarif: "29701", menge: "1" },
          { tarif: "59701", menge: "1" },
          { tarif: "89701", menge: "1" },
          { tarif: "33010", menge: "1" },
          { tarif: "33011", menge: "1" },
          { tarif: "54002", menge: "1" },
          { tarif: "12.1", menge: "1" },
          { tarif: "11906", menge: "1" },
          { tarif: "21906", menge: "1" },
          { tarif: "81906", menge: "1" },
          { tarif: "3.1", menge: "1" },
          { tarif: "20522", menge: "1" },
          { tarif: "20524", menge: "1" },
          { tarif: "54003", menge: "1" },
          { tarif: "54503", menge: "1" },
          { tarif: "20523", menge: "1" },
          { tarif: "20553", menge: "1" },
          { tarif: "20557", menge: "1"},
          { tarif: "59741", menge: "1"},
        ];


        // objects to hold error data
        rove.header_errors = {
          gebuehrenpflichtig: [],
          arzt: [],
          rezeptdatum: [],
          erfassungsdatum: [],
          hausbesuch: [],
          therapiebericht: [],
          genehmigungsnummer: [],
          genehmigungsdatum: [],
          icd10: [],
          privat_info_text: [],
          diagnose: [],
          ls: [],
          ls_text: [],
          diagnosetext: [],
          frequenz: [],
          physiotherapiebeginn: [],
          behandlungsbedarf: [],
        };

        rove.tarif_lkp = [
          "19701",
          "29701",
          "59701",
          "89701",
          "33010",
          "33011",
          "54002",
          "12.1",
          "11906",
          "21906",
          "81906",
          "3.1",
          "20553",
        ];

        rove.error_checking_flag = false;

        rove.header_err_msg = "";
        rove.position_err_msg = "";

        rove.position_errors = {
          positions: [],
          messages: [],
        };

        rove.date_errors = {
          positions: [],
          messages: [],
        };

        // error message that shows which rezept parts need to be rechecked
        rove.edit_err_msg = "";
        // ********************************* //

        // tracks whether something was set to edit mode
        // possible values = header, positions, dates
        rove.curr_step = "header";

        rove.header_changed = false;
        rove.changes_needed = false;

        rove.positionsChanged = false;
        rove.positionsChanges = {
          countsChanges: [],
          heilmittelChanges: [],
        };

        // tracks whether fields need to be fixed/rechecked for possible changes
        rove.modified_fields = [];

        // issue flags
        // this flag is set when data needs to be fixed/rechecked for possible changes
        rove.position_issue_flag = false;
        rove.date_issue_flag = false;

        // error flags
        // used to lock positions/data from being edited if there are errors in the data above
        rove.header_err_flag = false;
        rove.position_err_flag = false;

        // fix applied
        // this flag is set when data was automatically fixed (successful or not)
        rove.position_fix_flag = false;
        rove.date_fix_flag = false;

        // position loading flag
        rove.diagnose_data_loaded = true;

        // init values of fields - any changes to these fields will trigger the need to change/update date and positionen
        rove.init_vals = {
          rezeptdatum: null,
          erfassungsdatum: null,
          hausbesuch: null,
          therapiebericht: null,
          diagnose: null,
          locked: null,
          langf: null,
          besond: null,
          frequenz: null,
          genehmigung: null,
          genehmigungsnummer: null,
          genehmigungsart: null,
          genehmigungsdatum: null,
          blanko: null,
        };

        // used for anchor scroll to first error
        rove.error_scroll = null;
        $anchorScroll.yOffset = 200;

        // needed for access to date forms
        $scope.forms = {};

        // general object that holds data related to patient/rezeptHeader
        rove.general = {
          arzt_exists: null,

          // icd10 data
          icd10_data: null,

          // genehmigung
          genehmigung: false,
          genehmigungsnummer: null,
          genehmigungsart: null,
          genehmigungsdatum: null,

          // settings
          settings_data: null,

          // VPP needed data
          le_typ: token_data.le_typ,
          verband: token_data.verband,
          kt_region: token_data.kt_region,
          tarif_region: token_data.tarif_region,

          // locked status
          locked: null,

          // befreiungen
          aktive_befreiung: null,
          befreiungen: null,

          // heilmittelbereich
          heilmittelbereich: null,

          // frequenz
          frequenz: null,

          // blanko
          blankoDisabled: true,
          blankoLightCyclePhysio: false,
          blankoLightCyclePhysioVorrangig: 0,
          blankoLightCyclePhysioErgaenzend: 0,
          blankoLightCycleErgo: false,
          blankoLightCycleErgoGreenLimit: 0,
          blankoLightCycleErgoYellowLimit: 0,
        };

        // get ICD-10 Codes
        rove.getICD10Data();

        // get Settings data
        rove.getSettingsData();

        // object that holds information whether a given input field is irrelevant and or disabled
        rove.is_irrelevant = {};
        rove.is_disabled = {};

        // general data that needs to be set on data load
        rove.general.rezeptarten = null;
        rove.general.rezeptart_selected = null;

        // heilmittelbereich
        rove.general.heilmittelbereich = StoreService.get("kunde").heilmittelbereich;

        //diagnose
        rove.general.diagnose = null;
        rove.general.diagnose_key = null;
        rove.general.diagnose_exists = null;

        rove.isready = false;
        rove.rezeptHeader = null;
        rove.rezeptPositionen = null;
        rove.rezeptDaten = null;
        rove.patient = null;

        // heilmittel object
        rove.heilmittel = null;


        // blanko light cycle type/title/text
        rove.blanko = {
          type: "green", // green, yellow or red
          title: "",
          text: "",
        };

        // mitarbeiter object
        rove.mitarbeiter = null;

        // get related mitarbeiter data first
        rove.getMitarbeiterData();

        // positions object
        rove.init_positions = [];
        rove.positions = [];

        // Double treatment lookup
        rove.doubleTreatmentLookup = [];

        // dates object
        rove.dates = {
          group_lkp: {},
          original_entries: [],
          daten_entries: [],
          position_cnts: [],
          vorrangig_cnts: [],
          mehrfach_cnts: [],
        };

        rove.isBlankoInit = true;

        rove.getRezeptHeaderData(rove.header_id); //loads header + patient + arzt
        rove.getRezeptDatenData(rove.header_id);
        rove.getRezeptPositionenData(rove.header_id);

        rove.checkifready();
      };

      rove.openInformationModal = function (information) {
        // reset
        rove.information = information;
        rove.modal = Popeye.openModal({
          templateUrl: "static/angular_assets/rezepte/rezepte_overview/edit/modals/information_modal.template.html",
          scope: $scope,
          modalClass: "no_close extended-modal-container",
          click: false,
          keyboard: false,
        });
      };

      rove.closeModal = function () {
        rove.modal.close();
        rove.awaiting_response = false;
        rove.returnToPreviousState();
      };

      // ##########################################################
      // ##########################################################

      //functions to sort data (for tables) based on multiple attributes/properties
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      rove.dynamicSortMultiple = function () {
        var props = arguments;
        return function (obj1, obj2) {
          var i = 0,
            result = 0,
            numberOfProperties = props.length;
          /* try getting a different result from 0 (equal)
           * as long as we have extra properties to compare
           */
          while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
          }
          return result;
        };
      };

      rove.set_rezeptart_defaults = function (kt_typ) {
        if (kt_typ == "KK") {
          rove.general.rezeptarten = [
            {
              id: "KK",
              bezeichnung: "Krankenkasse",
            },
            {
              id: "ZA",
              bezeichnung: "Zahnarzt",
            },
            {
              id: "KR",
              bezeichnung: "Kur",
            },
          ];
        } else if (kt_typ == "PP") {
          rove.general.rezeptarten = [
            {
              id: "PP",
              bezeichnung: "Privat",
            },
            {
              id: "VPP",
              bezeichnung: "Vollprivat",
            },
          ];
        } else if (kt_typ == "BG") {
          rove.general.rezeptarten = [
            {
              id: "BG",
              bezeichnung: "Berufsgenossenschaft",
            },
          ];
        } else if (kt_typ == "S") {
          rove.general.rezeptarten = [
            {
              id: "KK",
              bezeichnung: "Krankenkasse",
            },
            {
              id: "BW",
              bezeichnung: "Bundeswehr",
            },
            {
              id: "ZA",
              bezeichnung: "Zahnarzt",
            },
            {
              id: "KR",
              bezeichnung: "Kur",
            },
          ];
        } else if (kt_typ == "PBeaKK") {
          rove.general.rezeptarten = [
            {
              id: "KK",
              bezeichnung: "Krankenkasse",
            },
            {
              id: "ZA",
              bezeichnung: "Zahnarzt",
            },
            {
              id: "KR",
              bezeichnung: "Kur",
            },
          ];
        }

        // set default value
        if (rove.rezeptHeader.rezeptart) {
          rove.general.rezeptart_selected = rove.general.rezeptarten.filter(function (x) {
            return x.id === rove.rezeptHeader.rezeptart;
          })[0];
        } else {
          rove.general.rezeptart_selected = rove.general.rezeptarten[0];
        }

        rove.setRules();
      };

      rove.setRules = function () {
        var rezeptart = rove.general.rezeptart_selected.id;
        var kttyp = rove.patient.kt_typ;

        // set rezeptart for header
        rove.rezeptHeader.rezeptart = rezeptart;

        // reset current errors first
        for (var error in rove.header_errors) {
          rove.header_errors[error] = [];
        }
        // remove icd10 red error-border
        try {
          angular
            .element(document.querySelector("#icd10_loc").querySelector("div > div"))
            .removeClass("is-error-border");
        } catch (error) { }

        if (rezeptart == "KK") {
          rove.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: false,
            diagnose: true,
          };

          rove.general.is_irrelevant = {
            rezeptart: false,
            gebuehrenpflichtig: false,
            kk_info: false,
            vers_nummer_info: false,
            vers_status_info: false,
            unfalltag_info: true,
            unfall: false,
            bvg: false,
            therapiebericht: false,
            icdcodes: false,
            genehmigung: false,
            arzt: false,
            privat_zusatztext: true,
            hausbesuch: false,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: false,
            physiotherapiebeginn: true,
            discharge_management: false,
            blanko: true,
          };

          // for PBeaKK disable gebuehrenpflichtig and set it to "Nein"
          if (kttyp == "PBeaKK") {
            rove.general.is_disabled.gebuehrenpflichtig = true;
            rove.general.is_irrelevant.gebuehrenpflichtig = true;
            rove.rezeptHeader.gebuehrenpflichtig = "Nein";
            rove.general.is_irrelevant.genehmigung = true;
            rove.general.is_irrelevant.icdcodes = true;
          } else if (kttyp == "KK") {
            rove.general.is_irrelevant.genehmigung = false;
          } else if (kttyp == "S") {
            rove.general.is_irrelevant.genehmigung = true;
            rove.general.is_irrelevant.icdcodes = true;
          }

          rove.general.is_disabled.diagnose = false;
          rove.general.is_irrelevant.diagnose = false;
          rove.general.is_irrelevant.behandlungsbedarf = false;

          if (["22", "26"].includes(rove.general.le_typ)) {
            rove.general.is_irrelevant.blanko = false;
          }

          rove.first_run = false;
        } else if (rezeptart == "PP") {
          rove.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: true,
            diagnose: true,
          };

          rove.general.is_irrelevant = {
            rezeptart: false,
            gebuehrenpflichtig: true,
            kk_info: true,
            vers_nummer_info: true,
            vers_status_info: true,
            unfalltag_info: true,
            unfall: true,
            bvg: true,
            therapiebericht: true,
            icdcodes: true,
            genehmigung: true,
            arzt: true,
            privat_zusatztext: false,
            hausbesuch: false,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: true,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          rove.general.arzt_num = "999999999";
          rove.check_arzt_num();
          rove.general.diagnose_key = "PPPP";
          rove.check_diagnose();
        } else if (rezeptart == "VPP") {
          rove.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: true,
            diagnose: true,
          };

          rove.general.is_irrelevant = {
            rezeptart: false,
            gebuehrenpflichtig: true,
            kk_info: true,
            vers_nummer_info: true,
            vers_status_info: true,
            unfalltag_info: true,
            unfall: true,
            bvg: true,
            therapiebericht: true,
            icdcodes: true,
            genehmigung: true,
            arzt: true,
            privat_zusatztext: false,
            hausbesuch: false,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: true,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          // set unchangeable defaults
          rove.rezeptHeader.gebuehrenpflichtig = "Nein";
          rove.rezeptHeader.unfall = null;
          rove.rezeptHeader.bvg = null;
          rove.rezeptHeader.therapiebericht = null;

          rove.general.arzt_num = "999999999";
          rove.check_arzt_num();
          rove.general.diagnose_key = "PPPP";
          rove.check_diagnose();
        } else if (rezeptart == "BG") {
          rove.general.is_disabled = {
            rezeptart: true,
            gebuehrenpflichtig: true,
            diagnose: true,
          };

          rove.general.is_irrelevant = {
            rezeptart: true,
            gebuehrenpflichtig: true,
            kk_info: false,
            vers_nummer_info: true,
            vers_status_info: true,
            unfalltag_info: false,
            unfall: true,
            bvg: true,
            therapiebericht: true,
            icdcodes: true,
            genehmigung: true,
            arzt: true,
            privat_zusatztext: true,
            hausbesuch: false,
            diagnose: true,
            behandlungsbedarf: false,
            frequenz: false,
            physiotherapiebeginn: false,
            discharge_management: true,
            blanko: true,
          };

          rove.rezeptHeader.gebuehrenpflichtig = "Nein";
          rove.rezeptHeader.unfall = null;
          rove.rezeptHeader.bvg = null;
          rove.rezeptHeader.therapiebericht = null;

          rove.general.arzt_num = "999999999";
          rove.check_arzt_num();
          rove.general.diagnose_key = "BGBG";
          rove.check_diagnose();
        } else if (rezeptart == "BW") {
          rove.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: true,
            diagnose: true,
          };

          rove.general.is_irrelevant = {
            rezeptart: false,
            gebuehrenpflichtig: true,
            kk_info: false,
            vers_nummer_info: false,
            vers_status_info: false,
            unfalltag_info: true,
            unfall: false,
            bvg: false,
            therapiebericht: false,
            icdcodes: true,
            genehmigung: true,
            arzt: true,
            privat_zusatztext: true,
            hausbesuch: false,
            diagnose: true,
            behandlungsbedarf: false,
            frequenz: false,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          rove.general.arzt_num = "999999999";
          rove.check_arzt_num();
          rove.general.diagnose_key = "BWBW";
          rove.check_diagnose();
        } else if (rezeptart == "KR") {
          rove.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: false,
            diagnose: true,
          };

          rove.general.is_irrelevant = {
            rezeptart: false,
            gebuehrenpflichtig: false,
            kk_info: false,
            vers_nummer_info: false,
            vers_status_info: false,
            unfalltag_info: true,
            unfall: false,
            bvg: false,
            therapiebericht: false,
            icdcodes: true,
            genehmigung: true,
            arzt: false,
            privat_zusatztext: true,
            hausbesuch: false,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: false,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          if (kttyp == "PBeaKK") {
            rove.general.is_disabled.gebuehrenpflichtig = true;
            rove.general.is_irrelevant.gebuehrenpflichtig = true;
            rove.rezeptHeader.gebuehrenpflichtig = "Nein";
            rove.general.is_irrelevant.genehmigung = true;
            rove.general.is_irrelevant.icdcodes = true;
          }

          rove.general.is_irrelevant.behandlungsbedarf = false;
          rove.general.diagnose_key = "KURE";
          rove.check_diagnose();
        } else if (rezeptart == "ZA") {
          rove.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: false,
            diagnose: true,
          };

          rove.general.is_irrelevant = {
            rezeptart: false,
            gebuehrenpflichtig: false,
            kk_info: false,
            vers_nummer_info: false,
            vers_status_info: false,
            unfalltag_info: true,
            unfall: false,
            bvg: false,
            therapiebericht: false,
            icdcodes: true,
            genehmigung: true,
            arzt: false,
            privat_zusatztext: true,
            hausbesuch: false,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: false,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          if (kttyp == "PBeaKK") {
            rove.general.is_disabled.gebuehrenpflichtig = true;
            rove.general.is_irrelevant.gebuehrenpflichtig = true;
            rove.rezeptHeader.gebuehrenpflichtig = "Nein";
            rove.general.is_irrelevant.genehmigung = true;
            rove.general.is_irrelevant.icdcodes = true;
          }

          rove.general.is_disabled.diagnose = false;
          rove.general.is_irrelevant.diagnose = false;
          rove.general.is_irrelevant.behandlungsbedarf = false;

          rove.general.is_irrelevant.genehmigung = true;


          if (["22", "26"].includes(rove.general.le_typ)) {
            rove.general.is_irrelevant.blanko = false;
          }


        }
      };

      rove.str_to_date = function (str) {
        if (str) {
          var parts = str.split("-");
          var dt = new Date(parts[0], parts[1] - 1, parts[2]);
          return dt;
        } else {
          return;
        }
      };

      rove.date_to_str_ger = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return (ddChars[1] ? dd : "0" + ddChars[0]) + "." + (mmChars[1] ? mm : "0" + mmChars[0]) + "." + yyyy;
      };

      rove.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      // ########## GET DATA FUNCTIONS ##########
      // ########################################

      rove.getSettingsData = function () {
        SettingsService.get()
          .$promise.then(function (data) {
            rove.general.settings_data = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      // get related patienten information
      rove.getPatientenData = function (id) {
        PatientenService.get({
          id: id,
        })
          .$promise.then(function (data) {
            rove.patient = data;

            // get related rezeptarten and set selected rezeptart
            // disable/hide irrelevant fields
            rove.set_rezeptart_defaults(rove.patient.kt_typ);

            rove.general.diagnose_key = rove.rezeptHeader.diagnose;

            rove.general.rezeptdatum = rove.str_to_date(rove.rezeptHeader.rezeptdatum);
            rove.general.erfassungsdatum = rove.str_to_date(rove.rezeptHeader.erfassungsdatum);
            rove.general.physiotherapiebeginn = rove.str_to_date(rove.rezeptHeader.physiotherapiebeginn);
            rove.general.genehmigung = rove.rezeptHeader.genehmigung;
            rove.general.genehmigungsnummer = rove.rezeptHeader.genehmigungsnummer;
            rove.general.genehmigungsart = rove.rezeptHeader.genehmigungsart;
            rove.general.genehmigungsdatum = rove.str_to_date(rove.rezeptHeader.genehmigungsdatum);
            rove.general.frequenz = rove.rezeptHeader.frequenz;
            
            rove.getBefreiungenData();

            if (rove.general.rezeptart_selected.id == "VPP") {
              rove.getVollprivatTarif();
            } else {
              // get related heilmittel/tarif data first
              rove.getHeilmittelData().then(function () {
                rove.getDiagnoseTarifData();
              });
            }
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      // get all Rezept Header information
      rove.getRezeptHeaderData = function (header_id) {
        RezepteHeaderService.get({
          id: header_id,
        })
          .$promise.then(function (data) {
            // save returned data
            rove.rezeptHeader = data;

            if (rove.rezeptHeader.status == "Neu-Gesperrt" || rove.rezeptHeader.status == "Vordatiert-Gesperrt") {
              rove.general.locked = true;
              rove.init_vals.locked = true;
            } else {
              rove.general.locked = false;
              rove.init_vals.locked = false;
            }

            // get patienten data
            rove.getPatientenData(rove.rezeptHeader.patient);
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rove.getVollprivatTarif = function () {
        VollprivatTarifService.query()
          .$promise.then(function (data) {
            rove.heilmittel = data;
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      rove.getDiagnoseTarifData = function () {
        var deferred = $.Deferred();

        DiagnoseTarifService.query({
          diagnose_key: rove.general.diagnose_key,
        })
          .$promise.then(function (data) {
            rove.general.diagnose_tarif_lkp = data;
            deferred.resolve();
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });

        return deferred.promise();
      };

      rove.getRezeptDatenData = function (header_id) {
        RezepteDatenService.query({
          header: header_id,
        })
          .$promise.then(function (data) {
            rove.rezeptDaten = data;

            for (var i = 0; i < data.length; i++) {
              rove.rezeptDaten[i].date = rove.str_to_date(rove.rezeptDaten[i].datum);
              rove.rezeptDaten[i].weekday = rove.getweekday(rove.rezeptDaten[i].date);
            }
          })
          .catch(function (error) { });
      };

      rove.getRezeptPositionenData = function (header_id) {
        RezeptePositionenService.query({
          header: header_id,
        })
          .$promise.then(function (data) {
            rove.rezeptPositionen = data;
          })
          .catch(function (error) { });
      };

      // get all ICD10 codes
      rove.getICD10Data = function () {
        rove.general.icd10_data = StoreService.get("icd10_codes");
      };

      // returns written weekday based on provided date
      rove.getweekday = function (dt) {
        var days = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
        return days[dt.getDay()];
      };

      // get all relevant HeilmittelData (new and old if necessary)
      rove.getHeilmittelData = function () {
        var deferred = $.Deferred();

        rove.general.preislisten = null;
        rove.general.preistyp = null; // alt, neu, mix (starts with alt but could be neu at some point)
        tt = null;

        // additional logic to check which HeilmittelData to return
        PreislistenCheckService.get({
          patient_id: rove.patient.id,
        })
          .$promise.then(function (data) {
            rove.general.preislisten = data;

            // transform string to date object
            rove.general.preislisten.neu.date_from = rove.str_to_date(rove.general.preislisten.neu.date_from);
            rove.general.preislisten.alt.date_from = rove.str_to_date(rove.general.preislisten.alt.date_from);
            rove.general.preislisten.alt_2.date_from = rove.str_to_date(rove.general.preislisten.alt_2.date_from);

            // if rezeptdatum < 01.08.2021 - use old check value
            var p_check = rove.general.preislisten.check;
            if (rove.dateDiffInDays(rove.general.rezeptdatum, rove.str_to_date("2021-08-01")) > 0) {
              p_check = rove.general.preislisten.old_check;
            }

            // if check is "Rezeptdatum" then no price changes can occur on different dates
            if (p_check == "Rezeptdatum") {
              diff_1 = rove.dateDiffInDays(rove.general.preislisten.neu.date_from, rove.general.rezeptdatum);
              diff_2 = rove.dateDiffInDays(rove.general.preislisten.alt.date_from, rove.general.rezeptdatum);

              if (diff_1 >= 0) {
                tt = rove.general.preislisten.neu.tarif_typ;
                rove.general.preistyp = "neu";
              } else if (diff_2 >= 0) {
                tt = rove.general.preislisten.alt.tarif_typ;
                rove.general.preistyp = "alt";
              } else {
                tt = rove.general.preislisten.alt_2.tarif_typ;
                rove.general.preistyp = "alt_2";
              }
            }

            // if check is "Behandlungsdatum" (Erfassungsdatum) then price changes can only occur when the old prices are selected first
            else if (p_check == "Behandlungsdatum") {
              diff_1 = rove.dateDiffInDays(rove.general.preislisten.neu.date_from, rove.general.erfassungsdatum);
              diff_2 = rove.dateDiffInDays(rove.general.preislisten.alt.date_from, rove.general.erfassungsdatum);

              if (diff_1 >= 0) {
                tt = rove.general.preislisten.neu.tarif_typ;
                rove.general.preistyp = "neu";
              } else if (diff_2 >= 0) {
                tt = rove.general.preislisten.alt.tarif_typ;
                rove.general.preistyp = "mix";
              } else {
                tt = rove.general.preislisten.alt_2.tarif_typ;
                rove.general.preistyp = "mix_alt";
              }
            }

            h_query = {
              diagnose_key: rove.general.diagnose_key,
              patient_id: rove.patient.id,
              tarif_typ: tt,
              blanko: rove.rezeptHeader.blanko,
            };

            if (rove.general.preistyp == "mix_alt" || rove.general.preistyp == "alt_2") {
              HeilmittelOldService.query(h_query)
                .$promise.then(function (data) {
                  rove.heilmittel = data;

                  // in addition grab Heilmittel Data with new prices if preistyp == "mix_alt"
                  if (rove.general.preistyp == "mix_alt") {
                    var tt_choice = rove.general.preislisten.neu.tarif_typ;
                    if (rove.general.preistyp == "mix_alt") {
                      tt_choice = rove.general.preislisten.alt.tarif_typ;
                    }
                    h_query = {
                      diagnose_key: rove.general.diagnose_key,
                      patient_id: rove.patient.id,
                      tarif_typ: tt_choice,
                      blanko: rove.rezeptHeader.blanko,
                    };

                    HeilmittelService.query(h_query)
                      .$promise.then(function (data) {
                        rove.general.heilmittel_neu = data;
                        deferred.resolve();
                      })
                      .catch(function (error) {
                        // fix for "Possibly unhandled rejection"
                      });
                  } else {
                    rove.general.heilmittel_neu = null;
                    deferred.resolve();
                  }
                })
                .catch(function (error) {
                  // fix for "Possibly unhandled rejection"
                });
            } else {
              HeilmittelService.query(h_query)
                .$promise.then(function (data) {
                  // ! TEMP - should be removed once future preislisten strategy is known
                  var to_splice = [];
                  for (var i = 0; i < data.length; i++) {
                    if (
                      (data[i].tarif_typ == "01" || ["alt", "mix"].includes(rove.general.preistyp)) &&
                      ["19901", "29901", "19902", "29902", "19907", "29907", "39907", "89907"].includes(data[i].tarif)
                    ) {
                      to_splice.push(i);
                    }
                  }

                  if (to_splice.length != 0) {
                    // descending
                    to_splice.sort(function (a, b) {
                      return b - a;
                    });
                    for (var idx of to_splice) {
                      data.splice(idx, 1);
                    }
                  }

                  rove.heilmittel = data;

                  // in addition grab Heilmittel Data with new prices if preistyp == "mix"
                  if (rove.general.preistyp == "mix") {
                    var tt_choice = rove.general.preislisten.neu.tarif_typ;
                    if (rove.general.preistyp == "mix_alt") {
                      tt_choice = rove.general.preislisten.alt.tarif_typ;
                    }
                    h_query = {
                      diagnose_key: rove.general.diagnose_key,
                      patient_id: rove.patient.id,
                      tarif_typ: tt_choice,
                      blanko: rove.rezeptHeader.blanko,
                    };

                    HeilmittelService.query(h_query)
                      .$promise.then(function (data) {
                        rove.general.heilmittel_neu = data;
                        deferred.resolve();
                      })
                      .catch(function (error) {
                        // fix for "Possibly unhandled rejection"
                      });
                  } else {
                    rove.general.heilmittel_neu = null;
                    deferred.resolve();
                  }
                })
                .catch(function (error) {
                  // fix for "Possibly unhandled rejection"
                });
            }
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });

        return deferred.promise();
      };

      // get all related Mitarbeiter
      rove.getMitarbeiterData = function () {
        rove.mitarbeiter = StoreService.get("mitarbeiter");
      };

      // ########################################
      // ########################################

      rove.getAge = function (bd_str) {
        var today = new Date();
        var birthDate = new Date(bd_str);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      };

      // ####### POSITIONEN FUNCTIONS ########
      // #####################################

      rove.addNewPosition = function () {
        var newNo = rove.positions.length + 1;

        rove.positions.push({
          id: newNo,
          heilmittel: null,
          km: null,
          doubletr: false,
        });

        // focus new element
        $timeout(function () {
          $window.document.getElementById("menge" + newNo).focus();
        }, 10);

        rove.checkPositionenInput();
      };

      rove.removePosition = function (pos) {
        var to_delete = 0;
        for (var i = 0; i < rove.positions.length; i++) {
          if (rove.positions[i].id == pos) {
            to_delete = i;
          }
        }
        rove.positions.splice(to_delete, 1);

        // update ids after remove
        for (var i = 0; i < rove.positions.length; i++) {
          rove.positions[i].id = i + 1;
        }

        rove.checkPositionenInput();
      };

      // #####################################

      // ########## CHECK FUNCTIONS #############
      // ########################################

      rove.check_arzt_num = function () {
        rove.header_errors.arzt = [];
        if (rove.general.arzt_num) {
          // '999999999' only valid for BG and PP
          if (
            rove.general.arzt_num == "999999999" &&
            !["BG", "PP", "VPP", "BW"].includes(rove.general.rezeptart_selected.id) &&
            rove.patient.kt_typ != "S"
          ) {
            rove.general.arzt_exists = false;
            rove.header_errors.arzt.push("Angegebener Arzt darf nicht verwendet werden!");
          } else {
            AerzteService.get({
              arztNum: rove.general.arzt_num,
            })
              .$promise.then(function (data) {

                rove.general.arzt = data;
                rove.general.arzt_exists = true;

              })
              .catch(function (error) { 
                rove.general.arzt_exists = false;
                rove.header_errors.arzt.push("Angegebener Arzt existiert nicht!");
              });
          }
        } else {
          rove.general.arzt_exists = false;
          rove.header_errors.arzt.push("Es wurde kein Arzt angegeben!");
        }
      };

      rove.resetLightCycle = function () {
        rove.general.blankoLightCyclePhysio = false;
        rove.general.blankoLightCyclePhysioVorrangig = 0;
        rove.general.blankoLightCyclePhysioErgaenzend = 0;

        rove.general.blankoLightCycleErgo = false;
        rove.general.blankoLightCycleErgoGreenLimit = 0;
        rove.general.blankoLightCycleErgoYellowLimit = 0;
      }

      rove.setLightCycleErgo = function (greenLimit, yellowLimit){
        // Red light cycle phase starts after the yellow limit
        rove.general.blankoLightCycleErgo = true;
        rove.general.blankoLightCycleErgoGreenLimit = greenLimit;
        rove.general.blankoLightCycleErgoYellowLimit = yellowLimit;
      }

      rove.setLightCyclePhysio = function (enable, vorrangigLimit, ergaenzendLimit) {
        rove.general.blankoLightCyclePhysio = enable;
        rove.general.blankoLightCyclePhysioVorrangig = vorrangigLimit;
        rove.general.blankoLightCyclePhysioErgaenzend = ergaenzendLimit;
      }

      rove.check_blanko = function (autoSet) {

        // Default value for autoSet is true
        var autoSet = (autoSet === undefined) ? true : autoSet;

        const leTyp = rove.general.le_typ;
        const leTypDiagnosisLkp = {
          "22": ["EX"],
          "26": ["SB1", "PS3", "PS4"]
        }

        // Only responsible for enabling/disabling blanko field
        if (rove.general.is_irrelevant.blanko || !rove.general.diagnose_exists || rove.rezeptHeader.discharge_management) {
          rove.rezeptHeader.blanko = false;
          rove.general.blankoDisabled = true;
          rove.isBlankoInit = false;
          rove.resetLightCycle();
          return
        }


        // Two cases where blanko is enabled
        // Requires at least the Diagnosis to be set
        
        const diagnosis = rove.general.diagnose.id;

        if (leTypDiagnosisLkp[leTyp] && leTypDiagnosisLkp[leTyp].includes(diagnosis)) {
          // Check "blankoEntries" property on the diagnosis to see if there are any ICD10 code entries required
          var blankoEntries = rove.general.diagnose.blankoEntries;

          if (blankoEntries.length == 0) {
            // No ICD10 codes required, enable blanko
            rove.general.blankoDisabled = false;

            // Automatically set blanko to true (not required during initialisation)
            if (!rove.isBlankoInit && autoSet) {
              rove.rezeptHeader.blanko = true;
            }

            // Light cycle is relevant for Ergo (26) but only if langf or besond is false
            if (leTyp == "26" && !rove.rezeptHeader.langf && !rove.rezeptHeader.besond){
              if (diagnosis == "SB1") {
                rove.setLightCycleErgo(128, 176);
              }
              else {
                rove.setLightCycleErgo(176, 200);
              }
            }
            else {
              // Otherwise light cycle is not relevant
              rove.resetLightCycle();
            }

          } else {
            // We need to check if the required ICD10 codes are set
            if (!rove.general.icdcodes || rove.general.icdcodes.length === 0) {
              rove.general.blankoDisabled = true;
              rove.rezeptHeader.blanko = false;
            }
            else {
              var matches = [];
              var icdCodeExists = function(code) {
                return rove.general.icdcodes.some(function(icd) {
                  return icd.id === code;
                });
              };   

              for (var i = 0; i < blankoEntries.length; i++) {
                var entry = blankoEntries[i];

                if (
                  (entry.icd10_b === null && icdCodeExists(entry.icd10_a)) ||
                  (entry.icd10_b !== null && icdCodeExists(entry.icd10_a) && icdCodeExists(entry.icd10_b))
                ) {
                  matches.push(entry);
                }
              }
              
              var hasMatch = matches.length > 0;

              rove.general.blankoDisabled = !hasMatch;

              // Automatically set blanko to true/false (not required during initialisation)
              if (!rove.isBlankoInit && autoSet) {
                rove.rezeptHeader.blanko = hasMatch;
              }

              if (rove.rezeptHeader.blanko) {
                var matchedEntry = matches.reduce(function(prev, current) {
                  return (prev.lc_vorrangig_limit > current.lc_vorrangig_limit) ? prev : current
                });

                rove.setLightCyclePhysio(matchedEntry.light_cycle, matchedEntry.lc_vorrangig_limit, matchedEntry.lc_ergaenzend_limit);
              } else {
                rove.resetLightCycle();
              }
      
            }
          }
        } else {
          // Diagnosis does not match the required diagnosis for blanko
          rove.rezeptHeader.blanko = false;
          rove.general.blankoDisabled = true;
          rove.resetLightCycle();
        }

        // FUGLY HACK
        rove.isBlankoInit = false;
      };

      rove.check_diagnose = function () {
        rove.header_errors.diagnose = [];

        if (rove.general.diagnose_key) {
          var same = false;
          if (rove.general.diagnose) {
            if (rove.general.diagnose.id == rove.general.diagnose_key) {
              same = true;
            }
          }

          if (!same) {
            if (rove.general.diagnose_key != rove.rezeptHeader.diagnose) {
              rove.rezeptHeader.ls_a = false;
              rove.rezeptHeader.ls_b = false;
              rove.rezeptHeader.ls_c = false;
              rove.rezeptHeader.ls_x = false;
              rove.rezeptHeader.ls_x_text = "";
            }

            DiagnoseService.get({
              id: rove.general.diagnose_key,
              rezeptart: rove.general.rezeptart_selected.id,
              bereich: rove.general.heilmittelbereich,
            })
              .$promise.then(function (data) {
                if (rove.general.diagnose_key == "9999" && rove.patient.kt_typ != "S") {
                  rove.general.diagnose_exists = false;
                  if (rove.general.rezeptart_selected.id == "ZA") {
                    rove.header_errors.diagnose.push("Angegebener Indikationsschlüssel ist ungültig!");
                  } else {
                    rove.header_errors.diagnose.push("Angegebene Diagnosegruppe ist ungültig!");
                  }
                  return;
                }

                rove.general.diagnose = data;
                rove.general.diagnose_exists = true;
                rove.general.diagnose_key = data.id;
                rove.rezeptHeader.diagnose = data.id;
                rove.check_lb_behandlungsbedarf();
                rove.check_blanko();
              })
              .catch(function (error) {
                rove.general.diagnose_exists = false;
                rove.rezeptHeader.diagnose = null;
                rove.general.diagnose = null;
                rove.header_errors.diagnose.push(error.data.detail);
                rove.check_lb_behandlungsbedarf();
                rove.check_blanko();
              });
          }
        } else {
          rove.general.diagnose_exists = false;
          rove.rezeptHeader.diagnose = null;
          rove.general.diagnose = null;

          rove.rezeptHeader.ls_a = false;
          rove.rezeptHeader.ls_b = false;
          rove.rezeptHeader.ls_c = false;
          rove.rezeptHeader.ls_x = false;
          rove.rezeptHeader.ls_x_text = "";

          if (rove.general.rezeptart_selected.id == "ZA") {
            rove.header_errors.diagnose.push("Es wurde kein Indikationsschlüssel angegeben!");
          } else {
            rove.header_errors.diagnose.push("Es wurde keine Diagnosegruppe angegeben!");
          }
          rove.check_lb_behandlungsbedarf();
          rove.check_blanko();
        }
      };

      rove.check_km = function (d) {
        if (d) {
          if (d.bezeichnung.indexOf("Wegegeld je Kilometer") !== -1) {
            return true;
          }
        }

        return false;
      };

      rove.clean_ls_x_text = function () {
        rove.rezeptHeader.ls_x_text = "";
      };

      rove.checkHeaderErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in rove.header_errors) {
          if (!rove.header_errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (rove.header_errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        rove.error_checking_flag = false;
        return true;
      };

      rove.checkPositionErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in rove.position_errors) {
          if (!rove.position_errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }

          if (typeof rove.position_errors[error] == "object") {
            for (var e in rove.position_errors[error]) {
              if (!rove.position_errors[error].hasOwnProperty(e)) {
                //The current error is not a direct property of p
                continue;
              }

              if (rove.position_errors[error][e].length != 0) {
                return false;
              }
            }
          } else {
            if (rove.position_errors[error].length != 0) {
              return false;
            }
          }
        }

        // no errors were found
        return true;
      };

      rove.checkDateErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in rove.date_errors) {
          if (!rove.date_errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }

          if (typeof rove.date_errors[error] == "object") {
            for (var e in rove.date_errors[error]) {
              if (!rove.date_errors[error].hasOwnProperty(e)) {
                //The current error is not a direct property of p
                continue;
              }

              if (rove.date_errors[error][e].length != 0) {
                return false;
              }
            }
          } else {
            if (rove.date_errors[error].length != 0) {
              return false;
            }
          }
        }

        // no errors were found
        return true;
      };

      // ########################################
      // ########################################

      rove.set_error_scroll = function (loc) {
        if (!rove.error_scroll) {
          rove.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      rove.set_default_genehmigungsart = function () {
        if (!rove.general.genehmigungsart) {
          rove.general.genehmigungsart = "Einzel (B1)";
        }
        // reset genehmigungsart if false
        if (!rove.general.genehmigung) {
          rove.general.genehmigungsart = null;
        }
      };

      rove.checkHeaderInput = function () {
        // reset current errors
        for (var error in rove.header_errors) {
          if (["diagnose"].indexOf(error) == -1) {
            rove.header_errors[error] = [];
          }
        }

        rove.header_err_msg = "";

        // reset anchor scroll position
        rove.error_scroll = null;

        // date limits
        lim_past = 1900;
        lim_future = parseInt(new Date().getFullYear());

        rove.error_checking_flag = true;

        rove.check_zulassungsbefreiung();

        // check if locked status was changed
        if (rove.init_vals.locked != rove.general.locked) {
          // is now locked (was unlocked)
          if (rove.general.locked) {
            if (rove.rezeptHeader.status == "Neu") {
              rove.rezeptHeader.status = "Neu-Gesperrt";
            } else if (rove.rezeptHeader.status == "Vordatiert") {
              rove.rezeptHeader.status = "Vordatiert-Gesperrt";
            }
            rove.init_vals.locked = true;
          }
          // is now unlocked (was locked)
          else if (!rove.general.locked) {
            if (rove.rezeptHeader.status == "Neu-Gesperrt") {
              rove.rezeptHeader.status = "Neu";
            } else if (rove.rezeptHeader.status == "Vordatiert-Gesperrt") {
              rove.rezeptHeader.status = "Vordatiert";
            }
            rove.init_vals.locked = false;
          }
        }

        // gebuehrenpflichtig
        if (rove.rezeptHeader.gebuehrenpflichtig === null) {
          rove.header_errors.gebuehrenpflichtig.push("Es wurde keine Auswahl getroffen!");
          rove.set_error_scroll("gebuehrenpflichtig_loc");
        }

        // arzt
        if (rove.general.arzt_num) {
          if (!rove.general.arzt_exists) {
            rove.header_errors.arzt.push("Der angegebene Arzt existiert nicht!");
            rove.set_error_scroll("arzt_loc");
          } else {
            rove.rezeptHeader.arzt = rove.general.arzt.id;
          }
        } else {
          rove.header_errors.arzt.push("Es wurde kein Arzt angegeben!");
          rove.set_error_scroll("arzt_loc");
        }

        // rezeptdatum
        if (rove.general.rezeptdatum == null || rove.general.rezeptdatum == "") {
          rove.header_errors.rezeptdatum.push("Es wurde kein Datum angegeben!");
          rove.set_error_scroll("rezeptdatum_loc");
        } else {
          given_year = parseInt($scope.forms.daten_header_forms.rd_input.$viewValue.slice(0, 4));

          if (lim_past <= given_year && lim_future >= given_year) {
            if (given_year < 2021) {
              rove.header_errors.rezeptdatum.push("Änderung des Rezeptdatums auf ein altes Jahr ist nicht erlaubt!");
              rove.set_error_scroll("rezeptdatum_loc");
            } else {
              rove.rezeptHeader.rezeptdatum = rove.date_to_str(rove.general.rezeptdatum);
              rove.general.rezeptdatum.setHours(0, 0, 0, 0);
            }
          } else {
            rove.header_errors.rezeptdatum.push("Ungültiges Datum angegeben!");
            rove.set_error_scroll("rezeptdatum_loc");
          }
        }

        // erfassungsdatum
        if (rove.general.erfassungsdatum == null || rove.general.erfassungsdatum == "") {
          rove.header_errors.erfassungsdatum.push("Es wurde kein Datum angegeben!");
          rove.set_error_scroll("erfassungsdatum_loc");
        } else {
          given_year = parseInt($scope.forms.daten_header_forms.ed_input.$viewValue.slice(0, 4));

          if (lim_past <= given_year) {
            if (rove.getweekday(rove.general.erfassungsdatum) != "Sonntag") {
              rove.rezeptHeader.erfassungsdatum = rove.date_to_str(rove.general.erfassungsdatum);
              rove.general.erfassungsdatum.setHours(0, 0, 0, 0);
            } else {
              rove.header_errors.erfassungsdatum.push("Behandlung an Sonntagen sind nicht erlaubt!");
              rove.set_error_scroll("erfassungsdatum_loc");
            }
          } else {
            rove.header_errors.erfassungsdatum.push("Ungültiges Datum angegeben!");
            rove.set_error_scroll("erfassungsdatum_loc");
          }
        }

        // physiotherapiebeginn
        if (!rove.general.is_irrelevant.physiotherapiebeginn) {
          if (rove.general.physiotherapiebeginn != null) {
            given_year = parseInt($scope.forms.daten_header_forms.pt_input.$viewValue.slice(0, 4));

            if (lim_past <= given_year && lim_future >= given_year) {
              rove.rezeptHeader.physiotherapiebeginn = rove.date_to_str(rove.general.physiotherapiebeginn);
              rove.general.physiotherapiebeginn.setHours(0, 0, 0, 0);
            } else {
              rove.header_errors.physiotherapiebeginn.push("Ungültiges Datum angegeben!");
              rove.set_error_scroll("physiotherapiebeginn_loc");
            }
          } else {
            // needed when rove.general.physiotherapiebeginn entry is cleared
            rove.rezeptHeader.physiotherapiebeginn = null;
          }
        }

        // check only if erfassungsdatum and rezeptdatum available
        if (
          rove.general.erfassungsdatum &&
          rove.general.rezeptdatum &&
          !["PP", "VPP"].includes(rove.general.rezeptart_selected.id)
        ) {
          var remainingDays = rove.dateDiffInDays(rove.general.rezeptdatum, rove.general.erfassungsdatum);

          var grace = null;
          var msg = null;

          if (rove.general.rezeptart_selected.id == "BW") {
            if (rove.rezeptHeader.behandlungsbedarf) {
              grace = 14;
              msg =
                "Erste Behandlung überschreitet Frist von " + grace + " Tagen (Bundeswehr) (dring. Behandlungsbedarf)!";
            } else {
              grace = 28;
              msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen (Bundeswehr)";
            }
          } else if (rove.general.rezeptart_selected.id == "BG") {
            if (rove.general.physiotherapiebeginn) {
              remainingDays = rove.dateDiffInDays(rove.general.physiotherapiebeginn, rove.general.erfassungsdatum);
            }

            if (rove.rezeptHeader.behandlungsbedarf) {
              grace = 7;
              msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen (BG) (dring. Behandlungsbedarf)!";
            } else {
              grace = 14;
              msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen (BG)";
            }
          } else if (rove.rezeptHeader.behandlungsbedarf) {
            grace = 14;
            msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen (dring. Behandlungsbedarf)!";
          } else {
            grace = 28;
            msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen!";
          }

          if (remainingDays > grace) {
            rove.header_errors.erfassungsdatum.push(msg);
            rove.set_error_scroll("erfassungsdatum_loc");
          }

          if (remainingDays < 0) {
            msg = !rove.general.physiotherapiebeginn
              ? "Erste Behandlung liegt vor Rezeptdatum!"
              : "Erste Behandlung liegt vor Physiotherapiebeginn!";
            rove.header_errors.erfassungsdatum.push(msg);
            rove.set_error_scroll("erfassungsdatum_loc");
          }

          // Entlassmanagement
          if (!rove.general.is_irrelevant.discharge_management && rove.rezeptHeader.discharge_management) {
            if (remainingDays > 7) {
              rove.header_errors.erfassungsdatum.push("Erste Behandlung überschreitet Frist von 7 Tagen (Entlassmanagement)!");
              rove.set_error_scroll("erfassungsdatum_loc");
            }
          }
        }

        // diagnose + leitsymptomatik
        if (!rove.general.is_irrelevant.diagnose) {
          if (rove.header_errors.diagnose.length != 0) {
            rove.set_error_scroll("diagnose_loc");
          } else {
            if (!rove.general.diagnose) {
              if (rove.general.rezeptart_selected.id == "ZA") {
                rove.header_errors.diagnose.push("Es wurde kein Indikationsschlüssel angegeben!");
              } else {
                rove.header_errors.diagnose.push("Es wurde keine Diagnosegruppe angegeben!");
              }
              rove.set_error_scroll("diagnose_loc");
            } else {
              if (
                !rove.rezeptHeader.ls_a &&
                !rove.rezeptHeader.ls_b &&
                !rove.rezeptHeader.ls_c &&
                !rove.rezeptHeader.ls_x &&
                (rove.general.diagnose.ls_a ||
                  rove.general.diagnose.ls_b ||
                  rove.general.diagnose.ls_c ||
                  rove.general.diagnose.ls_x)
              ) {
                rove.header_errors.ls.push("Es wurde keine Leitsymptomatik ausgewählt!");
                rove.set_error_scroll("leitsympt_loc");
              }

              if (rove.rezeptHeader.ls_x) {
                if (rove.rezeptHeader.ls_x_text.length == 0) {
                  rove.header_errors.ls_text.push("Beschreibung der patientenindividuellen Leitsymptomatik fehlt!");
                  rove.set_error_scroll("leitsympt_loc");
                }

                if (rove.rezeptHeader.ls_x_text.length > 70) {
                  rove.header_errors.ls_text.push(
                    "Beschreibung der patientenindividuellen Leitsymptomatik ist zu lang (max. 70 Zeichen erlaubt)!"
                  );
                  rove.set_error_scroll("leitsympt_loc");
                }

                var matches = get_unique(rove.rezeptHeader.ls_x_text.match(rove.regexp_diagnose));
                if (matches) {
                  var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
                  rove.header_errors.ls_text.push(err_msg);
                  rove.set_error_scroll("leitsympt_loc");
                }
              }
            }
          }
        }

        // frequenz
        if (!rove.general.is_irrelevant.frequenz) {
          if (!rove.general.frequenz) {
            rove.header_errors.frequenz.push("Es wurde keine gültige Therapiefrequenz angegeben!");
          } else {
            rove.rezeptHeader.frequenz = rove.general.frequenz;
          }
        }

        // privat_info_text
        if (!rove.general.is_irrelevant.privat_zusatztext) {
          if (rove.rezeptHeader.privat_info_text) {
            if (rove.rezeptHeader.privat_info_text.length > 200) {
              rove.header_errors.privat_info_text.push("Zusatztext ist zu lang! Nur 200 Zeichen sind erlaubt!");
            }

            var matches = get_unique(rove.rezeptHeader.privat_info_text.match(rove.regexp_text_all));
            if (matches) {
              var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
              rove.header_errors.privat_info_text.push(err_msg);
              rove.set_error_scroll("privat_zusatztext_loc");
            }
          }
        }

        // dringlicher behandlungsbedarf
        if (!rove.general.is_irrelevant.behandlungsbedarf && rove.rezeptHeader.behandlungsbedarf === null) {
          rove.header_errors.behandlungsbedarf.push("Es wurde keine Auswahl getroffen!");
          rove.set_error_scroll("behandlungsbedarf_loc");
        }

        // hausbesuch
        if (rove.rezeptHeader.hausbesuch === null) {
          rove.header_errors.hausbesuch.push("Es wurde keine Auswahl getroffen!");
          rove.set_error_scroll("hausbesuch_loc");
        }

        // therapiebericht
        if (!rove.general.is_irrelevant.therapiebericht) {
          if (rove.rezeptHeader.therapiebericht === null) {
            rove.header_errors.therapiebericht.push("Es wurde keine Auswahl getroffen!");
            rove.set_error_scroll("therapiebericht_loc");
          }
        }

        // icd10
        if (!rove.general.is_irrelevant.icdcodes) {
          if (!rove.general.icdcodes || rove.general.icdcodes.length == 0) {
            rove.header_errors.icd10.push("Es wurde kein ICD-10 Code angegeben!");
            rove.set_error_scroll("icd10_loc");
            var entry = angular.element(document.querySelector("#icd10_loc").querySelector("div > div"));
            entry.addClass("is-error-border");
          } else {
            rove.rezeptHeader.icd_code = rove.icd_to_str(rove.general.icdcodes);
            var entry = angular.element(document.querySelector("#icd10_loc").querySelector("div > div"));
            entry.removeClass("is-error-border");
          }
        } else {
          // if user wants to provide icd-10 even though it is not needed
          if (rove.general.icdcodes && rove.general.rezeptart_selected.id != "ZA") {
            rove.rezeptHeader.icd_code = rove.icd_to_str(rove.general.icdcodes);
            var entry = angular.element(document.querySelector("#icd10_loc").querySelector("div > div"));
            entry.removeClass("is-error-border");
          }
        }

        if (rove.general.rezeptart_selected.id == "ZA") {
          if (rove.rezeptHeader.diagnosetext) {
            if (rove.rezeptHeader.diagnosetext.length == 0) {
              rove.header_errors.diagnosetext.push("Zahnarzt Diagnosetext fehlt!");
              rove.set_error_scroll("diagnosetext_loc");
            }

            if (rove.rezeptHeader.diagnosetext.length > 70) {
              rove.header_errors.diagnosetext.push("Zahnarzt Diagnosetext ist zu lang (max. 70 Zeichen erlaubt)!");
              rove.set_error_scroll("diagnosetext_loc");
            }

            var matches = get_unique(rove.rezeptHeader.diagnosetext.match(rove.regexp_diagnose));
            if (matches) {
              var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
              rove.header_errors.diagnosetext.push(err_msg);
              rove.set_error_scroll("diagnosetext_loc");
            }
          } else {
            rove.header_errors.diagnosetext.push("Zahnarzt Diagnosetext fehlt!");
            rove.set_error_scroll("diagnosetext_loc");
          }
        }

        // genehmigung
        rove.rezeptHeader.genehmigung = false;
        rove.rezeptHeader.genehmigungsnummer = null;
        rove.rezeptHeader.genehmigungsart = null;
        rove.rezeptHeader.genehmigungsdatum = null;

        if (!rove.general.is_irrelevant.genehmigung) {
          if (rove.general.genehmigung) {
            // check genehmigungsdatum
            if (rove.general.genehmigungsdatum == null || rove.general.genehmigungsdatum == "") {
              rove.header_errors.genehmigungsdatum.push("Es wurde kein Datum angegeben!");
              rove.set_error_scroll("genehmigungsdatum_loc");
            }

            // check genehmigungsnummer
            if (rove.general.genehmigungsnummer) {
              if (rove.general.genehmigungsnummer.length > 20) {
                rove.header_errors.genehmigungsnummer.push("Genehmigungsnummer ist zu lang!");
                rove.set_error_scroll("genehmigungsnummer_loc");
              }
            } else {
              rove.header_errors.genehmigungsnummer.push("Es wurde keine Genehmigungsnummer angegeben!");
              rove.set_error_scroll("genehmigungsnummer_loc");
            }

            if (
              [rove.header_errors.genehmigungsdatum, rove.header_errors.genehmigungsnummer].every(function (arr) {
                return arr.length === 0;
              })
            ) {
              rove.rezeptHeader.genehmigung = true;
              rove.rezeptHeader.genehmigungsnummer = rove.general.genehmigungsnummer;
              rove.rezeptHeader.genehmigungsart = rove.general.genehmigungsart;
              rove.rezeptHeader.genehmigungsdatum = rove.date_to_str(rove.general.genehmigungsdatum);
            }
          }
        }

        var errs = rove.checkHeaderErrors();

        // set error flag for faster lookup
        if (errs) {
          rove.header_err_flag = false;
        } else {
          rove.header_err_flag = true;
          rove.position_edit_flag = false;
          rove.date_edit_flag = false;
        }

        return errs;
      };

      rove.check_lb_behandlungsbedarf = function () {
        // reset langf and besond to false first
        rove.rezeptHeader.langf = false;
        rove.rezeptHeader.besond = false;

        if (rove.general.icdcodes) {
          if (rove.rezeptHeader.diagnose && rove.general.icdcodes.length > 0) {
            for (var i = 0; i < rove.general.icdcodes.length; i++) {
              var keys = [];

              if (rove.general.heilmittelbereich == "PHYS" && rove.general.icdcodes[i].phys) {
                keys = rove.general.icdcodes[i].phys.split("/");
              } else if (rove.general.heilmittelbereich == "ERGO" && rove.general.icdcodes[i].ergo) {
                keys = rove.general.icdcodes[i].ergo.split("/");
              } else if (rove.general.heilmittelbereich == "LOGO" && rove.general.icdcodes[i].logo) {
                keys = rove.general.icdcodes[i].logo.split("/");
              }

              if (keys.length != 0) {
                var diag_match = false;
                var comb_match = false;

                // try to match icd-10 combination match
                if (rove.general.icdcodes[i].icd_comb) {
                  rove.general.icdcodes.forEach(function (icd, index) {
                    if (index != i) {
                      if (icd.id == rove.general.icdcodes[i].icd_comb) {
                        comb_match = true;
                      }
                    }
                  });
                } else {
                  comb_match = true;
                }

                // try to match diagnose gruppe
                keys.forEach(function (key, index) {
                  if (key == rove.rezeptHeader.diagnose.substring(0, key.length)) {
                    diag_match = true;
                  }
                });

                // if both diagnose and icd10 combination key are matched
                if (diag_match && comb_match) {
                  if (rove.general.icdcodes[i].langf) {
                    rove.rezeptHeader.langf = true;
                  }
                  if (rove.general.icdcodes[i].besond) {
                    rove.rezeptHeader.besond = true;
                  }
                  break;
                }
              }
            }
          } else {
          }
        }
      };

      // DATE RELATED FUNCTIONS ##############
      // #####################################

      // calculates date groups

      rove.loadGroupData = function () {
        // calc daten entries
        var g = 1;

        for (var i = 0; i < rove.rezeptDaten.length; i++) {
          var entry = rove.rezeptDaten[i];
          var e_date = rove.str_to_date(entry.datum);
          var e_mitarbeiter = rove.mitarbeiter.filter(function (a) {
            return a.mitarbeiter_id === entry.mitarbeiter_id;
          })[0];

          var first = false;
          var last = false;

          // set proper group (g) and first/last attribute
          if (i != 0) {
            if (entry.datum != rove.rezeptDaten[i - 1].datum) {
              g = g + 1;

              first = true;
              rove.dates.daten_entries[i - 1].last = true;
              rove.dates.original_entries[i - 1].last = true;
            }
          } else if (i == 0) {
            first = true;
            if (rove.rezeptDaten.length == 1) {
              last = true;
            }
          }

          // set group date
          rove.dates.group_lkp[g] = e_date;

          var doubletr = false
          pos_id = entry.pos_line_nr;

          if (entry.tarif_nr) {
            if (rove.general.rezeptart_selected.id == "VPP") {


              heilmittel = rove.heilmittel.filter(function (a) {
                return a.tarif === entry.tarif_nr;
              })[0];

              bezeichnung = heilmittel.bezeichnung;
              available = true;
            } else {
              diff = rove.dateDiffInDays(rove.general.preislisten.neu.date_from, e_date);
              heilmittel = null;
              bezeichnung = null;
              available = null;

              if (rove.general.preistyp == "mix" && diff >= 0) {
                for (h = 0; h < rove.general.heilmittel_neu.length; h++) {
                  if (rove.general.heilmittel_neu[h].tarif == entry.tarif_nr) {
                    // set updated tarif
                    heilmittel = rove.general.heilmittel_neu[h];
                    bezeichnung = rove.general.heilmittel_neu[h].bezeichnung;
                    available = true;

                    break;
                  }
                }

                // Try to find matching tarif once again using the "bezeichnung" field
                if (!heilmittel) {
                  for (h = 0; h < rove.general.heilmittel_neu.length; h++) {
                    if (rove.general.heilmittel_neu[h].bezeichnung == entry.tarif_bezeichnung) {
                      // set updated tarif
                      heilmittel = rove.general.heilmittel_neu[h];
                      bezeichnung = rove.general.heilmittel_neu[h].bezeichnung;
                      available = true;

                      break;
                    }
                  }
                }

                // assume no matching tarif was found in tarif table
                if (!heilmittel) {
                  heilmittel = rove.positions.filter(function (a) {
                    return a.heilmittel && a.heilmittel.tarif === entry.tarif_nr;
                  })[0].heilmittel;
                  bezeichnung = heilmittel.bezeichnung;
                  available = false;
                }
              } else {
                heilmittel = rove.positions.filter(function (a) {
                  return a.heilmittel && a.heilmittel.tarif === entry.tarif_nr;
                })[0].heilmittel;
                bezeichnung = heilmittel.bezeichnung;
                available = true;
              }
            }

            // double treatment flag for new rezepte
            doubletr = (rove.positions[pos_id - 1].doubletr === undefined) ? false : rove.positions[pos_id - 1].doubletr;
          }
          else {
            heilmittel = null;
            bezeichnung = null;
            available = false;
            doubletr = false;
          }

          var entryA = {
            id: entry.line_nr,
            pos_id: pos_id,
            group: g,
            first: first,
            last: last,

            old_pos: entry.line_nr,
            weekday: rove.getweekday(e_date),
            datum: e_date,

            heilmittel: heilmittel,
            bezeichnung: bezeichnung,
            available: available,

            unterbrechung: entry.unterbrechung,
            unterbrechung_grund: entry.unterbrechung_grund,
            mitarbeiter: e_mitarbeiter,
            doubletr: doubletr,
          };

          var entryB = {
            id: entry.line_nr,
            pos_id: pos_id,
            group: g,
            first: first,
            last: last,

            old_pos: entry.line_nr,
            weekday: rove.getweekday(e_date),
            datum: e_date,

            heilmittel: heilmittel,
            bezeichnung: bezeichnung,
            available: available,

            unterbrechung: entry.unterbrechung,
            unterbrechung_grund: entry.unterbrechung_grund,
            mitarbeiter: e_mitarbeiter,
            doubletr: doubletr,
          };

          rove.dates.daten_entries.push(entryA);
          rove.dates.original_entries.push(entryB);
        }
      };

      rove.updateGroup = function (g, dt, u, b, m) {
        rove.dates.group_lkp[g] = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());

        for (i = 0; i < rove.dates.daten_entries.length; i++) {
          var entry = rove.dates.daten_entries[i];
          if (entry.group == g) {
            rove.dates.daten_entries[i].datum = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
            rove.dates.daten_entries[i].unterbrechung = u;
            rove.dates.daten_entries[i].unterbrechung_grund = b;
            rove.dates.daten_entries[i].mitarbeiter = m;
            rove.updateWeekday(rove.dates.daten_entries[i].id);
          }
        }
      };

      rove.datenRefresh = function (data_check) {
        data_check = data_check === undefined ? true : data_check;

        rove.dates.daten_entries.sort(rove.dynamicSortMultiple("datum", "group", "id"));

        curr_grp = 0;
        curr_date = null;

        // update groups
        for (i = 0; i < rove.dates.daten_entries.length; i++) {
          // logic for rezepte where prices could differ (IGNORE FOR VOLLPRIVATPREISE VPP)
          if (rove.general.rezeptart_selected.id != "VPP") {
            diff_1 = rove.dateDiffInDays(rove.general.preislisten.neu.date_from, rove.dates.daten_entries[i].datum);
            diff_2 = rove.dateDiffInDays(rove.general.preislisten.alt.date_from, rove.dates.daten_entries[i].datum);
            if (
              (rove.general.preistyp == "mix" && diff_1 >= 0) ||
              (rove.general.preistyp == "mix_alt" && diff_2 >= 0)
            ) {
              heilmittel = null;

              // iterate over new heilmittel data to find maching tarif
              for (h = 0; h < rove.general.heilmittel_neu.length; h++) {
                if (rove.general.heilmittel_neu[h].tarif == rove.dates.daten_entries[i].heilmittel.tarif) {
                  // set updated tarif
                  heilmittel = rove.general.heilmittel_neu[h];
                  available = true;
                  break;
                }
              }


              if (!heilmittel) {
                // Try to find matching tarif once again using the "bezeichnung" field
                for (h = 0; h < rove.general.heilmittel_neu.length; h++) {
                  if (rove.general.heilmittel_neu[h].bezeichnung == rove.dates.daten_entries[i].heilmittel.bezeichnung) {
                    // set updated tarif
                    heilmittel = rove.general.heilmittel_neu[h];
                    available = true;
                    break;
                  }
                }
              }


              // assume no matching tarif was found in tarif table
              if (!heilmittel) {
                available = false;
              } else {
                rove.dates.daten_entries[i].heilmittel = heilmittel;
              }
            }
          }

          // UPDATE Groups
          rove.dates.daten_entries[i].old_pos = rove.dates.daten_entries[i].id;
          rove.dates.daten_entries[i].id = i + 1;

          // check for first element
          if (i == 0) {
            // no date provided
            if (rove.dates.daten_entries[i].datum == null || rove.dates.daten_entries[i].datum == "") {
              continue;
            }

            curr_grp = 1;
            rove.dates.daten_entries[i].group = curr_grp;
            curr_date = rove.dates.daten_entries[i].datum;
            rove.dates.daten_entries[i].first = true;
          }
          // check for following elements
          else {
            // no date provided
            if (
              rove.dates.daten_entries[i].datum == null ||
              rove.dates.daten_entries[i].datum == "" ||
              rove.dates.daten_entries[i - 1].datum == "" ||
              rove.dates.daten_entries[i - 1].datum == null
            ) {
              continue;
            }

            // different group
            if (rove.dateDiffInDays(rove.dates.daten_entries[i].datum, rove.dates.daten_entries[i - 1].datum) != 0) {
              curr_grp += 1;
              rove.dates.daten_entries[i].group = curr_grp;
              curr_date = rove.dates.daten_entries[i].datum;
              rove.dates.daten_entries[i].first = true;
            }
            // same group
            else {
              rove.dates.daten_entries[i].group = curr_grp;
              rove.dates.daten_entries[i].first = false;
            }
          }

          // set last for last element of each group
          if (i + 1 < rove.dates.daten_entries.length) {
            if (rove.dateDiffInDays(rove.dates.daten_entries[i].datum, rove.dates.daten_entries[i + 1].datum)) {
              rove.dates.daten_entries[i].last = true;
            } else {
              rove.dates.daten_entries[i].last = false;
            }
          } else {
            rove.dates.daten_entries[i].last = true;
          }

          // Update Double Treatment Flag (only revelant for PHYS and ERGO)
          if (rove.onlyPhysioErgo() && (!rove.rezeptHeader.blanko || rove.general.le_typ !== "26")){
            if (rove.doubleTreatmentLookup.includes(rove.dates.daten_entries[i].heilmittel.tarif)){
              rove.dates.daten_entries[i].doubletr = true;
            } else {
              rove.dates.daten_entries[i].doubletr = false;
            }
          }

        }

        // update weekday
        for (var i = 0; i < rove.dates.daten_entries.length; i++) {
          rove.updateWeekday(i + 1);
        }

        // update original entries
        rove.dates.original_entries = rove.copyDaten(rove.dates.daten_entries);

        if (data_check) {
          rove.checkDatenInput(false);
        }
      };

      rove.updateWeekday = function (id) {
        if (rove.dates.daten_entries[id - 1].datum) {
          rove.dates.daten_entries[id - 1].weekday = rove.getweekday(rove.dates.daten_entries[id - 1].datum);
        }
      };


      rove.copyDaten = function (entries) {
        var new_entries = [];
        for (i = 0; i < entries.length; i++) {
          var element = entries[i];
          var entry = {
            id: element.id,
            first: element.first,
            last: element.last,
            pos_id: element.pos_id,
            old_pos: element.id,
            group: element.group,
            weekday: element.weekday,
            datum: element.datum,
            heilmittel: element.heilmittel,
            bezeichnung: element.bezeichnung,
            available: element.available,
            unterbrechung: element.unterbrechung,
            unterbrechung_grund: element.unterbrechung_grund,
            mitarbeiter: element.mitarbeiter,
            doubletr: element.doubletr,
          };

          new_entries.push(entry);
        }

        return new_entries;
      }

      rove.datenReset = function () {
        // reset current errors
        for (var error in rove.date_errors) {
          rove.date_errors[error] = [];
        }
        rove.dates.daten_entries = rove.copyDaten(rove.dates.original_entries);
      };

      rove.unterbrechungReset = function (d) {
        d.unterbrechung_grund = null;
      };

      rove.add_week_to_list = function (container, year, week, dateStr, entry) {
        var id = entry.id;
        var pos_id = entry.pos_id;
        var aborted = entry.unterbrechung;

        if (week.toString().length < 2) {
          week = "0" + week;
        }

        var week_id = year + "-" + week;

        // total_cnt / frequency lookup
        var frequency_lkp = {};
        var total_cnt_lkp = {};
        rove.positions.forEach(function (item, index) {
          frequency_lkp[item.id] = rove.rezeptHeader.frequenz;
          if (rove.tarif_lkp.includes(item.heilmittel.tarif)) {
            frequency_lkp[item.id] = 1;
          }

          total_cnt_lkp[item.id] = item.menge;
        });

        if (!(week_id in container)) {
          container[week_id] = {};
          container[week_id][pos_id] = {
            ids: [id],
            dates: [dateStr],
            cnt: 1,
            aborted: [],
            frequency: frequency_lkp[pos_id],
            total_cnt: total_cnt_lkp[pos_id],
            doubletr: entry.doubletr,
            heilmittel: entry.heilmittel,
          };
        } else {
          if (!(pos_id in container[week_id])) {
            container[week_id][pos_id] = {
              ids: [id],
              dates: [dateStr],
              cnt: 1,
              aborted: [],
              frequency: frequency_lkp[pos_id],
              total_cnt: total_cnt_lkp[pos_id],
              doubletr: entry.doubletr,
              heilmittel: entry.heilmittel,
            };
          } else {
            container[week_id][pos_id].ids.push(id);
            container[week_id][pos_id].dates.push(dateStr);
            container[week_id][pos_id].cnt += 1;
          }
        }

        if (aborted) {
          container[week_id][pos_id].aborted.push(id);
        }
      };

      rove.getMaxPositionFrequency = function () {
        var max_freq = 0;

        for (var i = 0; i < rove.positions.length; i++) {
          max_freq = Math.max(max_freq, Number(rove.positions[i].menge));
        }

        return max_freq;
      }

      rove.checkDatenInput = function (data_refresh) {
        data_refresh = data_refresh === undefined ? true : data_refresh;

        // force update first
        if (data_refresh) {
          rove.datenRefresh(false);
        }

        // reset current errors
        for (var error in rove.date_errors) {
          rove.date_errors[error] = [];
        }

        // reset predated flag
        rove.predated = false;

        // object to store line numbers that have an issue
        rove.affected_lines = {
          unterbrechungnotallowed: [],
          unterschreitung: [],
          twoweekscheck: [],
          twentyonedaycheck: [],
          missinggrund: [],
          missingdatum: [],
          invaliddatum: [],
          erstebehandlung: [],
          notavailable: [],
          sundaycheck: [],
          frequency: [],
          vorrangig_cnt: [],
          doubletr_underused: [],
          doubletr_overused: [],
          ergaenzend_overused: [],
          ergaenzend_alone: [],
          none_alone: [],
          bandage_alone: [],
          validity_3_months: [],
          validity_6_months: [],
          validity_16_weeks: [],
          discharge_management: [],
          blanko_28_days_check: [],
          diagnostic_not_first: [],
          analysis_not_first: [],
          zi_min_max: [],
        };

        // reset anchor scroll position
        rove.error_scroll = null;

        // weekday collection used for frequency checks
        rove.weekdays = {};

        // date limits
        lim_past = 1900;
        lim_future = parseInt(new Date().getFullYear()) + 1;
        lim_today = new Date();


        // 3 or 6 months validity period (for all prescriptions other than PP)
        var prescription_validity_rule = rove.getMaxPositionFrequency() <= 6 ? 3 : 6;
        var max_validity_period_date = moment(rove.general.erfassungsdatum).add(prescription_validity_rule, 'months')

        var de = rove.dates.daten_entries;

        // check errors first
        for (var i = 0; i < de.length; i++) {
          var num = i + 1;

          // check if datum is missing
          if (de[i].datum == null || de[i].datum == "") {
            rove.affected_lines.missingdatum.push(num);
            rove.set_error_scroll("datum" + de[i].id);
          }

          // datum was provided
          else {
            // skip further checks if previous datum or first datum is empty
            if (i != 0) {
              if (de[i - 1].datum == null || de[i - 1].datum == "" || de[0].datum == null || de[0].datum == "") {
                continue;
              }
            }

            // get week number and save for later check
            var dt = moment(de[i].datum);
            var dtStr = dt.format("YYYY-MM-DD");
            var week = dt.isoWeek();
            var year = dt.isoWeekYear();
            var given_year = dt.year();

            rove.add_week_to_list(rove.weekdays, year, week, dtStr, de[i]);

            // remove lim future ( || lim_future < given_year) for now
            if (lim_past > given_year || lim_future < given_year) {
              for (j = 0; j < de.length; j++) {
                if (de[j].old_pos == num) {
                  rove.affected_lines.invaliddatum.push(de[j].id);
                  rove.set_error_scroll("datum" + de[j].id);
                }
              }
            } else {
              // check if a predated date is found
              if (rove.dateDiffInDays(de[i].datum, lim_today) < 0) {
                rove.predated = true;
              }

              // first date logic
              if (rove.dateDiffInDays(de[i].datum, de[0].datum) == 0) {
                // unterbrechung not allowed on first day
                if (de[i].unterbrechung) {
                  rove.affected_lines.unterbrechungnotallowed.push(num);
                  rove.set_error_scroll("datum" + de[i].id);
                } else {
                  // check if erfassungsdatum is the same as given
                  if (de[i].datum < rove.general.erfassungsdatum || de[i].datum > rove.general.erfassungsdatum) {
                    rove.affected_lines.erstebehandlung.push(num);
                    rove.set_error_scroll("datum" + de[i].id);
                  }
                }
              } else if (
                !de[i].unterbrechung &&
                rove.general.diagnose_key != "PPPP" &&
                rove.general.heilmittelbereich != "PODO" &&
                !(rove.rezeptHeader.blanko && rove.general.le_typ == "26")
              ) {
                var diffDays = rove.dateDiffInDays(de[i - 1].datum, de[i].datum);
                if (diffDays > 14) {
                  rove.affected_lines.twoweekscheck.push(num);
                  rove.set_error_scroll("datum" + de[i].id);
                }
              } else if (de[i].unterbrechung && !de[i].unterbrechung_grund) {
                rove.affected_lines.missinggrund.push(num);
                rove.set_error_scroll("datum" + de[i].id);
              }

              if (de[i].weekday == "Sonntag") {
                rove.affected_lines.sundaycheck.push(num);
                rove.set_error_scroll("datum" + de[i].id);
              }
            }

            // check if date is within prescription validity period (ignore if Privat, BG or blanko prescription)
            if (!["PPPP", "BGBG"].includes(rove.general.diagnose_key) && !rove.rezeptHeader.blanko){
              if (dt.isAfter(max_validity_period_date)) {
                rove.affected_lines["validity_" + prescription_validity_rule + "_months"].push(num);
                rove.set_error_scroll("datum" + de[i].id);
              }
            }

            // check for blanko prescriptions
            if (rove.rezeptHeader.blanko) {
              if (rove.dateDiffInDays(rove.general.rezeptdatum, de[i].datum) > 112) {
                rove.affected_lines.validity_16_weeks.push(num);
                rove.set_error_scroll("datum" + de[i].id);
              }
            }

            // check for discharge management
            if (rove.rezeptHeader.discharge_management) {
              var diffDays = rove.dateDiffInDays(rove.general.rezeptdatum, de[i].datum);
              if (diffDays > 12) {
                rove.affected_lines.discharge_management.push(num);
                rove.set_error_scroll("datum" + de[i].id);
              }
            }
          }

          if (!de[i].available) {
            //
            rove.affected_lines.notavailable.push(num);
            rove.set_error_scroll("datum" + de[i].id);
          }
        }

        // check for week / frequency errors
        if (
          rove.general.settings_data.frequency_check && 
          rove.general.diagnose_key != "PPPP") {
          // iterate and sort over weeks to get keys in ascending order
          var weeks = [];
          for (var key in rove.weekdays) {
            weeks.push(key);
          }
          weeks.sort();

          // check whether to check vorranginge or alleinstehende
          var check_id = "alone";
          for (var i = 0; i < rove.positions.length; i++) {
            var found = rove.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == rove.positions[i].heilmittel.tarif;
            });

            if (found.vorrangig) {
              check_id = "vorr";
              break;
            }
          }

          // iterate over all weeks and check frequency based on selected check_id
          for (var i = 0; i < weeks.length; i++) {
            // week to check
            var week = rove.weekdays[weeks[i]];

            var week_freq = rove.rezeptHeader.frequenz;
            var seen_tarife = new Set();
            var ids = [];
            var dates = new Set();

            // iterate over all tarife in the given week
            for (var t in week) {
              var tarif = week[t];

              var found = rove.general.diagnose_tarif_lkp.find(function (element) {
                return element.tarif == tarif.heilmittel.tarif;
              });

              // we are only interested in the tarife matching the selected check_id
              if (
                (check_id == "alone" && found.alleinstehend) ||
                (check_id == "vorr" && found.vorrangig && !tarif.heilmittel.mehrfach_kz)
              ) {
                if (!tarif.doubletr) {
                  ids = ids.concat(tarif.ids);

                  // ... spread-operator doesn't work properly here (not all dates are added)
                  tarif.dates.forEach(function (d) {
                    dates.add(d);
                  });

                } else {
                  if (!seen_tarife.has(tarif.heilmittel.bezeichnung)) {
                    seen_tarife.add(tarif.heilmittel.bezeichnung);
                    ids = ids.concat(tarif.ids);

                    // ... spread-operator doesn't work properly here (not all dates are added)
                    tarif.dates.forEach(function (d) {
                      dates.add(d);
                    });
                  }
                }
              }
            }

            // too many vorrangige/alleinstehende for this week
            if (dates.size > week_freq) {
              rove.affected_lines.frequency.push([ids, week_freq.toString()]);
            }
          }
        }

        // https://github.com/Korred/mkav03/issues/91
        if (
          !["PPPP", "BGBG", "BWBW", "KURE", "9999"].includes(rove.general.diagnose_key) &&
          rove.general.rezeptart_selected.id != "ZA"
        ) {
          var tarif_set = [];
          var lines = [];
          var double_tr_ids = [];

          var vorr_found = false;
          var ergaenzend_ids = [];
          var alleinstehend_ids = [];

          var bandage_ids = [];
          var mld_flag = false;
          var kb_lkp = ["10204", "20204", "80204", "8403a", "19d"];
          var mld_lkp = [
            "10201",
            "20201",
            "10202",
            "20202",
            "10205",
            "20205",
            "80201",
            "80202",
            "80205",
            "8402",
            "8403",
            "19a",
            "19b",
            "19c",
          ];

          var none_ids = [];

          var blanko_diagnostic_entries = [];
          var blanko_analysis_entry = null;

          var blanko_zi_lookup = {
            group_entries: [],
            zi_counts: [],
          }

          // check if any vorr tarife are available
          var has_vorr = false;
          for (var i = 0; i < rove.positions.length; i++) {
            entry = rove.positions[i];
            var found = rove.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });
            if (found.vorrangig) {
              has_vorr = true;
            }
          }

          for (i = 0; i < rove.dates.daten_entries.length; i++) {
            var entry = rove.dates.daten_entries[i];

            var found = rove.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });

            if (rove.rezeptHeader.blanko){
              if (['20522','20523'].includes(entry.heilmittel.tarif)){
                blanko_diagnostic_entries.push(entry);
              }

              if ('54003' == entry.heilmittel.tarif){
                blanko_analysis_entry = entry;
              }

              // Track ZI sums for each group (Timeunits)
              if (rove.general.le_typ == '26'){
                // Add empty arrays if a new group is found
                if (entry.group > blanko_zi_lookup.group_entries.length){
                  blanko_zi_lookup.group_entries.push([]);
                  blanko_zi_lookup.zi_counts.push(0);
                }
                blanko_zi_lookup.group_entries[entry.group - 1].push(entry.id);

                if (entry.heilmittel.bezeichnung.includes('1ZI')){
                  blanko_zi_lookup.zi_counts[entry.group - 1] += 1;
                }
              }
            }


            if (found.vorrangig && !entry.heilmittel.mehrfach_kz) {
              vorr_found = true;

              if (mld_lkp.includes(entry.heilmittel.tarif)) {
                mld_flag = true;
              }

              if (entry.doubletr) {
                double_tr_ids.push(entry.id);
              }

              if (!entry.doubletr || (entry.doubletr && !tarif_set.includes(entry.heilmittel.tarif))) {
                tarif_set.push(entry.heilmittel.tarif);
              }

              lines.push(entry.id);
            }

            if (kb_lkp.includes(entry.heilmittel.tarif)) {
              bandage_ids.push(entry.id);
            }

            if (found.alleinstehend) {
              alleinstehend_ids.push(entry.id);
            }

            if (found.ergaenzend) {
              ergaenzend_ids.push(entry.id);
            }

            if (!found.ergaenzend && !found.alleinstehend && !found.vorrangig) {
              var exclude_tarife = [
                "19701", // arztbericht
                "29701", // arztbericht
                "59701", // arztbericht
                "89701", // arztbericht
                "20553", // arztbericht
                "33010", // erstbefundung
                "54002", // funktionsanalyse erstvo
                "54003", // analyse ergo bedarf
                "20422", // physio diagnostik
                "20423", // bedarfsdiagnostik
              ];

              if (!exclude_tarife.includes(entry.heilmittel.tarif)) {
                none_ids.push(entry.id);
              }
            }

            // entry.last == last entry of the day e.g. reset group related data after the last entry is processed
            if (entry.last) {

              // ! This will be ignored for blanko with le_typ 26 (ergo)
              if ((tarif_set.length > 1 && !rove.rezeptHeader.blanko) || 
              (tarif_set.length > 2 && rove.rezeptHeader.blanko && rove.general.le_typ == "22")) {
                rove.affected_lines.vorrangig_cnt.push(lines);
              }

              if (double_tr_ids.length == 1) {
                // double_tr entry is missing its partner
                rove.affected_lines.doubletr_underused.push(double_tr_ids);
              } else if (double_tr_ids.length > 2) {
                // multiple double_tr entries in on one day
                rove.affected_lines.doubletr_overused.push(double_tr_ids);
              }

              if (ergaenzend_ids.length > 1) {
                // only 1 ergaenzend per day
                rove.affected_lines.ergaenzend_overused.push(ergaenzend_ids);
              }

              if (none_ids.length > 0 && !vorr_found && alleinstehend_ids.length == 0) {
                // allg tarife that are standing alone
                rove.affected_lines.none_alone.push(none_ids);
              }

              if (ergaenzend_ids.length == 1 && !vorr_found) {
                // possible vorraning missing / lone erganzend
                if (alleinstehend_ids.length == 1) {
                  if (alleinstehend_ids[0] != ergaenzend_ids[0] || has_vorr) {
                    rove.affected_lines.ergaenzend_alone.push(ergaenzend_ids);
                  }
                } else {
                  rove.affected_lines.ergaenzend_alone.push(ergaenzend_ids);
                }
              }

              if (!mld_flag && bandage_ids.length > 0) {
                rove.affected_lines.bandage_alone.push(bandage_ids);
              }

              tarif_set = [];
              lines = [];
              double_tr_ids = [];
              vorr_found = false;
              ergaenzend_ids = [];
              alleinstehend_ids = [];
              none_ids = [];
              bandage_ids = [];
              mld_flag = false;
            }
          }

          if (blanko_diagnostic_entries.length > 0) {

            if (blanko_diagnostic_entries[0].group != 1) {
              rove.affected_lines.diagnostic_not_first.push(blanko_diagnostic_entries[0].id);
            }

            if (blanko_diagnostic_entries.length > 1) {
              // compare pairwise
              for (var i = 0; i < blanko_diagnostic_entries.length - 1; i++) {
                var entry_1 = blanko_diagnostic_entries[i];
                var entry_2 = blanko_diagnostic_entries[i + 1];

                if (rove.dateDiffInDays(entry_1.datum, entry_2.datum) < 28) {
                  rove.affected_lines.blanko_28_days_check.push([entry_1.id, entry_2.id]);
                }
              }
            }
          }
          
          if (blanko_analysis_entry) {
            if (blanko_analysis_entry.group != 1) {
              rove.affected_lines.analysis_not_first.push(blanko_analysis_entry.id);
            }
          }

          // Check for ZI min/max (2ZI / 12ZI or 30min / 180min)
          if (rove.rezeptHeader.blanko && rove.general.le_typ == "26") {
            var zi_counts = blanko_zi_lookup.zi_counts;
            var group_entries = blanko_zi_lookup.group_entries;

            for (var i = 0; i < zi_counts.length; i++) {
              var zi_count = zi_counts[i];
              var group_entry = group_entries[i];

              if (zi_count < 2 || zi_count > 12) {
                rove.affected_lines.zi_min_max.push([i+1, group_entry]);
              }
            }
          }
        }

        if (rove.date_errors.positions.length == 0) {
          rove.date_errors.positions = {
            datum: [],
            unterbrechung: [],
          };
        }

        // iterate over affacted_lines to check for errors / to create error messages
        for (var type in rove.affected_lines) {
          if (!rove.affected_lines.hasOwnProperty(type)) {
            //The current error is not a direct property of p
            continue;
          }

          if (rove.affected_lines[type].length != 0) {
            if (type == "notavailable") {
              rove.date_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].join(", ") +
                ": Für diese Daten stehen keine aktuellen Tarifpreise zur Verfügung!"
              );
              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(rove.affected_lines[type]);
            }

            if (type == "missingdatum") {
              rove.date_errors.messages.push(
                "Eintrag " + rove.affected_lines[type].join(", ") + ": Datum wurde nicht angegeben!"
              );
              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(rove.affected_lines[type]);
            }

            if (type == "unterbrechungnotallowed") {
              rove.date_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].join(", ") +
                ": Erster Behandlungstermin darf nicht unterbrochen werden!"
              );
              rove.date_errors.positions.unterbrechung = rove.date_errors.positions.unterbrechung.concat(
                rove.affected_lines[type]
              );
            }

            if (type == "twoweekscheck") {
              rove.date_errors.messages.push(
                "Eintrag " + rove.affected_lines[type].join(", ") + ": Datum überschreitet die 14-Tage Frist!"
              );
              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(rove.affected_lines[type]);
            }

            if (type == "twentyonedaycheck") {
              rove.date_errors.messages.push(
                "Eintrag " + rove.affected_lines[type].join(", ") + ": Datum überschreitet die 21-Tage Frist!"
              );
              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(rove.affected_lines[type]);
            }

            if (type == "missinggrund") {
              rove.date_errors.messages.push(
                "Eintrag " + rove.affected_lines[type].join(", ") + ": Unterbrechungsgrund fehlt!"
              );
              rove.date_errors.positions.unterbrechung = rove.date_errors.positions.unterbrechung.concat(
                rove.affected_lines[type]
              );
            }

            if (type == "invaliddatum") {
              rove.date_errors.messages.push(
                "Eintrag " + rove.affected_lines[type].join(", ") + ": Ungültiges Datum angegeben!"
              );
              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(rove.affected_lines[type]);
            }

            if (type == "sundaycheck") {
              rove.date_errors.messages.push(
                "Eintrag " + rove.affected_lines[type].join(", ") + ": Behandlung an Sonntagen nicht erlaubt!"
              );
              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(rove.affected_lines[type]);
            }

            if (type == "erstebehandlung") {
              rove.date_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].join(", ") +
                ": Datum entspricht nicht dem angegebenen ersten Behandlungsdatum (" +
                rove.date_to_str_ger(rove.general.erfassungsdatum) +
                ")!"
              );
              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(rove.affected_lines[type]);
            }

            if (type == "frequency") {
              var err_container = rove.affected_lines[type];

              err_container.forEach(function (item, index) {
                var ids = item[0].sort(function(a, b) { return a - b; });
                var freq = item[1];
                var msg = "";

                if (freq == 1) {
                  msg =
                    "Eintrag " +
                    ids.join(", ") +
                    ": Die angegebene Frequenz von " +
                    freq +
                    "x pro Woche wurde nicht eingehalten!";
                } else {
                  msg =
                    "Eintrag " +
                    ids.join(", ") +
                    ": Die angegebene Frequenz von 1-" +
                    freq +
                    "x pro Woche wurde nicht eingehalten!";
                }

                rove.date_errors.messages.push(msg);
                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

            if (type == "vorrangig_cnt") {
              var err_container = rove.affected_lines[type];
              err_container.forEach(function (item, index) {

                var msg = ": Pro Behandlungstag ist nur ein vorrangiges Heilmittel erlaubt!";

                if (rove.rezeptHeader.blanko && rove.general.le_typ == "22") {
                  msg = ": Pro Behandlungstag sind maximal zwei vorrangige Heilmittel erlaubt!";
                }

                var ids = item;
                rove.date_errors.messages.push(
                  "Eintrag " + ids.join(", ") + msg
                );
                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

            if (type == "doubletr_underused") {
              var err_container = rove.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rove.date_errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Doppelbehandlungen müssen zusammenstehen!"
                );
                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

            if (type == "doubletr_overused") {
              var err_container = rove.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rove.date_errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Mehrere Doppelbehandlungen an einem Tag sind nicht erlaubt!"
                );
                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

            if (type == "ergaenzend_overused") {
              var err_container = rove.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rove.date_errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Nur ein ergänzendes Heilmittel pro Tag erlaubt!"
                );
                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

            if (type == "ergaenzend_alone") {
              var err_container = rove.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rove.date_errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Ergänzende Heilmittel dürfen nicht alleine stehen!"
                );
                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

            if (type == "none_alone") {
              var err_container = rove.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rove.date_errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Allgemeine Heilmittel dürfen nicht alleine stehen!"
                );
                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

            if (type == "bandage_alone") {
              var err_container = rove.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rove.date_errors.messages.push(
                  "Eintrag " +
                  ids.join(", ") +
                  ": Kompressionsbandage nur in Kombination mit MLD 30, 45 oder 60 möglich!"
                );
                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

            if (type == "validity_3_months") {
              var ids = rove.affected_lines[type];

              rove.date_errors.messages.push(
                "Eintrag " + ids.join(", ") + ": Gültigkeitszeitraum von 3 Monaten überschritten!"
              );

              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
            }

            if (type == "validity_6_months") {
              var ids = rove.affected_lines[type];

              rove.date_errors.messages.push(
                "Eintrag " + ids.join(", ") + ": Gültigkeitszeitraum von 6 Monaten überschritten!"
              );

              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
            }

            if (type == "validity_16_weeks") {
              var ids = rove.affected_lines[type];

              rove.date_errors.messages.push(
                "Eintrag " + ids.join(", ") + ": Blankoverordnung Gültigkeitszeitraum von 16 Wochen überschritten!"
              );

              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
            }

            if (type == "discharge_management") {
              var ids = rove.affected_lines[type];

              rove.date_errors.messages.push(
                "Eintrag " + ids.join(", ") + ": Abschluss muss innerhalb von 12 Tagen nach Rezeptdatum sein (Entlassmanagement)!"
              );

              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
            }

            if (type == "blanko_28_days_check") {
              var err_container = rove.affected_lines[type];
              err_container.forEach(function (item, index) {
                var ids = item;
                rove.date_errors.messages.push(
                  "Eintrag " + ids.join(", ") + ": Zwischen Diagnostiken müssen mindestens 28 Tage liegen!"
                );
                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

            if (type == "diagnostic_not_first") {
              var ids = rove.affected_lines[type];

              rove.date_errors.messages.push(
                "Eintrag " + ids + ": Diagnostik muss am ersten Tag erfolgen!"
              );

              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
            }


            if (type == "analysis_not_first") {
              var ids = rove.affected_lines[type];

              rove.date_errors.messages.push(
                "Eintrag " + ids + ": Analyse muss am ersten Tag erfolgen!"
              );

              rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
            }

            if (type == "zi_min_max") {
              var err_container = rove.affected_lines[type];
              err_container.forEach(function (item, index) {
                var group = item[0];
                var ids = item[1];
                rove.date_errors.messages.push(
                  "Gruppe " + group + ": Mindestens 2 und maximal 12 ZI pro Tag erlaubt!"
                );

                rove.date_errors.positions.datum = rove.date_errors.positions.datum.concat(ids);
              });
            }

          }
        }

        // if no errors were found and edit issue was fixed or never existed
        // true == true -> issues were fixed
        // false == false -> no issues were found in the first place
        if (rove.checkDateErrors()) {
          // no errors were found
          // now create proper rezeptdaten object
          var daten = [];
          for (i = 0; i < de.length; i++) {
            if (rove.general.rezeptart_selected.id == "VPP") {
              var entry = {
                line_nr: de[i].id,
                pos_line_nr: de[i].pos_id,
                datum: rove.date_to_str(de[i].datum),

                tarif_bezeichnung: de[i].heilmittel.bezeichnung,
                tarif_nr: de[i].heilmittel.tarif,
                le_typ: rove.general.le_typ,
                tarif_typ: "vpp",
                tarif_region: rove.general.tarif_region,
                verband: rove.general.verband,
                preis: de[i].heilmittel.preis,
                zuzahlung: "0.00",

                unterbrechung: de[i].unterbrechung,
                unterbrechung_grund: de[i].unterbrechung_grund,
                mitarbeiter: de[i].mitarbeiter.id,
              };
            } else {
              var entry = {
                line_nr: de[i].id,
                pos_line_nr: de[i].pos_id,
                datum: rove.date_to_str(de[i].datum),

                tarif_bezeichnung: de[i].heilmittel.bezeichnung,
                tarif_nr: de[i].heilmittel.tarif,
                le_typ: de[i].heilmittel.le_typ,
                tarif_typ: de[i].heilmittel.tarif_typ,
                tarif_region: de[i].heilmittel.tarif_region,
                verband: de[i].heilmittel.verband,
                preis: de[i].heilmittel.preis,
                zuzahlung: de[i].heilmittel.zuzahlung,

                unterbrechung: de[i].unterbrechung,
                unterbrechung_grund: de[i].unterbrechung_grund,
                mitarbeiter: de[i].mitarbeiter.id,
              };
            }

            daten.push(entry);
          }

          if (rove.predated) {
            if (rove.general.locked) {
              rove.rezeptHeader.status = "Vordatiert-Gesperrt";
            } else {
              rove.rezeptHeader.status = "Vordatiert";
            }
          } else {
            if (rove.general.locked) {
              rove.rezeptHeader.status = "Neu-Gesperrt";
            } else {
              rove.rezeptHeader.status = "Neu";
            }
          }
          rove.rezeptHeader.daten = daten;

          rove.modified_fields = [];

          return true;
        } else {
          return false;
        }
      };

      // calculates date groups
      rove.calculateNewGroups = function () {
        var suggest_dates = rove.general.settings_data.date_suggestion;
        var groups = {};
        var week_lkp = {};
        var groups_keys = [];
        var start_date = rove.general.erfassungsdatum;

        var new_daten_entries = [];
        var new_original_entries = [];

        if (
          ["PPPP", "BWBW", "KURE", "BGBG", "9999"].indexOf(rove.general.diagnose_key) != -1 ||
          ["LOGO"].indexOf(rove.general.heilmittelbereich) != -1
        ) {
          // calculate containers
          for (var i = 0; i < rove.positions.length; i++) {
            // find first occurence of tarif to select correct start date
            var pos_id = rove.positions[i].id;
            var date = null;

            for (var e = 0; e < rove.dates.daten_entries.length; e++) {
              if (rove.dates.daten_entries[e].pos_id == pos_id) {
                date = new Date(rove.dates.daten_entries[e].datum);
                break;
              }
            }

            if (!date) {
              date = new Date(start_date);
            }

            var c_frq = rove.rezeptHeader.frequenz ? rove.rezeptHeader.frequenz : 1;

            if (rove.tarif_lkp.includes(rove.positions[i].heilmittel.tarif)) {
              c_frq = 1;
            }

            container_data = {
              cnt: parseInt(rove.positions[i].menge),
              date: date,
              frequency: c_frq,
            };
            rove.dates.position_cnts.push(container_data);
          }

          // calculate group-lookup
          for (var pos = 0; pos < rove.dates.position_cnts.length; pos++) {
            while (rove.dates.position_cnts[pos].cnt != 0) {
              var pos_data = rove.dates.position_cnts[pos];
              var date = pos_data.date;
              var week = moment(date).week();
              var week_day = moment(date).isoWeekday();

              // update week_lkp data
              if (week in week_lkp) {
                if (pos in week_lkp[week]) {
                  week_lkp[week][pos] += 1;
                } else {
                  week_lkp[week][pos] = 1;
                }
              } else {
                week_lkp[week] = {};
                week_lkp[week][pos] = 1;
              }

              // CASE 1: this date entry already exists
              if (date in groups) {
                groups[date].push(pos);
              }
              // CASE 2: new date entry has to be created
              else {
                groups_keys.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
                groups[date] = [pos];
              }

              // check for previously provided date
              var prev_found = false;

              for (var e = 0; e < rove.dates.daten_entries.length; e++) {
                if (rove.dates.daten_entries[e].pos_id == pos_id) {
                  var prev_date = new Date(rove.dates.daten_entries[e].datum);

                  if (prev_date.getDate() > date.getDate()) {
                    rove.dates.position_cnts[pos].date.setDate(prev_date.getDate());
                    prev_found = true;
                    break;
                  }
                }
              }

              if (!prev_found) {
                // now calculate next valid date
                if (pos_data.frequency == 1) {
                  rove.dates.position_cnts[pos].date.setDate(rove.dates.position_cnts[pos].date.getDate() + 7);
                } else {
                  var week_cnt = week_lkp[week][pos];
                  var remaining = pos_data.frequency - week_cnt;

                  // there is still data missing for this week
                  if (remaining != 0) {
                    // remaining dates will fit in this week
                    if (week_day + remaining <= 5) {
                      // find out max next date
                      var margin = 5 - week_day - remaining;
                      var group_found = false;

                      // look for existing groups
                      for (var m = 1; m < margin + 2; m++) {
                        var possible_date = rove.dates.position_cnts[pos].date.getDate() + m;
                        if (possible_date in groups) {
                          group_found = true;
                          // set date to next existing group date
                          rove.dates.position_cnts[pos].date.setDate(rove.dates.position_cnts[pos].date.getDate() + m);
                          break;
                        }
                      }

                      // no group date was found, take earliest valid date
                      if (!group_found) {
                        if (week_day == 6) {
                          rove.dates.position_cnts[pos].date.setDate(rove.dates.position_cnts[pos].date.getDate() + 2);
                        } else {
                          rove.dates.position_cnts[pos].date.setDate(rove.dates.position_cnts[pos].date.getDate() + 1);
                        }
                      }
                    }
                    // remaining dates will not (all) fit in this week
                    else {
                      // try to fit as many as possible
                      var to_fit = 5 - week_day;
                      if (to_fit <= 0) {
                        // look for existing group next week
                        var margin = 5 - pos_data.frequency;
                        var group_found = false;

                        // look for existing group within marigin
                        for (var m = 1; m < margin + 2; m++) {
                          var possible_date = rove.dates.position_cnts[pos].date.getDate() + m;
                          if (possible_date in groups) {
                            group_found = true;
                            // set date to next existing group date
                            rove.dates.position_cnts[pos].date.setDate(
                              rove.dates.position_cnts[pos].date.getDate() + m
                            );
                            break;
                          }
                        }

                        // no group date was found, take earliest valid date
                        if (!group_found) {
                          rove.dates.position_cnts[pos].date.setDate(
                            rove.dates.position_cnts[pos].date.getDate() + (8 - week_day)
                          );
                        }
                      } else {
                        rove.dates.position_cnts[pos].date.setDate(rove.dates.position_cnts[pos].date.getDate() + 1);
                      }
                    }
                  }
                  // enough dates are assigned to this week, go to next one
                  else {
                    var margin = 5 - pos_data.frequency;
                    var group_found = false;

                    // look for existing group within marigin
                    for (var m = 1; m < margin + 2; m++) {
                      var possible_date = rove.dates.position_cnts[pos].date.getDate() + m;
                      if (possible_date in groups) {
                        group_found = true;
                        // set date to next existing group date
                        rove.dates.position_cnts[pos].date.setDate(rove.dates.position_cnts[pos].date.getDate() + m);
                        break;
                      }
                    }

                    // no group date was found, take earliest valid date
                    if (!group_found) {
                      rove.dates.position_cnts[pos].date.setDate(
                        rove.dates.position_cnts[pos].date.getDate() + (8 - week_day)
                      );
                    }
                  }
                }
              }

              rove.dates.position_cnts[pos].cnt -= 1;
            }
          }
        } else {
          // date selection process is different in edit form
          // instead of calculating every date - we use the dates that were previoulsy provided by the user

          var previous_dates = [];

          for (var e = 0; e < rove.dates.daten_entries.length; e++) {
            if (rove.dates.daten_entries[e].first) {
              previous_dates.push(new Date(rove.dates.daten_entries[e].datum));
            }
          }

          // reset previous
          rove.dates.vorrangig_cnts = [];
          rove.dates.mehrfach_cnts = [];

          start_date = new Date(start_date);

          // ensure first date in previous_dates is erfassungsdatum
          if (moment(start_date).isSame(previous_dates[0], "day")) {
            var curr_date = new Date(previous_dates.shift());
          } else if (moment(start_date).isBefore(previous_dates[0], "day")) {
            var curr_date = new Date(rove.general.erfassungsdatum);
          } else if (moment(start_date).isAfter(previous_dates[0], "day")) {
            // new start date is after the first (and maybe more previous dates)
            // remove dates from previous_dates if they are before start_date
            var curr_date = new Date(rove.general.erfassungsdatum);

            while (moment(start_date).isSameOrAfter(previous_dates[0], "day")) {
              previous_dates.shift();
            }
          }

          var vorr_freq = 0;
          var first_doubletr_pos = {};
          // NEW LOGIC
          // calculate containers

          // check if any vorr tarife are available
          var has_vorr = false;

          for (var i = 0; i < rove.positions.length; i++) {
            entry = rove.positions[i];
            var found = rove.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });
            if (found.vorrangig) {
              has_vorr = true;
            }
          }

          for (var i = 0; i < rove.positions.length; i++) {
            entry = rove.positions[i];

            var frq = rove.rezeptHeader.frequenz;

            if (rove.tarif_lkp.includes(entry.heilmittel.tarif)) {
              frq = 1;
            }

            container_data = {
              pos: i,
              tarif: entry.heilmittel.tarif,
              cnt: parseInt(entry.menge),
              date: new Date(start_date),
              frequency: frq,
              doubletr: false,
            };

            var found = rove.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });

            // vorranging with mehrfach_kz
            if (entry.heilmittel.mehrfach_kz) {
              rove.dates.mehrfach_cnts.push(container_data);
            } else if (found.vorrangig) {
              // check for double treatment entries
              for (var j = 0; j < rove.positions.length; j++) {
                if (
                  rove.positions[j].heilmittel.tarif == entry.heilmittel.tarif && 
                  i != j &&
                  (!rove.rezeptHeader.blanko || rove.general.le_typ !== "26")
                ) {
                  if (!(entry.heilmittel.tarif in first_doubletr_pos)) {
                    first_doubletr_pos[entry.heilmittel.tarif] = i;
                  }
                  container_data.doubletr = true;
                  break;
                }
              }

              rove.dates.vorrangig_cnts.push(container_data);
              vorr_freq = Math.max(vorr_freq, container_data.frequency);
            } else if (found.alleinstehend && !has_vorr) {
              rove.dates.vorrangig_cnts.push(container_data);
              vorr_freq = Math.max(vorr_freq, container_data.frequency);
            }
            // ergaenzend or no flag (neither vorrangig, ergaenzend nor alleinstehend)
            else {
              rove.dates.mehrfach_cnts.push(container_data);
            }
          }

          // sort rove.dates.vorrangig_cnts by cnt (desc)
          rove.dates.vorrangig_cnts.sort(function (a, b) {
            return b.cnt - a.cnt;
          });

          function is_empty(obj_cnts) {
            for (var i = 0; i < obj_cnts.length; i++) {
              if (obj_cnts[i].cnt != 0) {
                return false;
              }
            }
            return true;
          }

          vorr_pos = 0;
          vorr_used = 0;
          next_week = false;
          while (!is_empty(rove.dates.vorrangig_cnts)) {
            var vd = rove.dates.vorrangig_cnts[vorr_pos];
            var week = moment(curr_date).week();
            var week_day = moment(curr_date).isoWeekday();

            // skip doubletr pos
            if (vd.doubletr) {
              if (vd.pos != first_doubletr_pos[vd.tarif]) {
                vorr_pos = (vorr_pos + 1) % rove.dates.vorrangig_cnts.length;
                continue;
              }
            }

            // if freq has been fulfilled in current week go to next week
            if (vorr_used == vorr_freq && !next_week) {
              var next_found = false;
              while (previous_dates.length != 0) {
                var next_date = previous_dates.shift();
                if (moment(next_date).week() != week) {
                  next_found = true;
                  curr_date = next_date;
                  break;
                }
              }

              if (!next_found) {
                curr_date.setDate(curr_date.getDate() + (8 - week_day));
              }

              vorr_used = 0;
              continue;
            }

            if (next_week) {
              next_week = false;
              vorr_used = 0;
            }

            // tarif is empty, go to next tarif
            if (vd.cnt == 0) {
              vorr_pos = (vorr_pos + 1) % rove.dates.vorrangig_cnts.length;
              continue;
            }

            // add vorr.tarife to groups
            // CASE 1: this date entry already exists
            if (curr_date in groups) {
              groups[curr_date].push(vd.pos);
            }
            // CASE 2: new date entry has to be created
            else {
              groups_keys.push(new Date(curr_date.getFullYear(), curr_date.getMonth(), curr_date.getDate()));
              groups[curr_date] = [vd.pos];
            }

            // reduce vorr_cnt
            rove.dates.vorrangig_cnts[vorr_pos].cnt -= 1;

            // reduce double_treatment cnt
            if (vd.doubletr) {
              for (var i = 0; i < rove.dates.vorrangig_cnts.length; i++) {
                var dtr = rove.dates.vorrangig_cnts[i];
                if (dtr.doubletr && i != vorr_pos) {
                  if (dtr.pos != first_doubletr_pos[dtr.tarif] && dtr.tarif == vd.tarif) {
                    dtr.cnt -= 1;
                    groups[curr_date].push(dtr.pos);
                  }
                }
              }
            }

            // take care of mehrfach_cnts
            for (var m = 0; m < rove.dates.mehrfach_cnts.length; m++) {
              var md = rove.dates.mehrfach_cnts[m];

              // if nothing left to group go to next tarif
              if (md.cnt == 0) {
                continue;
              }

              // push tarif/pos to group
              groups[curr_date].push(md.pos);
              rove.dates.mehrfach_cnts[m].cnt -= 1;
            }

            var take_previous_date = false;

            if (previous_dates.length != 0) {
              if (vorr_used + 1 < vorr_freq && moment(previous_dates[0]).week() == week) {
                take_previous_date = true;
              }

              if (vorr_used + 1 == vorr_freq) {
                take_previous_date = true;
              }
            }

            if (take_previous_date) {
              // select date from previous selection
              if (moment(previous_dates[0]).isSame(moment(curr_date))) {
                // shift once more to remove duplicate entry
                previous_dates.shift();
              }
              if (previous_dates.length != 0) {
                curr_date = previous_dates.shift();
              } else {
                take_previous_date = false;
              }
            }

            if (!take_previous_date) {
              // increase date by one day - skip weekend
              if (week_day == 5) {
                curr_date.setDate(curr_date.getDate() + 3);
              } else {
                curr_date.setDate(curr_date.getDate() + 1);
              }
            }

            if (week != moment(curr_date).week()) {
              next_week = true;
            }

            // select next vorr_tarif
            vorr_pos = (vorr_pos + 1) % rove.dates.vorrangig_cnts.length;

            // increase used count
            vorr_used += 1;
          }
        }

        // order/sort groups_keys by ascending date
        groups_keys.sort(function (a, b) {
          return a.valueOf() - b.valueOf();
        });

        // calculate groups
        var j = 1; // running id
        var g = 1; // curr group number

        for (var i = 0; i < groups_keys.length; i++) {
          var sel_date = groups_keys[i];
          var entries = groups[sel_date];

          for (var e = 0; e < entries.length; e++) {
            var entry = entries[e];
            var first = e == 0 ? true : false;
            var last = e == entries.length - 1 ? true : false;

            // change 02.10.2019
            var pos_id = rove.positions[entry].id;

            // Vollprivat logic
            if (rove.general.rezeptart_selected.id == "VPP") {
              var heilmittel = rove.positions[entry].heilmittel;
              var bezeichnung = heilmittel.bezeichnung;
              var available = true;
            } else {
              var diff_1 = rove.dateDiffInDays(rove.general.preislisten.neu.date_from, sel_date);
              var diff_2 = rove.dateDiffInDays(rove.general.preislisten.alt.date_from, sel_date);
              var heilmittel = null;
              var bezeichnung = null;
              var available = false;

              if (
                (rove.general.preistyp == "mix" && diff_1 >= 0) ||
                (rove.general.preistyp == "mix_alt" && diff_2 >= 0)
              ) {
                for (h = 0; h < rove.general.heilmittel_neu.length; h++) {
                  if (rove.general.heilmittel_neu[h].tarif == rove.positions[entry].heilmittel.tarif) {
                    // set updated tarif
                    var heilmittel = rove.general.heilmittel_neu[h];
                    var bezeichnung = rove.general.heilmittel_neu[h].bezeichnung;
                    var available = true;

                    break;
                  }
                }

                // assume no matching tarif was found in tarif table
                if (!heilmittel) {
                  var heilmittel = rove.positions[entry].heilmittel;
                  var bezeichnung = heilmittel.bezeichnung;

                  // Try to find matching tarif once again using the "bezeichnung" field
                  for (h = 0; h < rove.general.heilmittel_neu.length; h++) {
                    if (rove.general.heilmittel_neu[h].bezeichnung == rove.positions[entry].heilmittel.bezeichnung) {
                      // set updated tarif
                      var heilmittel = rove.general.heilmittel_neu[h];
                      var bezeichnung = rove.general.heilmittel_neu[h].bezeichnung;
                      var available = true;

                      break;
                    }
                  }

                }
              } else {
                var heilmittel = rove.positions[entry].heilmittel;
                var bezeichnung = heilmittel.bezeichnung;
                var available = true;
              }
            }

            // set suggested dates if requested
            if (!suggest_dates && g != 1) {
              var datum1 = null;
              var weekday1 = null;
            } else {
              var datum1 = new Date(sel_date.getFullYear(), sel_date.getMonth(), sel_date.getDate());
              var weekday1 = rove.getweekday(sel_date);
            }

            // double treatment flag for new rezepte
            var doubletr = rove.positions[entry].doubletr;

            var entryA = {
              id: j,
              pos_id: pos_id,
              group: g,
              first: first,
              last: last,
              old_pos: j,
              weekday: weekday1,
              datum: datum1,
              heilmittel: heilmittel,
              bezeichnung: bezeichnung,
              available: available,
              unterbrechung: false,
              unterbrechung_grund: null,
              mitarbeiter: rove.mitarbeiter.filter(function (e) {
                return e.id == rove.patient.mitarbeiter;
              })[0],
              doubletr: doubletr,
            };

            var entryB = {
              id: j,
              pos_id: pos_id,
              group: g,
              first: first,
              last: last,
              old_pos: j,
              weekday: weekday1,
              datum: datum1,
              heilmittel: heilmittel,
              bezeichnung: bezeichnung,
              available: available,
              unterbrechung: false,
              unterbrechung_grund: null,
              mitarbeiter: rove.mitarbeiter.filter(function (e) {
                return e.id == rove.patient.mitarbeiter;
              })[0],
              doubletr: doubletr,
            };

            print("entryA", id, pod_id, doubletr)


            new_daten_entries.push(entryA);
            new_original_entries.push(entryB);

            j += 1;
          }

          g += 1;
        }

        // overwrite when done with parsing
        rove.dates.daten_entries = new_daten_entries;
        rove.dates.original_entries = new_original_entries;

        rove.dates.position_cnts = [];
      };

      rove.checkifallZero = function (arr) {
        var zero = true;
        for (var z = 0; z < arr.length; z++) {
          if (arr[z] != 0) {
            zero = false;
          }
        }
        return zero;
      };

      rove.updateDaten = function () {
        if (rove.positionsChanged) {

          // 1. Update/Replace existing tarife (heilmittel/bezeichnung) in groups
          if (rove.positionsChanges.heilmittelChanges) {
            for (var i = 0; i < rove.dates.daten_entries.length; i++) {
              var hChangeEntry = rove.positionsChanges.heilmittelChanges.find(function (a) {
                return a.posId === rove.dates.daten_entries[i].pos_id;
              });

              if (hChangeEntry) {
                rove.dates.daten_entries[i].heilmittel = hChangeEntry.heilmittel;
                rove.dates.daten_entries[i].bezeichnung = hChangeEntry.heilmittel.bezeichnung;
                rove.dates.daten_entries[i].available = true;
                rove.dates.original_entries[i].heilmittel = hChangeEntry.heilmittel;
                rove.dates.original_entries[i].bezeichnung = hChangeEntry.heilmittel.bezeichnung;
                rove.dates.original_entries[i].available = true;
              }
            }
          }

          // 2. Add/Remove positions from groups
          // This assumes that countChanges list is sorted by diff (desc)
          if (rove.positionsChanges.countsChanges) {
            // Iterate through the countsChanges array
            for (var i = 0; i < rove.positionsChanges.countsChanges.length; i++) {
              var entry = rove.positionsChanges.countsChanges[i];

              // Remove entries, starting from the end of the array until the count is 0
              if (entry.diff < 0) {
                var cnt = Math.abs(entry.diff);
                for (var j = rove.dates.daten_entries.length - 1; j >= 0; j--) {
                  if (rove.dates.daten_entries[j].pos_id === entry.posId) {
                    rove.dates.daten_entries.splice(j, 1);
                    rove.dates.original_entries.splice(j, 1);
                    cnt -= 1;
                  }
                  if (cnt === 0) {
                    break;
                  }
                }
              }

              // Add entries
              if (entry.diff > 0) {
                var cnt = Math.abs(entry.diff);
                var lastEntry = rove.dates.daten_entries[rove.dates.daten_entries.length - 1];
                var currGroup = 1;
                var foundInGroup = false;
                var toAdd = [];

                // Iterate through the daten_entries array and add new entries to existing groups if possible
                for (var j = 0; j < rove.dates.daten_entries.length; j++) {
                  // Break if all positions have been added
                  if (cnt === 0) {
                    break;
                  }

                  // Check if the position is already in a group (if so, skip)
                  if (foundInGroup && currGroup == rove.dates.daten_entries[j].group) {
                    continue;
                  } else {
                    // New group started
                    foundInGroup = false;
                    currGroup = rove.dates.daten_entries[j].group;
                  }

                  // Position just found in a group, skip
                  if (rove.dates.daten_entries[j].pos_id === entry.posId) {
                    foundInGroup = true;
                    continue;
                  }
                  // Position possibly not in a group (check if the current position is the last in the group)
                  else {
                    var newEntry = {
                      id: rove.dates.daten_entries.length + 1,
                      pos_id: entry.posId,
                      group: currGroup,
                      first: false,
                      last: false,
                      old_pos: rove.dates.daten_entries.length + 1,
                      weekday: rove.dates.daten_entries[j].weekday,
                      datum: rove.dates.daten_entries[j].datum,
                      heilmittel: entry.heilmittel,
                      bezeichnung: entry.heilmittel.bezeichnung,
                      available: true,
                      unterbrechung: false,
                      unterbrechung_grund: null,
                      mitarbeiter: rove.dates.daten_entries[j].mitarbeiter,
                      doubletr: false,
                    };

                    // If not foundInGroup and
                    // this is either the last daten_entries entry or
                    // the last entry in the current group, add the new entry
                    if (
                      !foundInGroup &&
                      (j + 1 === rove.dates.daten_entries.length ||
                        (j + 1 < rove.dates.daten_entries.length &&
                          rove.dates.daten_entries[j].group != rove.dates.daten_entries[j + 1].group))
                    ) {
                      toAdd.push(newEntry);
                      cnt -= 1;
                      continue;
                    }
                  }
                }

                // If there are still positions to add, create a new group
                if (cnt != 0) {
                  for (var k = 0; k < cnt; k++) {
                    var newDate = new Date(lastEntry.datum);
                    newDate.setDate(newDate.getDate() + 7 * (k + 1));
                    var idx = rove.dates.daten_entries.length - 1 + toAdd.length + (k + 1);
                    var newEntry = {
                      id: idx,
                      pos_id: entry.posId,
                      group: lastEntry.group + (k + 1),
                      first: true,
                      last: true,
                      old_pos: idx,
                      weekday: rove.getweekday(newDate),
                      datum: newDate,
                      heilmittel: entry.heilmittel,
                      bezeichnung: entry.heilmittel.bezeichnung,
                      available: true,
                      unterbrechung: false,
                      unterbrechung_grund: null,
                      mitarbeiter: lastEntry.mitarbeiter,
                      doubletr: false,
                    };
                    toAdd.push(newEntry);
                  }
                }

                // Add the new entries to the daten_entries array
                for (var l = 0; l < toAdd.length; l++) {
                  rove.dates.daten_entries.push(toAdd[l]);
                  rove.dates.original_entries.push(toAdd[l]);
                }
              }
            }
          }
        }

        // Remove error messages and reset modified fields
        rove.header_err_msg = "";
        rove.modified_fields = [];

        // Reset current errors
        for (var error in rove.date_errors) {
          rove.date_errors[error] = [];
        }

        // Refresh the data
        rove.datenRefresh();
      };

      // ########################################
      // ########################################

      // write positions based on RezeptPositionen data from database

      rove.loadPositionenData = function () {
        var seen_tarife = new Set();
        var doubletr_tarife = new Set();

        // create doubletr lookup
        if (rove.general.rezeptart_selected.id != "VPP") {
          for (var i = 0; i < rove.rezeptPositionen.length; i++) {
            var entry = rove.rezeptPositionen[i];

            // Missing tarif_nr is only possible for prescriptions that were generated from
            // wrongly entered appointment data
            if (!entry.tarif_nr) {
              continue;
            }

            var found = rove.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.tarif_nr;
            });

            var heilmittel = rove.heilmittel.filter(function (a) {
              return a.tarif === entry.tarif_nr;
            })[0];

            // doubletr only valid for vorr and non-mehrfach_kz tarife
            if (found.vorrangig && !heilmittel.mehrfach_kz && (!rove.rezeptHeader.blanko || rove.general.le_typ !== "26")) {
              if (!seen_tarife.has(entry.tarif_nr)) {
                seen_tarife.add(entry.tarif_nr);
              } else {
                doubletr_tarife.add(entry.tarif_nr);
              }
            }
          }
        }

        for (var i = 0; i < rove.rezeptPositionen.length; i++) {
          var entry = rove.rezeptPositionen[i];
          var km = null;
          var menge = null;

          if (entry.entfernung) {
            km = entry.entfernung.toString();
          }

          if (entry.verordnungsmenge) {
            menge = entry.verordnungsmenge.toString();
          }

          var heilmittel_a = null
          var heilmittel_b = null

          // heilmittel is null if tarif_nr is not found in heilmittel data
          if (entry.tarif_nr) {
            var heilmittel_a = rove.heilmittel.filter(function (a) {
              return a.tarif === entry.tarif_nr;
            })[0];
            var heilmittel_b = rove.heilmittel.filter(function (a) {
              return a.tarif === entry.tarif_nr;
            })[0];

          }

          var dataA = {
            id: entry.line_nr,
            menge: menge,
            km: km,
            heilmittel: heilmittel_a,
            doubletr: doubletr_tarife.has(entry.tarif_nr),
            invalidTarif: entry.tarif_missing,
          };

          var dataB = {
            id: entry.line_nr,
            menge: menge,
            km: km,
            heilmittel: heilmittel_b,
            doubletr: doubletr_tarife.has(entry.tarif_nr),
            invalidTarif: entry.tarif_missing,
          };

          rove.positions.push(dataA);
          rove.init_positions.push(dataB);
        }
      };

      rove.addDefaultBlankoLines = function () {
        // check if default lines are already set
        var default_lines_found = [];

        for (var i = 0; i < rove.positions.length; i++) {
          var entry = rove.positions[i];
          if (entry.heilmittel && ["20522", "54003", "20524", "54503"].includes(entry.heilmittel.tarif)){
            default_lines_found.push(entry.heilmittel.tarif);
          }
        }

        for (var i = 0; i < rove.heilmittel.length; i++) {
          var h = rove.heilmittel[i];

          if (!default_lines_found.includes(h.tarif) && ["20522", "54003", "20524", "54503"].includes(h.tarif)) {
            // Ensure that an existing line is not overwritten
            if (rove.positions[rove.positions.length - 1].heilmittel != null) {
              rove.positions.push({
                id: rove.positions.length + 1,
                heilmittel: h,
                km: null,
                doubletr: false,
                menge: "1",
              });
            }
            else {
              rove.positions[rove.positions.length - 1].menge = "1";
              rove.positions[rove.positions.length - 1].heilmittel = h;
            }
          }
        }
      };


      rove.checkBlankoLightCyclePhysio = function () {
        var vorrangigSum = 0;
        var ergaenzendSum = 0;

        for (var i = 0; i < rove.positions.length; i++) {
          // line object
          var entry = rove.positions[i];

          // check only if menge and heilmittel are provided
          if (entry.menge && entry.heilmittel) {

            // tarif info holds information regarding vorrangig/ergaenzend
            const tarifInfo = rove.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });

            if (tarifInfo) {
              if (tarifInfo.vorrangig) {
                vorrangigSum += parseInt(entry.menge);
              } else if (tarifInfo.ergaenzend) {
                ergaenzendSum += parseInt(entry.menge);
              }
            }
          }
        }

        if (vorrangigSum > rove.general.blankoLightCyclePhysioVorrangig || ergaenzendSum > rove.general.blankoLightCyclePhysioErgaenzend) {
          rove.blanko.type = "red";
          rove.blanko.title = "Ampelphase rot";
          rove.blanko.text = "Sie haben die maximale Anzahl an vorrangigen <strong>(≤" + rove.general.blankoLightCycleVorrangig + ")</strong> oder ergänzenden <strong>(≤" + rove.general.blankoLightCycleErgaenzend + ")</strong> Heilmitteln für die grüne Ampelphase überschritten! <strong><br>Es erfolgt ein Preisabschlag in Höhe von 9% auf die Behandlungen, die innerhalb der roten Phase erbracht werden.</strong>";
        }
        else {
          rove.blanko.type = "green";
          rove.blanko.title = "Ampelphase grün";
          rove.blanko.text = "Diese Ampelphase gilt bis zu einer Anzahl von <strong>" + rove.general.blankoLightCycleVorrangig + "</strong> vorrangigen und <strong>" + rove.general.blankoLightCycleErgaenzend + "</strong> ergänzenden Heilmitteln!";
        }

        return
      };


      rove.checkBlankoLightCycleErgo = function () {
        var timeUnitSum = 0;

        for (var i = 0; i < rove.positions.length; i++) {
          var entry = rove.positions[i];
          if (entry.menge && entry.heilmittel) {
            var name = entry.heilmittel.bezeichnung;

            if (name.includes("1ZI")) {
              timeUnitSum += parseInt(entry.menge);
            }
          }
        }

        if (timeUnitSum <= rove.general.blankoLightCycleErgoGreenLimit){
          const max_limit = rove.general.blankoLightCycleErgoGreenLimit;

          rove.blanko.type = "green";
          rove.blanko.title = "Ampelphase grün";
          rove.blanko.text = "Diese Ampelphase gilt bis zu <strong>" + max_limit + "</strong> Zeitintervallen! Aktuelle Anzahl: <strong>" + timeUnitSum + " ZI</strong>";
        }
        else if (rove.general.blankoLightCycleErgoGreenLimit < timeUnitSum && timeUnitSum <= rove.general.blankoLightCycleErgoYellowLimit){
          const min_limit = rove.general.blankoLightCycleErgoGreenLimit + 1;
          const max_limit = rove.general.blankoLightCycleErgoYellowLimit;

          rove.blanko.type = "yellow";
          rove.blanko.title = "Ampelphase gelb";
          rove.blanko.text = "Diese Ampelphase gilt bei <strong>" + min_limit + "</strong> bis <strong>" + max_limit + "</strong> Zeitintervallen! Aktuelle Anzahl: <strong>" + timeUnitSum + " ZI</strong>";
        }
        else {
          const min_limit = rove.general.blankoLightCycleErgoYellowLimit + 1;

          rove.blanko.type = "red";
          rove.blanko.title = "Ampelphase rot";
          rove.blanko.text = "Diese Ampelphase gilt ab <strong>" + min_limit + "</strong> Zeitintervallen! Aktuelle Anzahl: <strong>" + timeUnitSum + " ZI</strong> <strong><br>Es erfolgt ein Preisabschlag in Höhe von 9% auf die ZI innerhalb der roten Phase.</strong>";
        }

        return
      };

      rove.checkBlankoLightCycle = function () {
        if (!rove.rezeptHeader.blanko || (!rove.general.blankoLightCyclePhysio && !rove.general.blankoLightCycleErgo)) {
          return;
        }

        if (rove.general.le_typ == "22") {
          rove.checkBlankoLightCyclePhysio();
        } else if (rove.general.le_typ == "26") {
          rove.checkBlankoLightCycleErgo();
        }

        return;
      };


      rove.refreshPositionenData = function () {
        rove.diagnose_data_loaded = false;

        var promise = rove.getDiagnoseTarifData();

        promise.then(function (data) {
          var heilmittel_promise = rove.getHeilmittelData();
          heilmittel_promise.then(function (data) {
            // next check existing Positionen to update/reset certain fields
            for (var i = 0; i < rove.positions.length; i++) {
              var entry = rove.positions[i];

              if (entry.heilmittel) {
                // look for matching entry in new heilmittel data
                var match = rove.heilmittel.filter(function (a) {
                  return a.bezeichnung === entry.heilmittel.bezeichnung;
                });

                // found old entry name in new data
                if (match.length != 0) {
                  var matched_heilmittel = match[0];

                  rove.positions[i].heilmittel = matched_heilmittel;
                }
                // old entry not found in new data, reset Heilmittel
                else {
                  // reset heilmittel and km
                  rove.positions[i].heilmittel = null;
                  rove.positions[i].km = null;
                }
              }
            }

            // if Blanko is set to true, check if default entry is available
            if (rove.rezeptHeader.blanko) {
              rove.addDefaultBlankoLines();
              rove.checkBlankoLightCycle();
            }

            // check PostionenInput once data is refreshed
            rove.checkPositionenInput();

            rove.diagnose_data_loaded = true;
          });
        });
      };

      rove.updatePosition = function () {
        // try to automatically fix possible issues
        if (rove.modified_fields.indexOf("Therapiebericht") != -1) {
          // first check actual status of therapiebericht
          var is_included = false;
          var tarif_lkp = ["19701", "29701", "59701", "89701", "20553"];

          for (var i = 0; i < rove.positions.length; i++) {
            var entry = rove.positions[i];
            if (entry.heilmittel && tarif_lkp.includes(entry.heilmittel.tarif)) {
              is_included = true;
              break;
            }
          }

          // add missing therapiebericht
          if (rove.init_vals.therapiebericht) {
            if (!is_included) {
              for (var i = 0; i < rove.heilmittel.length; i++) {
                var entry = rove.heilmittel[i];
                if (tarif_lkp.includes(entry.tarif)) {
                  var data = {
                    id: rove.positions.length + 1,
                    menge: null,
                    km: null,
                    heilmittel: entry,
                  };

                  rove.positions.push(data);

                  break;
                }
              }
            }
          }

          // remove therapiebericht
          else if (!rove.init_vals.therapiebericht) {
            if (is_included) {
              for (var i = 0; i < rove.positions.length; i++) {
                var entry = rove.positions[i];
                if (tarif_lkp.includes(entry.tarif)) {
                  rove.positions.splice(i, 1);
                  break;
                }
              }

              // iterate over again to fix ids
              for (var i = 0; i < rove.positions.length; i++) {
                rove.positions[i].id = i + 1;
              }
            }
          }
        }

        // Diagnose/Blanko field was changed which should result in a change of HeilmittelData
        if (rove.modified_fields.includes("Diagnose") || rove.modified_fields.includes("Blanko")) { 
          rove.refreshPositionenData();
        }
        else {

          if (rove.rezeptHeader.blanko) {
            rove.checkBlankoLightCycle();
          }

          rove.checkPositionenInput();
        }
      };

      rove.checkPositionChanges = function () {
        var changesFound = false;
        var changes = {
          countsChanges: [],
          heilmittelChanges: [],
        };

        for (var i = 0; i < rove.positions.length; i++) {
          var curr = rove.positions.at(i);
          var init = rove.init_positions.at(i);

          // Reset invalidTarif flag
          if (curr.heilmittel) {
            curr.invalidTarif = false;
          }

          if (curr.menge && curr.heilmittel) {
            if (init) {

              if (init.menge != curr.menge) {
                // Store change in counts
                // e.g. if init was 2 and now it is 4 -> {posId: 1, diff: 2}
                // e.g. if init was 4 and now it is 2 -> {posId: 1, diff: -2}
                changes.countsChanges.push({
                  posId: curr.id,
                  diff: curr.menge - init.menge,
                  heilmittel: curr.heilmittel,
                });
                changesFound = true;
              }

              if (!init.heilmittel || init.heilmittel.id != curr.heilmittel.id) {
                // Store the new heilmittel
                changes.heilmittelChanges.push({ posId: curr.id, heilmittel: curr.heilmittel });
                changesFound = true;
              }

            } else {
              // Position was added (compared to initial data)
              changes.countsChanges.push({
                posId: curr.id,
                diff: curr.menge,
                heilmittel: curr.heilmittel,
              });
              changesFound = true;
            }
          }
        }

        // Positions were removed (compared to initial data)
        if (rove.positions.length < rove.init_positions.length) {
          changesFound = true;
          // Iterate over remaining initial positions e.g. start with rove.positions.length
          for (var i = rove.positions.length; i < rove.init_positions.length; i++) {
            var init = rove.init_positions[i];
            changes.countsChanges.push({
              posId: init.id,
              diff: -init.menge,
              heilmittel: init.heilmittel,
            });
          }
        }

        // Order countsChanges by diff (desc)
        // This way we ensure we start with adding new entries first
        // and remove/reduce entries later
        changes.countsChanges.sort(function (a, b) {
          return b.diff - a.diff;
        });

        return [changesFound, changes];
      };

      rove.has_affected_lines = function () {
        for (var type in rove.affected_lines) {
          if (!rove.affected_lines.hasOwnProperty(type)) {
            //The current error is not a direct property of p
            continue;
          }
          if (rove.affected_lines[type].length != 0) {
            return true;
          }
        }
        return false;
      };

      rove.onlyPhysioErgo = function () {
        var isPhysErgo = ["PHYS", "ERGO"].indexOf(rove.general.heilmittelbereich) != -1
        var onlyRelevantDiagnoses = ["PPPP", "BWBW", "KURE", "BGBG", "9999"].indexOf(rove.general.diagnose_key) == -1
        return isPhysErgo && onlyRelevantDiagnoses
      }

      rove.checkPositionenInput = function () {
        // reset current errors
        for (var error in rove.position_errors) {
          rove.position_errors[error] = [];
        }

        // Check for possible changes in position data
        var changeData = rove.checkPositionChanges();
        rove.positionsChanged = changeData[0];
        rove.positionsChanges = changeData[1];

        // object to store line numbers that have an issue
        rove.affected_lines = {
          mengenotnumber: [],
          mengenotprovided: [],
          heilmittel: [],
          kmnotnumber: [],
          usedcount: [],
          mengetoohigh: [],
          vorrangig_toohigh: [],
          optional_toohigh: [],
          ergaenzend_toohigh: [],
          ergaenzend_alone: [],
          none_alone: [],
          invalidcombination: [],
          invalidcombination_a_b: [],
          vorrangig_toohigh_3: [],
          vorrangig_toohigh_za: [],
          ergo_funktionsanalyse: [],
          bandage_mld: [],
          ergaenzend_duplicated: [],
          vorrangig_inconsistent: [],
          v_mengetoohigh: [],
          e_mengetoohigh: [],
          mehrfach_mengetoohigh: [],
          none_mengetoohigh: [],
          vorrangig_mehrfach: [],
          invalid_tarif: [],
          blanko_diagnostik_toohigh: [],
          max_1_entry: [],
        };

        // reset anchor scroll position
        rove.error_scroll = null;

        // check if hausbesuch position was selected in header
        if (rove.rezeptHeader.hausbesuch == true) {
          var missing = true;

          for (i = 0; i < rove.positions.length; i++) {
            var entry = rove.positions[i];
            if (entry.heilmittel) {
              if (
                entry.heilmittel.bezeichnung.indexOf("Hausbesuch") !== -1 ||
                entry.heilmittel.bezeichnung.indexOf("Heimbesuch") !== -1
              ) {
                missing = false;
                break;
              }
            }
          }

          if (missing) {
            rove.position_errors.messages.push("Hausbesuch/Heimbesuch fehlt!");
          }
        }
        // if hausbesuch position was not selected in header
        else {
          var set = false;

          for (i = 0; i < rove.positions.length; i++) {
            var entry = rove.positions[i];
            if (entry.heilmittel) {
              if (
                entry.heilmittel.bezeichnung.includes("Hausbesuch") ||
                entry.heilmittel.bezeichnung.includes("Heimbesuch")
              ) {
                set = true;
                break;
              }
            }
          }

          if (set) {
            rove.position_errors.messages.push(
              "Hausbesuch/Heimbesuch ausgewählt! Eingabe entspricht nicht der Auswahl in den Kopfdaten!"
            );
          }
        }

        // check if default blanko positions are missing and also if the additional diagnotic isn't too high
        if (rove.rezeptHeader.blanko){
          var defaultTarifLkpPD = ["20522", "54003"];
          var defaultTarifLkpPau = ["20524", "54503"];

          var missingPD = true;
          var missingPau = true;

          for (var i = 0; i < rove.positions.length; i++) {
            var entry = rove.positions[i];
            if (entry.heilmittel) {
              if (defaultTarifLkpPD.includes(entry.heilmittel.tarif)) {
                missingPD = false;
              }

              if (defaultTarifLkpPau.includes(entry.heilmittel.tarif)) {
                missingPau = false;
              }
            }
          }

          if (missingPD) {
            if (rove.general.le_typ == "22") {
              rove.position_errors.messages.push("Physiotherapeutische Diagnostik fehlt!");
            } else if (rove.general.le_typ == "26") {
              rove.position_errors.messages.push("Analyse ergotherapeutischer Bedarf fehlt!");
            }
          }

          if (missingPau) {
            rove.position_errors.messages.push("Versorgungspauschale Blanko-VO fehlt!");
          }

        }

        var tarif_lkp = ["19701", "29701", "59701", "89701", "20553", "59741"];
        // check if therapiebericht position was selected in header
        if (rove.rezeptHeader.therapiebericht == true) {
          var missing = true;
          for (i = 0; i < rove.positions.length; i++) {
            var entry = rove.positions[i];
            if (entry.heilmittel) {
              if (tarif_lkp.includes(entry.heilmittel.tarif)) {
                missing = false;
                break;
              }
            }
          }

          if (missing) {
            var tarif_entry_available = false;
            for (var i = 0; i < rove.heilmittel.length; i++) {
              var t = rove.heilmittel[i]["tarif"];
              if (tarif_lkp.includes(t)) {
                tarif_entry_available = true;
                break;
              }
            }

            if (tarif_entry_available) {
              rove.position_errors.messages.push("Arztbericht/Therapiebericht fehlt!");
            }
          }
        }
        // if therapiebericht position was not selected in header
        else {
          var set = false;

          for (i = 0; i < rove.positions.length; i++) {
            var entry = rove.positions[i];
            if (entry.heilmittel) {
              if (tarif_lkp.includes(entry.heilmittel.tarif)) {
                set = true;
                break;
              }
            }
          }

          if (set) {
            rove.position_errors.messages.push(
              "Arztbericht ausgewählt! Eingabe entspricht nicht der Auswahl in den Kopfdaten!"
            );
          }
        }

        // check if data was provided
        rove.general.lim = rove.general.diagnose.max_vo;

        // Overwrite limit for blanko light cycle
        // Blanko light cycle as it has no upper limit for the amount of entries
        if (
          rove.general.blankoLightCyclePhysio || 
          (rove.rezeptHeader.blanko && rove.general.le_typ == "26")
        ) {
          rove.general.lim = 300;
        }

        rove.general.multiplier = rove.rezeptHeader.frequenz ? rove.rezeptHeader.frequenz : 1;

        var tarifSummary = {};

        for (var i = 0; i < rove.positions.length; i++) {
          var entry = rove.positions[i];
          var num = i + 1;

          // mehrfach-kennzeichen
          var mehrfkz = false;

          if (!entry.heilmittel) {
            rove.affected_lines.heilmittel.push(num);
          } else {
            mehrfkz = entry["heilmittel"]["mehrfach_kz"];

            if (tarifSummary[entry.heilmittel.tarif]) {
              tarifSummary[entry.heilmittel.tarif].cnt += 1;
              tarifSummary[entry.heilmittel.tarif].pos.push(num);
            }
            else {
              tarifSummary[entry.heilmittel.tarif] = {cnt: 1, pos: [num]};
            }

          }

          if (entry.invalidTarif) {
            rove.affected_lines.invalid_tarif.push(num);
          }

          if (!entry.menge) {
            rove.affected_lines.mengenotprovided.push(num);
          } else {
            if (!rove.isNumber(entry.menge) || entry.menge <= 0) {
              rove.affected_lines.mengenotnumber.push(num);
            } else if (!mehrfkz && rove.general.diagnose_key != "PPPP" && rove.general.diagnose_key != "BGBG") {
              // BWBW, KURE are set to max_vo 999
              // default limit
              if (
                (rove.rezeptHeader.langf || rove.rezeptHeader.besond || rove.rezeptHeader.genehmigung) &&
                !(rove.rezeptHeader.blanko && rove.general.le_typ == "26")
              ) {
                rove.general.lim = 12 * rove.general.multiplier;
              }

              if (entry.menge > rove.general.lim) {
                rove.affected_lines.usedcount.push(num);
              }
            }
          }

          // check if provided km-distance is a number (and) greater 0
          if (entry.km) {
            if (!rove.isNumber(entry.km) || entry.km < 0) {
              rove.affected_lines.kmnotnumber.push(num);
            }
          }
        }



        // Check if the same tarif was selected multiple times (compare with tarifWithLockedMenge)
        for (var tarif in tarifSummary) {
          if (tarifSummary[tarif].cnt > 1 && rove.tarifWithLockedMenge.find(function(entry) { return entry.tarif === tarif; })) {
            rove.affected_lines.max_1_entry = rove.affected_lines.max_1_entry.concat(tarifSummary[tarif].pos);
          }
        }

        // Kompressionsbandage - MLD check
        if (["PHYS", "KURE"].indexOf(rove.general.heilmittelbereich) != -1) {
          var kb_pos = [];
          var mld_found = false;

          for (i = 0; i < rove.positions.length; i++) {
            var entry = rove.positions[i];
            if (entry.heilmittel) {
              var kb_lkp = ["10204", "20204", "80204", "8403a", "19d"];
              var mld_lkp = [
                "10201",
                "20201",
                "10202",
                "20202",
                "10205",
                "20205",
                "80201",
                "80202",
                "80205",
                "8402",
                "8403",
                "19a",
                "19b",
                "19c",
              ];
              var tarif = entry.heilmittel.tarif;

              if (kb_lkp.includes(tarif)) {
                var idx = rove.positions
                  .map(function (x) {
                    return x.id;
                  })
                  .indexOf(entry.id);

                kb_pos.push(idx + 1);
              }

              if (mld_lkp.includes(tarif)) {
                mld_found = true;
              }
            }
          }

          if (kb_pos.length != 0 && !mld_found) {
            rove.affected_lines.bandage_mld = kb_pos;
          }
        }

        // check whether selected heilmittel combination is valid
        if (
          rove.affected_lines.heilmittel.length == 0 &&
          rove.onlyPhysioErgo()
        ) {
          var type_collection = {
            A: [],
            C: [],
            lone: [],
            none: [],
          };

          // NEW
          var tarife_seen = [];

          var cnt_funktionsanalyse = 0;
          var ergo_funktionsanalyse = [];

          // Lone belongs to C but is considered a special case
          for (var i = 0; i < rove.positions.length; i++) {
            var num = i + 1;
            var entry = rove.positions[i];
            var found = rove.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });
            var tarif = entry.heilmittel.tarif;
            var menge = parseInt(entry.menge);

            // ERGO - 54002 (Funktionsanalyse can only be selected once!)
            if (tarif == "54002" && menge >= 1) {
              cnt_funktionsanalyse += menge;
              ergo_funktionsanalyse.push(num);
            }

            if (!tarife_seen.includes(tarif)) {
              tarife_seen.push(tarif);
              if (found.ergaenzend && found.alleinstehend || found.ergaenzend) {
                type_collection.C.push(num);
              } else if (found.alleinstehend) {
                type_collection.lone.push(num);
              } else if (found.vorrangig && !entry.heilmittel.mehrfach_kz) {
                type_collection.A.push(num);
              } else {
                // should not be used
                type_collection.none.push(num);
              }
            }
          }

          // ERGO - 54002 (Funktionsanalyse can only be selected once!)
          if (
            rove.general.heilmittelbereich == "ERGO" &&
            (ergo_funktionsanalyse.length > 1 || cnt_funktionsanalyse > 1)
          ) {
            rove.affected_lines.ergo_funktionsanalyse = ergo_funktionsanalyse;
          }

          var a_len = type_collection.A.length;
          var c_len = type_collection.C.length;
          var lone_len = type_collection.lone.length;
          var none_len = type_collection.none.length;

          if (a_len > 3) {
            rove.affected_lines.vorrangig_toohigh_3 = type_collection.A;
          } else if (rove.rezeptHeader.rezeptart == "ZA" && a_len > 1) {
            rove.affected_lines.vorrangig_toohigh_za = type_collection.A;
          } else if (c_len > 1) {
            rove.affected_lines.ergaenzend_toohigh = type_collection.C;
          } else if (c_len == 1 && a_len == 0) {
            rove.affected_lines.ergaenzend_alone = type_collection.C;
          } else if (none_len > 0 && a_len == 0 && lone_len == 0) {
            rove.affected_lines.none_alone = type_collection.none;
          }
        }

        // only check if no previous errors were found
        if (
          !rove.has_affected_lines() &&
          rove.onlyPhysioErgo()
        ) {
          var seen_heilmittel = [];
          var sums = {
            vorrangig: 0,
            unique_vorrangig: 0,
            ergaenzend: 0,
            alleinstehend: 0,
          };

          // check if vorrangig in positionen
          var vorr_available = false;
          for (var i = 0; i < rove.positions.length; i++) {
            var entry = rove.positions[i];
            var found = rove.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });

            if (found.vorrangig) {
              vorr_available = true;
              break;
            }
          }

          // ids for v_mengetoohigh
          var vorrangig_ids = [];

          // ids for e_mengetoohigh
          var ergaenzend_ids = [];

          // sum and ids for none_mengetoohigh
          var none_ids = [];

          // for mehrfach_mengetoohigh
          var mehrfach_ids = [];

          // reset doubletr
          for (var i = 0; i < rove.positions.length; i++) {
            rove.positions[i].doubletr = false;
          }

          for (var i = 0; i < rove.positions.length; i++) {
            // line object
            var entry = rove.positions[i];

            // current sum
            var sum = 0;

            var inconsistent = false;

            // line position of related heilmittel
            var hm_pos = [];

            // check only if menge and heilmittel are provided
            if (entry.menge && entry.heilmittel) {
              var mehrfkz = entry["heilmittel"]["mehrfach_kz"];
              var hm = entry.heilmittel.bezeichnung;
              hm_pos.push(i + 1);

              if (mehrfkz) {
                mehrfach_ids.push([i + 1, parseInt(entry.menge)]);
              }

              // ignore if heilmittel was already seen or mehrfach_kz is true
              else if (!seen_heilmittel.includes(hm)) {
                seen_heilmittel.push(hm);

                sum += parseInt(entry.menge);

                for (var j = 0; j < rove.positions.length; j++) {
                  var next = rove.positions[j];
                  
                  // check only new entries
                  // checks double treatments and inconsistent counts (menge) e.g. if you are using double treatments
                  // the amount of treatments must be the same
                  // double treatments also mean that only two entries are allowed e.g. 2x KG + 2x KG
                  if (j > i && next.menge && next.heilmittel) {
                    if (next.heilmittel.bezeichnung == hm) {

                      if (!rove.rezeptHeader.blanko || rove.general.le_typ !== "26"){

                        // set double treatment flag
                        rove.positions[j].doubletr = true;
                        rove.positions[i].doubletr = true;

                        if (next.menge != entry.menge) {
                          inconsistent = true;
                        }
                      }

                      sum += parseInt(next.menge);
                      hm_pos.push(j + 1);
                    }
                  }
                }

                var found = rove.general.diagnose_tarif_lkp.find(function (element) {
                  return element.tarif == entry.heilmittel.tarif;
                });

                if (found.vorrangig) {
                  if (inconsistent) {
                    rove.affected_lines.vorrangig_inconsistent.push(hm_pos);
                  }

                  if (hm_pos.length > 2) {
                    if (!rove.rezeptHeader.blanko || rove.general.le_typ !== "26"){
                      rove.affected_lines.vorrangig_mehrfach.push(hm_pos);
                    }
                  }

                  sums.vorrangig += sum;

                  if (rove.rezeptHeader.blanko && rove.general.le_typ == "26") {
                    sums.unique_vorrangig += sum;
                  }
                  else {
                    sums.unique_vorrangig += parseInt(entry.menge);
                  }

                  vorrangig_ids.push.apply(vorrangig_ids, hm_pos);
                } else if (found.ergaenzend || found.alleinstehend) {
                  if (!vorr_available && found.alleinstehend) {
                    sums.alleinstehend += sum;
                  } else if (found.ergaenzend) {
                    sums.ergaenzend += sum;
                    ergaenzend_ids.push.apply(ergaenzend_ids, hm_pos);
                  }

                  if (hm_pos.length > 1) {
                    // duplicated ergaenzend entries found
                    rove.affected_lines.ergaenzend_duplicated.push.apply(
                      rove.affected_lines.ergaenzend_duplicated,
                      hm_pos
                    );
                  }
                } else {
                  // none case (neither vorranging, ergaenzend nor alleinstehend)
                  none_ids.push([hm_pos, sum]);
                }
              }
            }
          }

          if (sums.vorrangig > rove.general.lim) {
            rove.affected_lines.v_mengetoohigh.push.apply(rove.affected_lines.v_mengetoohigh, vorrangig_ids);
          }

          if (sums.unique_vorrangig > 0 && sums.ergaenzend > sums.unique_vorrangig && ergaenzend_ids.length == 1) {
            rove.affected_lines.e_mengetoohigh = [sums.unique_vorrangig, ergaenzend_ids];
          }

          // check none_ids
          for (var i = 0; i < none_ids.length; i++) {
            if (sums.unique_vorrangig > 0) {
              if (none_ids[i][1] > sums.unique_vorrangig) {
                rove.affected_lines.none_mengetoohigh.push([none_ids[i][0], sums.unique_vorrangig]);
              }
            } else if (sums.alleinstehend > 0) {
              if (none_ids[i][1] > sums.alleinstehend) {
                rove.affected_lines.none_mengetoohigh.push([none_ids[i][0], sums.alleinstehend]);
              }
            }
          }
          // check mehrfach_ids agains sums.unique_vorrangig
          for (var i = 0; i < mehrfach_ids.length; i++) {
            if (mehrfach_ids[i][1] > sums.unique_vorrangig) {
              rove.affected_lines.mehrfach_mengetoohigh.push([mehrfach_ids[i][0], sums.unique_vorrangig]);
            }
          }
        }

        if (rove.position_errors.positions.length == 0) {
          rove.position_errors.positions = {
            menge: [],
            heilmittel: [],
            km: [],
            usedcount: [],
          };
        }

        // iterate over affected_lines to check for errors / to create error messages
        for (var type in rove.affected_lines) {
          if (!rove.affected_lines.hasOwnProperty(type)) {
            //The current error is not a direct property of p
            continue;
          }
          if (rove.affected_lines[type].length != 0) {
            if (type == "mengenotnumber") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].join(", ") +
                ": Menge ist keine gültige Zahl oder ist kleiner/gleich 0!"
              );
              rove.position_errors.positions.menge = rove.position_errors.positions.menge.concat(
                rove.affected_lines[type]
              );
            } else if (type == "mengenotprovided") {
              rove.position_errors.messages.push(
                "Eintrag " + rove.affected_lines[type].join(", ") + ": Es wurde keine Menge angegeben!"
              );
              rove.position_errors.positions.menge = rove.position_errors.positions.menge.concat(
                rove.affected_lines[type]
              );
            } else if (type == "heilmittel") {
              rove.position_errors.messages.push(
                "Eintrag " + rove.affected_lines[type].join(", ") + ": Es wurde kein Heilmittel ausgewählt!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "kmnotnumber") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].join(", ") +
                ": Kilomenter-Angabe ist keine gültige Zahl oder ist kleiner/gleich 0!"
              );
              rove.position_errors.positions.km = rove.position_errors.positions.km.concat(rove.affected_lines[type]);
            } else if (type == "usedcount") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].join(", ") +
                ": Maximale Verordnungsmenge von " +
                rove.general.lim +
                " überschritten!"
              );

              rove.position_errors.positions.usedcount = rove.position_errors.positions.usedcount.concat(
                rove.affected_lines[type]
              );
            } else if (type == "v_mengetoohigh") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].join(", ") +
                ": Maximale Verodnungsmenge (in Summe) von " +
                rove.general.lim +
                " überschritten!"
              );
              rove.position_errors.positions.usedcount = rove.position_errors.positions.usedcount.concat(
                rove.affected_lines[type]
              );
            } else if (type == "e_mengetoohigh") {
              var ids = rove.affected_lines[type][1];
              rove.position_errors.messages.push(
                "Eintrag " +
                ids.sort().join(", ") +
                ": Maximale Verodnungsmenge für ergänzende Heilmittel von " +
                rove.affected_lines[type][0] +
                " überschritten!"
              );
              rove.position_errors.positions.usedcount = rove.position_errors.positions.usedcount.concat(ids);
            } else if (type == "ergaenzend_duplicated") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Mehrfachbehandlung für ergänzende Heilmittel ist nicht erlaubt!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "vorrangig_inconsistent") {
              for (i = 0; i < rove.affected_lines[type].length; i++) {
                rove.position_errors.messages.push(
                  "Eintrag " +
                  rove.affected_lines[type][i].join(", ") +
                  ": Menge bei Mehrfachbehandlungen darf nicht unterschiedlich sein!"
                );
                rove.position_errors.positions.usedcount = rove.position_errors.positions.usedcount.concat(
                  rove.affected_lines[type][i]
                );
              }
            } else if (type == "vorrangig_toohigh") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Es darf nur ein vorrangiges Heilmittel ausgewählt werden!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "vorrangig_mehrfach") {
              for (i = 0; i < rove.affected_lines[type].length; i++) {
                rove.position_errors.messages.push(
                  "Eintrag " +
                  rove.affected_lines[type][i].join(", ") +
                  ": Für vorranginge Heilmittel sind nur Einzel- oder Doppelbehandlungen erlaubt!"
                );
                rove.position_errors.positions.usedcount = rove.position_errors.positions.usedcount.concat(
                  rove.affected_lines[type][i]
                );
              }
            } else if (type == "mehrfach_mengetoohigh") {
              for (i = 0; i < rove.affected_lines[type].length; i++) {
                rove.position_errors.messages.push(
                  "Eintrag " +
                  rove.affected_lines[type][i][0] +
                  ": Maximale Verodnungsmenge von " +
                  rove.affected_lines[type][i][1] +
                  " überschritten!"
                );
                rove.position_errors.positions.menge = rove.position_errors.positions.menge.concat([
                  rove.affected_lines[type][i][0],
                ]);
              }
            } else if (type == "none_mengetoohigh") {
              for (i = 0; i < rove.affected_lines[type].length; i++) {
                rove.position_errors.messages.push(
                  "Eintrag " +
                  rove.affected_lines[type][i][0] +
                  ": Maximale Verodnungsmenge von " +
                  rove.affected_lines[type][i][1] +
                  " überschritten!"
                );
                rove.position_errors.positions.menge = rove.position_errors.positions.menge.concat(
                  rove.affected_lines[type][i][0]
                );
              }
            } else if (type == "optional_toohigh") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Es darf nur ein optionales Heilmittel ausgewählt werden!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "ergaenzend_toohigh") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Es darf nur ein ergänzendes Heilmittel ausgewählt werden!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "invalidcombination") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Eine Kombination aus vorrangigen, optionalen und ergänzenden Heilmitteln ist nicht erlaubt!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "ergaenzend_alone") {
              var msg = ": Ergänzende Heilmittel nur in Kombination mit einem vorrangigen Heilmittel möglich!";
              rove.position_errors.messages.push("Eintrag " + rove.affected_lines[type].sort().join(", ") + msg);
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "none_alone") {
              rove.position_errors.messages.push(
                "Es muss mind. ein vorrangiges oder alleinstehendes Heilmittel ausgewählt werden!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat([1]);
            } else if (type == "invalidcombination_a_b") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Eine Kombination aus vorrangigen und optionalen Heilmitteln ist nicht erlaubt!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "vorrangig_toohigh_3") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Es dürfen maximal 3 vorrangige Heilmittel ausgewählt werden!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "vorrangig_toohigh_za") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Es darf nur ein vorrangiges Heilmittel ausgewählt werden!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "max_1_entry") {
              rove.position_errors.messages.push(
                "Eintrag " +
                  rove.affected_lines[type].sort().join(", ") +
                  ": Heilmittel kann nur 1x pro Rezept ausgewählt werden!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(rove.affected_lines[type]);
            } else if (type == "ergo_funktionsanalyse") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Funktionsanalyse ist nur 1x pro Rezept möglich!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "bandage_mld") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Kompressionsbandage nur in Kombination mit MLD 30, 45 oder 60 möglich!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "invalid_tarif") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Zuvor eingegebenes Heilmittel unter dieser Diagnose nicht möglich!"
              );
              rove.position_errors.positions.heilmittel = rove.position_errors.positions.heilmittel.concat(
                rove.affected_lines[type]
              );
            } else if (type == "blanko_diagnostik_toohigh") {
              rove.position_errors.messages.push(
                "Eintrag " +
                rove.affected_lines[type].sort().join(", ") +
                ": Maximal mögliche Anzahl der Bedarfsdiagnostik von 4 überschritten!"
              );
            }
          }
        }

        if (rove.checkPositionErrors()) {
          rove.position_err_flag = false;
          // no errors were found
          // create proper Rezept-Positionen object
          var de = rove.positions;
          var daten = [];
          for (var i = 0; i < de.length; i++) {
            var entry = {
              line_nr: de[i].id,
              tarif_nr: de[i].heilmittel.tarif,
              tarif_bezeichnung: de[i].heilmittel.bezeichnung,
              verordnungsmenge: de[i].menge,
              entfernung: de[i].km,
            };
            daten.push(entry);
          }

          rove.rezeptHeader.positionen = daten;

          return true;
        } else {
          return false;
        }
      };

      rove.updateInitPositions = function () {
        // TODO: Improve copy function
        rove.init_positions = [];
        for (var i = 0; i < rove.positions.length; i++) {
          var e = rove.positions[i];
          var d = {
            id: e.id,
            menge: e.menge,
            km: e.km,
            heilmittel: e.heilmittel,
          };
          rove.init_positions.push(d);
        }
      };

      rove.isMengeLocked = function(position) {
        if (!position.heilmittel) {
          return false;
        }
      
        var matchingTarif = rove.tarifWithLockedMenge.find(function(entry) {
          return entry.tarif === position.heilmittel.tarif;
        });
      
        if (matchingTarif) {
          var positionIndex = rove.positions.findIndex(function(x) {
            return x.id === position.id;
          });
          
          rove.positions[positionIndex].menge = matchingTarif.menge;
          return true;
        }
      
        return false;
      };

      rove.checkifready = function () {
        if (!rove.general.rezeptart_selected || !rove.general.settings_data) {
          setTimeout(rove.checkifready, 50);
          return;
        }

        if (rove.general.rezeptart_selected.id == "VPP") {
          if (
            !rove.rezeptHeader ||
            !rove.rezeptPositionen ||
            !rove.rezeptDaten ||
            !rove.general.icd10_data ||
            !rove.patient ||
            !rove.mitarbeiter ||
            !rove.heilmittel
          ) {
            setTimeout(rove.checkifready, 50);
            return;
          }
        } else {
          if (!rove.rezeptHeader) {
            setTimeout(rove.checkifready, 50);
            return;
          } else {
            if (
              !rove.rezeptHeader ||
              !rove.rezeptPositionen ||
              !rove.rezeptDaten ||
              !rove.general.icd10_data ||
              !rove.patient ||
              !rove.mitarbeiter ||
              !rove.heilmittel ||
              !rove.general.diagnose_tarif_lkp
            ) {
              setTimeout(rove.checkifready, 50);
              return;
            }
          }
        }

        var from = rove.patient.geburtsdatum.split("-");
        rove.general.geburtsdatum = from[2] + "." + from[1] + "." + from[0];
        rove.general.kostentraeger_name = rove.patient.kostentraeger_name;
        rove.general.kostentraeger_ik_num = rove.patient.kostentraeger_ik_num;
        rove.general.icdcodes = rove.str_to_icd(rove.rezeptHeader.icd_code);

        // arzt_num might be null in some cases
        rove.general.arzt_num = rove.rezeptHeader.arzt_num ? rove.rezeptHeader.arzt_num : "999999999";
        
        rove.general.verordnungsart_selected = null;
        rove.general.diagnose_key = rove.rezeptHeader.diagnose;
        rove.check_diagnose();

        rove.general.arzt = null;
        rove.check_arzt_num();

        rove.init_arzt_modal_data();

        rove.init_vals = {
          rezeptdatum: rove.general.rezeptdatum,
          erfassungsdatum: rove.general.erfassungsdatum,
          hausbesuch: rove.rezeptHeader.hausbesuch,
          therapiebericht: rove.rezeptHeader.therapiebericht,
          diagnose: rove.general.diagnose_key,
          locked: rove.general.locked,
          langf: rove.rezeptHeader.langf,
          besond: rove.rezeptHeader.besond,
          frequenz: rove.rezeptHeader.frequenz,
          genehmigung: rove.general.genehmigung,
          genehmigungsnummer: rove.general.genehmigungsnummer,
          genehmigungsart: rove.general.genehmigungsart,
          genehmigungsdatum: rove.general.genehmigungsdatum,
          discharge_management: rove.rezeptHeader.discharge_management,
          blanko: rove.rezeptHeader.blanko,
        };

        // POSITIONEN INIT
        rove.loadPositionenData();

        // DATEN INIT
        rove.loadGroupData();

        rove.isready = true;
      };

      rove.checkHeaderInitVals = function () {
        //rezeptdatum
        if (rove.general.rezeptdatum != null && rove.general.rezeptdatum != "") {
          if (rove.dateDiffInDays(rove.general.rezeptdatum, rove.init_vals.rezeptdatum) != 0) {
            // date was changed
            if (rove.modified_fields.indexOf("Rezeptdatum") == -1) {
              rove.modified_fields.push("Rezeptdatum");
            }
            rove.init_vals.rezeptdatum = rove.general.rezeptdatum;

            // SET ISSUE AND FIX FLAGS FOR POSITION AND DATE
            rove.position_issue_flag = true;
            rove.date_issue_flag = true;

            rove.changes_needed = true;
          }
        }

        //erfassungsdatum
        if (rove.general.erfassungsdatum != null && rove.general.erfassungsdatum != "") {
          if (rove.dateDiffInDays(rove.general.erfassungsdatum, rove.init_vals.erfassungsdatum) != 0) {
            // date was changed
            if (rove.modified_fields.indexOf("Erste Behandlung") == -1) {
              rove.modified_fields.push("Erste Behandlung");
            }
            rove.init_vals.erfassungsdatum = rove.general.erfassungsdatum;

            // SET ISSUE AND FIX FLAGS FOR DATE
            rove.date_issue_flag = true;

            rove.changes_needed = true;
          }
        }

        if (rove.init_vals.discharge_management != rove.rezeptHeader.discharge_management) {
          // further checks on dates are only necessary if discharge management was changed from false to true
          if (rove.rezeptHeader.discharge_management){
            if (rove.modified_fields.indexOf("Entlassmanagement") == -1) {
              rove.modified_fields.push("Entlassmanagement");
            }
            rove.init_vals.discharge_management = rove.rezeptHeader.discharge_management;
            rove.changes_needed = true;
          }
        }


        //diagnose
        if (rove.init_vals.diagnose != rove.general.diagnose_key) {
          if (rove.modified_fields.indexOf("Diagnose") == -1) {
            rove.modified_fields.push("Diagnose");
          }
          // SET ISSUE AND FIX FLAGS FOR POSITION AND DATE
          rove.position_issue_flag = true;
          rove.date_issue_flag = true;

          rove.changes_needed = true;
        }

        // frequenz
        if (rove.init_vals.frequenz != rove.general.frequenz) {
          if (rove.modified_fields.indexOf("Frequenz") == -1) {
            rove.modified_fields.push("Frequenz");
          }

          rove.init_vals.frequenz = rove.general.frequenz;
          rove.date_issue_flag = true;
          rove.changes_needed = true;
        }

        //hausbesuch
        if (rove.init_vals.hausbesuch != rove.rezeptHeader.hausbesuch) {
          if (rove.modified_fields.indexOf("Hausbesuch") == -1) {
            rove.modified_fields.push("Hausbesuch");
          }
          rove.init_vals.hausbesuch = rove.rezeptHeader.hausbesuch;

          // SET ISSUE AND FIX FLAGS FOR POSITION AND DATE
          rove.position_issue_flag = true;
          rove.date_issue_flag = true;

          rove.changes_needed = true;
        }

        //therapiebericht
        if (rove.init_vals.therapiebericht != rove.rezeptHeader.therapiebericht) {
          if (rove.modified_fields.indexOf("Therapiebericht") == -1) {
            rove.modified_fields.push("Therapiebericht");
          }

          rove.init_vals.therapiebericht = rove.rezeptHeader.therapiebericht;

          // SET ISSUE AND FIX FLAGS FOR POSITION AND DATE
          rove.position_issue_flag = true;
          rove.date_issue_flag = true;

          rove.changes_needed = true;
        }

        // langfristiger Behandlungsbedarf
        if (rove.init_vals.langf != rove.rezeptHeader.langf) {
          rove.init_vals.langf = rove.rezeptHeader.langf;

          // SET ISSUE AND FIX FLAGS FOR POSITION AND DATE
          rove.position_issue_flag = true;
          rove.date_issue_flag = true;

          rove.changes_needed = true;
        }

        // besonderer Verordnungsbedarf
        if (rove.init_vals.besond != rove.rezeptHeader.besond) {
          rove.init_vals.besond = rove.rezeptHeader.besond;

          // SET ISSUE AND FIX FLAGS FOR POSITION AND DATE
          rove.position_issue_flag = true;
          rove.date_issue_flag = true;

          rove.changes_needed = true;
        }

        // Genehmigung
        if (rove.init_vals.genehmigung != rove.general.genehmigung) {
          rove.init_vals.genehmigung = rove.general.genehmigung;
          rove.init_vals.genehmigungsnummer = rove.general.genehmigungsnummer;
          rove.init_vals.genehmigungsart = rove.general.genehmigungsart;
          rove.init_vals.genehmigungsdatum = rove.general.genehmigungsdatum;

          // SET ISSUE AND FIX FLAGS FOR POSITION AND DATE
          rove.position_issue_flag = true;
          rove.date_issue_flag = true;

          rove.changes_needed = true;
        }

        // Blanko
        if (rove.init_vals.blanko != rove.rezeptHeader.blanko) {

          if (rove.modified_fields.indexOf("Blanko") == -1) {
            rove.modified_fields.push("Blanko");
          }

          rove.init_vals.blanko = rove.rezeptHeader.blanko;
          rove.position_issue_flag = true;
          rove.date_issue_flag = true;

          rove.changes_needed = true;
        }
      };

      rove.updateRezeptHeader = function () {
        header_check = rove.checkHeaderInput();

        if (header_check && !rove.changes_needed) {
          rove.saving = true;
          RezepteHeaderService.update(
            {
              id: rove.rezeptHeader.id,
            },
            rove.rezeptHeader
          ).$promise.then(function (data) {
            var rezepte = StoreService.get("rezepte");
            rezepte[rove.rezeptHeader.id] = data;
            StoreService.set("rezepte", rezepte);

            var custom_msg = data["custom_msg"];
            if (custom_msg.set) {
              rove.openInformationModal(custom_msg);
            } else {
              rove.saving = false;
              rove.returnToPreviousState();
            }
          });
        }
      };

      rove.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      rove.updateRezeptAll = function () {
        dates_check = rove.checkDatenInput();

        if (dates_check) {
          rove.awaiting_response = true;
          RezepteHeaderService.update(
            {
              id: rove.rezeptHeader.id,
            },
            rove.rezeptHeader
          ).$promise.then(function (data) {
            var rezepte = StoreService.get("rezepte");
            rezepte[rove.rezeptHeader.id] = data;
            StoreService.set("rezepte", rezepte);

            var custom_msg = data["custom_msg"];

            if (custom_msg.set) {
              rove.openInformationModal(custom_msg);
            } else {
              rove.awaiting_response = false;
              rove.returnToPreviousState();
            }
          });
        }

      };

      // initialize component
      rove.init();
    },
  ],
});
