angular.module("aerzteView").component("aerzteView", {
  templateUrl:
    "static/angular_assets/aerzte/aerzte_view/aerzte_view.template.html",
  controller: [
    "$state",
    "$uiRouterGlobals",
    "AerzteService",
    "$stateParams",
    function AerzteDetailsController(
      $state,
      $uiRouterGlobals,
      AerzteService,
      $stateParams
    ) {
      var aev = this;

      aev.init = function () {
        aev.isready = false;
        aev.transparam = $uiRouterGlobals.params;
        aev.arzt_num = aev.transparam.arztNum;
        aev.arzt = {};

        aev.getArzt();
      };

      aev.getArzt = function () {
        AerzteService.get({
          arztNum: aev.arzt_num,
        })
          .$promise.then(function (data) {
            aev.arzt = data;
            aev.isready = true;
          })
          .catch(function (error) {});
      };

      aev.deleteArzt = function () {
        AerzteService.delete({
          arztNum: aev.arzt_num,
        })
          .$promise.then(function (data) {
            aev.returnToPreviousState();
          })
          .catch(function (error) {});
      };

      aev.editArzt = function () {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, {
          arztNum: aev.arzt_num,
        });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.aerzte-edit", newParams);
      };

      aev.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      aev.init();
    },
  ],
});
