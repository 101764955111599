angular.module("mitarbeiterEdit").component("mitarbeiterEdit", {
  templateUrl: "static/angular_assets/mitarbeiter/mitarbeiter_edit/mitarbeiter_edit.template.html",
  controller: [
    "$state",
    "$anchorScroll",
    "$timeout",
    "StoreService",
    "MitarbeiterService",
    "$stateParams",

    function MitarbeiterEditController(
      $state,
      $anchorScroll,
      $timeout,
      StoreService,
      MitarbeiterService,
      $stateParams
    ) {
      // add proper CSS classes
      var me = this;

      me.regexp_text = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+']/g;
      me.regexp_text_num = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+'0-9]/g;

      get_unique = function (arr) {
        return arr
          ? arr.reduce(function (a, b) {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, [])
          : null;
      };

      me.init = function () {
        me.errors = {
          name: [],
          vorname: [],
          strasse: [],
          ort: [],
        };

        // used for anchor scroll to first error
        me.error_scroll = null;
        $anchorScroll.yOffset = 200;

        me.mitarbeiter_data = StoreService.get("mitarbeiter");
        me.mitarbeiter_id = $stateParams.mitarbeiterId;

        me.mitarbeiter = null;
        me.getMitarbeiterDetails();
      };

      me.set_error_scroll = function (loc) {
        if (!me.error_scroll) {
          me.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      me.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in me.errors) {
          if (!me.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (me.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      me.checkMitarbeiterInput = function () {
        // reset current errors
        for (var error in me.errors) {
          me.errors[error] = [];
        }

        // reset anchor scroll position
        me.error_scroll = null;

        // vorname
        if (!me.mitarbeiter.vorname) {
          me.errors.vorname.push("Es wurde kein Vorname angegeben!");
          me.set_error_scroll("vorname_loc");
        } else {
          var matches = get_unique(me.mitarbeiter.vorname.match(me.regexp_text));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            me.errors.vorname.push(err_msg);
            me.set_error_scroll("vorname_loc");
          }
        }

        // name
        if (!me.mitarbeiter.name) {
          me.errors.name.push("Es wurde kein Nachname angegeben!");
          me.set_error_scroll("name_loc");
        } else {
          var matches = get_unique(me.mitarbeiter.name.match(me.regexp_text));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            me.errors.name.push(err_msg);
            me.set_error_scroll("name_loc");
          }
        }

        if (me.mitarbeiter.strasse) {
          var matches = get_unique(me.mitarbeiter.strasse.match(me.regexp_text_num));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            me.errors.strasse.push(err_msg);
            me.set_error_scroll("strasse_loc");
          }
        }

        if (me.mitarbeiter.ort) {
          var matches = get_unique(me.mitarbeiter.ort.match(me.regexp_text_num));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            me.errors.ort.push(err_msg);
            me.set_error_scroll("ort_loc");
          }
        }

        return me.checkErrors();
      };

      me.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      // functions

      me.getMitarbeiterDetails = function () {
        me.mitarbeiter = me.mitarbeiter_data.find(function (x) {
          return x.id == me.mitarbeiter_id;
        });
      };

      me.editMitarbeiterDetails = function () {
        if (me.checkMitarbeiterInput()) {
          MitarbeiterService.update(
            {
              id: me.mitarbeiter_id,
            },
            me.mitarbeiter
          ).$promise.then(function (data) {
            var id_to_change = me.mitarbeiter_id;
            var index = me.mitarbeiter_data
              .map(function (item) {
                return item.id;
              })
              .indexOf(id_to_change);

            me.mitarbeiter_data[index] = me.mitarbeiter;

            StoreService.set("mitarbeiter", me.mitarbeiter_data);

            me.returnToPreviousState();
          });
        }
      };

      me.init();
    },
  ],
});
