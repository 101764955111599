angular.module("rezepteOverviewFilter").component("rezepteOverviewFilter", {
  templateUrl: "static/angular_assets/rezepte/rezepte_overview/overview_filter/overview_filter.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "$filter",
    "RememberService",
    "StoreService",
    "$stateParams",
    function RezepteOverviewFilterController(
      $scope,
      $rootScope,
      $state,
      $filter,
      RememberService,
      StoreService,
      $stateParams
    ) {
      var rofc = this;

      rofc.init = function () {
        rofc.tables = {
          filter_search_data: {},
        };

        rofc.filter = {
          kt_typ: "ALL",
          datum_von: null,
          datum_bis: null,
          status: "ALL",
        };

        rofc.fromState = $rootScope.fromState;
        rofc.savedSelections = RememberService.rezeptFilterSelections;
        if (rofc.fromState == "menu.rezepte-overview-view") {
          rofc.tables.filter_search_data = {
            query: "",
            select_filter: "",
            sort: {
              sortingOrder: "rezeptdatum",
              reverse: true,
            },
            gap: 3,
            filteredItems: [],
            itemsPerPage: 10,
            pagedItems: [],
            currentPage: 1,
            rezept_von_filter: rofc.savedSelections.rezept_von_filter,
            rezept_bis_filter: rofc.savedSelections.rezept_bis_filter,
            kttyp_filter: rofc.savedSelections.kttyp_filter,
            status_filter: rofc.savedSelections.status_filter,
            unfalltag_filter: rofc.savedSelections.unfalltag_filter,
            vorname_filter: rofc.savedSelections.vorname_filter,
            nachname_filter: rofc.savedSelections.nachname_filter,
            versnr_filter: rofc.savedSelections.versnr_filter,
            arztnr_filter: rofc.savedSelections.arztnr_filter,
            ktid_filter: rofc.savedSelections.ktid_filter,
          };
        } else {
          rofc.tables.filter_search_data = {
            query: "",
            select_filter: "",
            sort: {
              sortingOrder: "rezeptdatum",
              reverse: true,
            },
            gap: 3,
            filteredItems: [],
            itemsPerPage: 10,
            pagedItems: [],
            currentPage: 1,
            rezept_von_filter: "",
            rezept_bis_filter: "",
            kttyp_filter: "ALL",
            status_filter: "ALL",
            unfalltag_filter: "",
            vorname_filter: "",
            nachname_filter: "",
            versnr_filter: "",
            arztnr_filter: "",
            ktid_filter: "",
          };
        }

        rofc.rezepte = null;
        rofc.rezepte_cnt = 0;
        rofc.isready = false;
        rofc.show_table = false;

        // get related rezepte first
        rofc.getRezeptHeaderData();
      };

      rofc.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      rofc.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      rofc.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      // ############ TABLE FUNCTIONS ############
      // #########################################

      // function to find a given string (needle) in given attribute (haystack)
      var searchMatch = function (haystack, needle) {
        if (!needle) {
          return true;
        }
        if (!haystack) {
          return false;
        }
        return haystack.toString().toLowerCase().indexOf(needle.toLowerCase()) !== -1;
      };

      // search-filter to filter out only relevant data; based on query term and a filter-dropdown (rofc.select_filter)
      rofc.filter_rezepte = function (data) {
        var sp = rofc.tables.filter_search_data;

        sp.filteredItems = $filter("filter")(data, function (item) {
          // 1. check rezeptdatum
          var rezept_date = rofc.str_to_date(item["rezeptdatum"]);
          if (sp.rezept_von_filter && sp.rezept_bis_filter) {
            if (rezept_date < sp.rezept_von_filter || rezept_date > sp.rezept_bis_filter) {
              return false;
            }
          } else if (sp.rezept_von_filter) {
            if (rezept_date < sp.rezept_von_filter) {
              return false;
            }
          } else if (sp.rezept_bis_filter) {
            if (rezept_date > sp.rezept_bis_filter) {
              return false;
            }
          }

          // 2. check kostentraeger-typ
          if (sp.kttyp_filter != "ALL") {
            if (item["kostentraeger_typ"] != sp.kttyp_filter) {
              return false;
            }
          }

          // 3. check status
          if (sp.status_filter != "ALL") {
            if (item["status"] != sp.status_filter) {
              return false;
            }
          }

          // 4. unfalltag
          if (sp.unfalltag_filter) {
            if (item["unfalltag"]) {
              var unfalltag = rofc.str_to_date(item["unfalltag"]);
              if (unfalltag.toString() !== sp.unfalltag_filter.toString()) {
                return false;
              }
            } else {
              return false;
            }
          }

          // 5. vorname
          if (sp.vorname_filter) {
            var relevant = searchMatch(item["vorname"], sp.vorname_filter);
            if (!relevant) {
              return false;
            }
          }

          // 6. nachname
          if (sp.nachname_filter) {
            var relevant = searchMatch(item["name"], sp.nachname_filter);
            if (!relevant) {
              return false;
            }
          }

          // 7. versicherungsnr.
          if (sp.versnr_filter) {
            var relevant = searchMatch(item["vers_nummer"], sp.versnr_filter);
            if (!relevant) {
              return false;
            }
          }

          // 8. kostentraeger_ik_num
          if (sp.ktid_filter) {
            var relevant = searchMatch(item["kostentraeger_ik_num"], sp.ktid_filter);
            if (!relevant) {
              return false;
            }
          }

          // 9. arztnr
          if (sp.arztnr_filter) {
            var relevant = searchMatch(item["arzt_num"], sp.arztnr_filter);
            if (!relevant) {
              return false;
            }
          }

          // all checks were successful - item is relevant so return true
          return true;
        });

        // take care of the sorting order
        if (sp.sort.sortingOrder !== "") {
          sp.filteredItems = $filter("orderBy")(sp.filteredItems, sp.sort.sortingOrder, sp.sort.reverse);
        }
        sp.currentPage = 1;

        RememberService.rezeptFilterSelections = {
          rezept_von_filter: sp.rezept_von_filter,
          rezept_bis_filter: sp.rezept_bis_filter,
          kttyp_filter: sp.kttyp_filter,
          status_filter: sp.status_filter,
          unfalltag_filter: sp.unfalltag_filter,
          vorname_filter: sp.vorname_filter,
          nachname_filter: sp.nachname_filter,
          versnr_filter: sp.versnr_filter,
          arztnr_filter: sp.arztnr_filter,
          ktid_filter: sp.ktid_filter,
        };
        // now group by pages
        rofc.groupToPages(rofc.tables.filter_search_data);
      };

      // calculate and group filteredItems into pages with itemsPerPage
      rofc.groupToPages = function (cnt) {
        var sp = cnt;
        sp.pagedItems = [];

        for (var i = 0; i < sp.filteredItems.length; i++) {
          if (i % sp.itemsPerPage === 0) {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)] = [sp.filteredItems[i]];
          } else {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)].push(sp.filteredItems[i]);
          }
        }
      };

      // pagination range
      rofc.range = function (size, curr) {
        var ret = [];

        if (size == 1) {
          return ret;
        } else if (size <= 5) {
          for (var i = 2; i <= size - 1; i++) {
            ret.push(i);
          }
          return ret;
        } else {
          // left end
          if (curr == 1) {
            ret.push(curr + 1);
            ret.push(curr + 2);
            ret.push(curr + 3);
          } else if (curr == size) {
            ret.push(curr - 3);
            ret.push(curr - 2);
            ret.push(curr - 1);
          } else if (curr - 1 == 1) {
            ret.push(curr);
            ret.push(curr + 1);
            ret.push(curr + 2);
          } else if (curr + 1 == size) {
            ret.push(curr - 2);
            ret.push(curr - 1);
            ret.push(curr);
          } else {
            ret.push(curr - 1);
            ret.push(curr);
            ret.push(curr + 1);
          }
          return ret;
        }
      };

      // pagination back-button function
      rofc.prevPage = function (cnt) {
        var d = rofc.tables[cnt];

        if (d.currentPage > 1) {
          d.currentPage--;
        }
      };

      // pagination forward-button function
      rofc.nextPage = function (cnt) {
        var d = rofc.tables[cnt];
        if (d.currentPage < d.pagedItems.length) {
          d.currentPage++;
        }
      };

      // set currently viewed page to n
      rofc.setPage = function (cnt, n) {
        var d = rofc.tables[cnt];
        d.currentPage = n;
      };

      //functions to sort data (for tables) based on multiple attributes/properties
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      rofc.dynamicSortMultiple = function () {
        var props = arguments;
        return function (obj1, obj2) {
          var i = 0,
            result = 0,
            numberOfProperties = props.length;
          /* try getting a different result from 0 (equal)
           * as long as we have extra properties to compare
           */
          while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
          }
          return result;
        };
      };
      // #########################################

      rofc.showRezept = function (header_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { headerId: header_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-overview-view", newParams);
      };

      rofc.getRezeptHeaderData = function (pat) {
        var rezepte = StoreService.get("rezepte");

        var alt_status_keys = {
          Vordatiert: "V",
          Neu: "N",
          Versendet: "VE",
          Bearbeitung: "B",
          Abgerechnet: "OK",
          Abgelehnt: "REJ",
          "Neu-Gesperrt": "NG",
          "Vordatiert-Gesperrt": "VG",
        };

        rofc.rezepte = {};
        rofc.rezepte_cnt = 0;

        for (key in rezepte) {
          if (rezepte.hasOwnProperty(key)) {
            if (typeof pat === "undefined") {
              rofc.rezepte[key] = rezepte[key];
              rofc.rezepte_cnt += 1;
            } else {
              if (rezepte[key]["patient"].id == pat.id) {
                rofc.rezepte[key] = rezepte[key];
                rofc.rezepte_cnt += 1;
              }
            }

            // set alternative UNIQUE/NON-OVERLAPPING status
            rofc.rezepte[key].status_alt = alt_status_keys[rezepte[key].status];
          }
        }

        if (rofc.rezepte_cnt > 1000) {
          setTimeout(function () {
            //do what you need here
            rofc.isready = true;
          }, 5);
        } else {
          rofc.isready = true;
        }
      };

      rofc.on_change_status = function () {
        var filtering = FooTable.get("#rezepte_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = '"' + rofc.filter.status + '"'; // get the value to filter by

        if (filter === '"ALL"') {
          // if the value is "none" remove the filter
          filtering.removeFilter("status");
        } else {
          // otherwise add/update the filter.
          filtering.addFilter("status", filter, ["status_alt"]);
        }
        filtering.filter();
      };

      rofc.on_change_kt_typ = function () {
        var filtering = FooTable.get("#rezepte_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = rofc.filter.kt_typ; // get the value to filter by

        if (filter === "ALL") {
          // if the value is "none" remove the filter
          filtering.removeFilter("kt_typ");
        } else {
          // otherwise add/update the filter.
          filter = '"' + filter + '"';
          filtering.addFilter("kt_typ", filter, ["kt_typ"]);
        }
        filtering.filter();
      };

      rofc.on_change_datum_von = function () {
        var filtering = FooTable.get("#rezepte_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = rofc.filter.datum_von; // get the value to filter by

        if (!filter) {
          // if the value is "none" remove the filter
          filtering.removeFilter("datum_von");
        } else {
          // otherwise add/update the filter.

          filter = ">" + rofc.date_to_str(filter);
          filtering.addFilter("datum_von", filter, ["datum"]);
        }
        filtering.filter();
      };

      rofc.on_change_datum_bis = function () {
        var filtering = FooTable.get("#rezepte_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = rofc.filter.datum_bis; // get the value to filter by

        if (!filter) {
          // if the value is "none" remove the filter
          filtering.removeFilter("datum_bis");
        } else {
          // otherwise add/update the filter.

          filter = "<" + rofc.date_to_str(filter);
          filtering.addFilter("datum_bis", filter, ["datum"]);
        }
        filtering.filter();
      };

      rofc.initFooTable = function () {
        // custom dropdown filter
        FooTable.MyFiltering = FooTable.Filtering.extend({
          construct: function (instance) {
            this._super(instance);
            this.$filter_status = null;
            this.connectors = false;
          },

          $create: function () {
            this._super();
            var self = this;
            this.$filter_status = $(".btn-primary").on(
              "click",
              {
                self: self,
              },
              self._onFilterStatusChanged
            );
          },
          _onFilterStatusChanged: function (e) {
            rofc.filter = {
              kt_typ: "ALL",
              datum_von: null,
              datum_bis: null,
              status: "ALL",
            };
          },
        });

        rofc.table.footable({
          components: {
            filtering: FooTable.MyFiltering,
          },
        });

        // show table
        rofc.show_table = true;
      };

      $scope.$watch(
        "rofc.data",
        function () {
          rofc.data = angular.copy(rofc.data);
        },
        true
      );

      // INIT
      rofc.init();
    },
  ],
});
