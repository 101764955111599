angular.
  module('core.download-abrechnung').
  factory('DownloadAbrechnungService', ['$resource', 'Blob', '__env',
    function($resource, Blob, __env) {
      return $resource(__env.apiUrl + 'download/abrechnung/:ik_num/\.',{},
      {
        'zip': {
          method: 'GET',
          headers: {
              accept: 'application/zip'
          },
          responseType: 'blob',
          cache: false,
          transformResponse: function (data, headers) {
            var zip;
            var hdrs = headers()            
            if (data) {
                zip = new Blob([data], {
                    type: 'application/zip'
                });
            }
            return {
                response: zip,
                filename: hdrs["content-disposition"].slice(22,hdrs["content-disposition"].length - 1),

            };
        }
        }
      }
    );
    }
  ]);
