angular.
  module('core.tokenrefresh').
  factory('TokenRefreshService', ['$resource', '__env',
    function($resource, __env) {
    	var r = $resource(__env.apiUrl + 'token-refresh/\.');
      delete r.prototype.$get;
      delete r.prototype.$query;
      delete r.prototype.$delete;
      delete r.prototype.$remove;
      return r
    }
  ]);
