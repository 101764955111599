angular.module("resetTestaccount").component("resetTestaccount", {
  templateUrl: "static/angular_assets/admin/reset_testaccount/reset_testaccount.template.html",
  controller: [
    "ResetTestaccountService",
    function ResetTestAccountController(ResetTestaccountService) {
      var rtac = this;

      rtac.saving = false;
      rtac.cleared = false;
      rtac.errors = false;
      rtac.reset = new ResetTestaccountService();

      rtac.reset_testaccount = function () {
        rtac.cleared = false;
        rtac.errors = false;
        rtac.saving = true;

        rtac.reset.$save().then(
          function (success_response) {
            rtac.cleared = true;
            rtac.saving = false;
          },
          function (error_response) {
            rtac.errors = true;
            rtac.message = error_response;
            rtac.saving = false;
          }
        );
      };
    },
  ],
});
