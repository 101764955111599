angular.module("mitarbeiterAdd").component("mitarbeiterAdd", {
  templateUrl: "static/angular_assets/mitarbeiter/mitarbeiter_add/mitarbeiter_add.template.html",
  controller: [
    "$state",
    "$window",
    "$anchorScroll",
    "$timeout",
    "MitarbeiterService",
    "StoreService",
    "$stateParams",

    function MitarbeiterAddController(
      $state,
      $window,
      $anchorScroll,
      $timeout,
      MitarbeiterService,
      StoreService,
      $stateParams
    ) {
      var madd = this;

      madd.regexp_text = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+']/g;
      madd.regexp_text_num = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+'0-9]/g;

      get_unique = function (arr) {
        return arr
          ? arr.reduce(function (a, b) {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, [])
          : null;
      };

      madd.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      madd.init = function () {
        // holds errors
        madd.errors = {
          name: [],
          vorname: [],
          strasse: [],
          ort: [],
        };

        // used for anchor scroll to first error
        madd.error_scroll = null;
        $anchorScroll.yOffset = 200;

        // focus first input element
        $window.document.getElementById("vorname_input").focus();

        // prepare objects for Mitarbeiter input
        madd.mitarbeiter = new MitarbeiterService();

        // set mitarbeiter_id to default 0 - this will be overriden during creation
        madd.mitarbeiter.mitarbeiter_id = 0;
        madd.mitarbeiter.name = "";
        madd.mitarbeiter.vorname = "";
        madd.mitarbeiter.plz = "";
        madd.mitarbeiter.ort = "";
        madd.mitarbeiter.strasse = "";
        madd.mitarbeiter.telefon = "";
        madd.mitarbeiter.color = "#ba3f68";
      };

      madd.set_error_scroll = function (loc) {
        if (!madd.error_scroll) {
          madd.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      madd.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in madd.errors) {
          if (!madd.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (madd.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      madd.checkMitarbeiterInput = function () {
        // reset current errors
        for (var error in madd.errors) {
          madd.errors[error] = [];
        }

        // reset anchor scroll position
        madd.error_scroll = null;

        // vorname
        if (!madd.mitarbeiter.vorname) {
          madd.errors.vorname.push("Es wurde kein Vorname angegeben!");
          madd.set_error_scroll("vorname_loc");
        } else {
          var matches = get_unique(madd.mitarbeiter.vorname.match(madd.regexp_text));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            madd.errors.vorname.push(err_msg);
            madd.set_error_scroll("vorname_loc");
          }
        }

        // name
        if (!madd.mitarbeiter.name) {
          madd.errors.name.push("Es wurde kein Nachname angegeben!");
          madd.set_error_scroll("name_loc");
        } else {
          var matches = get_unique(madd.mitarbeiter.name.match(madd.regexp_text));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            madd.errors.name.push(err_msg);
            madd.set_error_scroll("name_loc");
          }
        }

        if (madd.mitarbeiter.strasse) {
          var matches = get_unique(madd.mitarbeiter.strasse.match(madd.regexp_text_num));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            madd.errors.strasse.push(err_msg);
            madd.set_error_scroll("strasse_loc");
          }
        }

        if (madd.mitarbeiter.ort) {
          var matches = get_unique(madd.mitarbeiter.ort.match(madd.regexp_text_num));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            madd.errors.ort.push(err_msg);
            madd.set_error_scroll("ort_loc");
          }
        }

        return madd.checkErrors();
      };

      madd.saveMitarbeiter = function () {
        if (madd.checkMitarbeiterInput()) {
          madd.mitarbeiter.$save().then(
            function (success_response) {
              // update local mitarbeiter with response
              madd.mitarbeiter_data = StoreService.get("mitarbeiter");
              madd.mitarbeiter_data.push(success_response);

              StoreService.set("mitarbeiter", madd.mitarbeiter_data);

              madd.returnToPreviousState();
            },
            function (error) {
              console.log(error);
            }
          );
        }
      };

      madd.init();
    },
  ],
});
