angular.module("menu").component("menu", {
  templateUrl: "static/angular_assets/menu/menu.template.html",
  controller: [
    "$rootScope",
    "$scope",
    "$element",
    "$state",
    "store",
    "Popeye",
    "$window",
    function MenuController($rootScope, $scope, $element, $state, store, Popeye, $window) {
      // add proper CSS classes
      //OLD $element.addClass('column is-three-quarters menu_form');
      $element.addClass("column is-11 menu_form");

      var menu = this;
      var modal = null;

      $scope.$on("ShowUnreadMessageModal", function () {
        if (!modal) {
          menu.ShowUnreadMessageModal();
        }
      });

      menu.ShowUnreadMessageModal = function () {
        // task
        modal = Popeye.openModal({
          templateUrl: "static/angular_assets/menu/modals/unread_modal.template.html",
          scope: $scope,
          click: false,
          keyboard: false,
          modalClass: "normal-modal-container no_close",
        });
      };

      menu.ignoreUnread = function () {
        $rootScope.ignoreUnread = true;
        modal.close();
        modal = null;
      };

      menu.showUnread = function () {
        modal.close();
        modal = null;
        $state.go("menu.nachrichten");
      };

      // MODAL
      menu.timeoutModal = function () {
        modal = Popeye.openModal({
          templateUrl: "static/angular_assets/menu/modals/timeout_modal.template.html",
          click: false,
          keyboard: false,
          scope: $scope,
          modalClass: "normal-modal-container",
        });
      };

      menu.logoutModal = function () {
        modal = Popeye.openModal({
          templateUrl: "static/angular_assets/menu/modals/logout_modal.template.html",
          scope: $scope,
          click: false,
          modalClass: "normal-modal-container",
        });
      };

      menu.loginAgain = function () {
        modal.close();
      };

      $rootScope.$on("ShowRestartAppModal", function (event, version_data) {
        menu.ShowRestartAppModal(version_data);
      });

      menu.ShowRestartAppModal = function (version_data) {
        // task
        menu.version_data = version_data;
        modal = Popeye.openModal({
          templateUrl: "static/angular_assets/menu/modals/restart_app.template.html",
          scope: $scope,
          click: false,
          keyboard: false,
          modalClass: "normal-modal-container",
        });

        modal.closed.then(function () {
          menu.restartApp();
        });
      };

      // will restart app by using https://developer.mozilla.org/en-US/docs/Web/API/location/reload
      // Passing true so the page is always reloaded from the server, bypassing the browser HTTP cache.
      menu.restartApp = function () {
        $window.location.reload(true);
      };

      // Currently logged in user information taken from store
      menu.currentUser = store.get("currentUser");

      menu.logout = function () {
        menu.watcher(); // will unregister watcher
        $window.sessionStorage.clear();
        $window.location.reload(true);
        //$state.go('login');
      };

      menu.stayloggedIn = function () {
        $rootScope.refreshToken();
        modal.close();
        modal = null;
      };

      menu.modal_showed = false;
      menu.watcher = $rootScope.$watch("counter", function (newValue, oldValue) {
        if (newValue > oldValue) {
          menu.modal_showed = false; // reset modal_showed in case the view was changed
        }

        if (!menu.modal_showed && newValue == 120) {
          menu.modal_showed = true;
          menu.timeoutModal();
        }

        if (menu.modal_showed && newValue == 0) {
          menu.logoutModal();
          menu.watcher(); // kills watcher
          menu.logout();
        }
      });
    },
  ],
});
