angular.
  module('core.messages').
  factory('MessagesService', ['$resource', '__env',
    function($resource, __env) {
      return $resource(__env.apiUrl + 'messages/\:id/',{},
      {
        'update': { method:'PUT' }
      }
    );
    }
  ]);