angular.
module('core.preiscalculate').
factory('PreisCalculateService', ['$resource', '__env',
  function ($resource, __env) {
    var r = $resource(__env.apiUrl + 'preiscalculate/:header_id/\.', {});
    delete r.prototype.$set;
    delete r.prototype.$query;
    delete r.prototype.$delete;
    delete r.prototype.$remove;
    return r
  }
]);