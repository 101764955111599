angular.module("nachrichtenOverview").component("nachrichtenOverview", {
  templateUrl:
    "static/angular_assets/nachrichten_center/nachrichten_overview/nachrichten_overview.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "MessagesService",
    "MarkMessagesService",
    "DeleteMessagesService",
    "$stateParams",
    function PatientenOverviewController(
      $scope,
      $rootScope,
      $state,
      MessagesService,
      MarkMessagesService,
      DeleteMessagesService,
      $stateParams
    ) {
      var nov = this;

      nov.init = function () {
        nov.isready = false;
        nov.nachrichten = null;
        nov.marked_entries = {};

        nov.getNachrichten();

        nov.checkifready();
      };

      nov.getNachrichten = function () {
        MessagesService.query()
          .$promise.then(function (data) {
            nov.nachrichten = data;

            var unread_count = 0;

            for (var i = 0; i < nov.nachrichten.length; i++) {
              nov.marked_entries[nov.nachrichten[i].id] = false;
              if (!nov.nachrichten[i].gelesen) {
                unread_count++;
              }
            }

            $rootScope.unread_messages = unread_count;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      nov.showNachricht = function (id) {
        var nachricht = nov.nachrichten.find(function (element) {
          return element.id == id;
        });

        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { message: nachricht });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }

        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.nachrichten-view", newParams);
      };

      nov.getMarkedEntries = function () {
        // ensure ids are numbers
        var ids = Object.keys(nov.marked_entries).filter(function (key) {
          return nov.marked_entries[key];
        });

        for (var i = 0; i < ids.length; i++) {
          ids[i] = Number(ids[i]);
        }

        return ids;
      };

      nov.deleteMessages = function () {
        var markedIds = nov.getMarkedEntries();
        var to_delete = new DeleteMessagesService();
        to_delete.ids = markedIds;

        to_delete.$save().then(
          function (success_response) {
            $state.reload();
          },
          function (error) {
            console.log(error);
          }
        );
      };

      nov.markMessagesAsRead = function () {
        var markedIds = nov.getMarkedEntries();
        var to_mark = new MarkMessagesService();
        to_mark.ids = markedIds;

        to_mark.$save().then(
          function (success_response) {
            $state.reload();
          },
          function (error) {
            console.log(error);
          }
        );
      };

      nov.checkifready = function () {
        if (!nov.nachrichten) {
          setTimeout(nov.checkifready, 50);
        } else {
          nov.isready = true;
          $scope.$apply();
        }
      };

      nov.initFooTable = function () {

        nov.table.footable();

        // get current count
        nov.snapshot = FooTable.get("#nachrichten_table").use(
          FooTable.Snapshot
        ).snapshot;

        $("#markAll").change(function () {
          // manual filter to update snapshot...
          var filtering = FooTable.get("#nachrichten_table").use(
            FooTable.Filtering
          );
          filtering.filter();

          // get current snapshot of visible entries
          nov.snapshot = FooTable.get("#nachrichten_table").use(
            FooTable.Snapshot
          ).snapshot;

          for (var i = 0; i < nov.snapshot.length; i++) {
            var id = Number(nov.snapshot[i].value.id);
            nov.marked_entries[id] = this.checked;
          }
          $scope.$apply();
        });
      };

      nov.init();
    },
  ],
});
