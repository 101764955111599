angular
  .module("mkav03App")
  .config([
    "$stateProvider",
    "$urlRouterProvider",
    "$locationProvider",
    "$resourceProvider",
    "$httpProvider",
    "jwtInterceptorProvider",
    "storeProvider",
    function config(
      $stateProvider,
      $urlRouterProvider,
      $locationProvider,
      $resourceProvider,
      $httpProvider,
      jwtInterceptorProvider,
      storeProvider
    ) {
      // use sessionStorage instead of localStorage
      storeProvider.setStore("sessionStorage");

      $urlRouterProvider.otherwise("/login");
      $locationProvider.html5Mode(true);
      $resourceProvider.defaults.stripTrailingSlashes = false;

      jwtInterceptorProvider.tokenGetter = function (store) {
        return store.get("access_token");
      };

      // if available - push authentification token with each request
      $httpProvider.interceptors.push("jwtInterceptor");

      // definition of possible application states
      $stateProvider
        .state("login", {
          url: "/login",
          template: "<login></login>",
          data: {
            requireLogin: false,
          },
        })
        .state("register", {
          url: "/register",
          template: "<register></register>",
          data: {
            requireLogin: false,
          },
        })
        .state("menu", {
          abstract: true,
          template: "<menu></menu>",
          data: {
            requireLogin: false,
          },
        })
        .state("menu.nav", {
          url: "/main",
          template: "<main_nav></main_nav>",
          data: {
            requireLogin: true,
          },
        })
        .state("menu.profile", {
          url: "/profil",
          template: "<profile></profile>",
          data: {
            requireLogin: true,
          },
        })
        .state("menu.aerzte", {
          url: "/aerzte",
          template: "<aerzte-overview></aerzte-overview>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.aerzte-view", {
          url: "/aerzte/view",
          template: "<aerzte-view></aerzte-view>",
          data: {
            requireLogin: true,
          },
          params: {
            arztNum: null,
            stateChain: null,
          },
        })
        .state("menu.aerzte-edit", {
          url: "/aerzte/edit",
          template: "<aerzte-edit></aerzte-edit>",
          data: {
            requireLogin: true,
          },
          params: {
            arztNum: null,
            stateChain: null,
          },
        })
        .state("menu.aerzte-add", {
          url: "/aerzte/add",
          template: "<aerzte-add></aerzte-add>",
          data: {
            requireLogin: true,
          },
          params: {
            arztNum: null,
            stateChain: null,
          },
        })
        .state("menu.patienten", {
          url: "/patienten",
          component: "patientenOverview",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.patienten-view", {
          url: "/patienten/view",
          template: "<patienten-view></patienten-view>",
          data: {
            requireLogin: true,
          },
          params: {
            patientenId: null,
            stateChain: null,
          },
        })
        .state("menu.patienten-edit", {
          url: "/patienten/edit",
          template: "<patienten-edit></patienten-edit>",
          data: {
            requireLogin: true,
          },
          params: {
            patientenId: null,
            stateChain: null,
          },
        })
        .state("menu.patienten-add", {
          url: "/patienten/add",
          template: "<patienten-add></patienten-add>",
          data: {
            requireLogin: true,
          },
          params: {
            patientenId: null,
            stateChain: null,
          },
        })
        .state("menu.mitarbeiter", {
          url: "/mitarbeiter",
          template: "<mitarbeiter-overview></mitarbeiter-overview>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.mitarbeiter-view", {
          url: "/mitarbeiter/view/",
          template: "<mitarbeiter-view></mitarbeiter-view>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            mitarbeiterId: null,
          },
        })
        .state("menu.mitarbeiter-edit", {
          url: "/mitarbeiter/edit",
          template: "<mitarbeiter-edit></mitarbeiter-edit>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            mitarbeiterId: null,
          },
        })
        .state("menu.mitarbeiter-add", {
          url: "/mitarbeiter/add",
          template: "<mitarbeiter-add></mitarbeiter-add>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.rezepte-nav", {
          url: "/rezepte",
          template: "<rezepte-nav></rezepte-nav>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.rezepte-archive", {
          url: "/rezepte/archiv",
          template: "<rezepte-archive></rezepte-archive>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.termine-rezepte", {
          url: "/rezepte/termine",
          template: "<termine-rezepte></termine-rezepte>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.rezepte-overview-nav", {
          url: "/rezepte/overview",
          template: "<rezepte-overview-nav></rezepte-overview-nav>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.rezepte-overview-filter", {
          url: "/rezepte/overview/filter",
          template: "<rezepte-overview-filter></rezepte-overview-filter>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.rezepte-overview-view", {
          url: "/rezepte/overview/view",
          template: "<rezepte-overview-view></rezepte-overview-view>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            headerId: null,
          },
        })
        .state("menu.rezepte-edit", {
          url: "/rezepte/overview/edit",
          template: "<rezepte-edit></rezepte-edit>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            headerId: null,
          },
        })
        .state("menu.rezepte-overview-patient", {
          url: "/rezepte/overview/patient",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
          component: "patientenOverview",
        })
        .state("menu.rezepte-overview-rezept", {
          url: "/rezepte/overview/rezept",
          template: "<rezepte-overview-rezept></rezepte-overview-rezept>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            patientenId: null,
          },
        })
        .state("menu.offene-posten", {
          url: "/offeneposten",
          template: "<offeneposten-overview></offeneposten-overview>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.offene-posten-edit", {
          url: "/offeneposten/edit",
          template: "<offeneposten-edit></offeneposten-edit>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            offenerPostenId: null,
          },
        })
        .state("menu.kassenbuch", {
          url: "/kassenbuch",
          template: "<kassenbuch-overview></kasssenbuch-overview>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.rezepte-add-selection", {
          url: "/rezepte/add-selection",
          component: "patientenOverview",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.rezepte-add-header", {
          url: "/rezepte/add-header",
          template: "<rezepte-add-header></rezepte-add-header>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            patientenId: null,
          },
        })
        .state("menu.rezepte-add-lines", {
          url: "/rezepte/add-lines",
          template: "<rezepte-add-lines></rezepte-add-lines>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            params: null,
          },
        })
        .state("menu.rezepte-add-dates", {
          url: "/rezepte/add-dates",
          template: "<rezepte-add-dates></rezepte-add-dates>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            params: null,
          },
        })
        .state("menu.rezepte-add-summary", {
          url: "/rezepte/add-summary",
          template: "<rezepte-add-summary></rezepte-add-summary>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            params: null,
          },
        })
        .state("menu.rezepte-abrechnen", {
          url: "/rezepte/abrechnen",
          template: "<rezepte-abrechnen></rezepte-abrechnen>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.nachrichten", {
          url: "/inbox/",
          template: "<nachrichten-overview></nachrichten-overview>",
          data: {
            requireLogin: true,
          },
        })
        .state("menu.nachrichten-view", {
          url: "/inbox/view",
          template: "<nachrichten-view></nachrichten-view>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            message: null,
          },
        })
        .state("menu.admin-nav", {
          url: "/utility",
          template: "<admin-nav></admin-nav>",
          data: {
            requireLogin: true,
            requireAdmin: true,
          },
        })
        .state("menu.abrechnung-download", {
          url: "/utility/abrechnung/download",
          template: "<abrechnung-download></abrechnung-download>",
          data: {
            requireLogin: true,
            requireAdmin: true,
          },
        })
        .state("menu.abrechnung-upload", {
          url: "/utility/abrechnung/upload",
          template: "<abrechnung-upload></abrechnung-upload>",
          data: {
            requireLogin: true,
            requireAdmin: true,
          },
        })
        .state("menu.reset-testaccount", {
          url: "/utility/resetTestaccount/",
          template: "<reset-testaccount></reset-testaccount>",
          data: {
            requireLogin: true,
            requireAdmin: true,
          },
        })
        .state("menu.settings", {
          url: "/settings",
          template: "<settings></settings>",
          data: {
            requireLogin: true,
          },
        })
        .state("menu.statistics", {
          url: "/statistik",
          template: "<statistics></statistics>",
          params: {
            stateChain: null,
          },
          data: {
            requireLogin: true,
          },
        })
        .state("menu.vollprivattarife-edit", {
          url: "/vollprivattarife/edit",
          template: "<vollprivattarife-edit></vollprivattarife-edit>",
          data: {
            requireLogin: true,
          },
          params: {
            stateChain: null,
            vptId: null,
          },
        })
        .state("menu.admin-nachricht", {
          url: "/utility/messages",
          template: "<admin-messages></admin-messages>",
          data: {
            requireLogin: true,
            requireAdmin: true,
          },
        });
    },
  ])
  .run([
    "$rootScope",
    "$timeout",
    "$transitions",
    "$state",
    "store",
    "TokenRefreshService",
    "jwtHelper",
    "ScrollerService",
    "MessagesService",
    "VersionService",
    "$window",
    "StoreService",
    function run(
      $rootScope,
      $timeout,
      $transitions,
      $state,
      store,
      TokenRefreshService,
      jwtHelper,
      ScrollerService,
      MessagesService,
      VersionService,
      $window,
      StoreService
    ) {
      // Service used for automatic (smooth) scrolling to next visible input/select/textarea element that is off screen
      ScrollerService.registerScrollFunction();

      $rootScope.default_timeLeft = "60:00";
      $rootScope.default_counter = 3600;

      $rootScope.ignoreUnread = false;
      $rootScope.unread_messages = null;
      $rootScope.versions = null;

      $rootScope.onTimeout = function () {
        // do not drop below 0:00
        if ($rootScope.counter != 0) {
          $rootScope.counter--;
          $rootScope.timeLeft = new Date($rootScope.counter * 1000).toISOString().substr(14, 5);
          $rootScope.mytimer = $timeout($rootScope.onTimeout, 1000);
        }
      };

      $rootScope.stopTimer = function () {
        $rootScope.timeLeft = $rootScope.default_timeLeft;
        $timeout.cancel($rootScope.mytimer);
        $rootScope.mytimer = null;
      };

      $rootScope.refreshTimer = function () {
        $rootScope.stopTimer();
        $rootScope.timeLeft = $rootScope.default_timeLeft;
        $rootScope.counter = $rootScope.default_counter;
        $rootScope.mytimer = $timeout($rootScope.onTimeout, 1000);
      };

      $rootScope.refreshToken = function () {
        var refresher = new TokenRefreshService();
        var token = store.get("refresh_token");
        refresher.refresh = token;
        $rootScope.timeLeft = $rootScope.default_timeLeft;

        refresher.$save().then(
          function (success_response) {
            if (success_response.access) {
              store.set("access_token", success_response.access);
              // refresh timer
              $rootScope.refreshTimer();

              // refresh unread messages count
              $rootScope.getUnreadMessagesCount();

              // refresh version numbers
              $rootScope.getVersionNumbers();
              return true;
            }
          },
          function (error_response) {
            // token signature could not be verified or some other error

            // remove all store data
            $window.sessionStorage.clear();

            // send to login
            $state.transitionTo("login");
            return;
          }
        );
      };

      $rootScope.getUnreadMessagesCount = function (skipModal) {

        skipModal = (skipModal === undefined) ? false : skipModal;

        var params = { 
          unreadOnly: true 
        };

        MessagesService.query(params)
          .$promise.then(function (data) {
            $rootScope.unread_messages = data.length;

            if ($rootScope.unread_messages != 0 && !$rootScope.ignoreUnread && !skipModal) {
              $rootScope.$broadcast("ShowUnreadMessageModal", {});
            }

          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      $rootScope.getVersionNumbers = function () {
        VersionService.get()
          .$promise.then(function (data) {
            if ($rootScope.versions) {
              if ($rootScope.versions.APP_VERSION != data.APP_VERSION) {
                $rootScope.$emit("ShowRestartAppModal", {
                  curr_version: $rootScope.versions.APP_VERSION,
                  new_version: data.APP_VERSION,
                });
              }
            } else {
              $rootScope.versions = data;
              $rootScope.app_version = data.APP_VERSION;
              $rootScope.backend_version = data.BACKEND_VERSION;
            }
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      $transitions.onBefore({}, function (transition) {
        var currentState = transition.$from();
        var newToState = transition.$to();

        if (
          currentState == "" &&
          newToState.name !== "login" &&
          newToState.name !== "register" &&
          newToState.name != "menu.nav"
        ) {
          transition.abort();
          $state.transitionTo("menu.nav");
          return;
        }
      });


      $rootScope.useTimer = function () {


        if (!$rootScope.mytimer) {
          $rootScope.counter = $rootScope.default_counter;
          $rootScope.timeLeft = $rootScope.default_timeLeft;
          $rootScope.mytimer = $timeout($rootScope.onTimeout, 1000);
        }

        if ($rootScope.unread_messages == null) {
           $rootScope.getUnreadMessagesCount()
        }

        // refresh version numbers
        if ($rootScope.versions === null) {
          $rootScope.getVersionNumbers()
        }
        
        // refresh token if less than 10 minutes left
        if ($rootScope.counter < 600) {

          if ($rootScope.timeLeft) {
            $timeout.cancel($rootScope.mytimer);
          }

          $rootScope.refreshToken();
        }

      }


      // redirect to login page if not logged in and trying to access a restricted page
      $transitions.onSuccess({}, function ($transitions) {
        // load version numbers once on app start
        if ($rootScope.versions === null) {
          // get current version numbers
          $rootScope.getVersionNumbers();
        }

        var newToState = $transitions.$to();
        var currentState = $transitions.$from();

        var token = store.get("access_token");

        var user_type = null;
        var modules = {};

        if (token) {
          user_type = jwtHelper.decodeToken(token).type;
          modules.terminplaner = jwtHelper.decodeToken(token).mtp_access;
        }

        // user needs to login in case:
        // 1. no token is found in store
        // 2. has token but token is expired
        // 3. tried to access admin pages without proper permissions

        var needsLogin = newToState.data.requireLogin && !token;
        var redirectMain = false;


        if (token && !needsLogin) {
          var admin = jwtHelper.decodeToken(store.get("access_token")).admin;
          store.set("modules", modules);

          if (jwtHelper.isTokenExpired(token)) {
            needsLogin = true;
          }

          if (!modules.terminplaner && newToState.name == "menu.scheduler") {
            redirectMain = true;
          }

          if ((newToState.data.requireAdmin && !admin) || (!newToState.data.requireAdmin && admin)) {
            redirectMain = true;
          }
        }

        if (needsLogin) {
          $rootScope.stopTimer();
          $state.transitionTo("login");
          return;
        }

        if (redirectMain) {
          $rootScope.useTimer();
          $state.transitionTo("menu.nav");
          return;
        }

        // TODO: add time check
        if (newToState.name !== "login" && newToState.name !== "register") {
          // used when page is refreshed since store is lost on refresh

          if (currentState.name != "login" && currentState.name != "register") {
            if (!StoreService.get("mitarbeiter")) {
              StoreService.load_mitarbeiter();
            }

            if (!StoreService.get("rezepte")) {
              params = { age: "new" };
              StoreService.load_rezeptHeader(params);
            }

            if (!StoreService.get("icd10_codes")) {
              StoreService.load_icd10();
            }

            if (!StoreService.get("kunde")) {
              StoreService.load_kunde();
            }
          }
          $rootScope.useTimer();
        }

        
        $rootScope.fromState = currentState.name;
        return;
      });
    },
  ]);
