angular.module("rezepteOverviewRezept").component("rezepteOverviewRezept", {
  templateUrl: "static/angular_assets/rezepte/rezepte_overview/overview_rezept/overview_rezept.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "$filter",
    "RememberService",
    "StoreService",
    "$stateParams",
    function RezepteOverviewRezeptController(
      $scope,
      $rootScope,
      $state,
      $filter,
      RememberService,
      StoreService,
      $stateParams
    ) {
      var rorc = this;

      // ############ TABLE FUNCTIONS ############
      // #########################################

      // function to find a given string (needle) in given attribute (haystack)
      var searchMatch = function (haystack, needle) {
        if (!needle) {
          return true;
        }
        if (!haystack) {
          return false;
        }
        return haystack.toString().toLowerCase().indexOf(needle.toLowerCase()) !== -1;
      };

      rorc.search_rezepte = function (data) {
        var sp = rorc.tables.rezepte_search_data;

        sp.filteredItems = $filter("filter")(data, function (item) {
          // search without using a filter / search for query term over all objects
          if (sp.select_filter == "ALL" || !sp.select_filter) {
            for (var i = 0; i < sp.search_attrs.length; i++) {
              if (searchMatch(item[sp.search_attrs[i]], sp.query)) {
                return true;
              }
            }
          }
          // search using a filter
          else {
            for (var i = 0; i < sp.search_attrs.length; i++) {
              if (item["status"] == sp.select_filter) {
                if (searchMatch(item[sp.search_attrs[i]], sp.query)) {
                  return true;
                }
              }
            }
          }
          return false;
        });

        // take care of the sorting order
        if (sp.sort.sortingOrder !== "") {
          sp.filteredItems = $filter("orderBy")(sp.filteredItems, sp.sort.sortingOrder, sp.sort.reverse);
        }
        sp.currentPage = 1;
        // now group by pages
        RememberService.rezeptSearchSelections = {
          query: sp.query,
          select_filter: sp.select_filter,
          patienten_id: rorc.patienten_id,
        };

        rorc.groupToPages(rorc.tables.rezepte_search_data);
      };

      // calculate and group filteredItems into pages with itemsPerPage
      rorc.groupToPages = function (cnt) {
        var sp = cnt;
        sp.pagedItems = [];

        for (var i = 0; i < sp.filteredItems.length; i++) {
          if (i % sp.itemsPerPage === 0) {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)] = [sp.filteredItems[i]];
          } else {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)].push(sp.filteredItems[i]);
          }
        }
      };

      // pagination range
      rorc.range = function (size, curr) {
        var ret = [];

        if (size == 1) {
          return ret;
        } else if (size <= 5) {
          // TODO: Something is mising here
          for (var i = 2; i <= size - 1; i++) {
            ret.push(i);
          }
          return ret;
        } else {
          // left end
          if (curr == 1) {
            ret.push(curr + 1);
            ret.push(curr + 2);
            ret.push(curr + 3);
          } else if (curr == size) {
            ret.push(curr - 3);
            ret.push(curr - 2);
            ret.push(curr - 1);
          } else if (curr - 1 == 1) {
            ret.push(curr);
            ret.push(curr + 1);
            ret.push(curr + 2);
          } else if (curr + 1 == size) {
            ret.push(curr - 2);
            ret.push(curr - 1);
            ret.push(curr);
          } else {
            ret.push(curr - 1);
            ret.push(curr);
            ret.push(curr + 1);
          }
          /*
           */
          return ret;
        }
      };

      // pagination back-button function
      rorc.prevPage = function (cnt) {
        var d = rorc.tables[cnt];

        if (d.currentPage > 1) {
          d.currentPage--;
        }
      };

      // pagination forward-button function
      rorc.nextPage = function (cnt) {
        var d = rorc.tables[cnt];
        if (d.currentPage < d.pagedItems.length) {
          d.currentPage++;
        }
      };

      // set currently viewed page to n
      rorc.setPage = function (cnt, n) {
        var d = rorc.tables[cnt];
        d.currentPage = n;
      };

      //functions to sort data (for tables) based on multiple attributes/properties
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      rorc.dynamicSortMultiple = function () {
        var props = arguments;
        return function (obj1, obj2) {
          var i = 0,
            result = 0,
            numberOfProperties = props.length;
          /* try getting a different result from 0 (equal)
           * as long as we have extra properties to compare
           */
          while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
          }
          return result;
        };
      };
      // #########################################

      rorc.getRezeptHeaderData = function (patienten_id) {
        var rezepte = StoreService.get("rezepte");

        rorc.rezepte = {};
        rorc.rezepte_cnt = 0;

        for (key in rezepte) {
          if (rezepte.hasOwnProperty(key)) {
            if (rezepte[key]["patient"] == patienten_id) {
              rorc.rezepte[key] = rezepte[key];
              rorc.rezepte_cnt += 1;
            }
          }
        }

        RememberService.rezeptSearchSelections = {
          patienten_id: patienten_id,
        };

        if (rorc.rezepte_cnt > 1000) {
          setTimeout(function () {
            //do what you need here
            rorc.isready = true;
          }, 5);
        } else {
          rorc.isready = true;
        }
      };

      rorc.init = function () {
        rorc.prevState = $rootScope.fromState;
        rorc.savedSelections = RememberService.rezeptSearchSelections;

        rorc.tables = {
          rezepte_search_data: {},
        };

        if (rorc.prevState == "menu.rezepte-overview-view") {
          rorc.tables.rezepte_search_data = {
            query: rorc.savedSelections.query,
            select_filter: rorc.savedSelections.select_filter,
            search_attrs: ["name", "vorname", "strasse", "vers_nummer", "unfalltag"],
            sort: {
              sortingOrder: "id",
              reverse: false,
            },
            gap: 3,
            filteredItems: [],
            itemsPerPage: 10,
            pagedItems: [],
            currentPage: 1,
            patienten_id: rorc.savedSelections.patienten_id,
          };
          rorc.patienten_id = rorc.tables.rezepte_search_data.patienten_id;
        } else {
          rorc.patienten_id = $stateParams.patientenId;

          // if no patienten_id was provided check if one was saved before
          if (!rorc.patienten_id) {
            rorc.patienten_id = rorc.savedSelections.patienten_id;

            // if no patienten_id was saved before, redirect to main menu
            if (!rorc.patienten_id) {
              $state.go("menu.nav");
            }
          }

          rorc.tables.rezepte_search_data = {
            query: "",
            select_filter: "",
            search_attrs: ["name", "vorname", "strasse", "vers_nummer", "unfalltag"],
            sort: {
              sortingOrder: "id",
              reverse: false,
            },
            gap: 3,
            filteredItems: [],
            itemsPerPage: 10,
            pagedItems: [],
            currentPage: 1,
            patienten_id: rorc.patienten_id,
          };
        }

        // reset objects that were most likely used before
        rorc.rezepte = null;
        rorc.isready = false;
        rorc.show_table = false;

        // get related rezepte first
        rorc.getRezeptHeaderData(rorc.patienten_id);
      };

      rorc.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      rorc.showRezept = function (header_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { headerId: header_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-overview-view", newParams);
      };

      // foo-table

      rorc.initFooTable = function () {
        // custom dropdown filter
        FooTable.MyFiltering = FooTable.Filtering.extend({
          construct: function (instance) {
            this._super(instance);
            this.statuses = [
              ["Neu", "Neu"],
              ["Versendet", "Versendet"],
              ["Bearbeitung", "Bearbeitung"],
              ["Abgerechnet", "Abgerechnet"],
              ["Abgelehnt", "Abgelehnt"],
              ["Vordatiert", "Vordatiert"],
              ["Neu-Gesperrt", "Neu-Gesperrt"],
              ["Vordatiert-Gesperrt", "Vordatiert-Gesperrt"],
            ];
            this.def = "Status";
            this.$status = null;
          },

          $create: function () {
            this._super();
            var self = this,
              $form_grp = $("<div/>", {
                class: "form-group",
              })
                .append(
                  $("<label/>", {
                    class: "sr-only",
                    text: "Status",
                  })
                )
                .prependTo(self.$form);

            self.$status = $("<select/>", {
              class: "form-control",
            })
              .on(
                "change",
                {
                  self: self,
                },
                self._onStatusDropdownChanged
              )
              .append(
                $("<option/>", {
                  text: self.def,
                })
              )
              .appendTo($form_grp);

            $.each(self.statuses, function (i, status) {
              self.$status.append(
                $("<option/>", {
                  text: status[1],
                  value: status[0],
                })
              );
            });
          },
          _onStatusDropdownChanged: function (e) {
            var self = e.data.self,
              selected = $(this).val();
            if (selected !== self.def) {
              // ensures exact matches
              selected = '"' + selected + '"';

              self.addFilter("status", selected, ["status"]);
            } else {
              self.removeFilter("status");
            }
            self.filter();
          },
          draw: function () {
            this._super();
            var status = this.find("status");

            // fix issue caused by forcing exact matches
            // fixes dropdown label missing after selecting a filter value
            if (status) {
              var proper_val = status.query.parts[0].query;
              status.query._original = proper_val;
              status.query._value = proper_val;
            }

            if (status instanceof FooTable.Filter) {
              this.$status.val(status.query.val());
            } else {
              this.$status.val(this.def);
            }
          },
        });

        rorc.table.footable({
          components: {
            filtering: FooTable.MyFiltering,
          },
        });

        // show table
        rorc.show_table = true;
      };

      $scope.$watch(
        "rorc.data",
        function () {
          rorc.data = angular.copy(rorc.data);
        },
        true
      );

      rorc.init();
    },
  ],
});
