angular.module("register").component("register", {
  templateUrl: "static/angular_assets/register/register.template.html",
  controller: [
    "$scope",
    "$element",
    "$state",
    "$anchorScroll",
    "$timeout",
    "RegisterService",
    "UsernameService",
    "LeistungserbringerService",
    "VerbaendeService",
    "KostentraegerregionenService",
    "TarifregionenService",
    "IKNumService",
    "store",
    "$window",

    function RegisterController(
      $scope,
      $element,
      $state,
      $anchorScroll,
      $timeout,
      RegisterService,
      UsernameService,
      LeistungserbringerService,
      VerbaendeService,
      KostentraegerregionenService,
      TarifregionenService,
      IKNumService,
      store,
      $window
    ) {
      // add proper CSS classes
      $element.addClass("column is-half form");
      var reg = this;

      isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      reg.username_regexp = /[^A-Za-zßöÖäÄüÜéÉèÈ\-+\_\d]/g;

      get_unique = function (arr) {
        return arr
          ? arr.reduce(function (a, b) {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, [])
          : null;
      };

      reg.set_error_scroll = function (loc) {
        if (!reg.error_scroll) {
          reg.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      //  Loads data for select forms
      reg.getSelectData = function () {
        reg.choices = {};

        LeistungserbringerService.query()
          .$promise.then(function (data) {
            reg.choices.leistungserbringer = data;
          })
          .catch(function (error) {});

        VerbaendeService.query()
          .$promise.then(function (data) {
            reg.choices.verbaende = data;
          })
          .catch(function (error) {});

        KostentraegerregionenService.query()
          .$promise.then(function (data) {
            reg.choices.kostentraegerregionen = data;
          })
          .catch(function (error) {});

        TarifregionenService.query()
          .$promise.then(function (data) {
            reg.choices.tarifregionen = data;
          })
          .catch(function (error) {});
      };

      // INIT
      reg.init = function () {
        // reset login status by removing user from local storage and clearing http auth header
        store.remove("currentUser");
        store.remove("token");

        // load data for select forms
        reg.getSelectData();

        // set navigation points
        reg.navigation = {
          login_view: true,
          kunden_view: false,
          bank_view: false,
          confirm_view: false,
        };

        // holds errors:
        reg.errors = {
          username: [],
          passwort: [],
          passwort_wiederholen: [],
          ik_num: [],
          praxis_name: [],
          le_typ: [],
          verband: [],
          kt_region: [],
          tarif_region: [],
          vorname: [],
          name: [],
          strasse: [],
          plz: [],
          ort: [],
          telefon: [],
          email: [],
          steuer_num: [],
          umsatzsteuer_id: [],
          geldinstitut: [],
          iban: [],
          bic: [],
        };

        reg.disabled = {
          ik_num: false,
          verband: false,
          kt_region: false,
          tarif_region: false,
          umsatzsteuer_id: false,
          steuer_num: false,
          geldinstitut: false,
          iban: false,
          bic: false,
        };

        // used for anchor scroll to first error
        reg.error_scroll = null;

        // stores information for validated data
        reg.valid = {};

        // password strength data
        reg.passwordStrength = {
          score: 0,
        };
        reg.progressText = "Leer";
        reg.progressValue = 0;

        // prepare objects for registration data input
        reg.register = new RegisterService();
        reg.register.username = "";
        reg.register.passwort = "";
        reg.register.passwort_wiederholen = "";
        reg.register.activation_code = "";

        reg.messe_account = false;

        reg.account_activated = false;

        reg.saving = false;

        // setup watcher for passwordStrength
        $scope.$watch(
          "$ctrl.passwordStrength",
          function (newValue, oldValue) {
            reg.progressValue = reg.passwordStrength.score * 25;
            if (!reg.register.passwort) {
              reg.progressText = "Leer";
            } else if (reg.passwordStrength.score == 0) {
              reg.progressText = "Unsicher";
            } else if (reg.passwordStrength.score == 1) {
              reg.progressText = "Schwach";
            } else if (reg.passwordStrength.score == 2) {
              reg.progressText = "Mittel";
            } else if (reg.passwordStrength.score == 3) {
              reg.progressText = "Gut";
            } else {
              reg.progressText = "Stark";
            }
          },
          true
        );

        reg.ik_exists = false;

        // object to hold kunden data
        reg.kunde = {
          ik_num: null,
          praxis_name: null,
          le_typ: null,
          verband: null,
          kt_region: null,
          tarif_region: null,
          vorname: null,
          name: null,
          strasse: null,
          plz: null,
          ort: null,
          telefon: null,
          fax: "",
          email: null,
          umsatzsteuer_id: "",
          steuer_num: null,
          geldinstitut: null,
          iban: null,
          bic: null,
        };

        // object to hold select input selections
        reg.selections = {
          le_typ: null,
          verband: null,
          kt_region: null,
          tarif_region: null,
        };
      };

      //####################################
      //######### MODAL-FUNCTIONS ##########

      //	function for setting Register Success modal
      reg.showSuccess = function () {
        var success_link = angular.element(document.querySelector("#register_success_modal"));
        success_link.addClass("is-active");
      };

      //####################################
      //####################################

      //####################################
      //###### NAVIGATION-FUNCTIONS ########

      // 	function that changes current state to "login"
      reg.loginReturn = function () {
        reg.saving = false;
        $state.go("login");
      };

      //####################################
      //####################################

      //####################################
      //##### ERR-HANDLING-FUNCTIONS #######

      // reset given error in errors object
      reg.resetError = function (err) {
        reg.errors[err] = [];
      };

      reg.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in reg.errors) {
          if (!reg.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (reg.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      // check whether provided username is already in use
      reg.checkUsername = function () {
        reg.errors.username = [];
        delete reg.valid.username;

        if (reg.register.username) {
          if (reg.register.username.includes(" ")) {
            reg.errors.username.push("Im Benutzernamen sind Leerzeichen nicht erlaubt!");
            return;
          }

          var matches = get_unique(reg.register.username.match(reg.username_regexp));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            reg.errors.username.push(err_msg);
          } else {
            username_exists = UsernameService.get({
              username: reg.register.username,
            });
            username_exists.$promise.then(function (data) {
              if (data.message == true) {
                reg.errors.username.push("Dieser Benutzername ist bereits vergeben!");
              } else {
                reg.valid.username = ["Dieser Benutzername ist verfügbar!"];
              }
            });
          }
        }
      };

      // check input in login form
      reg.checkLoginInput = function () {
        if (!reg.register.username) {
          reg.errors.username.push("Es wurde kein Benutzername angegeben!");
        }

        //check passwort input
        if (!reg.register.passwort) {
          reg.resetError("passwort");
          reg.errors.passwort.push("Passwort fehlt!");
        }

        if (!reg.register.passwort_wiederholen) {
          reg.resetError("passwort_wiederholen");
          reg.errors.passwort_wiederholen.push("Passwort wurde nicht wiederholt!");
        }

        if (reg.register.passwort && reg.passwordStrength.score < 2) {
          reg.resetError("passwort");
          reg.errors.passwort.push("Angegebenes Passwort ist nicht stark genug!");
        }

        return reg.checkErrors();
      };

      reg.checkPasswords = function () {
        if (reg.register.passwort) {
          reg.resetError("passwort");
          reg.valid.passwort = true;
        } else {
          delete reg.valid.passwort;
        }

        if (reg.register.passwort && reg.passwordStrength.score < 2) {
          reg.resetError("passwort");
          reg.errors.passwort.push("Angegebenes Passwort ist nicht stark genug!");
        }

        if (reg.register.passwort_wiederholen) {
          reg.resetError("passwort_wiederholen");
          reg.valid.passwort_wiederholen = true;
        } else {
          delete reg.valid.passwort_wiederholen;
        }

        if (reg.register.passwort && reg.register.passwort_wiederholen) {
          if (reg.register.passwort != reg.register.passwort_wiederholen) {
            delete reg.valid.passwort;
            delete reg.valid.passwort_wiederholen;
            reg.errors.passwort_wiederholen = ["Passwörter sind nicht identisch!"];
          }
        }
      };

      reg.gotoKundeInput = function () {
        if (reg.checkLoginInput()) {
          // update stepper
          var step_curr = angular.element(document.querySelector("#login-step"));
          var step_next = angular.element(document.querySelector("#kunde-step"));
          step_curr.removeClass("is-active");
          step_next.addClass("is-active");

          // switch view from login to kunde
          reg.navigation.login_view = false;
          reg.navigation.kunden_view = true;
        }
      };

      reg.returntoLoginInput = function () {
        // update stepper
        var step_curr = angular.element(document.querySelector("#kunde-step"));
        var step_next = angular.element(document.querySelector("#login-step"));
        step_curr.removeClass("is-active");
        step_next.addClass("is-active");

        // reset possible errors
        for (var error in reg.errors) {
          reg.errors[error] = [];
        }

        // switch view from login to kunde
        reg.navigation.kunden_view = false;
        reg.navigation.login_view = true;
      };

      reg.checkIKNum = function () {
        if (reg.kunde.ik_num && reg.kunde.ik_num.length == 9) {
          IKNumService.get({
            ik_num: reg.kunde.ik_num,
          }).$promise.then(function (data) {
            reg.ik_exists = data.message;
          });
        }
      };

      // check Kunden-Data input
      reg.checkKundeInput = function () {
        // reset errors
        for (var error in reg.errors) {
          reg.errors[error] = [];
        }

        // reset anchor scroll position
        reg.error_scroll = null;

        // general data
        if (!reg.kunde.vorname) {
          reg.errors.vorname.push("Es wurde kein Vorname angegeben!");
          reg.set_error_scroll("vorname_loc");
        }

        if (!reg.kunde.name) {
          reg.errors.name.push("Es wurde kein Nachname angegeben!");
          reg.set_error_scroll("nachname_loc");
        }

        if (!reg.kunde.strasse) {
          reg.errors.strasse.push("Es wurde keine Straße angegeben!");
          reg.set_error_scroll("strasse_loc");
        }

        if (!reg.kunde.plz) {
          reg.errors.plz.push("Es wurde keine PLZ angegeben!");
          reg.set_error_scroll("plz_loc");
        } else {
          if (!isNumber(reg.kunde.plz)) {
            reg.errors.plz.push("Angegebene PLZ ist ungültig (nur Zahlen sind erlaubt)!");
            reg.set_error_scroll("plz_loc");
          }
          if (reg.kunde.plz.length != 5) {
            reg.errors.plz.push("Angegebene PLZ ist nicht 5 Stellen lang!");
            reg.set_error_scroll("plz_loc");
          }
        }

        if (!reg.kunde.ort) {
          reg.errors.ort.push("Es wurde kein Ort angegeben!");
          reg.set_error_scroll("ort_loc");
        }

        if (!reg.kunde.telefon) {
          reg.errors.telefon.push("Es wurde keine Telefonnr. angegeben!");
          reg.set_error_scroll("telefon_loc");
        }

        if (!reg.kunde.email) {
          reg.errors.email.push("Es wurde keine Email angegeben!");
          reg.set_error_scroll("email_loc");
        } else {
          var re =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
          if (!re.test(reg.kunde.email)) {
            reg.errors.email.push("Angegebene Email ist ungültig (ungültiges Format)!");
            reg.set_error_scroll("email_loc");
          }
        }

        // praxis data
        if (!reg.kunde.ik_num) {
          reg.errors.ik_num.push("Es wurde keine IK-Nummer angegeben!");
          reg.set_error_scroll("ik_num_loc");
        } else {
          if (!isNumber(reg.kunde.ik_num)) {
            reg.errors.ik_num.push("Angegebene IK-Nummer ist keine gültige Nummer!");
            reg.set_error_scroll("ik_num_loc");
          } else {
            if (reg.ik_exists) {
              reg.errors.ik_num.push("Angegebene IK-Nummer wird bereits verwendet!");
              reg.set_error_scroll("ik_num_loc");
            }
          }
          if (reg.kunde.ik_num.length != 9) {
            reg.errors.ik_num.push("IK-Nummer ist nicht 9 Stellen lang!");
            reg.set_error_scroll("ik_num_loc");
          }
        }

        if (!reg.kunde.praxis_name) {
          reg.errors.praxis_name.push("Es wurde kein Praxisname angegeben!");
          reg.set_error_scroll("praxis_name_loc");
        }

        if (!reg.selections.le_typ) {
          reg.errors.le_typ.push("Es wurde kein Leistungserbringer angegeben!");
          reg.set_error_scroll("le_typ_loc");
        }

        if (!reg.selections.verband) {
          reg.errors.verband.push("Es wurde kein Verband angegeben!");
          reg.set_error_scroll("verband_loc");
        }

        if (!reg.selections.kt_region) {
          reg.errors.kt_region.push("Es wurde keine Kostenträger-Region angegeben!");
          reg.set_error_scroll("kt_region_loc");
        }

        if (!reg.selections.tarif_region) {
          reg.errors.tarif_region.push("Es wurde keine Tarif-Region angegeben!");
          reg.set_error_scroll("tarif_region_loc");
        }

        /*
				if(!reg.kunde.umsatzsteuer_id){
					reg.errors.umsatzsteuer_id.push("Es wurde keine Umsatzsteuer-ID angegeben!");
					reg.set_error_scroll('umsatzsteuer_id_loc');
				}
				*/

        if (!reg.kunde.steuer_num) {
          reg.errors.steuer_num.push("Es wurde keine Steuernummer angegeben!");
          reg.set_error_scroll("steuer_num_loc");
        } else {
          if (reg.kunde.steuer_num.length > 13) {
            reg.errors.steuer_num.push("Angegebene Steuernummer ist zu lang!");
            reg.set_error_scroll("steuer_num_loc");
          }
        }

        return reg.checkErrors();
      };

      reg.gotoBankInput = function () {
        if (reg.checkKundeInput()) {
          // update stepper
          var step_curr = angular.element(document.querySelector("#kunde-step"));
          var step_next = angular.element(document.querySelector("#bank-step"));
          step_curr.removeClass("is-active");
          step_next.addClass("is-active");

          // switch view from login to kunde
          reg.navigation.kunden_view = false;
          reg.navigation.bank_view = true;
        }
      };

      reg.focus_input = function (input_name) {
        $timeout(function () {
          $anchorScroll("confirm-step");
          $window.document.getElementById(input_name).focus();
        });
      };

      reg.checkBankInput = function () {
        // reset errors
        for (var error in reg.errors) {
          reg.errors[error] = [];
        }

        // reset anchor scroll position
        reg.error_scroll = null;

        if (!reg.kunde.geldinstitut) {
          reg.errors.geldinstitut.push("Es wurde kein Geldinstitut angegeben!");
          reg.set_error_scroll("geldinstitut_loc");
        }

        if (!reg.kunde.iban) {
          reg.errors.iban.push("Es wurde keine IBAN angegeben!");
          reg.set_error_scroll("iban_loc");
        } else {
          if (reg.kunde.iban.length > 34) {
            reg.errors.iban.push("Angegebene IBAN ist zu lang!");
            reg.set_error_scroll("iban_loc");
          }
        }

        if (!reg.kunde.bic) {
          reg.errors.bic.push("Es wurde keine BIC angegeben!");
          reg.set_error_scroll("bic_loc");
        } else {
          if (reg.kunde.bic.length < 8) {
            reg.errors.bic.push("Angegebene BIC ist zu kurz!");
            reg.set_error_scroll("bic_loc");
          } else if (reg.kunde.bic.length > 11) {
            reg.errors.bic.push("Angegebene BIC ist zu lang!");
            reg.set_error_scroll("bic_loc");
          }
        }

        return reg.checkErrors();
      };

      reg.gotoConfirmInput = function () {
        if (reg.checkBankInput()) {
          // update stepper
          var step_curr = angular.element(document.querySelector("#bank-step"));
          var step_next = angular.element(document.querySelector("#confirm-step"));
          step_curr.removeClass("is-active");
          step_next.addClass("is-active");

          // switch view from login to kunde
          reg.navigation.bank_view = false;
          reg.navigation.confirm_view = true;
        }
      };

      reg.returntoKundeInput = function () {
        // update stepper
        var step_curr = angular.element(document.querySelector("#bank-step"));
        var step_next = angular.element(document.querySelector("#kunde-step"));
        step_curr.removeClass("is-active");
        step_next.addClass("is-active");

        // reset possible errors
        for (var error in reg.errors) {
          reg.errors[error] = [];
        }

        // switch view from login to kunde
        reg.navigation.bank_view = false;
        reg.navigation.kunden_view = true;
      };

      reg.returntoBankInput = function () {
        // update stepper
        var step_curr = angular.element(document.querySelector("#confirm-step"));
        var step_next = angular.element(document.querySelector("#bank-step"));
        step_curr.removeClass("is-active");
        step_next.addClass("is-active");

        // reset possible errors
        for (var error in reg.errors) {
          reg.errors[error] = [];
        }

        // switch view from login to kunde
        reg.navigation.confirm_view = false;
        reg.navigation.bank_view = true;
      };

      reg.showErrorMsg = function () {
        reg.error_msg = {
          "padding-top": "0.5em",
          display: "block",
        };
      };

      reg.hideErrorMsg = function () {
        reg.error_msg = {
          "padding-top": "0.5em",
          display: "none",
        };
      };

      reg.handleMesseAccount = function () {
        if (reg.messe_account) {
          // disable all fields
          for (var key in reg.disabled) {
            // check if the property/key is defined in the object itself, not in parent
            if (reg.disabled.hasOwnProperty(key)) {
              reg.disabled[key] = true;
            }
          }

          // IK-NUM DUMMY
          var today = new Date();
          var month = (today.getMonth() + 1).toString().padStart(2, "0");
          var day = today.getDate().toString();
          var hour = today.getHours().toString();
          var minute = today.getMinutes().toString();

          var ik_num = "9" + month + day + hour + minute;
          reg.kunde.ik_num = ik_num;
          reg.checkIKNum();

          var verband_typ = '----';
          var kt_region_id = "02";
          var tarif_region_id = "05";

          // verband selection
          reg.choices.verbaende.forEach(function (item, index) {
            if (item.typ == verband_typ) {
              reg.selections.verband = item;
            }
          });

          // kt_region selection
          reg.choices.kostentraegerregionen.forEach(function (item, index) {
            if (item.id == kt_region_id) {
              reg.selections.kt_region = item;
            }
          });

          // tarif_region selection
          reg.choices.tarifregionen.forEach(function (item, index) {
            if (item.id == tarif_region_id) {
              reg.selections.tarif_region = item;
            }
          });

          // steuernummer
          reg.kunde.steuer_num = "9999123459999";

          // bank informationen
          reg.kunde.geldinstitut = "Testbank Hamburg";
          reg.kunde.iban = "DE23500105170012345678";
          reg.kunde.bic = "INGDDEFF";
        } else {
          // enable all fields

          for (var key in reg.disabled) {
            // check if the property/key is defined in the object itself, not in parent
            if (reg.disabled.hasOwnProperty(key)) {
              reg.disabled[key] = false;
            }
          }

          reg.kunde.ik_num = "";
          reg.selections.verband = null;
          reg.selections.kt_region = null;
          reg.selections.tarif_region = null;
          reg.kunde.steuer_num = "";
          reg.kunde.geldinstitut = "";
          reg.kunde.iban = "";
          reg.kunde.bic = "";
        }
      };

      //COMMON
      reg.registerUser = function () {
        // set le_typ/verband/kt_region/tarif_region to related ID
        reg.kunde.le_typ = reg.selections.le_typ.id;
        reg.kunde.verband = reg.selections.verband.id;
        reg.kunde.kt_region = reg.selections.kt_region.id;
        reg.kunde.tarif_region = reg.selections.tarif_region.id;
        reg.register.kunde = reg.kunde;

        reg.saving = true;
        reg.register.$save().then(
          function (success_response) {
            if (success_response.username) {
              reg.account_activated = success_response.activated;
              reg.showSuccess();
            }
          },
          function (error) {
            reg.saving = false;
            console.log(error);
          }
        );
      };

      reg.init();
    },
  ],
});
