angular.module("rezepteAddLines").component("rezepteAddLines", {
  templateUrl: "static/angular_assets/rezepte/rezepte_add_new/rezepte_add_lines/rezepte_add_lines.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "$window",
    "$anchorScroll",
    "$timeout",
    "HeilmittelService",
    "HeilmittelOldService",
    "PreislistenCheckService",
    "IdsTarifService",
    "RememberService",
    "VollprivatTarifService",
    "DiagnoseTarifService",
    "$stateParams",
    function RezepteAddController(
      $scope,
      $rootScope,
      $state,
      $window,
      $anchorScroll,
      $timeout,
      HeilmittelService,
      HeilmittelOldService,
      PreislistenCheckService,
      IdsTarifService,
      RememberService,
      VollprivatTarifService,
      DiagnoseTarifService,
      $stateParams
    ) {
      var ral = this;

      ral.init = function () {
        // holds errors
        ral.errors = {
          positions: [],
          messages: [],
        };

        // used for anchor scroll to first error
        ral.error_scroll = null;
        $anchorScroll.yOffset = 200;

        // previously visited state
        ral.fromState = $rootScope.fromState;

        // rezeptHeader object
        ral.rezeptHeader = null;

        // patient object
        ral.patient = null;

        // general object that holds data related to patient/rezeptHeader
        ral.general = null;

        // heilmittel object
        ral.heilmittel = null;

        // set page readiness to false
        ral.isready = false;

        // blanko light cycle type/title/text
        ral.blanko = {
          type: "green", // green / yellow / red
          title: "",
          text: "",
        };

        ral.tarifWithLockedMenge = [
          { tarif: "19701", menge: "1" },
          { tarif: "29701", menge: "1" },
          { tarif: "59701", menge: "1" },
          { tarif: "89701", menge: "1" },
          { tarif: "33010", menge: "1" },
          { tarif: "33011", menge: "1" },
          { tarif: "54002", menge: "1" },
          { tarif: "12.1", menge: "1" },
          { tarif: "11906", menge: "1" },
          { tarif: "21906", menge: "1" },
          { tarif: "81906", menge: "1" },
          { tarif: "3.1", menge: "1" },
          { tarif: "20522", menge: "1" },
          { tarif: "20524", menge: "1" },
          { tarif: "54003", menge: "1" },
          { tarif: "54503", menge: "1" },
          { tarif: "20523", menge: "1"},
          { tarif: "20553", menge: "1"},
          { tarif: "20557", menge: "1"},
          { tarif: "59741", menge: "1"},
        ];

        if (ral.fromState == "menu.rezepte-add-dates") {
          ral.rezeptHeader = RememberService.rezeptAddLinesData.rezeptHeader;
          ral.patient = RememberService.rezeptAddLinesData.patient;
          ral.general = RememberService.rezeptAddLinesData.general;
          ral.positions = RememberService.rezeptAddLinesData.positions;
        } else {
          // if no parameters were provided return to rezepte_add_selection
          if (!$stateParams.params) {
            ral.goToPreviousState();
            return;
          }

          ral.patient = $stateParams.params.patient;
          ral.rezeptHeader = $stateParams.params.rezeptHeader;
          ral.general = $stateParams.params.general;

          ral.positions = [
            {
              id: 1,
              heilmittel: null,
              km: null,
              doubletr: false,
            },
          ];
        }

        // VOLLPRIVAT LOGIC
        if (ral.general.rezeptart_selected.id == "VPP") {
          ral.getVollprivatTarif();
          ral.checkifready_vpp();

          // REMAINING REZEPTARTEN
        } else {
          // get related heilmittel/tarif data first
          ral.getHeilmittelData();

          if (ral.rezeptHeader.rezeptversion == "Alt") {
            // get related ids-tarif lookup
            ral.getIdsTarifData();
          } else {
            ral.getDiagnoseTarifData();
          }

          // check if ready to be displayed
          ral.checkifready();
        }
      };

      ral.getVollprivatTarif = function () {
        VollprivatTarifService.query()
          .$promise.then(function (data) {
            ral.heilmittel = data;
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      ral.getHeilmittelData = function () {
        ral.general.preislisten = null;
        ral.general.preistyp = null; // alt, neu, mix (starts with alt but could be neu at some point), alt_2, mix_alt
        tt = null;

        // additional logic to check which HeilmittelData to return
        PreislistenCheckService.get({
          patient_id: ral.patient.id,
        })
          .$promise.then(function (data) {
            ral.general.preislisten = data;

            // transform string to date object
            ral.general.preislisten.neu.date_from = ral.str_to_date(ral.general.preislisten.neu.date_from);
            ral.general.preislisten.alt.date_from = ral.str_to_date(ral.general.preislisten.alt.date_from);
            ral.general.preislisten.alt_2.date_from = ral.str_to_date(ral.general.preislisten.alt_2.date_from);

            // TODO: Remove this logic
            // if rezeptdatum < 01.08.2021 - use old check value
            var p_check = ral.general.preislisten.check;
            if (ral.dateDiffInDays(ral.general.rezeptdatum, ral.str_to_date("2021-08-01	")) > 0) {
              p_check = ral.general.preislisten.old_check;
            }

            // if check is "Rezeptdatum" then no price changes can occur on different dates
            if (p_check == "Rezeptdatum") {
              diff_1 = ral.dateDiffInDays(ral.general.preislisten.neu.date_from, ral.general.rezeptdatum);
              diff_2 = ral.dateDiffInDays(ral.general.preislisten.alt.date_from, ral.general.rezeptdatum);

              if (diff_1 >= 0) {
                tt = ral.general.preislisten.neu.tarif_typ;
                ral.general.preistyp = "neu";
              } else if (diff_2 >= 0) {
                tt = ral.general.preislisten.alt.tarif_typ;
                ral.general.preistyp = "alt";
              } else {
                tt = ral.general.preislisten.alt_2.tarif_typ;
                ral.general.preistyp = "alt_2";
              }
            }

            // if check is "Behandlungsdatum" then price changes can only occur when the old prices are selected first
            else if (p_check == "Behandlungsdatum") {
              diff_1 = ral.dateDiffInDays(ral.general.preislisten.neu.date_from, ral.general.erfassungsdatum);
              diff_2 = ral.dateDiffInDays(ral.general.preislisten.alt.date_from, ral.general.erfassungsdatum);
              if (diff_1 >= 0) {
                tt = ral.general.preislisten.neu.tarif_typ;
                ral.general.preistyp = "neu";
              } else if (diff_2 >= 0) {
                tt = ral.general.preislisten.alt.tarif_typ;
                ral.general.preistyp = "mix";
              } else {
                tt = ral.general.preislisten.alt_2.tarif_typ;
                ral.general.preistyp = "mix_alt";
              }
            }

            if (ral.rezeptHeader.rezeptversion == "Alt") {
              h_query = {
                id_key: ral.general.ids_key,
                patient_id: ral.patient.id,
                tarif_typ: tt,
              };
            } else {
              h_query = {
                diagnose_key: ral.general.diagnose_key,
                patient_id: ral.patient.id,
                tarif_typ: tt,
                blanko: ral.rezeptHeader.blanko
              };
            }

            if (ral.general.preistyp == "mix_alt" || ral.general.preistyp == "alt_2") {
              HeilmittelOldService.query(h_query)
                .$promise.then(function (data) {
                  ral.heilmittel = data;
                })
                .catch(function (error) {
                  // fix for "Possibly unhandled rejection"
                });
            } else {
              HeilmittelService.query(h_query)
                .$promise.then(function (data) {
                  ral.heilmittel = data;
                })
                .catch(function (error) {
                  // fix for "Possibly unhandled rejection"
                });
            }

            // in addition grab Heilmittel Data with new prices if preistyp == "mix"
            if (ral.general.preistyp == "mix" || ral.general.preistyp == "mix_alt") {
              if (ral.rezeptHeader.rezeptversion == "Alt") {
                h_query = {
                  id_key: ral.general.ids_key,
                  patient_id: ral.patient.id,
                  tarif_typ: ral.general.preislisten.neu.tarif_typ,
                };
              } else {
                var tt_choice = ral.general.preislisten.neu.tarif_typ;
                if (ral.general.preistyp == "mix_alt") {
                  tt_choice = ral.general.preislisten.alt.tarif_typ;
                }
                h_query = {
                  diagnose_key: ral.general.diagnose_key,
                  patient_id: ral.patient.id,
                  tarif_typ: tt_choice,
                  blanko: ral.rezeptHeader.blanko
                };
              }

              HeilmittelService.query(h_query)
                .$promise.then(function (data) {
                  ral.general.heilmittel_neu = data;
                })
                .catch(function (error) {
                  // fix for "Possibly unhandled rejection"
                });
            } else {
              ral.general.heilmittel_neu = null;
            }

          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      ral.getIdsTarifData = function () {
        IdsTarifService.query({
          id_key: ral.general.ids_key,
        })
          .$promise.then(function (data) {
            ral.general.ids_tarif_lkp = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      ral.getDiagnoseTarifData = function () {
        DiagnoseTarifService.query({
          diagnose_key: ral.general.diagnose_key,
        })
          .$promise.then(function (data) {
            ral.general.diagnose_tarif_lkp = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      ral.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      ral.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      ral.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      ral.dateDiffInDays = function (a, b) {
        // Discard the time and time-zone information.
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      };

      ral.set_error_scroll = function (loc) {
        if (!ral.error_scroll) {
          ral.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      // ####### POSITIONEN FUNCTIONS ########
      // #####################################

      ral.addNewPosition = function () {
        var newNo = ral.positions.length + 1;

        ral.positions.push({
          id: newNo,
          heilmittel: null,
          km: null,
          doubletr: false,
        });

        // focus new element
        $timeout(function () {
          $window.document.getElementById("menge" + newNo).focus();
        }, 10);
      };

      ral.removePosition = function (pos) {
        var to_delete = 0;
        for (var i = 0; i < ral.positions.length; i++) {
          if (ral.positions[i].id == pos) {
            to_delete = i;
          }
        }
        ral.positions.splice(to_delete, 1);

        // update ids after remove
        for (var i = 0; i < ral.positions.length; i++) {
          ral.positions[i].id = i + 1;
        }

        if (!ral.checkErrors()) {
          ral.checkPositionenInput();
        }
      };

      // #####################################
      ral.addDefaultLines = function () {
        // check if ergo conditions for setting a default value are fullfiled
        var ergo_default_flag =
          ral.general.verordnungsart_selected.name == "Erstverordnung" && ral.general.le_typ == "26";
        var logo_default_flag = ral.rezeptHeader.logo_erstbefund;

        for (var i = 0; i < ral.heilmittel.length; i++) {
          var h = ral.heilmittel[i];

          if (ergo_default_flag) {
            // tarif 12.1 - BG is also "Funktionsanalyse ErstVO" - ignore
            if (h.tarif == "54002") {
              ral.positions[0].menge = "1";
              ral.positions[0].heilmittel = h;
              ral.addNewPosition();
            }
          }

          if (logo_default_flag) {
            if (h.tarif == "33010") {
              ral.positions[0].menge = "1";
              ral.positions[0].heilmittel = h;
              ral.addNewPosition();
            }
          }
        }
      };


      ral.addDefaultBlankoLines = function () {
        // check if default lines are already set
        var default_lines_found = [];

        for (var i = 0; i < ral.positions.length; i++) {
          var entry = ral.positions[i];
          if (entry.heilmittel && ["20522", "54003", "20524", "54503"].includes(entry.heilmittel.tarif)){
            default_lines_found.push(entry.heilmittel.tarif);
          }
        }

        for (var i = 0; i < ral.heilmittel.length; i++) {
          var h = ral.heilmittel[i];

          if (!default_lines_found.includes(h.tarif) && ["20522", "54003", "20524", "54503"].includes(h.tarif)) {
            // add to last position
            ral.positions[ral.positions.length - 1].menge = "1";
            ral.positions[ral.positions.length - 1].heilmittel = h;
            ral.addNewPosition();
          }
        }
      };
            

      // ########### CHECK FUNCTIONS ###########
      // #######################################
      ral.checkifready = function () {
        var done = false;

        if (ral.rezeptHeader.rezeptversion == "Alt") {
          if (!ral.heilmittel | !ral.general.ids_tarif_lkp) {
            setTimeout(ral.checkifready, 50);
          } else {
            done = true;
          }
        } else {
          if (!ral.heilmittel | !ral.general.diagnose_tarif_lkp) {
            setTimeout(ral.checkifready, 50);
          } else {
            done = true;
          }
        }

        if (done) {
          if (ral.rezeptHeader.rezeptversion == "Alt") {
            // add default lines based on provided preconditions
            ral.addDefaultLines();
          }

          if (ral.rezeptHeader.blanko){
            ral.addDefaultBlankoLines();
            ral.checkBlankoLightCycle();
          }

          // data is ready to be displayed
          ral.isready = true;
          $scope.$apply();
          // focus first input element
          $window.document.getElementById("menge1").focus();
        }
      };

      ral.checkifready_vpp = function () {
        if (!ral.heilmittel) {
          setTimeout(ral.checkifready_vpp, 50);
        } else {
          ral.isready = true;
          $scope.$apply();
          // focus first input element
          $window.document.getElementById("menge1").focus();
        }
      };

      ral.check_km = function (d) {
        if (d) {
          if (d.bezeichnung.indexOf("Wegegeld je Kilometer") !== -1) {
            return true;
          }
        }

        return false;
      };

      ral.checkBlankoLightCyclePhysio = function () {
        var vorrangigSum = 0;
        var ergaenzendSum = 0;

        for (var i = 0; i < ral.positions.length; i++) {
          // line object
          var entry = ral.positions[i];

          // check only if menge and heilmittel are provided
          if (entry.menge && entry.heilmittel) {

            // tarif info holds information regarding vorrangig/ergaenzend
            const tarifInfo = ral.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });

            if (tarifInfo) {
              if (tarifInfo.vorrangig) {
                vorrangigSum += parseInt(entry.menge);
              } else if (tarifInfo.ergaenzend) {
                ergaenzendSum += parseInt(entry.menge);
              }
            }
          }
        }

        if (vorrangigSum > ral.general.blankoLightCyclePhysioVorrangig || ergaenzendSum > ral.general.blankoLightCyclePhysioErgaenzend) {
          ral.blanko.type = "red";
          ral.blanko.title = "Ampelphase rot";
          ral.blanko.text = "Sie haben die maximale Anzahl an vorrangigen <strong>(≤" + ral.general.blankoLightCyclePhysioVorrangig + ")</strong> oder ergänzenden <strong>(≤" + ral.general.blankoLightCyclePhysioErgaenzend + ")</strong> Heilmitteln für die grüne Ampelphase überschritten! <strong><br>Es erfolgt ein Preisabschlag in Höhe von 9% auf die Behandlungen, die innerhalb der roten Phase erbracht werden.</strong>";
        }
        else {
          ral.blanko.type = "green";
          ral.blanko.title = "Ampelphase grün";
          ral.blanko.text = "Diese Ampelphase gilt bis zu einer Anzahl von <strong>" + ral.general.blankoLightCyclePhysioVorrangig + "</strong> vorrangigen und <strong>" + ral.general.blankoLightCyclePhysioErgaenzend + "</strong> ergänzenden Heilmitteln!";
        }

        return
      };

      ral.checkBlankoLightCycleErgo = function () {
        var timeUnitSum = 0;

        for (var i = 0; i < ral.positions.length; i++) {
          var entry = ral.positions[i];
          if (entry.menge && entry.heilmittel) {
            var name = entry.heilmittel.bezeichnung;

            if (name.includes("1ZI")) {
              timeUnitSum += parseInt(entry.menge);
            }
          }
        }


        if (timeUnitSum <= ral.general.blankoLightCycleErgoGreenLimit){
          const max_limit = ral.general.blankoLightCycleErgoGreenLimit;

          ral.blanko.type = "green";
          ral.blanko.title = "Ampelphase grün";
          ral.blanko.text = "Diese Ampelphase gilt bis zu <strong>" + max_limit + "</strong> Zeitintervallen! Aktuelle Anzahl: <strong>" + timeUnitSum + " ZI</strong>";
        }
        else if (ral.general.blankoLightCycleErgoGreenLimit < timeUnitSum && timeUnitSum <= ral.general.blankoLightCycleErgoYellowLimit){
          const min_limit = ral.general.blankoLightCycleErgoGreenLimit + 1;
          const max_limit = ral.general.blankoLightCycleErgoYellowLimit;

          ral.blanko.type = "yellow";
          ral.blanko.title = "Ampelphase gelb";
          ral.blanko.text = "Diese Ampelphase gilt bei <strong>" + min_limit + "</strong> bis <strong>" + max_limit + "</strong> Zeitintervallen! Aktuelle Anzahl: <strong>" + timeUnitSum + " ZI</strong>";
        }
        else {
          const min_limit = ral.general.blankoLightCycleErgoYellowLimit + 1;

          ral.blanko.type = "red";
          ral.blanko.title = "Ampelphase rot";
          ral.blanko.text = "Diese Ampelphase gilt ab <strong>" + min_limit + "</strong> Zeitintervallen! Aktuelle Anzahl: <strong>" + timeUnitSum + " ZI</strong> <strong><br>Es erfolgt ein Preisabschlag in Höhe von 9% auf die ZI innerhalb der roten Phase.</strong>";
        }

        return
      };

      ral.checkBlankoLightCycle = function () {
        if (!ral.rezeptHeader.blanko || (!ral.general.blankoLightCyclePhysio && !ral.general.blankoLightCycleErgo)) {
          return;
        }

        if (ral.general.le_typ == "22") {
          ral.checkBlankoLightCyclePhysio();
        } else if (ral.general.le_typ == "26") {
          ral.checkBlankoLightCycleErgo();
        }

        return;
      };

      ral.isMengeLocked = function(position) {
        if (!position.heilmittel) {
          return false;
        }
      
        var matchingTarif = ral.tarifWithLockedMenge.find(function(entry) {
          return entry.tarif === position.heilmittel.tarif;
        });
      
        if (matchingTarif) {
          var positionIndex = ral.positions.findIndex(function(x) {
            return x.id === position.id;
          });
          
          ral.positions[positionIndex].menge = matchingTarif.menge;
          return true;
        }
      
        return false;
      };

      ral.has_affected_lines = function () {
        for (var type in ral.affected_lines) {
          if (!ral.affected_lines.hasOwnProperty(type)) {
            //The current error is not a direct property of p
            continue;
          }
          if (ral.affected_lines[type].length != 0) {
            return true;
          }
        }

        return false;
      };

      ral.checkPositionenInput = function () {
        // reset current errors
        for (var error in ral.errors) {
          ral.errors[error] = [];
        }

        // object to store line numbers that have an issue
        ral.affected_lines = {
          mengenotnumber: [],
          mengenotprovided: [],
          heilmittel: [],
          kmnotnumber: [],
          usedcount: [],
          mengetoohigh: [],
          vorrangig_toohigh: [],
          optional_toohigh: [],
          ergaenzend_toohigh: [],
          ergaenzend_alone: [],
          none_alone: [],
          invalidcombination: [],
          invalidcombination_a_b: [],
          vorrangig_toohigh_3: [],
          vorrangig_toohigh_za: [],
          ergo_funktionsanalyse: [],
          bandage_mld: [],
          logo_erstbefund: [],
          ergaenzend_duplicated: [],
          vorrangig_inconsistent: [],
          v_mengetoohigh: [],
          e_mengetoohigh: [],
          mehrfach_mengetoohigh: [],
          none_mengetoohigh: [],
          vorrangig_mehrfach: [],
          blanko_diagnostik_toohigh: [],
          max_1_entry: [],
        };

        // reset anchor scroll position
        ral.error_scroll = null;

        // check if hausbesuch position was selected in header
        if (ral.rezeptHeader.hausbesuch == true) {
          var missing = true;

          for (i = 0; i < ral.positions.length; i++) {
            var entry = ral.positions[i];
            if (entry.heilmittel) {
              if (
                entry.heilmittel.bezeichnung.indexOf("Hausbesuch") !== -1 ||
                entry.heilmittel.bezeichnung.indexOf("Heimbesuch") !== -1
              ) {
                missing = false;
                break;
              }
            }
          }

          if (missing) {
            ral.errors.messages.push("Hausbesuch/Heimbesuch fehlt!");
          }
        }
        // if hausbesuch position was not selected in header
        else {
          var set = false;

          for (i = 0; i < ral.positions.length; i++) {
            var entry = ral.positions[i];
            if (entry.heilmittel) {
              if (
                entry.heilmittel.bezeichnung.includes("Hausbesuch") ||
                entry.heilmittel.bezeichnung.includes("Heimbesuch")
              ) {
                set = true;
                break;
              }
            }
          }

          if (set) {
            ral.errors.messages.push(
              "Hausbesuch/Heimbesuch ausgewählt! Eingabe entspricht nicht der Auswahl in den Kopfdaten!"
            );
          }
        }

        // check if default blanko positions are missing and also if the additional diagnotic isn't too high
        if (ral.rezeptHeader.blanko){
          var defaultTarifLkpPD = ["20522", "54003"];
          var defaultTarifLkpPau = ["20524", "54503"];

          var missingPD = true;
          var missingPau = true;

          for (var i = 0; i < ral.positions.length; i++) {
            var entry = ral.positions[i];
            if (entry.heilmittel) {
              if (defaultTarifLkpPD.includes(entry.heilmittel.tarif)) {
                missingPD = false;
              }

              if (defaultTarifLkpPau.includes(entry.heilmittel.tarif)) {
                missingPau = false;
              }
            }
          }

          if (missingPD) {
            if (ral.general.le_typ == "22") {
              ral.errors.messages.push("Physiotherapeutische Diagnostik fehlt!");
            } else if (ral.general.le_typ == "26") {
              ral.errors.messages.push("Analyse ergotherapeutischer Bedarf fehlt!");
            }
          }

          if (missingPau) {
            ral.errors.messages.push("Versorgungspauschale Blanko-VO fehlt!");
          }
        }

        // check if therapiebericht position was selected in header
        if (ral.rezeptHeader.therapiebericht == true) {
          var tarif_lkp = ["19701", "29701", "59701", "89701", "20553", "59741"];
          var missing = true;

          for (var i = 0; i < ral.positions.length; i++) {
            var entry = ral.positions[i];
            if (entry.heilmittel) {
              if (tarif_lkp.includes(entry.heilmittel.tarif)) {
                missing = false;
                break;
              }
            }
          }

          if (missing) {
            var tarif_entry_available = false;
            for (var i = 0; i < ral.heilmittel.length; i++) {
              var t = ral.heilmittel[i]["tarif"];
              if (tarif_lkp.includes(t)) {
                tarif_entry_available = true;
                break;
              }
            }

            if (tarif_entry_available) {
              ral.errors.messages.push("Arztbericht/Therapiebericht fehlt!");
            }
          }
        }
        // if therapiebericht position was not selected in header
        else {
          var tarif_lkp = ["19701", "29701", "59701", "89701", "20553", "59741"];
          var set = false;

          for (i = 0; i < ral.positions.length; i++) {
            var entry = ral.positions[i];
            if (entry.heilmittel) {
              if (tarif_lkp.includes(entry.heilmittel.tarif)) {
                set = true;
                break;
              }
            }
          }

          if (set) {
            ral.errors.messages.push("Arztbericht ausgewählt! Eingabe entspricht nicht der Auswahl in den Kopfdaten!");
          }
        }


        ral.general.lim = ral.general.diagnose.max_vo;

        // Overwrite limit for blanko light cycle
        // Blanko light cycle as it has no upper limit for the amount of entries
        if (
          ral.general.blankoLightCyclePhysio || 
          (ral.rezeptHeader.blanko && ral.general.le_typ == "26")
        ) {
          ral.general.lim = 300;
        }

        ral.general.multiplier = ral.rezeptHeader.frequenz ? ral.rezeptHeader.frequenz : 1;

        var tarifSummary = {};

        for (var i = 0; i < ral.positions.length; i++) {
          var entry = ral.positions[i];
          var num = i + 1;

          // check if heilmittel was selected
          if (!entry.heilmittel) {
            ral.affected_lines.heilmittel.push(num);
          } else {

            if (tarifSummary[entry.heilmittel.tarif]) {
              tarifSummary[entry.heilmittel.tarif].cnt += 1;
              tarifSummary[entry.heilmittel.tarif].pos.push(num);
            }
            else {
              tarifSummary[entry.heilmittel.tarif] = {cnt: 1, pos: [num]};
            }
          }

          // check if menge was provided
          if (!entry.menge) {
            ral.affected_lines.mengenotprovided.push(num);
          } else {
            if (!ral.isNumber(entry.menge) || entry.menge <= 0) {
              ral.affected_lines.mengenotnumber.push(num);
            } else if (
              ral.general.ids_key != "BGBG" &&
              ral.general.ids_key != "PPPP" &&
              ral.general.diagnose_key != "PPPP" &&
              ral.general.diagnose_key != "BGBG"
            ) {
              if (ral.rezeptHeader.rezeptversion == "Alt") {
                if (
                  ral.general.verordnungsart_selected.name == "Erstverordnung" &&
                  entry.menge > ral.general.indikationsschluessel.max_erstv
                ) {
                  ral.affected_lines.usedcount.push(num);
                }

                if (
                  ral.general.verordnungsart_selected.name == "Folgeverordnung" &&
                  entry.menge > ral.general.indikationsschluessel.max_folgev
                ) {
                  ral.affected_lines.usedcount.push(num);
                }
              } else {
                // BWBW, KURE are set to max_vo 999
                // default limit

                if (
                  (ral.rezeptHeader.langf || 
                  ral.rezeptHeader.besond || 
                  ral.rezeptHeader.genehmigung) && 
                  !(ral.rezeptHeader.blanko && ral.general.le_typ == "26")
                ) {
                  ral.general.lim = 12 * ral.general.multiplier;
                }

                if (entry.menge > ral.general.lim) {
                  ral.affected_lines.usedcount.push(num);
                }
              }
            }
          }

          // check if provided km-distance is a number (and) greater 0
          if (entry.km) {
            if (!ral.isNumber(entry.km) || entry.km < 0) {
              ral.affected_lines.kmnotnumber.push(num);
            }
          }
        }

        // Check if the same tarif was selected multiple times (compare with tarifWithLockedMenge)
        for (var tarif in tarifSummary) {
          if (tarifSummary[tarif].cnt > 1 && ral.tarifWithLockedMenge.find(function(entry) { return entry.tarif === tarif; })) {
            ral.affected_lines.max_1_entry = ral.affected_lines.max_1_entry.concat(tarifSummary[tarif].pos);
          }
        }

        // Kompressionsbandage - MLD check
        // check is used for Krankenkassen Diagnose + KURE,BGBG,BWBW,PPPP
        if (["PHYS", "KURE"].indexOf(ral.general.heilmittelbereich) != -1) {
          var kb_pos = [];
          var mld_found = false;

          for (i = 0; i < ral.positions.length; i++) {
            var entry = ral.positions[i];
            if (entry.heilmittel) {
              var kb_lkp = ["10204", "20204", "80204", "8403a", "19d"];
              var mld_lkp = [
                "10201",
                "20201",
                "10202",
                "20202",
                "10205",
                "20205",
                "80201",
                "80202",
                "80205",
                "8402",
                "8403",
                "19a",
                "19b",
                "19c",
              ];
              var tarif = entry.heilmittel.tarif;

              if (kb_lkp.includes(tarif)) {
                var idx = ral.positions
                  .map(function (x) {
                    return x.id;
                  })
                  .indexOf(entry.id);

                kb_pos.push(idx + 1);
              }

              if (mld_lkp.includes(tarif)) {
                mld_found = true;
              }
            }
          }

          if (kb_pos.length != 0 && !mld_found) {
            ral.affected_lines.bandage_mld = kb_pos;
          }
        }

        if (["LOGO"].indexOf(ral.general.heilmittelbereich) != -1) {
          var menge = 0;
          var leb_pos = [];

          for (i = 0; i < ral.positions.length; i++) {
            var entry = ral.positions[i];
            if (entry.heilmittel) {
              var tarif = entry.heilmittel.tarif;
              if ((tarif == "33010" || tarif == "47") && entry.menge) {
                menge += parseInt(entry.menge);
                leb_pos.push(i + 1);
              }
            }
          }

          if (menge > 1) {
            ral.affected_lines.logo_erstbefund = leb_pos;
          }
        }

        // check whether selected heilmittel combination is valid
        // e.g. how many unique/different heilmittel are selected
        // check is not used for Heilmittelbereich LOGO - vorrangig, ergaenzend and alleinstehend is not used in LOGO
        if (
          ["PPPP", "BWBW", "KURE", "BGBG", "9999"].indexOf(ral.general.diagnose_key) == -1 &&
          ral.affected_lines.heilmittel.length == 0 &&
          ["PHYS", "ERGO"].indexOf(ral.general.heilmittelbereich) != -1
        ) {
          var type_collection = {
            A: [],
            C: [],
            lone: [],
            none: [],
          };

          // NEW
          var tarife_seen = [];

          var cnt_funktionsanalyse = 0;
          var ergo_funktionsanalyse = [];

          // Lone belongs to C ONLY if C is also set but is considered a special case
          for (var i = 0; i < ral.positions.length; i++) {
            var num = i + 1;
            var entry = ral.positions[i];
            var found = ral.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });
            var tarif = entry.heilmittel.tarif;
            var menge = parseInt(entry.menge);

            // ERGO - 54002 (Funktionsanalyse can only be selected once!)
            if (tarif == "54002" && menge >= 1) {
              cnt_funktionsanalyse += menge;
              ergo_funktionsanalyse.push(num);
            }

            if (!tarife_seen.includes(tarif)) {
              tarife_seen.push(tarif);
              if (found.ergaenzend && found.alleinstehend || found.ergaenzend) {
                type_collection.C.push(num);
              } else if (found.alleinstehend) {
                type_collection.lone.push(num);
              } else if (found.vorrangig && !entry.heilmittel.mehrfach_kz) {
                type_collection.A.push(num);
              } else {
                // should not be used
                type_collection.none.push(num);
              }
            }
          }

          // ERGO - 540002 (Funktionsanalyse can only be selected once!)
          if (
            ral.general.heilmittelbereich == "ERGO" &&
            (ergo_funktionsanalyse.length > 1 || cnt_funktionsanalyse > 1)
          ) {
            ral.affected_lines.ergo_funktionsanalyse = ergo_funktionsanalyse;
          }

          var a_len = type_collection.A.length;
          var c_len = type_collection.C.length;
          var lone_len = type_collection.lone.length;
          var none_len = type_collection.none.length;

          if (a_len > 3) {
            ral.affected_lines.vorrangig_toohigh_3 = type_collection.A;
          } else if (ral.rezeptHeader.rezeptart == "ZA" && a_len > 1) {
            ral.affected_lines.vorrangig_toohigh_za = type_collection.A;
          } else if (c_len > 1) {
            ral.affected_lines.ergaenzend_toohigh = type_collection.C;
          } else if (c_len == 1 && a_len == 0) {
            ral.affected_lines.ergaenzend_alone = type_collection.C;
          } else if (none_len > 0 && a_len == 0 && lone_len == 0) {
            ral.affected_lines.none_alone = type_collection.none;
          }
        }

        // only check if no previous errors were found
        if (
          ["PPPP", "BWBW", "KURE", "BGBG", "9999"].indexOf(ral.general.diagnose_key) == -1 &&
          !ral.has_affected_lines() &&
          ["PHYS", "ERGO"].indexOf(ral.general.heilmittelbereich) != -1
        ) {
          var seen_heilmittel = [];
          var sums = {
            vorrangig: 0,
            unique_vorrangig: 0,
            ergaenzend: 0,
            alleinstehend: 0,
          };

          // check if vorrangig in positionen
          var vorr_available = false;
          for (var i = 0; i < ral.positions.length; i++) {
            var entry = ral.positions[i];
            var found = ral.general.diagnose_tarif_lkp.find(function (element) {
              return element.tarif == entry.heilmittel.tarif;
            });

            if (found.vorrangig) {
              vorr_available = true;
              break;
            }
          }

          // ids for v_mengetoohigh
          var vorrangig_ids = [];

          // ids for e_mengetoohigh
          var ergaenzend_ids = [];

          // sum and ids for none_mengetoohigh
          var none_ids = [];

          // for mehrfach_mengetoohigh
          var mehrfach_ids = [];

          // reset doubletr
          for (var i = 0; i < ral.positions.length; i++) {
            ral.positions[i].doubletr = false;
          }

          for (var i = 0; i < ral.positions.length; i++) {
            // line object
            var entry = ral.positions[i];

            // current sum
            var sum = 0;

            var inconsistent = false;

            // line position of related heilmittel
            var hm_pos = [];

            // check only if menge and heilmittel are provided
            if (entry.menge && entry.heilmittel) {
              var mehrfkz = entry["heilmittel"]["mehrfach_kz"];
              var hm = entry.heilmittel.bezeichnung;
              hm_pos.push(i + 1);

              if (mehrfkz) {
                mehrfach_ids.push([i + 1, parseInt(entry.menge)]);
              }

              // ignore if heilmittel was already seen or mehrfach_kz is true
              else if (!seen_heilmittel.includes(hm)) {
                seen_heilmittel.push(hm);

                sum += parseInt(entry.menge);

                for (var j = 0; j < ral.positions.length; j++) {
                  var next = ral.positions[j];
                  // check only new entries
                  // checks double treatments and inconsistent counts (menge) e.g. if you are using double treatments
                  // the amount of treatments must be the same
                  // double treatments also mean that only two entries are allowed e.g. 2x KG + 2x KG
                  if (j > i && next.menge && next.heilmittel) {
                    if (next.heilmittel.bezeichnung == hm) {

                      // double treatment checks are irrelevant for ergo blanko
                      if (!ral.rezeptHeader.blanko || ral.general.le_typ !== "26"){
                        // set double treatment flag
                        ral.positions[j].doubletr = true;
                        ral.positions[i].doubletr = true;

                        if (next.menge != entry.menge) {
                          inconsistent = true;
                        }
                      }

                      sum += parseInt(next.menge);
                      hm_pos.push(j + 1);
                    }
                  }
                }

                var found = ral.general.diagnose_tarif_lkp.find(function (element) {
                  return element.tarif == entry.heilmittel.tarif;
                });

                if (found.vorrangig) {
                  if (inconsistent) {
                    ral.affected_lines.vorrangig_inconsistent.push(hm_pos);
                  }

                  if (hm_pos.length > 2) {
                    if (!ral.rezeptHeader.blanko || ral.general.le_typ !== "26"){
                      ral.affected_lines.vorrangig_mehrfach.push(hm_pos);
                    }
                  }

                  sums.vorrangig += sum;

                  if (ral.rezeptHeader.blanko && ral.general.le_typ == "26") {
                    sums.unique_vorrangig += sum;
                  }
                  else {
                    sums.unique_vorrangig += parseInt(entry.menge);
                  }

                  vorrangig_ids.push.apply(vorrangig_ids, hm_pos);
                } else if (found.ergaenzend || found.alleinstehend) {
                  if (!vorr_available && found.alleinstehend) {
                    sums.alleinstehend += sum;
                  } else if (found.ergaenzend) {
                    sums.ergaenzend += sum;
                    ergaenzend_ids.push.apply(ergaenzend_ids, hm_pos);
                  }

                  if (hm_pos.length > 1) {
                    // duplicated ergaenzend entries found
                    ral.affected_lines.ergaenzend_duplicated.push.apply(
                      ral.affected_lines.ergaenzend_duplicated,
                      hm_pos
                    );
                  }
                } else {
                  // none case (neither vorranging, ergaenzend nor alleinstehend)
                  none_ids.push([hm_pos, sum]);
                }
              }
            }
          }

          if (sums.vorrangig > ral.general.lim) {
            ral.affected_lines.v_mengetoohigh.push.apply(ral.affected_lines.v_mengetoohigh, vorrangig_ids);
          }

          if (sums.unique_vorrangig > 0 && sums.ergaenzend > sums.unique_vorrangig && ergaenzend_ids.length == 1) {
            ral.affected_lines.e_mengetoohigh = [sums.unique_vorrangig, ergaenzend_ids];
          }

          // check none_ids
          for (var i = 0; i < none_ids.length; i++) {
            if (sums.unique_vorrangig > 0) {
              if (none_ids[i][1] > sums.unique_vorrangig) {
                ral.affected_lines.none_mengetoohigh.push([none_ids[i][0], sums.unique_vorrangig]);
              }
            } else if (sums.alleinstehend > 0) {
              if (none_ids[i][1] > sums.alleinstehend) {
                ral.affected_lines.none_mengetoohigh.push([none_ids[i][0], sums.alleinstehend]);
              }
            }
          }
          // check mehrfach_ids agains sums.unique_vorrangig
          for (var i = 0; i < mehrfach_ids.length; i++) {
            if (mehrfach_ids[i][1] > sums.unique_vorrangig) {
              ral.affected_lines.mehrfach_mengetoohigh.push([mehrfach_ids[i][0], sums.unique_vorrangig]);
            }
          }
        }
        

        if (ral.errors.positions.length == 0) {
          ral.errors.positions = {
            menge: [],
            heilmittel: [],
            km: [],
            usedcount: [],
          };
        }

        // iterate over affected_lines to check for errors / to create error messages
        for (var type in ral.affected_lines) {
          if (!ral.affected_lines.hasOwnProperty(type)) {
            //The current error is not a direct property of p
            continue;
          }
          if (ral.affected_lines[type].length != 0) {
            if (type == "mengenotnumber") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].join(", ") +
                  ": Menge ist keine gültige Zahl oder ist kleiner/gleich 0!"
              );
              ral.errors.positions.menge = ral.errors.positions.menge.concat(ral.affected_lines[type]);
            } else if (type == "mengenotprovided") {
              ral.errors.messages.push(
                "Eintrag " + ral.affected_lines[type].join(", ") + ": Es wurde keine Menge angegeben!"
              );
              ral.errors.positions.menge = ral.errors.positions.menge.concat(ral.affected_lines[type]);
            } else if (type == "heilmittel") {
              ral.errors.messages.push(
                "Eintrag " + ral.affected_lines[type].join(", ") + ": Es wurde kein Heilmittel ausgewählt!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "kmnotnumber") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].join(", ") +
                  ": Kilomenter-Angabe ist keine gültige Zahl oder ist kleiner/gleich 0!"
              );
              ral.errors.positions.km = ral.errors.positions.km.concat(ral.affected_lines[type]);
            } else if (type == "usedcount") {
              if (ral.rezeptHeader.rezeptversion == "Alt") {
                if (ral.general.verordnungsart_selected.name == "Erstverordnung") {
                  ral.errors.messages.push(
                    "Eintrag " +
                      ral.affected_lines[type].join(", ") +
                      ": Maximale Menge für Erstverordnungen von " +
                      ral.general.indikationsschluessel.max_erstv +
                      " überschritten!"
                  );
                }

                if (ral.general.verordnungsart_selected.name == "Folgeverordnung") {
                  ral.errors.messages.push(
                    "Eintrag " +
                      ral.affected_lines[type].join(", ") +
                      ": Maximale Menge für Folgeverordnungen von " +
                      ral.general.indikationsschluessel.max_folgev +
                      " überschritten!"
                  );
                }
              } else {
                ral.errors.messages.push(
                  "Eintrag " +
                    ral.affected_lines[type].join(", ") +
                    ": Maximale Verordnungsmenge von " +
                    ral.general.lim +
                    " überschritten!"
                );
              }

              ral.errors.positions.usedcount = ral.errors.positions.usedcount.concat(ral.affected_lines[type]);
            } else if (type == "mengetoohigh") {
              if (ral.rezeptHeader.rezeptversion == "Alt") {
                if (ral.general.verordnungsart_selected.name == "Erstverordnung") {
                  for (i = 0; i < ral.affected_lines[type].length; i++) {
                    ral.errors.messages.push(
                      "Eintrag " +
                        ral.affected_lines[type][i].join(", ") +
                        ": Maximale Menge (in Summe) für Erstverordnungen von " +
                        ral.general.indikationsschluessel.max_erstv +
                        " überschritten!"
                    );
                    ral.errors.positions.usedcount = ral.errors.positions.usedcount.concat(ral.affected_lines[type][i]);
                  }
                }

                if (ral.general.verordnungsart_selected.name == "Folgeverordnung") {
                  for (i = 0; i < ral.affected_lines[type].length; i++) {
                    ral.errors.messages.push(
                      "Eintrag " +
                        ral.affected_lines[type][i].join(", ") +
                        ": Maximale Menge (in Summe) für Folgeverordnunge von " +
                        ral.general.indikationsschluessel.max_erstv +
                        " überschritten!"
                    );
                    ral.errors.positions.usedcount = ral.errors.positions.usedcount.concat(ral.affected_lines[type][i]);
                  }
                }
              }
            } else if (type == "v_mengetoohigh") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].join(", ") +
                  ": Maximale Verodnungsmenge (in Summe) von " +
                  ral.general.lim +
                  " überschritten!"
              );
              ral.errors.positions.usedcount = ral.errors.positions.usedcount.concat(ral.affected_lines[type]);
            } else if (type == "e_mengetoohigh") {
              var ids = ral.affected_lines[type][1];

              ral.errors.messages.push(
                "Eintrag " +
                  ids.sort().join(", ") +
                  ": Maximale Verodnungsmenge für ergänzende Heilmittel von " +
                  ral.affected_lines[type][0] +
                  " überschritten!"
              );
              ral.errors.positions.usedcount = ral.errors.positions.usedcount.concat(ids);
            } else if (type == "ergaenzend_duplicated") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Mehrfachbehandlung für ergänzende Heilmittel ist nicht erlaubt!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "vorrangig_inconsistent") {
              for (i = 0; i < ral.affected_lines[type].length; i++) {
                ral.errors.messages.push(
                  "Eintrag " +
                    ral.affected_lines[type][i].join(", ") +
                    ": Menge bei Mehrfachbehandlungen darf nicht unterschiedlich sein!"
                );
                ral.errors.positions.usedcount = ral.errors.positions.usedcount.concat(ral.affected_lines[type][i]);
              }
            } else if (type == "vorrangig_mehrfach") {
              for (i = 0; i < ral.affected_lines[type].length; i++) {
                ral.errors.messages.push(
                  "Eintrag " +
                    ral.affected_lines[type][i].join(", ") +
                    ": Für vorranginge Heilmittel sind nur Einzel- oder Doppelbehandlungen erlaubt!"
                );
                ral.errors.positions.usedcount = ral.errors.positions.usedcount.concat(ral.affected_lines[type][i]);
              }
            } else if (type == "mehrfach_mengetoohigh") {
              for (i = 0; i < ral.affected_lines[type].length; i++) {
                ral.errors.messages.push(
                  "Eintrag " +
                    ral.affected_lines[type][i][0] +
                    ": Maximale Verodnungsmenge von " +
                    ral.affected_lines[type][i][1] +
                    " überschritten!"
                );
                ral.errors.positions.menge = ral.errors.positions.menge.concat([ral.affected_lines[type][i][0]]);
              }
            } else if (type == "none_mengetoohigh") {
              for (i = 0; i < ral.affected_lines[type].length; i++) {
                ral.errors.messages.push(
                  "Eintrag " +
                    ral.affected_lines[type][i][0] +
                    ": Maximale Verodnungsmenge von " +
                    ral.affected_lines[type][i][1] +
                    " überschritten!"
                );
                ral.errors.positions.menge = ral.errors.positions.menge.concat(ral.affected_lines[type][i][0]);
              }
            } else if (type == "vorrangig_toohigh") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Es darf nur ein vorrangiges Heilmittel ausgewählt werden!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "optional_toohigh") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Es darf nur ein optionales Heilmittel ausgewählt werden!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "ergaenzend_toohigh") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Es darf nur ein ergänzendes Heilmittel ausgewählt werden!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "invalidcombination") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Eine Kombination aus vorrangigen, optionalen und ergänzenden Heilmitteln ist nicht erlaubt!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "ergaenzend_alone") {
              var msg = ": Ergänzende Heilmittel nur in Kombination mit einem vorrangigen Heilmittel möglich!";
              ral.errors.messages.push("Eintrag " + ral.affected_lines[type].sort().join(", ") + msg);
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "none_alone") {
              ral.errors.messages.push(
                "Es muss mind. ein vorrangiges oder alleinstehendes Heilmittel ausgewählt werden!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat([1]);
            } else if (type == "invalidcombination_a_b") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Eine Kombination aus vorrangigen und optionalen Heilmitteln ist nicht erlaubt!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "vorrangig_toohigh_3") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Es dürfen maximal 3 vorrangige Heilmittel ausgewählt werden!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "vorrangig_toohigh_za") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Es darf nur ein vorrangiges Heilmittel ausgewählt werden!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "max_1_entry") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Heilmittel kann nur 1x pro Rezept ausgewählt werden!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "ergo_funktionsanalyse") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Funktionsanalyse ist nur 1x pro Rezept möglich!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "bandage_mld") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Kompressionsbandage nur in Kombination mit MLD 30, 45 oder 60 möglich!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } else if (type == "logo_erstbefund") {
              ral.errors.messages.push(
                "Eintrag " +
                  ral.affected_lines[type].sort().join(", ") +
                  ": Sprachtherap. Erstbefundung ist nur 1x pro Rezept möglich!"
              );
              ral.errors.positions.heilmittel = ral.errors.positions.heilmittel.concat(ral.affected_lines[type]);
            } 
          }
        }

        if (ral.checkErrors()) {
          // no errors were found
          // create proper Rezept-Positionen object
          var de = ral.positions;
          var daten = [];
          for (var i = 0; i < de.length; i++) {
            var entry = {
              line_nr: de[i].id,
              tarif_nr: de[i].heilmittel.tarif,
              tarif_bezeichnung: de[i].heilmittel.bezeichnung,
              verordnungsmenge: de[i].menge,
              entfernung: de[i].km,
            };
            daten.push(entry);
          }
          ral.rezeptHeader.positionen = daten;

          return true;
        } else {
          return false;
        }
      };

      ral.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in ral.errors) {
          if (!ral.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }

          if (typeof ral.errors[error] == "object") {
            for (var e in ral.errors[error]) {
              if (!ral.errors[error].hasOwnProperty(e)) {
                //The current error is not a direct property of p
                continue;
              }

              if (ral.errors[error][e].length != 0) {
                return false;
              }
            }
          } else {
            if (ral.errors[error].length != 0) {
              return false;
            }
          }
        }

        // no errors were found
        return true;
      };

      ral.gotoDaten = function () {
        if (ral.checkPositionenInput()) {
          RememberService.rezeptAddLinesData = {
            rezeptHeader: ral.rezeptHeader,
            patient: ral.patient,
            general: ral.general,
            positions: ral.positions,
          };

          var params = {
            rezeptHeader: ral.rezeptHeader,
            patient: ral.patient,
            general: ral.general,
            positions: ral.positions,
          };

          var stateChainEntry = {
            state: $state.current.name,
            params: {},
          };

          var newParams = Object.assign({}, $stateParams, { params: params });
          if (!newParams.stateChain) {
            newParams.stateChain = [];
          }
          newParams.stateChain.push(stateChainEntry);

          $state.go("menu.rezepte-add-dates", newParams);
        }
      };

      ral.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      // #######################################
      // COLLAPSIBLES
      ral.toggle_collapsible = function (c) {
        var collapse = angular.element(document.querySelector(c));
        collapse[0].style.height = collapse[0].clientHeight ? 0 : collapse[0].scrollHeight + "px";
        if (collapse.hasClass("is-expanded")) {
          collapse.removeClass("is-expanded");
        } else {
          collapse.addClass("is-expanded");
        }
      };

      ral.init();
    },
  ],
});
