angular.module("patientenEdit").component("patientenEdit", {
  templateUrl:
    "static/angular_assets/patienten/patienten_edit/patienten_edit.template.html",
  controller: [
    "$scope",
    "$state",
    "store",
    "$anchorScroll",
    "$timeout",
    "PatientenService",
    "KostentraegertypService",
    "VersicherungsStatusService",
    "jwtHelper",
    "KundenService",
    "KostentraegerService",
    "MitarbeiterService",
    "BefreiungenService",
    "RezepteHeaderService",
    "Popeye",
    "StoreService",
    "$stateParams",

    function PatientenEditController(
      $scope,
      $state,
      store,
      $anchorScroll,
      $timeout,
      PatientenService,
      KostentraegertypService,
      VersicherungsStatusService,
      jwtHelper,
      KundenService,
      KostentraegerService,
      MitarbeiterService,
      BefreiungenService,
      RezepteHeaderService,
      Popeye,
      StoreService,
      $stateParams
    ) {
      var pe = this;

      pe.regexp_text = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+']/g;
      pe.regexp_text_num = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+'0-9]/g;
      pe.regexp_text_all =
        /[^A-Za-zßöÖäÄüÜéÉèÈ.,:\\\*\-_#@+§&%$€~=| <>°'!?\/()\[\]\{\}\"0-9]/g;

      get_unique = function (arr) {
        return arr
          ? arr.reduce(function (a, b) {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, [])
          : null;
      };

      pe.init = function () {
        pe.isready = false;

        // holds errors
        pe.errors = {
          anrede: [],
          name: [],
          vorname: [],
          geburtsdatum: [],
          strasse: [],
          plz: [],
          ort: [],
          kt_typ: [],
          unfalltag: [],
          bg_kt: [],
          kk_kt: [],
          pbs_kt: [],
          vers_status: [],
          vers_nummer: [],
          patient: [],
          alt_anrede: [],
          alt_name: [],
          alt_vorname: [],
          alt_strasse: [],
          alt_plz: [],
          alt_ort: [],
          zuzahlung_von: [],
          zuzahlung_bis: [],
          titel: [],
          alt_titel: [],
          info: [],
        };

        // read params
        pe.patienten_id = $stateParams.patientenId;
        pe.returnState = $stateParams.returnState;

        // init forms
        $scope.forms = {};

        // focus first input element
        //$window.document.getElementById('titel_input').focus()

        // information modal
        pe.modal = null;

        // used for anchor scroll to first error
        pe.error_scroll = null;
        $anchorScroll.yOffset = 200;

        pe.awaiting_response = false;

        pe.importantDataChanged = false;

        // PATIENT
        pe.patient = null;

        // DATA lists
        pe.kostentraeger = null;
        pe.versicherungsstatus = null;
        pe.kostentraegertyp = null;
        pe.befreiungen = null;

        // KUNDEN DATA
        pe.kunden_id = jwtHelper.decodeToken(store.get("access_token")).user_id;
        pe.kunde = null;

        // gets Kunden related data + Versicherungsstatus list data
        pe.getKundenData();
        // gets Kostentraeger_Typ list data
        pe.getKostentraegerTypData();

        // objects
        pe.geburtsdatum = null;
        pe.zuzahlung_bis = null;
        pe.zuzahlung_von = null;
        pe.unfalltag = null;
        pe.anrede = ["Herr", "Frau", "-----"];

        // selected versicherungs_status
        pe.vers_status = null;
        // selected kostentraeger_typ
        pe.init_kt_typ = null;
        pe.kt_typ = null;
        // selected kostentraeger
        pe.kt = null;

        pe.kk_ik_num = null;
        pe.kk_ik_exists = null;

        pe.getPatientenDetails();

        pe.checkifready();
      };

      pe.openUpdatePrescriptionModal = function () {
        pe.modal = Popeye.openModal({
          templateUrl:
            "static/angular_assets/patienten/patienten_edit/modals/update_prescription.template.html",
          scope: $scope,
          modalClass: "no_close extended-modal-container",
          click: false,
          keyboard: false,
        });
      };

      pe.openInformationModal = function (
        information,
        patientHeaderChangesFound,
        patientDatesChangesFound,
        excemptionChangesFound
      ) {
        // reset
        pe.information = {
          data: information,
          patientHeaderChangesFound: patientHeaderChangesFound,
          patientDatesChangesFound: patientDatesChangesFound,
          excemptionChangesFound: excemptionChangesFound,
        };

        pe.modal = Popeye.openModal({
          templateUrl:
            "static/angular_assets/patienten/patienten_edit/modals/information_modal.template.html",
          scope: $scope,
          modalClass: "no_close extended-modal-container",
          click: false,
          keyboard: false,
        });
      };

      pe.closeModal = function () {
        if (pe.modal) {
          pe.modal.close();
          pe.modal = null;
        }
        pe.awaiting_response = false;
      };

      pe.closeModalAndReturn = function () {
        pe.closeModal();
        pe.returnToPreviousState();
      };

      pe.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      pe.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      pe.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return (
          yyyy +
          "-" +
          (mmChars[1] ? mm : "0" + mmChars[0]) +
          "-" +
          (ddChars[1] ? dd : "0" + ddChars[0])
        );
      };

      pe.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      // functions

      pe.getPatientenDetails = function () {
        if (!pe.kostentraegertyp || !pe.versicherungsstatus) {
          setTimeout(pe.getPatientenDetails, 50);
        } else {
          PatientenService.get({
            id: pe.patienten_id,
          })
            .$promise.then(function (data) {
              pe.patient = data;

              // get related mitarbeiter
              pe.getMitarbeiterData();

              // get related befreiungen
              pe.getBefreiungenData();

              // transform geburtsdatum/zuzahlung bis-von/unfalltag string into date object
              pe.geburtsdatum = pe.str_to_date(pe.patient.geburtsdatum);

              if (pe.patient.unfalltag) {
                pe.unfalltag = pe.str_to_date(pe.patient.unfalltag);
              }

              // set proper kt_typ
              for (var i = 0; i < pe.kostentraegertyp.length; i++) {
                if (pe.kostentraegertyp[i].id === pe.patient.kt_typ) {
                  pe.init_kt_typ = pe.kostentraegertyp[i];
                  pe.kt_typ = pe.kostentraegertyp[i];
                  break;
                }
              }

              // get and set proper kostentraeger based on previous kt_typ

              if (["PBeaKK", "S", "BG"].indexOf(pe.patient.kt_typ) != -1) {
                pe.getKtRelatedData_nonreset();
              }

              // set proper versicherungs_status
              if (pe.patient.vers_status) {
                for (var i = 0; i < pe.versicherungsstatus.length; i++) {
                  if (pe.versicherungsstatus[i].id === pe.patient.vers_status) {
                    pe.vers_status = pe.versicherungsstatus[i];
                    break;
                  }
                }
              }

              // set ik_num for KK
              if (pe.patient.kt_typ == "KK") {
                KostentraegerService.get({
                  id: pe.patient.kostentraeger,
                })
                  .$promise.then(function (data) {
                    pe.kt = data;
                    pe.kk_ik_num = data.ik_num;
                    pe.kk_ik_exists = true;
                  })
                  .catch(function (error) {
                    // fix for "Possibly unhandled rejection"
                  });
              }
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      pe.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in pe.errors) {
          if (!pe.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (pe.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      pe.set_error_scroll = function (loc) {
        if (!pe.error_scroll) {
          pe.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      pe.checkInput = function () {
        // reset current errors
        for (var error in pe.errors) {
          pe.errors[error] = [];
        }

        pe.error_scroll = null;

        // GENERIC data
        if (pe.patient.anrede != "Herr" && pe.patient.anrede != "Frau") {
          pe.errors.anrede.push("Es wurde keine Auswahl getroffen!");
          pe.set_error_scroll("anrede_loc");
        }

        //titel
        if (pe.patient.titel) {
          var matches = get_unique(pe.patient.titel.match(pe.regexp_text));
          if (matches) {
            var err_msg =
              "Folgende Zeichen dürfen nicht verwendet werden: " +
              matches.join(" ");
            pe.errors.titel.push(err_msg);
            pe.set_error_scroll("titel_loc");
          }
        }

        //name
        if (!pe.patient.name) {
          pe.errors.name.push("Es wurde kein Name angegeben!");
          pe.set_error_scroll("name_loc");
        } else {
          var matches = get_unique(pe.patient.name.match(pe.regexp_text));
          if (matches) {
            var err_msg =
              "Folgende Zeichen dürfen nicht verwendet werden: " +
              matches.join(" ");
            pe.errors.name.push(err_msg);
            pe.set_error_scroll("name_loc");
          }
        }

        //vorname
        if (!pe.patient.vorname) {
          pe.errors.vorname.push("Es wurde kein Vorname angegeben!");
          pe.set_error_scroll("vorname_loc");
        } else {
          var matches = get_unique(pe.patient.vorname.match(pe.regexp_text));
          if (matches) {
            var err_msg =
              "Folgende Zeichen dürfen nicht verwendet werden: " +
              matches.join(" ");
            pe.errors.vorname.push(err_msg);
            pe.set_error_scroll("vorname_loc");
          }
        }

        //geburtsdatum
        if (!pe.geburtsdatum) {
          pe.errors.geburtsdatum.push("Es wurde kein Geburtsdatum angegeben!");
          pe.set_error_scroll("geburtsdatum_loc");
        } else {
          lim_past = 1900;
          lim_future = parseInt(new Date().getFullYear());
          lim_month = parseInt(new Date().getMonth()) + 1;
          lim_day = parseInt(new Date().getDate());

          given_date =
            $scope.forms.geburtsdatum_form.geburtsdatum_input.$viewValue;
          given_year = parseInt(given_date.slice(0, 4));
          given_month = parseInt(given_date.slice(5, 7));
          given_day = parseInt(given_date.slice(8, 10));

          if (lim_past <= given_year && lim_future >= given_year) {
            if (
              given_year != lim_future ||
              (given_year == lim_future && given_month < lim_month) ||
              (given_year == lim_future &&
                given_month == lim_month &&
                given_day < lim_day)
            ) {
              pe.patient.geburtsdatum = pe.date_to_str(pe.geburtsdatum);
            } else {
              pe.errors.geburtsdatum.push("Geburtsdatum liegt in der Zukunft!");
              pe.set_error_scroll("geburtsdatum_loc");
            }
          } else {
            pe.errors.geburtsdatum.push("Ungültiges Datum angegeben!");
            pe.set_error_scroll("geburtsdatum_loc");
          }
        }

        //strasse
        if (!pe.patient.strasse) {
          pe.errors.strasse.push("Es wurde keine Strasse angegeben!");
          pe.set_error_scroll("strasse_loc");
        } else {
          var matches = get_unique(
            pe.patient.strasse.match(pe.regexp_text_num)
          );
          if (matches) {
            var err_msg =
              "Folgende Zeichen dürfen nicht verwendet werden: " +
              matches.join(" ");
            pe.errors.strasse.push(err_msg);
            pe.set_error_scroll("strasse_loc");
          }
        }

        //plz
        if (!pe.patient.plz) {
          pe.errors.plz.push("Es wurde keine PLZ angegeben!");
          pe.set_error_scroll("plz_loc");
        } else if (!pe.isNumber(pe.patient.plz)) {
          pe.errors.plz.push("Ungültige PLZ! (nur Zahlen sind erlaubt)");
          pe.set_error_scroll("plz_loc");
        } else if (pe.patient.plz.length != 5) {
          pe.errors.plz.push("PLZ ist nicht 5 Stellen lang!");
          pe.set_error_scroll("plz_loc");
        }

        //ort
        if (!pe.patient.ort) {
          pe.errors.ort.push("Es wurde kein Ort angegeben!");
          pe.set_error_scroll("ort_loc");
        } else {
          var matches = get_unique(pe.patient.ort.match(pe.regexp_text_num));
          if (matches) {
            var err_msg =
              "Folgende Zeichen dürfen nicht verwendet werden: " +
              matches.join(" ");
            pe.errors.ort.push(err_msg);
            pe.set_error_scroll("ort_loc");
          }
        }

        //info field
        if (pe.patient.info) {
          var matches = get_unique(pe.patient.info.match(pe.regexp_text_all));
          if (matches) {
            var err_msg =
              "Folgende Zeichen dürfen nicht verwendet werden: " +
              matches.join(" ");
            pe.errors.info.push(err_msg);
            pe.set_error_scroll("info_loc");
          }
        }

        //kt_typ
        if (!pe.kt_typ) {
          pe.errors.kt_typ.push("Es wurde kein Kostenträger-Typ ausgewählt!");
          pe.set_error_scroll("kt_typ_loc");
        } else {
          if (pe.kt_typ.id == "BG") {
            if (!pe.unfalltag) {
              pe.errors.unfalltag.push("Es wurde kein Unfalltag ausgewählt!");
              pe.set_error_scroll("unfalltag_loc");
            } else {
              lim_past = 1900;
              lim_future = parseInt(new Date().getFullYear());
              given_year = parseInt(
                $scope.forms.unfalltag_form.unfalltag_input.$viewValue.slice(
                  0,
                  4
                )
              );

              if (lim_past <= given_year && lim_future >= given_year) {
                pe.patient.unfalltag = pe.date_to_str(pe.unfalltag);
              } else {
                pe.errors.unfalltag.push("Ungültiges Datum angegeben!");
                pe.set_error_scroll("unfalltag_loc");
              }
            }

            if (!pe.kt) {
              pe.errors.bg_kt.push("Es wurde kein Kostenträger ausgewählt!");
              pe.set_error_scroll("bg_kt_loc");
            }
          }

          if (
            pe.kt_typ.id == "KK" ||
            pe.kt_typ.id == "PBeaKK" ||
            pe.kt_typ.id == "S"
          ) {
            if (pe.kt_typ.id == "KK") {
              if (!pe.kk_ik_num) {
                pe.errors.kk_kt.push("Es wurde kein Kostenträger angegeben!");
                pe.set_error_scroll("kk_kt_loc");
              } else {
                if (pe.kk_ik_exists == null) {
                  pe.errors.kk_kt.push("Kostenträger wurde nicht überprüft!");
                  pe.set_error_scroll("kk_kt_loc");
                } else if (!pe.kk_ik_exists) {
                  pe.errors.kk_kt.push("Kostenträger existiert nicht!");
                  pe.set_error_scroll("kk_kt_loc");
                }
              }

              if (pe.zuzahlung_von && !pe.zuzahlung_bis) {
                pe.errors.zuzahlung_bis.push(
                  "Beide Zuzahlungsbefreiungs-Daten müssen angegeben werden!"
                );
                pe.set_error_scroll("kk_zzb_loc");
              }

              if (!pe.zuzahlung_von && pe.zuzahlung_bis) {
                pe.errors.zuzahlung_bis.push(
                  "Beide Zuzahlungsbefreiungs-Daten müssen angegeben werden!"
                );
                pe.set_error_scroll("kk_zzv_loc");
              }

              if (pe.zuzahlung_von && pe.zuzahlung_bis) {
                var zzv = moment(pe.zuzahlung_von);
                var zzb = moment(pe.zuzahlung_bis);

                if (zzb.isBefore(zzv)) {
                  pe.errors.zuzahlung_bis.push(
                    "Zuzahlung-Bis liegt vor Zuzahlung-Von!"
                  );
                } else if (zzv.isSame(zzb)) {
                  pe.errors.zuzahlung_bis.push(
                    "Zuzahlung-Bis und Zuzahlung-Von sind identisch!"
                  );
                } else {
                  for (var i = 0; i < pe.befreiungen.length; i++) {
                    var b = pe.befreiungen[i];
                    var b_zzv = moment(b.befreit_von);
                    var b_zzb = moment(b.befreit_bis);

                    if (b_zzv.isSameOrBefore(zzv) && b_zzb.isSameOrAfter(zzb)) {
                      pe.errors.zuzahlung_von.push(
                        "Befreiungszeitraum wird bereits durch die Befreiung " +
                          b_zzv.format("DD.MM.YYYY") +
                          " - " +
                          b_zzb.format("DD.MM.YYYY") +
                          " übernommen!"
                      );
                      break;
                    }
                  }
                }

                if (
                  pe.errors.zuzahlung_von.length == 0 &&
                  pe.errors.zuzahlung_bis.length == 0
                ) {
                  pe.patient.zuzahlung_v = pe.date_to_str(pe.zuzahlung_von);
                  pe.patient.zuzahlung_b = pe.date_to_str(pe.zuzahlung_bis);
                }
              }
            }

            if (pe.kt_typ.id == "PBeaKK" || pe.kt_typ.id == "S") {
              if (!pe.kt) {
                pe.errors.pbs_kt.push("Es wurde kein Kostenträger ausgewählt!");
                pe.set_error_scroll("pbs_kt_loc");
              }
            }

            if (!pe.vers_status) {
              pe.errors.vers_status.push(
                "Es wurde kein Versicherungsstatus ausgewählt!"
              );
              pe.set_error_scroll("vers_status_loc");
            }

            if (!pe.patient.vers_nummer) {
              pe.errors.vers_nummer.push(
                "Es wurde keine Versicherungsnummer angegeben!"
              );
              pe.set_error_scroll("vers_nummer_loc");
            }
          }

          //new additions to PP
          if (pe.kt_typ.id == "PP" && pe.patient.alt_rechnungsadresse) {
            // alt GENERIC data
            if (
              pe.patient.alt_anrede != "Herr" &&
              pe.patient.alt_anrede != "Frau"
            ) {
              pe.errors.alt_anrede.push("Es wurde keine Auswahl getroffen!");
              pe.set_error_scroll("alt_anrede_loc");
            }

            //titel
            if (pe.patient.alt_titel) {
              var matches = get_unique(
                pe.patient.alt_titel.match(pe.regexp_text)
              );

              if (matches) {
                var err_msg =
                  "Folgende Zeichen dürfen nicht verwendet werden: " +
                  matches.join(" ");
                pe.errors.alt_titel.push(err_msg);
                pe.set_error_scroll("alt_titel_loc");
              }
            }

            //name
            if (!pe.patient.alt_name) {
              pe.errors.alt_name.push("Es wurde kein Name angegeben!");
              pe.set_error_scroll("alt_name_loc");
            } else {
              var matches = get_unique(
                pe.patient.alt_name.match(pe.regexp_text)
              );

              if (matches) {
                var err_msg =
                  "Folgende Zeichen dürfen nicht verwendet werden: " +
                  matches.join(" ");
                pe.errors.alt_name.push(err_msg);
                pe.set_error_scroll("alt_name_loc");
              }
            }

            //vorname
            if (!pe.patient.alt_vorname) {
              pe.errors.alt_vorname.push("Es wurde kein Vorname angegeben!");
              pe.set_error_scroll("alt_vorname_loc");
            } else {
              var matches = get_unique(
                pe.patient.alt_vorname.match(pe.regexp_text)
              );

              if (matches) {
                var err_msg =
                  "Folgende Zeichen dürfen nicht verwendet werden: " +
                  matches.join(" ");
                pe.errors.alt_vorname.push(err_msg);
                pe.set_error_scroll("alt_vorname_loc");
              }
            }

            //strasse
            if (!pe.patient.alt_strasse) {
              pe.errors.alt_strasse.push("Es wurde keine Strasse angegeben!");
              pe.set_error_scroll("alt_strasse_loc");
            } else {
              var matches = get_unique(
                pe.patient.alt_strasse.match(pe.regexp_text_num)
              );

              if (matches) {
                var err_msg =
                  "Folgende Zeichen dürfen nicht verwendet werden: " +
                  matches.join(" ");
                pe.errors.alt_strasse.push(err_msg);
                pe.set_error_scroll("alt_strasse_loc");
              }
            }

            //plz
            if (!pe.patient.alt_plz) {
              pe.errors.alt_plz.push("Es wurde keine PLZ angegeben!");
              pe.set_error_scroll("alt_plz_loc");
            } else if (!pe.isNumber(pe.patient.alt_plz)) {
              pe.errors.alt_plz.push(
                "Ungültige PLZ! (nur Zahlen sind erlaubt)"
              );
              pe.set_error_scroll("alt_plz_loc");
            } else if (pe.patient.alt_plz.length != 5) {
              pe.errors.alt_plz.push("PLZ ist nicht 5 Stellen lang!");
              pe.set_error_scroll("alt_plz_loc");
            }

            //ort
            if (!pe.patient.alt_ort) {
              pe.errors.alt_ort.push("Es wurde kein Ort angegeben!");
              pe.set_error_scroll("alt_ort_loc");
            } else {
              var matches = get_unique(
                pe.patient.alt_ort.match(pe.regexp_text_num)
              );

              if (matches) {
                var err_msg =
                  "Folgende Zeichen dürfen nicht verwendet werden: " +
                  matches.join(" ");
                pe.errors.alt_ort.push(err_msg);
                pe.set_error_scroll("alt_ort_loc");
              }
            }
          }
        }

        return pe.checkErrors();
      };

      pe.getKostentraegerTypData = function () {
        KostentraegertypService.query()
          .$promise.then(function (data) {
            pe.kostentraegertyp = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      pe.getKundenData = function () {
        KundenService.get({
          id: pe.kunden_id,
        })
          .$promise.then(function (data) {
            pe.kunde = data;
            // forcing to run after KundenService promise is resolved
            pe.getVersicherungsStatusData();
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      pe.getVersicherungsStatusData = function () {
        if (
          ["03", "12", "13", "14", "16"].indexOf(pe.kunde.tarif_region) != -1
        ) {
          var bundesland = "alt";
        } else {
          var bundesland = "neu";
        }

        VersicherungsStatusService.query({
          bundesland: bundesland,
        })
          .$promise.then(function (data) {
            pe.versicherungsstatus = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      pe.getKtRelatedData = function () {
        // reset data
        pe.patient.kostentraeger = "";
        pe.kt = null;
        pe.patient.vers_nummer = "";
        pe.patient.vers_status = "";
        pe.vers_status = null;
        pe.patient.zuzahlung_bis = null;
        pe.zuzahlung_bis = null;
        pe.patient.zuzahlung_von = null;
        pe.zuzahlung_von = null;
        pe.patient.unfalltag = null;
        pe.kk_ik_exists = null;
        pe.kk_ik_num = null;
        pe.unfalltag = null;

        if (["PBeaKK", "S", "BG"].indexOf(pe.kt_typ.id) != -1) {
          KostentraegerService.query({
            kt_typ: pe.kt_typ.id,
          })
            .$promise.then(function (data) {
              pe.kostentraeger = data;
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      pe.getKtRelatedData_nonreset = function () {
        if (["PBeaKK", "S", "BG"].indexOf(pe.kt_typ.id) != -1) {
          KostentraegerService.query({
            kt_typ: pe.kt_typ.id,
          })
            .$promise.then(function (data) {
              pe.kostentraeger = data;

              KostentraegerService.get({
                id: pe.patient.kostentraeger,
              })
                .$promise.then(function (data) {
                  pe.kt = data;

                  // set proper selected value (workaround for select component)
                  if (pe.patient.kt_typ == "PBeaKK") {
                    for (var i = 0; i < pe.kostentraeger.length; i++) {
                      if (pe.kostentraeger[i].id === pe.patient.kostentraeger) {
                        pe.kt = pe.kostentraeger[i];
                        break;
                      }
                    }
                  }
                })
                .catch(function (error) {
                  // fix for "Possibly unhandled rejection"
                });
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      pe.check_kk_ik_num = function () {
        if (pe.kk_ik_num.length == 7) {
          pe.kk_ik_num = "10" + pe.kk_ik_num;
        }
        KostentraegerService.query({
          ik_num: pe.kk_ik_num,
          kt_typ: "KK",
        })
          .$promise.then(function (data) {
            if (data.length == 1) {
              pe.kt = data[0];
              pe.kk_ik_exists = true;
              pe.errors.kk_kt = [];
            } else {
              pe.kk_ik_exists = false;
              pe.errors.kk_kt.push("Kostenträger existiert nicht!");
            }
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      pe.editPatientenDetails = function () {
        if (pe.checkInput()) {
          // Set proper values for patient object
          pe.patient.kunde = pe.kunden_id;
          pe.patient.kt_typ = pe.kt_typ.id;
          pe.patient.mitarbeiter = pe.mitarbeiter_selected.id;

          if (pe.kt) {
            pe.patient.kostentraeger = pe.kt.id;
          }

          if (pe.vers_status) {
            pe.patient.vers_status = pe.vers_status.id;
          }

          if (!pe.patient.alt_rechnungsadresse) {
            //reset data to null in case user typed something in before
            pe.patient.alt_titel = "";
            pe.patient.alt_anrede = "-----";
            pe.patient.alt_name = "";
            pe.patient.alt_vorname = "";
            pe.patient.alt_plz = "";
            pe.patient.alt_ort = "";
            pe.patient.alt_strasse = "";
          }

          pe.awaiting_response = true;
          if (pe.importantDataChanged) {
            pe.openUpdatePrescriptionModal();
          } else {
            pe.update_patienten();
          }
        }
      };

      pe.update_patienten = function (updateOpenPrescriptions) {
        updateOpenPrescriptions =
          updateOpenPrescriptions === undefined
            ? false
            : updateOpenPrescriptions;

        // Close modal if necessary
        pe.closeModal();
        pe.awaiting_response = true;

        var params = {
          id: pe.patienten_id,
          updateOpenPrescriptions: updateOpenPrescriptions,
        };

        PatientenService.update(params, pe.patient).$promise.then(
          function (success_response) {
            var changes = success_response["changes"];

            var patientHeaderChangesFound =
              changes.patientChanges &&
              changes.patientChanges.updatedHeaders != 0;
            var patientDatesChangesFound =
              changes.patientChanges &&
              changes.patientChanges.updatedDates != 0;
            var excemptionChangesFound =
              changes.excemptionChanges &&
              changes.excemptionChanges.length != 0;

            // TODO: Remove loading/updating of prescription headers in storage
            // Data should be loaded from server on demand
            if (patientHeaderChangesFound || excemptionChangesFound) {
              pe.update_rezepte_in_storage(pe.patienten_id);
            }

            if (
              patientHeaderChangesFound ||
              patientDatesChangesFound ||
              excemptionChangesFound
            ) {
              pe.openInformationModal(
                changes,
                patientHeaderChangesFound,
                patientDatesChangesFound,
                excemptionChangesFound
              );
            } else {
              // no prescription headers or dates were changed
              pe.awaiting_response = false;
              pe.returnToPreviousState();
            }
          },
          function (error_response) {

            if (error_response.data["non_field_errors"]) {
              var err_rep = error_response.data["non_field_errors"];
              for (i = 0; i < err_rep.length; i++) {
                if (err_rep[i] == "PatientExists") {
                  pe.errors.name.push("Patient existiert bereits!");
                  pe.errors.vorname.push("");
                  pe.errors.geburtsdatum.push("");
                  pe.set_error_scroll("name_loc");
                } else if (err_rep[i] == "VersnrExists") {
                  pe.errors.vers_nummer.push("Patient mit dieser Versicherungsnummer existiert bereits!");
                  pe.set_error_scroll("vers_nummer_loc");
                } else if (err_rep[i] == "BGPatientExists") {
                  pe.errors.name.push("BG-Patient existiert bereits!");
                  pe.errors.vorname.push("");
                  pe.errors.geburtsdatum.push("");
                  pe.errors.unfalltag.push("");
                  pe.set_error_scroll("name_loc");
                } 
              }
            }

            pe.awaiting_response = false;
          }
        );
      };

      pe.update_rezepte_in_storage = function (patienten_id) {
        var rezepte = StoreService.get("rezepte");
        RezepteHeaderService.query({ patient: patienten_id, age: "new" })
          .$promise.then(function (data) {
            for (var i = 0; i < data.length; i++) {
              rezepte[data[i].id] = data[i];
            }

            StoreService.set("rezepte", rezepte);
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      pe.getMitarbeiterData = function () {
        MitarbeiterService.query()
          .$promise.then(function (data) {
            pe.mitarbeiter = data;
            pe.mitarbeiter_selected = data.filter(function (e) {
              return e.id == pe.patient.mitarbeiter;
            })[0];
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      pe.getBefreiungenData = function () {
        BefreiungenService.query({ patient: pe.patienten_id })
          .$promise.then(function (data) {
            pe.befreiungen = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      pe.changesPerformed = function () {
        pe.importantDataChanged = true;
      };

      pe.checkifready = function () {
        if (
          !pe.kostentraegertyp ||
          !pe.versicherungsstatus ||
          !pe.patient ||
          !pe.mitarbeiter
        ) {
          setTimeout(pe.checkifready, 50);
        } else {
          pe.isready = true;
          $scope.$apply();
        }
      };

      pe.init();
    },
  ],
});
