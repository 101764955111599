angular.
  module('core.remember_choices').
  factory('RememberService', function() {
    var RememberService = {}

    RememberService.rezeptFilterSelections = {
      rezept_von_filter: '',
      rezept_bis_filter: '',
      kttyp_filter: 'ALL',
      status_filter: 'ALL',
      unfalltag_filter: '',
      vorname_filter: '',
      nachname_filter: '',
      versnr_filter: '',
      arztnr_filter: '',
      ktid_filter: '',
    }

    RememberService.rezeptPatientSelections = {
      query: '',
      select_filter: '',
      search_attrs: ['name', 'vorname', 'strasse', 'vers_nummer', 'unfalltag'],
      sort: {sortingOrder : 'id', reverse : false},
      gap: 3,
      filteredItems: [],
      itemsPerPage: 10,
      pagedItems: [],
      currentPage: 1
    }

    RememberService.rezeptSearchSelections = {
      query: '',
      select_filter: '',
      search_attrs: ['name', 'vorname', 'strasse', 'vers_nummer', 'unfalltag'],
      sort: {sortingOrder : 'id', reverse : false},
      gap: 3,
      filteredItems: [],
      itemsPerPage: 10,
      pagedItems: [],
      currentPage: 1,
      patienten_id: null
    }

    RememberService.rezeptViewData = {
      header_id: null,
      redirectFromNachricht: null
    }

    RememberService.rezeptAddPatientSelections = {
      query: '',
      select_filter: 'ALL',
    }

    RememberService.rezeptAddHeaderData = null
    RememberService.rezeptAddLinesData = null
    RememberService.rezeptAddDatesData = null
    

    RememberService.AbrechnungFilterSelections = {
      rezept_von_filter: '',
      rezept_bis_filter: '',
      kttyp_filter: 'ALL',
      status_filter: 'Neu',
      unfalltag_filter: '',
      vorname_filter: '',
      nachname_filter: '',
      versnr_filter: '',
      arztnr_filter: '',
      ktid_filter: '',
    }










    return RememberService;

  });