angular.
  module('core.username').
  factory('UsernameService', ['$resource', '__env',
    function($resource, __env) {
        var r = $resource(__env.apiUrl + 'username/:username/\.',{username: "@username"});
    	delete r.prototype.$set;
    	delete r.prototype.$query;
  		delete r.prototype.$delete;
  		delete r.prototype.$remove;
      	return r
    }
  ]);
