angular.
module('login').
component('login', {
  templateUrl: 'static/angular_assets/login/login.template.html',
  controller: ['$element',
    '$scope',
    '$state',
    'store',
    'ICD10Service',
    'LoginService',
    'MitarbeiterService',
    'RezepteHeaderService',
    '$window',
    'Popeye',
    'jwtHelper',
    'ChangePasswordService',
    'StoreService',
    'KundenService',


    function LoginController($element,
      $scope,
      $state,
      store,
      ICD10Service,
      LoginService,
      MitarbeiterService,
      RezepteHeaderService,
      $window,
      Popeye,
      jwtHelper,
      ChangePasswordService,
      StoreService,
      KundenService) {


      // add proper CSS classes
      $element.addClass('column is-half form');
      var lgn = this;

      lgn.modal = null;
      lgn.awaiting_response = false;

      lgn.pw_change_reason = ""


			// password strength data
			lgn.passwordStrength = {
					score: 0
      };
      

      lgn.errors = {
        login: [],
        new_password: [],
        new_password_repeated: [],
      }

      lgn.progressText = "Leer";
      lgn.progressValue = 0;

      lgn.new_password = '';
      lgn.new_password_repeated = '';
      lgn.new_valid = false;
      lgn.repeated_valid = false;


      // setup watcher for passwordStrength
      $scope.$watch('$ctrl.passwordStrength', function (newValue, oldValue) {
        lgn.progressValue = lgn.passwordStrength.score * 25;
        if (!lgn.new_password) {
          lgn.progressText = 'Leer';
        } else if (lgn.passwordStrength.score == 0) {
          lgn.progressText = 'Unsicher';
        } else if (lgn.passwordStrength.score == 1) {
          lgn.progressText = 'Schwach';
        } else if (lgn.passwordStrength.score == 2) {
          lgn.progressText = 'Mittel';
        } else if (lgn.passwordStrength.score == 3) {
          lgn.progressText = 'Gut';
        } else {
          lgn.progressText = 'Stark';
        }
      }, true);



			lgn.resetError = function (err) {
        lgn.errors[err] = []
        lgn.loginSuccess = true;
			}


      lgn.checkPasswords = function() {
        lgn.resetError("new_password")
        lgn.resetError("new_password_repeated")

        lgn.passwordStrength = zxcvbn(lgn.new_password)
        
        lgn.new_valid = true;
        lgn.repeated_valid = true;

        if (!lgn.new_password){
          lgn.new_valid = false;
        }

        if (lgn.new_password && lgn.new_password.length < 8){
          lgn.errors.new_password.push("Angegebenes Passwort ist zu kurz! (mind. 8 Zeichen)");
          lgn.new_valid = false;
        }
    
				if (lgn.new_password && lgn.passwordStrength.score < 2) {
          lgn.errors.new_password.push("Angegebenes Passwort ist nicht stark genug!");
          lgn.new_valid = false;
        }

        if (lgn.new_password == lgn.password){
          lgn.errors.new_password.push("Aktuelles Passwort ist nicht erlaubt!");
          lgn.new_valid = false;
        }

        if (!lgn.new_password_repeated || (lgn.new_password_repeated && !lgn.new_password)){
          lgn.repeated_valid = false;
        }
        
				if (lgn.new_password && lgn.new_password_repeated) {
					if (lgn.new_password != lgn.new_password_repeated) {
            lgn.errors.new_password_repeated.push("Angegebene Passwörter sind nicht identisch!");
            lgn.repeated_valid = false;
					}
				}

      }


      
      lgn.updatePassword = function() {
        lgn.awaiting_response = true;
        pwd_data = new ChangePasswordService();
        pwd_data.password_old = lgn.password
        pwd_data.password_new = lgn.new_password

        pwd_data.$save().then(
          function (success_response) {
            lgn.closePasswordModal();

          },
          function (error_response) {}
        );


      }











      initController();

      function initController() {
        // reset login status
        // remove user from local storage and clear http auth header
        $window.sessionStorage.clear();

        lgn.progress = 0;
        lgn.data_loaded = {
          'icd10': false,
          'mitarbeiter': false,
          'rezepte': false,
          'kunde': false
        }

      };


      lgn.progressModal = function () {



        lgn.modal = Popeye.openModal({
          templateUrl: 'static/angular_assets/login/modals/progress_modal.template.html',
          scope: $scope,
          click: false,
          modalClass: 'no_close normal-modal-container',
        });
      }

      lgn.forcePasswordModal = function () {
        lgn.modal = Popeye.openModal({
          templateUrl: 'static/angular_assets/login/modals/change_password_modal.template.html',
          scope: $scope,
          click: false,
          keyboard: false,
          modalClass: 'no_close extended-modal-container',
        });
      }



      lgn.closePasswordModal = function () {
				lgn.modal.close();
				lgn.awaiting_response = false;
				$window.location.reload(true);
			}



      lgn.load_kunde_to_storage = function() {
        KundenService.query().$promise.then(function(data){
          StoreService.set('kunde', data[0]);
          lgn.data_loaded.kunde = true;
          lgn.progress += 100;
        }).catch(function (error) {
          // fix for "Possibly unhandled rejection"
          console.log(error);
        });
      }

      lgn.load_icd10_to_storage = function () {
        ICD10Service.query().$promise.then(function (data) {
          StoreService.set('icd10_codes', data);
          lgn.data_loaded.icd10 = true;
          lgn.progress += 100;

        }).catch(function (error) {
          // fix for "Possibly unhandled rejection"
          console.log(error);
        });
      }

      lgn.load_mitarbeiter_to_storage = function () {
        MitarbeiterService.query().$promise.then(function (data) {

          StoreService.set('mitarbeiter', data);
          lgn.data_loaded.mitarbeiter = true;
          lgn.progress += 100;
        }).catch(function (error) {
          // fix for "Possibly unhandled rejection"
          console.log(error);
        });
      }

      lgn.load_rezeptHeader_to_storage = function (params) {
        RezepteHeaderService.query(params).$promise.then(function (data) {

          // change list to dict
          var dict_data = data.results.reduce(function (map, obj) {
            map[obj.id] = obj;
            return map;
          }, {});

          StoreService.set('rezepte', dict_data);
          lgn.data_loaded.rezepte = true;
          lgn.progress += 100;
        }).catch(function (error) {
          // fix for "Possibly unhandled rejection"
          console.log(error);
        });
      }

      lgn.checkifready = function () {
        if (!lgn.data_loaded.icd10 ||
          !lgn.data_loaded.mitarbeiter ||
          !lgn.data_loaded.rezepte ||
          !lgn.data_loaded.kunde) {

          setTimeout(lgn.checkifready, 50);

        } else {

          $state.go('menu.nav');
          lgn.modal.close();
          lgn.modal = null;


        }
      }



      lgn.login = function login() {
        lgn.loginSuccess = true;
        lgn.loginResponse = new LoginService({
          username: lgn.username,
          password: lgn.password
        });

        lgn.err_msg = null;


        if (!lgn.username && !lgn.password){
          lgn.err_msg = "Anmeldedaten fehlen!";
          lgn.loginSuccess = false;
          return
        }


        lgn.loginResponse.$save().then(

          function (success_response) {

            if (success_response.access) {


              // store username and token in local storage to keep user logged in between page refreshes
              store.set('currentUser', lgn.username);
              store.set('access_token',success_response.access)
              store.set('refresh_token',success_response.refresh)

              var pw_expired = jwtHelper.decodeToken(success_response.access).pw_exp
              var pw_force = jwtHelper.decodeToken(success_response.access).pw_force

              if (pw_expired){
                lgn.pw_change_reason = "expired"
                lgn.forcePasswordModal();
              }
              else if(pw_force){
                lgn.pw_change_reason = "security"
                lgn.forcePasswordModal();
              }

              else {


                if (jwtHelper.decodeToken(success_response.access).admin) {
                  $state.go('menu.nav');
                } else {

                  // show progressModal
                  lgn.progressModal();

                  lgn.checkifready();

                  lgn.load_kunde_to_storage() 
                  
                  // load rezepte from db
                  header_params = {age: 'new'}
                  lgn.load_rezeptHeader_to_storage(header_params)

                  // var icd10_codes = 
                  lgn.load_icd10_to_storage()

                  // load mitarbeiter from db
                  lgn.load_mitarbeiter_to_storage()
                }

              }
            }
          },
          function (error) {
            console.log(error)
            err = error.data;

            if (err.password) {
              if (err.password[0] == "Dieses Feld ist erforderlich." ||
                err.password[0] == "Dieses Feld darf nicht leer sein.") {
                lgn.err_msg = "Passwort darf nicht leer sein!";
              };
            } else if (err.username) {
              if (err.username[0] == "Dieses Feld ist erforderlich." ||
                err.username[0] == "Dieses Feld darf nicht leer sein.") {
                lgn.err_msg = "Benutzername darf nicht leer sein!";
              };
            } else if (err.id) {

              if (err.id == "AUTH") {
                lgn.err_msg = "Anmeldedaten sind ungültig!";
              };

              if (err.id == "BLOCK") {
                lgn.err_msg = "Durch fehlerhafte Login-Versuche ist der Login für 5 Minuten gesperrt!"
              }

            }

            lgn.loginSuccess = false;
          }
        )
      };
    }
  ]
});