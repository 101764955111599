angular.module("aerzteOverview").component("aerzteOverview", {
  templateUrl:
    "static/angular_assets/aerzte/aerzte_overview/aerzte_overview.template.html",
  controller: [
    "$scope",
    "$state",
    "$compile",
    "AerzteService",
    "Popeye",
    "DTColumnDefBuilder",
    "__env",
    "store",
    "$document",
    "$stateParams",
    function AerzteOverviewController(
      $scope,
      $state,
      $compile,
      AerzteService,
      Popeye,
      DTColumnDefBuilder,
      __env,
      store,
      $document,
      $stateParams
    ) {
      var aeov = this;


      function delay(callback, ms) {
        var timer = 0;
        return function () {
          var context = this,
            args = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
            callback.apply(context, args);
          }, ms || 0);
        };
      }


      aeov.getActionButtons = function (row) {
        var show_button =
          '<button ng-click="$ctrl.showArzt(' +
          row.arzt_num +
          ')" class="button mka-dark-button mka-is-small is-outlined row-controls" data-tooltip="Anzeigen" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-eye"></i> </span> </button>';
        var edit_button =
          '<button ng-click="$ctrl.editArzt(' +
          row.arzt_num +
          ')" class="button mka-dark-button mka-is-small is-outlined row-controls" data-tooltip="Editieren" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-edit"></i> </span> </button>';
        var delete_button =
          '<button ng-click="$ctrl.openDeleteModal(' +
          row.arzt_num +
          ')" class="button herbst-is-delete mka-is-small is-outlined row-controls" data-tooltip="Löschen" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-trash"></i> </span> </button>';

        return show_button + edit_button + delete_button;
      };

      aeov.openDeleteModal = function (arzt_num) {

        aeov.arzt_protected = false;
        if (aeov.rememberchoice) {
          aeov.deleteArzt(arzt_num);
        } else {
          aeov.arzt_to_del = arzt_num;
          aeov.modal = Popeye.openModal({
            templateUrl:
              "static/angular_assets/aerzte/aerzte_overview/modals/delete_modal.template.html",
            scope: $scope,
            modalClass: "normal-modal-container",
          });
        }
      };

      aeov.closeModal = function () {
        aeov.modal.close();
        aeov.modal = null;
      };

      aeov.showArzt = function (arzt_num) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { arztNum: arzt_num });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.aerzte-view", newParams);
      };

      aeov.deleteArzt = function (arzt_num) {
        AerzteService.delete({
          arztNum: arzt_num,
        })
          .$promise.then(function (data) {
            aeov.arzt_to_del = null;

            // Ensures that the datatable is reloaded after the deletion and it stays on the same page
            aeov.dtInstance.DataTable.ajax.reload(null, false);

            aeov.closeModal();
          })
          .catch(function (error) {
            if (error.data.type == "PROTECTED") {
              aeov.arzt_protected = true;
            }

            if (!aeov.modal) {
              aeov.modal = Popeye.openModal({
                templateUrl:
                  "static/angular_assets/aerzte/aerzte_overview/modals/delete_modal.template.html",
                scope: $scope,
                modalClass: "normal-modal-container",
              });
            }
          });
      };

      aeov.editArzt = function (arzt_num) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { arztNum: arzt_num });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.aerzte-edit", newParams);
      };

      aeov.addArzt = function () {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams);
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.aerzte-add", newParams);
      };

      // Navigation
      aeov.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      aeov.init = function () {
        // Modal related
        aeov.modal = null;
        aeov.rememberchoice = false;
        aeov.arzt_to_del = null;
        aeov.arzt_protected = false;

        // Table related
        aeov.dtInstance = {};
        aeov.dtOptions = {
          responsive: true,
          displayLength: 10,
          displayStart: 0,
          processing: true,
          search: {
            search: "",
          },
          serverSide: true,

          initComplete: function () {
            // Adjust search behaviour
            $document
              .find(".dataTables_filter input")
              .unbind() // Unbind previous default bindings
              .bind(
                "input",
                delay(function (e) {
                  // Bind our desired behavior
                  // If the length is 3 or more characters, or the user pressed ENTER, search
                  if (this.value.length >= 3 || e.keyCode == 13) {
                    // Call the API search function
                    aeov.dtInstance.DataTable.search(this.value).draw();
                  }
                  // Ensure we clear the search if they backspace far enough
                  if (this.value == "") {
                    aeov.dtInstance.DataTable.search("").draw();
                  }
                  return;
                }, 300)
              );
          },

          ajax: {
            url: __env.apiUrl + "aerzte/dt/",
            type: "POST",
            beforeSend: function (request) {
              request.setRequestHeader(
                "Authorization",
                "Bearer " + store.get("access_token")
              );
            },
            dataSrc: function (json) {
              return json.data;
            },
          },

          columns: [
            { data: "arzt_num" },
            { data: "betriebs_num" },
            { data: "name" },
            {
              data: null,
              orderable: false,
              searchable: false,
              render: function (data, type, row) {
                return aeov.getActionButtons(row);
              },
            },
          ],

          createdRow: function (row, data, index) {
            $compile(row)($scope);
          },

          // INFO: do not provide any order as it seems to be breaking the table header after trying to sort
          order: [],

          language: {
            decimal: "",
            emptyTable: "Keine Daten verfügbar",
            info: "Zeige _START_ bis _END_ von _TOTAL_ Einträgen",
            infoEmpty: "Zeige 0 bis 0 von 0 Einträgen",
            infoFiltered: "(gefiltert von insg. _MAX_ Einträgen)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Zeige _MENU_ Einträge",
            loadingRecords: "Laden...",
            processing:
              "<span class='icon' style='font-size: 4em;'><i class='fas fa-spinner fa-spin'></i></span>",
            search: "Suche:",
            zeroRecords: "Keine passenden Einträge gefunden!",
            paginate: {
              first: "Erste",
              last: "Letzte",
              next: ">",
              previous: "<",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        };
        aeov.dtColumns = [
          DTColumnDefBuilder.newColumnDef(0).withClass("all"),
          DTColumnDefBuilder.newColumnDef(1).withClass("all"),
          DTColumnDefBuilder.newColumnDef(2).withClass("all"),
          DTColumnDefBuilder.newColumnDef(3).withClass("all"),
        ];
      };

      aeov.init();
    },
  ],
});
