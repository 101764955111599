angular.module("offenepostenOverview").component("offenepostenOverview", {
  templateUrl: "static/angular_assets/offene_posten/offene_posten_overview/offene_posten_overview.template.html",
  controller: [
    "$scope",
    "$state",
    "$filter",
    "OffenePostenService",
    "$stateParams",
    function OffenePostenOverviewController($scope, $state, $filter, OffenePostenService, $stateParams) {
      var opo = this;

      opo.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      opo.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      opo.filter = {
        kt_typ: "ALL",
        datum_von: null,
        datum_bis: null,
        status: "ALL",
      };

      opo.on_change_status = function () {
        var filtering = FooTable.get("#offene_posten_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = opo.filter.status; // get the value to filter by

        if (filter === "ALL") {
          // if the value is "none" remove the filter
          filtering.removeFilter("status");
        } else {
          // otherwise add/update the filter.
          filter = '"' + filter + '"';
          filtering.addFilter("status", filter, ["status"]);
        }
        filtering.filter();
      };

      opo.on_change_kt_typ = function () {
        var filtering = FooTable.get("#offene_posten_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = opo.filter.kt_typ; // get the value to filter by

        if (filter === "ALL") {
          // if the value is "none" remove the filter
          filtering.removeFilter("kt_typ");
        } else {
          // otherwise add/update the filter.
          filter = '"' + filter + '"';
          filtering.addFilter("kt_typ", filter, ["kt_typ"]);
        }
        filtering.filter();
      };

      opo.on_change_datum_von = function () {
        var filtering = FooTable.get("#offene_posten_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = opo.filter.datum_von; // get the value to filter by

        if (!filter) {
          // if the value is "none" remove the filter
          filtering.removeFilter("datum_von");
        } else {
          // otherwise add/update the filter.

          filter = ">" + opo.date_to_str(filter);
          filtering.addFilter("datum_von", filter, ["datum"]);
        }
        filtering.filter();
      };

      opo.on_change_datum_bis = function () {
        var filtering = FooTable.get("#offene_posten_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = opo.filter.datum_bis; // get the value to filter by

        if (!filter) {
          // if the value is "none" remove the filter
          filtering.removeFilter("datum_bis");
        } else {
          // otherwise add/update the filter.

          filter = "<" + opo.date_to_str(filter);
          filtering.addFilter("datum_bis", filter, ["datum"]);
        }
        filtering.filter();
      };

      // ############ TABLE DATA/FUNCTIONS ############
      // #########################################

      // table object information
      opo.tables = {
        offene_posten_search_data: {},
      };

      opo.tables.offene_posten_search_data = {
        query: "",
        select_filter: "",
        sort: {
          sortingOrder: "rechnungsdatum",
          reverse: true,
        },
        gap: 3,
        filteredItems: [],
        itemsPerPage: 10,
        pagedItems: [],
        currentPage: 1,
        rechnung_von_filter: "",
        rechnung_bis_filter: "",
        kttyp_filter: "ALL",
        status_filter: "ALL",
        name1_filter: "",
        name2_filter: "",
        rechnungsnr_filter: "",
        kt_ik_filter: "",
      };

      var searchMatch = function (haystack, needle) {
        if (!needle) {
          return true;
        }
        if (!haystack) {
          return false;
        }
        return haystack.toString().toLowerCase().indexOf(needle.toLowerCase()) !== -1;
      };

      opo.search_offene_posten = function (data) {
        var sp = opo.tables.offene_posten_search_data;

        sp.filteredItems = $filter("filter")(data, function (item) {
          // 1. check rechnungsdatum
          var rechnungsdatum = opo.str_to_date(item["rechnungsdatum"]);
          if (sp.rechnung_von_filter && sp.rechnung_bis_filter) {
            if (rechnungsdatum < sp.rechnung_von_filter || rechnungsdatum > sp.rechnung_bis_filter) {
              return false;
            }
          } else if (sp.rechnung_von_filter) {
            if (rechnungsdatum < sp.rechnung_von_filter) {
              return false;
            }
          } else if (sp.rechnung_bis_filter) {
            if (rechnungsdatum > sp.rechnung_bis_filter) {
              return false;
            }
          }

          // 2. check kostentraeger-typ
          if (sp.kttyp_filter != "ALL") {
            if (item["kt_typ"] != sp.kttyp_filter) {
              return false;
            }
          }

          // 3. status
          if (sp.status_filter != "ALL") {
            if (item["status"] != sp.status_filter) {
              return false;
            }
          }

          // 4. name1
          if (sp.name1_filter) {
            var relevant = searchMatch(item["name1"], sp.name1_filter);
            if (!relevant) {
              return false;
            }
          }

          // 5. name2
          if (sp.name2_filter) {
            var relevant = searchMatch(item["name2"], sp.name2_filter);
            if (!relevant) {
              return false;
            }
          }

          // 6. kostentraeger_ik_num
          if (sp.kt_ik_filter) {
            var relevant = searchMatch(item["kostentraeger_ik_num"], sp.kt_ik_filter);
            if (!relevant) {
              return false;
            }
          }

          // 7. rechnungs_nr
          if (sp.rechungsnr_filter) {
            var relevant = searchMatch(item["rechnungs_nr"], sp.rechungsnr_filter);
            if (!relevant) {
              return false;
            }
          }

          // all checks were successful - item is relevant so return true
          return true;
        });

        // take care of the sorting order
        if (sp.sort.sortingOrder !== "") {
          sp.filteredItems = $filter("orderBy")(sp.filteredItems, sp.sort.sortingOrder, sp.sort.reverse);
        }
        sp.currentPage = 1;
        // now group by pages
        opo.groupToPages(opo.tables.offene_posten_search_data);
      };

      // calculate and group filteredItems into pages with itemsPerPage
      opo.groupToPages = function (cnt) {
        var sp = cnt;
        sp.pagedItems = [];

        for (var i = 0; i < sp.filteredItems.length; i++) {
          if (i % sp.itemsPerPage === 0) {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)] = [sp.filteredItems[i]];
          } else {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)].push(sp.filteredItems[i]);
          }
        }
      };

      // pagination range
      opo.range = function (size, curr) {
        var ret = [];

        if (size == 1) {
          return ret;
        } else if (size <= 5) {
          for (var i = 2; i <= size - 1; i++) {
            ret.push(i);
          }
          return ret;
        } else {
          // left end
          if (curr == 1) {
            ret.push(curr + 1);
            ret.push(curr + 2);
            ret.push(curr + 3);
          } else if (curr == size) {
            ret.push(curr - 3);
            ret.push(curr - 2);
            ret.push(curr - 1);
          } else if (curr - 1 == 1) {
            ret.push(curr);
            ret.push(curr + 1);
            ret.push(curr + 2);
          } else if (curr + 1 == size) {
            ret.push(curr - 2);
            ret.push(curr - 1);
            ret.push(curr);
          } else {
            ret.push(curr - 1);
            ret.push(curr);
            ret.push(curr + 1);
          }
          return ret;
        }
      };

      // pagination back-button function
      opo.prevPage = function (cnt) {
        var d = opo.tables[cnt];

        if (d.currentPage > 1) {
          d.currentPage--;
        }
      };

      // pagination forward-button function
      opo.nextPage = function (cnt) {
        var d = opo.tables[cnt];
        if (d.currentPage < d.pagedItems.length) {
          d.currentPage++;
        }
      };

      // set currently viewed page to n
      opo.setPage = function (cnt, n) {
        var d = opo.tables[cnt];
        d.currentPage = n;
      };

      //functions to sort data (for tables) based on multiple attributes/properties
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      opo.dynamicSortMultiple = function () {
        var props = arguments;
        return function (obj1, obj2) {
          var i = 0,
            result = 0,
            numberOfProperties = props.length;
          /* try getting a different result from 0 (equal)
           * as long as we have extra properties to compare
           */
          while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
          }
          return result;
        };
      };
      // #######################################

      opo.showOffenerPosten = function (offener_posten_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { offenerPostenId: offener_posten_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.offene-posten-edit", newParams);
      };

      // ########## GET DATA FUNCTIONS #########
      // #######################################
      opo.getOffenePostenData = function () {
        OffenePostenService.query()
          .$promise.then(function (data) {
            opo.offene_posten = data;
            opo.offene_posten_cnt = opo.offene_posten.length;
          })
          .catch(function (error) {
            console.log(error);
            // fix for "Possibly unhandled rejection"
          });
      };
      // #######################################

      // ########### CHECK FUNCTIONS ###########
      // #######################################
      opo.checkifready = function () {
        if (!opo.offene_posten) {
          setTimeout(opo.checkifready, 50);
        } else {
          opo.search_offene_posten(opo.offene_posten);
          opo.isready = true;
          $scope.$apply();
        }
      };
      // #######################################

      opo.offene_posten = null;
      opo.isready = false;

      // foo-table

      opo.initFooTable = function () {
        // custom dropdown filter
        FooTable.MyFiltering = FooTable.Filtering.extend({
          construct: function (instance) {
            this._super(instance);
            this.$filter_status = null;
          },

          $create: function () {
            this._super();
            var self = this;
            this.$filter_status = $(".btn-primary").on(
              "click",
              {
                self: self,
              },
              self._onFilterStatusChanged
            );
          },
          _onFilterStatusChanged: function (e) {
            opo.filter = {
              kt_typ: "ALL",
              datum_von: null,
              datum_bis: null,
              status: "ALL",
            };
          },
        });

        opo.table.footable({
          components: {
            filtering: FooTable.MyFiltering,
          },
        });
      };

      $scope.$watch(
        "opo.data",
        function () {
          opo.data = angular.copy(opo.data);
        },
        true
      );

      opo.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      //get all available Mitarbeiter
      opo.getOffenePostenData();
      opo.checkifready();
    },
  ],
});
