angular.
module('mkav03App')
	.directive('onFinishRender', function ($log, $timeout) {
		return {
			restrict: 'A',
			link: function (scope, element, attr) {
				if (scope.$last === true) {
					scope.$evalAsync(attr.onFinishRender);
				} else {
					// element not loaded yet
				}
			}
		}

	})
	.directive("jqlElement", function ($log, $parse) {
		return {
			restrict: "A",

			compile: function compile(tElement, tAttrs, transclude) {
				return {
					pre: function preLink(scope, iElement, iAttrs, controller) {
						$parse(iAttrs.jqlElement).assign(scope, iElement);
					}
				};
			}
		}

	})
	.directive('compile', ['$compile', function ($compile) {
		return function(scope, element, attrs) {
			scope.$watch(
				function(scope) {
					// watch the 'compile' expression for changes
					return scope.$eval(attrs.compile);
				},
				function(value) {
					// when the 'compile' expression changes
					// assign it into the current DOM
					element.html(value);
	
					// compile the new DOM and link it to the current
					// scope.
					// NOTE: we only compile .childNodes so that
					// we don't get into infinite loop compiling ourselves
					$compile(element.contents())(scope);
				}
			);
		};
	}]);