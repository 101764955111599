angular.module("patientenOverview").component("patientenOverview", {
  templateUrl: "static/angular_assets/patienten/patienten_overview/patienten_overview.template.html",
  controller: [
    "$scope",
    "$state",
    "$compile",
    "PatientenService",
    "Popeye",
    "DTColumnDefBuilder",
    "store",
    "__env",
    "$uiRouterGlobals",
    "$document",
    "$stateParams",
    function PatientenOverviewController(
      $scope,
      $state,
      $compile,
      PatientenService,
      Popeye,
      DTColumnDefBuilder,
      store,
      __env,
      $uiRouterGlobals,
      $document,
      $stateParams
    ) {
      var pov = this;

      var ktFilterTemplate = `
				<label style='top:4px'>
				<select id="patient_kttyp_filter" ng-model="$ctrl.kt_typ_filter"
										ng-change="$ctrl.onChangeKtTyp()">
										<option value="ALL">Alle Kostenträger</option>
										<option value="BG">Berufsgenossenschaft</option>
										<option value="KK">Krankenkasse</option>
										<option value="PBeaKK">Postbeamtenkrankenkasse</option>
										<option value="PP">Privat</option>
										<option value="S">Sonstige</option>
				</select>
				</label>`;

      function delay(callback, ms) {
        var timer = 0;
        return function () {
          var context = this,
            args = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
            callback.apply(context, args);
          }, ms || 0);
        };
      }

      function toGermanDate(date) {
        var d = moment(date);
        return d.isValid() ? d.format("DD.MM.YYYY") : "";
      }

      pov.onChangeKtTyp = function () {
        if (pov.kt_typ_filter == "ALL") {
          pov.dtInstance.DataTable.columns(0).search("").draw();
          pov.dtInstance.DataTable.ajax.reload(null, false);
        } else {
          pov.dtInstance.DataTable.columns(0).search(pov.kt_typ_filter).draw();
        }
      };

      pov.showPatienten = function (patienten_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { patientenId: patienten_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.patienten-view", newParams);
      };

      pov.editPatienten = function (patienten_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { patientenId: patienten_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.patienten-edit", newParams);
      };

      pov.openDeleteModal = function (patienten_id) {
        pov.patient_protected = false;
        if (pov.rememberchoice) {
          pov.deletePatienten(patienten_id);
        } else {
          pov.patient_to_del = patienten_id;
          pov.modal = Popeye.openModal({
            templateUrl: "static/angular_assets/patienten/patienten_overview/modals/delete_modal.template.html",
            scope: $scope,
            modalClass: "normal-modal-container",
          });
        }
      };

      pov.closeModal = function () {
        pov.modal.close();
        pov.modal = null;
      };

      pov.deletePatienten = function (patienten_id) {
        PatientenService.delete({
          id: patienten_id,
        })
          .$promise.then(function (data) {
            pov.patient_to_del = null;

            // Ensures that the datatable is reloaded after the deletion and it stays on the same page
            pov.dtInstance.DataTable.ajax.reload(null, false);

            pov.closeModal();
          })
          .catch(function (error) {
            if (error.data.type == "PROTECTED") {
              pov.patient_protected = true;
            }

            if (!pov.modal) {
              pov.modal = Popeye.openModal({
                templateUrl: "static/angular_assets/patienten/patienten_overview/modals/delete_modal.template.html",
                scope: $scope,
                modalClass: "normal-modal-container",
              });
            }
          });
      };

      pov.gotoRezeptSearch = function (patienten_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { patientenId: patienten_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-overview-rezept", newParams);
      };

      pov.selectPatienten = function (patienten_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { patientenId: patienten_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-add-header", newParams);
      };

      pov.addPatienten = function () {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams);
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.patienten-add", newParams);
      };

      pov.getActionButtons = function (row) {
        var buttons = "";
        if (pov.currentState.name == "menu.patienten") {
          var show_button =
            '<button ng-click="$ctrl.showPatienten(' +
            row.id +
            ')" class="button mka-dark-button mka-is-small is-outlined row-controls" data-tooltip="Anzeigen" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-eye"></i> </span> </button>';
          var edit_button =
            '<button ng-click="$ctrl.editPatienten(' +
            row.id +
            ')" class="button mka-dark-button mka-is-small is-outlined row-controls" data-tooltip="Editieren" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-edit"></i> </span> </button>';
          var delete_button =
            '<button ng-click="$ctrl.openDeleteModal(' +
            row.id +
            ')" class="button mka-dark-button mka-is-small is-outlined row-controls" data-tooltip="Löschen" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-trash"></i> </span> </button>';
          buttons = show_button + edit_button + delete_button;
        }

        if (pov.currentState.name == "menu.rezepte-overview-patient") {
          var select_button =
            '<button ng-click="$ctrl.gotoRezeptSearch(' +
            row.id +
            ')" class="button mka-dark-button mka-is-small is-outlined row-controls" data-tooltip="Auswählen" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-check"></i> </span> </button>';
          var show_button =
            '<button ng-click="$ctrl.showPatienten(' +
            row.id +
            ')" class="button mka-dark-button mka-is-small is-outlined row-controls" data-tooltip="Patient anzeigen" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-eye"></i> </span> </button>';
          buttons = select_button + show_button;
        }

        if (pov.currentState.name == "menu.rezepte-add-selection") {
          var select_button =
            '<button ng-click="$ctrl.selectPatienten(' +
            row.id +
            ')" class="button mka-dark-button mka-is-small is-outlined row-controls" data-tooltip="Auswählen" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-check"></i> </span> </button>';
          var show_button =
            '<button ng-click="$ctrl.showPatienten(' +
            row.id +
            ')" class="button mka-dark-button mka-is-small is-outlined row-controls" data-tooltip="Patient anzeigen" data-placement="bottom"> <span class="icon is-small"> <i class="fa fa-eye"></i> </span> </button>';
          buttons = select_button + show_button;
        }

        return buttons;
      };

      pov.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      pov.init = function () {
        // Modal related variables
        pov.modal = null;
        pov.rememberchoice = false;
        pov.patient_to_del = null;
        pov.data_filtered = false;
        pov.patient_protected = false;

        // KT Filter
        pov.kt_typ_filter = "ALL";

        // Component transport parameters
        pov.currentState = $uiRouterGlobals.current;

        pov.dtInstance = {};
        pov.dtOptions = {
          responsive: true,
          displayLength: 10,
          displayStart: 0,
          processing: true,
          search: {
            search: "",
          },
          serverSide: true,

          initComplete: function () {
            // Add KT Filter and compile it so that angular can bind to it
            var length_element = $(".dataTables_length");
            length_element.append(ktFilterTemplate);
            $compile(length_element)($scope);

            // Adjust search behaviour
            $document
              .find(".dataTables_filter input")
              .unbind() // Unbind previous default bindings
              .bind(
                "input",
                delay(function (e) {
                  // Bind our desired behavior
                  // If the length is 3 or more characters, or the user pressed ENTER, search
                  if (this.value.length >= 3 || e.keyCode == 13) {
                    // Call the API search function
                    pov.dtInstance.DataTable.search(this.value).draw();
                  }
                  // Ensure we clear the search if they backspace far enough
                  if (this.value == "") {
                    pov.dtInstance.DataTable.search("").draw();
                  }
                  return;
                }, 300)
              );
          },

          drawCallback: function (settings) {
            // This ensures that the KT Filter is recompiled after the table is redrawn
            // otherwise the current selected value is not displayed
            var length_element = $(".dataTables_length");
            $compile(length_element)($scope);
          },

          ajax: {
            url: __env.apiUrl + "patienten/dt/",
            type: "POST",
            beforeSend: function (request) {
              request.setRequestHeader("Authorization", "Bearer " + store.get("access_token"));
            },
            dataSrc: function (json) {
              return json.data;
            },
          },

          columns: [
            { data: "kt_typ", width: "8%" },
            { data: "name" },
            { data: "vorname" },
            {
              data: "geburtsdatum",
              type: "date-eu",
              width: "12%",
              render: function (data) {
                return toGermanDate(data);
              },
            },
            { data: "kostentraeger_ik_num", defaultContent: "", orderable: false },
            { data: "vers_nummer", defaultContent: "", orderable: false },
            {
              data: "unfalltag",
              type: "date-eu",
              defaultContent: "",
              width: "10%",
              render: function (data) {
                return toGermanDate(data);
              },
            },
            {
              data: null,
              orderable: false,
              searchable: false,
              render: function (data, type, row) {
                return pov.getActionButtons(row);
              },
            },
          ],

          createdRow: function (row, data, index) {
            $compile(row)($scope);
          },

          // INFO: do not provide any order as it seems to be breaking the table header after trying to sort
          order: [],

          language: {
            decimal: "",
            emptyTable: "Keine Daten verfügbar",
            info: "Zeige _START_ bis _END_ von _TOTAL_ Einträgen",
            infoEmpty: "Zeige 0 bis 0 von 0 Einträgen",
            infoFiltered: "(gefiltert von insg. _MAX_ Einträgen)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Zeige _MENU_ Einträge",
            loadingRecords: "Laden...",
            processing: "<span class='icon' style='font-size: 4em;'><i class='fas fa-spinner fa-spin'></i></span>",
            search: "Suche:",
            zeroRecords: "Keine passenden Einträge gefunden!",
            paginate: {
              first: "Erste",
              last: "Letzte",
              next: ">",
              previous: "<",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        };

        pov.dtColumnDefs = [
          DTColumnDefBuilder.newColumnDef(0).withClass("all"),
          DTColumnDefBuilder.newColumnDef(1).withClass("all"),
          DTColumnDefBuilder.newColumnDef(2).withClass("all"),
          DTColumnDefBuilder.newColumnDef(3).withClass("all"),
          DTColumnDefBuilder.newColumnDef(4).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(5).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(6).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(7).withClass("all"),
        ];
      };

      pov.init();
    },
  ],
});
