angular.
  module('core.preislisten_check').
  factory('PreislistenCheckService', ['$resource', '__env',
    function($resource, __env) {
        var r = $resource(__env.apiUrl + 'preischeck/:patient_id/\.',{patient_id: "@patient_id"});
    	delete r.prototype.$set;
    	delete r.prototype.$query;
  		delete r.prototype.$delete;
  		delete r.prototype.$remove;
      	return r
    }
  ]);
