angular.
  module('core.kassenbuch_create').
  factory('KassenbuchCreateService', ['$resource', '__env',
    function($resource, __env) {
        var r = $resource(__env.apiUrl + 'createkassenbucheintrag/:id/\.',{});
    	delete r.prototype.$query;
  		delete r.prototype.$delete;
  		delete r.prototype.$remove;
      	return r
    }
  ]);
