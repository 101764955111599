angular.
  module('core.ik_num').
  factory('IKNumService', ['$resource', '__env',
    function($resource, __env) {
        var r = $resource(__env.apiUrl + 'ik/:ik_num/\.',{ik_num: "@ik_num"});
    	delete r.prototype.$set;
    	delete r.prototype.$query;
  		delete r.prototype.$delete;
  		delete r.prototype.$remove;
      	return r
    }
  ]);
