angular.
  module('core.mahnung_preview').
  factory('MahnungPreviewService', ['$resource', 'Blob', '__env',
    function($resource, Blob, __env) {

      
      r =  $resource(__env.apiUrl + 'mahnung/preview/\.', {},
      {
        'pdf': {
          method: 'POST',
          hasBody: true,
          headers: {
              accept: 'application/pdf',
              'Content-type': 'application/json'
          },
          responseType: 'arraybuffer',
          cache: false,
          
          transformResponse: function (data, headers) {
            var pdf;
            var hdrs = headers()            
            if (data) {
                pdf = new Blob([data], {
                    type: 'application/pdf'
                });
            }
            return {
                response: pdf,
                filename: hdrs["content-disposition"].slice(22,hdrs["content-disposition"].length - 1),

            };
        }
        }
      }
    );

      return r

    }
  ]);
