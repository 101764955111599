angular.module("mitarbeiterOverview").component("mitarbeiterOverview", {
  templateUrl: "static/angular_assets/mitarbeiter/mitarbeiter_overview/mitarbeiter_overview.template.html",
  controller: [
    "$scope",
    "$state",
    "$filter",
    "Popeye",
    "MitarbeiterService",
    "StoreService",
    "$stateParams",
    function MitarbeiterOverviewController(
      $scope,
      $state,
      $filter,
      Popeye,
      MitarbeiterService,
      StoreService,
      $stateParams
    ) {
      var mov = this;

      // MODAL
      mov.modal = null;
      mov.rememberchoice = false;
      mov.mitarbeiter_to_del = null;

      mov.opendeleteModal = function (mitarbeiter_id) {
        if (mov.rememberchoice) {
          mov.deleteMitarbeiter(mitarbeiter_id);
        } else {
          mov.mitarbeiter_to_del = mitarbeiter_id;
          mov.modal = Popeye.openModal({
            templateUrl: "static/angular_assets/mitarbeiter/mitarbeiter_overview/modals/delete_modal.template.html",
            scope: $scope,
            modalClass: "normal-modal-container",
          });
        }
      };

      mov.closeModal = function () {
        mov.modal.close();
      };

      mov.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      // ############ TABLE DATA/FUNCTIONS ############
      // #########################################

      // table object information
      mov.tables = {
        mitarbeiter_search_data: {},
      };

      mov.tables.mitarbeiter_search_data = {
        query: "",
        select_filter: "",
        search_attrs: ["name", "vorname", "mitarbeiter_id"],
        sort: {
          sortingOrder: "mitarbeiter_id",
          reverse: false,
        },
        gap: 3,
        filteredItems: [],
        itemsPerPage: 10,
        pagedItems: [],
        currentPage: 1,
      };

      // function to find a given string (needle) in given attribute (haystack)
      var searchMatch = function (haystack, needle) {
        if (!needle) {
          return true;
        }
        if (!haystack) {
          return false;
        }
        return haystack.toString().toLowerCase().indexOf(needle.toLowerCase()) !== -1;
      };

      // search-filter to filter out only relevant data; based on query term and a filter-dropdown (mov.select_filter)
      mov.search_mitarbeiter = function (data) {
        var sp = mov.tables.mitarbeiter_search_data;
        sp.filteredItems = $filter("filter")(data, function (item) {
          // search without using a filter / search for query term over all objects
          if (sp.select_filter == "ALL" || !sp.select_filter) {
            for (var i = 0; i < sp.search_attrs.length; i++) {
              if (searchMatch(item[sp.search_attrs[i]], sp.query)) {
                return true;
              }
            }
          }
          // search using a filter
          else {
            for (var i = 0; i < sp.search_attrs.length; i++) {
              if (item["kt_typ"] == sp.select_filter) {
                if (searchMatch(item[sp.search_attrs[i]], sp.query)) {
                  return true;
                }
              }
            }
          }
          return false;
        });

        // take care of the sorting order
        if (sp.sort.sortingOrder !== "") {
          sp.filteredItems = $filter("orderBy")(sp.filteredItems, sp.sort.sortingOrder, sp.sort.reverse);
        }
        sp.currentPage = 1;
        // now group by pages
        mov.groupToPages(mov.tables.mitarbeiter_search_data);
      };

      // calculate and group filteredItems into pages with itemsPerPage
      mov.groupToPages = function (cnt) {
        var sp = cnt;
        sp.pagedItems = [];

        for (var i = 0; i < sp.filteredItems.length; i++) {
          if (i % sp.itemsPerPage === 0) {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)] = [sp.filteredItems[i]];
          } else {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)].push(sp.filteredItems[i]);
          }
        }
      };

      // pagination range
      mov.range = function (size, curr) {
        var ret = [];

        if (size == 1) {
          return ret;
        } else if (size <= 5) {
          for (var i = 2; i <= size - 1; i++) {
            ret.push(i);
          }
          return ret;
        } else {
          // left end
          if (curr == 1) {
            ret.push(curr + 1);
            ret.push(curr + 2);
            ret.push(curr + 3);
          } else if (curr == size) {
            ret.push(curr - 3);
            ret.push(curr - 2);
            ret.push(curr - 1);
          } else if (curr - 1 == 1) {
            ret.push(curr);
            ret.push(curr + 1);
            ret.push(curr + 2);
          } else if (curr + 1 == size) {
            ret.push(curr - 2);
            ret.push(curr - 1);
            ret.push(curr);
          } else {
            ret.push(curr - 1);
            ret.push(curr);
            ret.push(curr + 1);
          }
          return ret;
        }
      };

      // pagination back-button function
      mov.prevPage = function (cnt) {
        var d = mov.tables[cnt];

        if (d.currentPage > 1) {
          d.currentPage--;
        }
      };

      // pagination forward-button function
      mov.nextPage = function (cnt) {
        var d = mov.tables[cnt];
        if (d.currentPage < d.pagedItems.length) {
          d.currentPage++;
        }
      };

      // set currently viewed page to n
      mov.setPage = function (cnt, n) {
        var d = mov.tables[cnt];
        d.currentPage = n;
      };

      //functions to sort data (for tables) based on multiple attributes/properties
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      mov.dynamicSortMultiple = function () {
        var props = arguments;
        return function (obj1, obj2) {
          var i = 0,
            result = 0,
            numberOfProperties = props.length;
          /* try getting a different result from 0 (equal)
           * as long as we have extra properties to compare
           */
          while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
          }
          return result;
        };
      };
      // #########################################

      // functions
      mov.checkifready = function () {
        if (!mov.mitarbeiter) {
          setTimeout(mov.checkifready, 50);
        } else {
          mov.search_mitarbeiter(mov.mitarbeiter);
          mov.isready = true;
          //$scope.$apply();
        }
      };

      mov.getMitarbeiterData = function () {
        mov.mitarbeiter = StoreService.get("mitarbeiter");
        mov.mitarbeiter_cnt = mov.mitarbeiter.length;
      };

      mov.showMitarbeiter = function (mitarbeiter_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { mitarbeiterId: mitarbeiter_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.mitarbeiter-view", newParams);
      };

      mov.deleteMitarbeiter = function (mitarbeiter_id) {
        MitarbeiterService.delete({
          id: mitarbeiter_id,
        })
          .$promise.then(function (data) {
            var id_to_remove = mitarbeiter_id;
            var index = mov.mitarbeiter
              .map(function (item) {
                return item.id;
              })
              .indexOf(id_to_remove);

            mov.mitarbeiter.splice(index, 1);

            StoreService.set("mitarbeiter", mov.mitarbeiter);

            // show changed available Mitarbeiter
            mov.mitarbeiter_to_del = null;

            mov.modal.close();

            $state.reload();
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      mov.editMitarbeiter = function (mitarbeiter_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { mitarbeiterId: mitarbeiter_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.mitarbeiter-edit", newParams);
      };

      mov.addMitarbeiter = function () {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams);
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.mitarbeiter-add", newParams);
      };

      //init
      mov.mitarbeiter = null;
      mov.isready = false;

      mov.initFooTable = function () {
        mov.table.footable();
      };

      $scope.$watch(
        "mov.data",
        function () {
          mov.data = angular.copy(mov.data);
        },
        true
      );

      //get all available Mitarbeiter
      mov.getMitarbeiterData();
      mov.checkifready();
    },
  ],
});
