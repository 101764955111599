angular.
module('core.statistics').
factory('StatisticsService', ['$resource', 'Blob', '__env', '$http',
  function ($resource, Blob, __env, $http) {
    return $resource(__env.apiUrl + 'statistics/\.', {}, {
      'pdf': {
        method: 'GET',
        headers: {
          accept: 'application/pdf'
        },
        responseType: 'arraybuffer',
        cache: false,
        transformResponse: function (data, headers, status) {
          var pdf;
          var hdrs = headers()

          if (status == 204) {
            throw {
              'msg': 'Für diese Suchkriterien sind keine Daten verfügbar!'
            };
          }

          if (data) {
            pdf = new Blob([data], {
              type: 'application/pdf'
            });
          }
          return {
            response: pdf,
            filename: hdrs["content-disposition"].slice(22, hdrs["content-disposition"].length - 1),

          };

        }
      },
      'zip': {
        method: 'GET',
        headers: {
          accept: 'application/zip'
        },
        responseType: 'blob',
        cache: false,
        transformResponse: function (data, headers, status) {
          var zip;
          var hdrs = headers()

          if (status == 204) {
            throw {
              'msg': 'Für diese Suchkriterien sind keine Daten verfügbar!'
            };
          }

          if (data) {
            zip = new Blob([data], {
              type: 'application/zip'
            });
          }
          return {
            response: zip,
            filename: hdrs["content-disposition"].slice(22, hdrs["content-disposition"].length - 1),

          };
        }
      },
      'csv': {
        method: 'GET',
        headers: {
          accept: 'text/csv'
        },
        responseType: 'blob',
        cache: false,
        transformResponse: function (data, headers) {
          var csv;
          var hdrs = headers()


          if (data) {
            csv = new Blob([data], {
              type: 'text/csv'
            });
          }
          return {
            response: csv,
            filename: hdrs["content-disposition"].slice(22, hdrs["content-disposition"].length - 1),

          };
        }
      }
    });
  }
]);