angular.
module('profile').
component('profile', {
	templateUrl: 'static/angular_assets/profile/profile.template.html',
	controller: ['$scope',
		'$element',
		'$state',
		'$attrs',
		'store',
		'jwtHelper',
		'LeistungserbringerService',
		'VerbaendeService',
		'KostentraegerregionenService',
		'TarifregionenService',
		'KundenService',
		'ChangePasswordService',
		'$window',
		function ProfileController($scope,
			$element,
			$state,
			$attrs,
			store,
			jwtHelper,
			LeistungserbringerService,
			VerbaendeService,
			KostentraegerregionenService,
			TarifregionenService,
			KundenService,
			ChangePasswordService,
			$window
		) {

			// add proper CSS classes
			//$element.addClass('column is-half form');
			var prfl = this;

			isNumber = function (n) {
				return !isNaN(parseFloat(n)) && !isNaN(n - 0)
			}

			//	function for showing password change modal
			prfl.showModal = function () {
				var success_link = angular.element(document.querySelector('#password_success_modal'));
				success_link.addClass('is-active');
			};


			prfl.logout = function () {
				$window.sessionStorage.clear();
				$state.go('login');
			}




			//  function that loads data for select inputs
			prfl.getSelectData = function () {
				prfl.choices = {}

				LeistungserbringerService.query().$promise.then(function (data) {
					prfl.choices.leistungserbringer = data;
				}).catch(function (error) {});

				VerbaendeService.query().$promise.then(function (data) {
					prfl.choices.verbaende = data;
				}).catch(function (error) {});

				KostentraegerregionenService.query().$promise.then(function (data) {
					prfl.choices.kostentraegerregionen = data;
				}).catch(function (error) {});

				TarifregionenService.query().$promise.then(function (data) {
					prfl.choices.tarifregionen = data;
				}).catch(function (error) {});
			};


			prfl.getKundenData = function () {
				if (!prfl.choices.leistungserbringer || !prfl.choices.verbaende || !prfl.choices.kostentraegerregionen || !prfl.choices.tarifregionen) {
					setTimeout(prfl.getKundenData, 50);
				} else {
					KundenService.get({
						id: prfl.kunden_id
					}).$promise.then(function (data) {
						prfl.kunde = data;
						// set proper le-typ
						for (var i = 0; i < prfl.choices.leistungserbringer.length; i++) {
							if (prfl.choices.leistungserbringer[i].id === prfl.kunde.le_typ) {
								prfl.selections.le_typ = prfl.choices.leistungserbringer[i]
								break;
							}
						}

						// set proper verband
						for (var i = 0; i < prfl.choices.verbaende.length; i++) {
							if (prfl.choices.verbaende[i].id === prfl.kunde.verband) {
								prfl.selections.verband = prfl.choices.verbaende[i]
								break;
							}
						}

						// set proper kt-region
						for (var i = 0; i < prfl.choices.kostentraegerregionen.length; i++) {
							if (prfl.choices.kostentraegerregionen[i].id === prfl.kunde.kt_region) {
								prfl.selections.kt_region = prfl.choices.kostentraegerregionen[i]
								break;
							}
						}
						// set proper tarif-region
						for (var i = 0; i < prfl.choices.tarifregionen.length; i++) {
							if (prfl.choices.tarifregionen[i].id === prfl.kunde.tarif_region) {
								prfl.selections.tarif_region = prfl.choices.tarifregionen[i]
								break;
							}
						}

					}).catch(function (error) {
						// fix for "Possibly unhandled rejection"
					});
				}
			}


			prfl.checkifready = function () {
				if (!prfl.choices || !prfl.kunde) {
					setTimeout(prfl.checkifready, 50);
				} else {
					if (!prfl.choices.leistungserbringer || !prfl.choices.verbaende || !prfl.choices.kostentraegerregionen || !prfl.choices.tarifregionen) {
						setTimeout(prfl.checkifready, 50);
					} else {
						prfl.isready = true;
						// prevents browser from auto filling saved passwords!
						prfl.pwd = null
						prfl.pwd_rep = null
						// ###################################################
						$scope.$apply();
					}

				}
			}

			prfl.checkPasswordInput = function () {
				// reset errors
				prfl.errors = {}

				if (!prfl.pwd) {
					prfl.errors.pwd_missing = true;
				}

				if (!prfl.pwd_new) {
					prfl.errors.pwd_new_missing = true;
				}

				if (prfl.pwd_new && !prfl.pwd_rep) {
					prfl.errors.pwd_rep_missing = true;
				}

				if (prfl.pwd && prfl.pwd_new && prfl.pwd_rep) {
					if (prfl.pwd_new != prfl.pwd_rep) {
						prfl.errors.pwd_unequal = true;
					}
				}

				if (Object.keys(prfl.errors).length == 0) {
					return true
				} else {
					return false
				}

			}

			prfl.updatePassword = function () {
				if (prfl.checkPasswordInput()) {
					prfl.pwd_data.password_old = prfl.pwd
					prfl.pwd_data.password_new = prfl.pwd_new
					prfl.pwd_data.$save().then(
						function (success_response) {
							prfl.showModal();
						},
						function (error_response) {
							pwd_old_err = error_response.data.password_old
							pwd_new_err = error_response.data.password_new
							if (pwd_old_err) {
								prfl.errors.pwd_wrong = true;
							}
							if (pwd_new_err) {
								prfl.errors.pwd_new_wrong_0 = null
								prfl.errors.pwd_new_wrong_1 = null
								prfl.errors.pwd_new_wrong_2 = null
								prfl.errors.pwd_new_wrong_3 = null

								for (var i = 0; i < pwd_new_err.length; i++) {
									prfl.errors["pwd_new_wrong_" + i] = pwd_new_err[i]
								}
							}
						}
					);
				}
			}

			prfl.checkProfileInput = function () {
				// reset errors
				prfl.errors = {}

				if (!prfl.kunde.ik_num) {
					prfl.errors.ik_missing = true;
				} else {
					if (!isNumber(prfl.kunde.ik_num)) {
						prfl.errors.ik_notnumber = true;
					}
					if (prfl.kunde.ik_num.length != 9) {
						prfl.errors.ik_lengtherror = true;
					}
				}


				if (!prfl.kunde.praxis_name) {
					prfl.errors.praxis_missing = true;
				}


				if (!prfl.selections.le_typ) {
					prfl.errors.le_missing = true;
				}


				if (!prfl.selections.verband) {
					prfl.errors.verband_missing = true;
				}


				if (!prfl.selections.kt_region) {
					prfl.errors.kt_missing = true;
				}


				if (!prfl.selections.tarif_region) {
					prfl.errors.tarif_missing = true;
				}


				if (!prfl.kunde.vorname) {
					prfl.errors.vorname_missing = true;
				}


				if (!prfl.kunde.name) {
					prfl.errors.name_missing = true;
				}


				if (!prfl.kunde.strasse) {
					prfl.errors.strasse_missing = true;
				}


				if (!prfl.kunde.plz) {
					prfl.errors.plz_missing = true;
				} else {
					if (!isNumber(prfl.kunde.plz)) {
						prfl.errors.plz_notnumber = true;
					}
					if (prfl.kunde.plz.length != 5) {
						prfl.errors.plz_lengtherror = true;
					}
				}


				if (!prfl.kunde.ort) {
					prfl.errors.ort_missing = true;
				}


				if (!prfl.kunde.telefon) {
					prfl.errors.telefon_missing = true;
				}


				if (!prfl.kunde.email) {
					prfl.errors.email_missing = true;
				} else {
					var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
					if (!re.test(prfl.kunde.email)) {
						prfl.errors.email_invalid = true;
					}
				}

				if (!prfl.kunde.steuer_num) {
					prfl.errors.steuernum_missing = true;
				} else {
					if (prfl.kunde.steuer_num.length > 13) {
						prfl.errors.steuernum_lengtherror = true;
					}
				}


				if (!prfl.kunde.geldinstitut) {
					prfl.errors.geldinstitut_missing = true;
				}


				if (!prfl.kunde.iban) {
					prfl.errors.iban_missing = true;
				} else {
					if (prfl.kunde.iban.length > 34) {
						// TODO missing logic here
					}
				}




				if (Object.keys(prfl.errors).length == 0) {
					return true
				} else {
					return false
				}
			}

			prfl.saveProfile = function () {
				prfl.changes_success = false;
				if (prfl.checkProfileInput()) {
					prfl.kunde.le_typ = prfl.selections.le_typ.id;
					prfl.kunde.verband = prfl.selections.verband.id;
					prfl.kunde.kt_region = prfl.selections.kt_region.id;
					prfl.kunde.tarif_region = prfl.selections.tarif_region.id;
					KundenService.update({
						id: prfl.kunden_id
					}, prfl.kunde).$promise.then(
						function (success) {
							prfl.changes_success = true;
						},
						function (error) {
							console.log(error);
						}
					);
				}
			};


			// holds errors:
			prfl.errors = {};

			prfl.benutzername = store.get("currentUser");
			prfl.kunden_id = jwtHelper.decodeToken(store.get('access_token')).user_id
			prfl.kunde = null
			prfl.changes_success = false;

			prfl.pwd_data = new ChangePasswordService();
			prfl.pwd = null
			prfl.pwd_new = null
			prfl.pwd_rep = null

			prfl.selections = {}
			prfl.selections.le_typ = null;
			prfl.selections.verband = null;
			prfl.selections.kt_region = null;
			prfl.selections.tarif_region = null;

			prfl.isready = false;

			prfl.getSelectData();
			prfl.getKundenData();




			//only show profile if/once all data is ready
			prfl.checkifready();

		}
	],
});