angular.module("patientenView").component("patientenView", {
  templateUrl: "static/angular_assets/patienten/patienten_view/patienten_view.template.html",
  controller: [
    "$scope",
    "$state",
    "$location",
    "$anchorScroll",
    "store",
    "$uiRouterGlobals",
    "PatientenService",
    "KostentraegertypService",
    "VersicherungsStatusService",
    "jwtHelper",
    "KundenService",
    "KostentraegerService",
    "MitarbeiterService",
    "BefreiungenService",
    "$stateParams",

    function PatientenAddController(
      $scope,
      $state,
      $location,
      $anchorScroll,
      store,
      $uiRouterGlobals,
      PatientenService,
      KostentraegertypService,
      VersicherungsStatusService,
      jwtHelper,
      KundenService,
      KostentraegerService,
      MitarbeiterService,
      BefreiungenService,
      $stateParams
    ) {
      var cv = this;

      cv.init = function () {
        cv.isready = false;

        // read params
        cv.transparam = $uiRouterGlobals.params;
        cv.patienten_id = cv.transparam.patientenId;

        cv.befreiungen = null;
        // get related befreiungen
        cv.getBefreiungenData();

        // PATIENT
        cv.patient = null;

        cv.isready = false;

        // DATA lists
        cv.kostentraeger = null;
        cv.versicherungsstatus = null;
        cv.kostentraegertyp = null;

        // KUNDEN DATA
        cv.kunden_id = jwtHelper.decodeToken(store.get("access_token")).user_id;
        cv.kunde = null;
        // gets Kunden related data + Versicherungsstatus list data
        cv.getKundenData();
        // gets Kostentraeger_Typ list data
        cv.getKostentraegerTypData();

        // holds errors:
        cv.errors = {};

        // objects
        cv.geburtsdatum = null;
        cv.unfalltag = null;
        cv.anrede = ["Herr", "Frau", " "];

        // selected versicherungs_status
        cv.vers_status = null;
        // selected kostentraeger_typ
        cv.kt_typ = null;
        // selected kostentraeger
        cv.kt = null;

        cv.kk_ik_num = null;
        cv.kk_ik_exists = null;

        cv.getPatientenDetails();
        cv.checkifready();
      };

      cv.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      // functions
      cv.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      cv.getBefreiungenData = function () {
        BefreiungenService.query({ patient: cv.patienten_id })
          .$promise.then(function (data) {
            cv.befreiungen = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cv.getPatientenDetails = function () {
        if (!cv.kostentraegertyp || !cv.versicherungsstatus) {
          setTimeout(cv.getPatientenDetails, 50);
        } else {
          PatientenService.get({
            id: cv.patienten_id,
          })
            .$promise.then(function (data) {
              cv.patient = data;

              // get related mitarbeiter
              cv.getMitarbeiterData();

              // transform geburtsdatum/unfalltag string into date object
              cv.geburtsdatum = cv.str_to_date(cv.patient.geburtsdatum);

              if (cv.patient.unfalltag) {
                cv.unfalltag = cv.str_to_date(cv.patient.unfalltag);
              }

              // get MitarbeiterData
              //cv.getMitarbeiterData();

              // set proper kt_typ

              for (var i = 0; i < cv.kostentraegertyp.length; i++) {
                if (cv.kostentraegertyp[i].id === cv.patient.kt_typ) {
                  cv.kt_typ = cv.kostentraegertyp[i];
                  break;
                }
              }

              // get and set proper kostentraeger based on previous kt_typ

              if (["PBeaKK", "S", "BG"].indexOf(cv.patient.kt_typ) != -1) {
                cv.getKtRelatedData_nonreset();
                KostentraegerService.get({
                  id: cv.patient.kostentraeger,
                })
                  .$promise.then(function (data) {
                    cv.kt = data;

                    // set proper selected value (workaround for select component)
                    if (cv.patient.kt_typ == "PBeaKK") {
                      for (var i = 0; i < cv.kostentraeger.length; i++) {
                        if (cv.kostentraeger[i].id === cv.patient.kostentraeger) {
                          cv.kt = cv.kostentraeger[i];
                          break;
                        }
                      }
                    }
                  })
                  .catch(function (error) {
                    // fix for "Possibly unhandled rejection"
                  });
              }

              // set proper versicherungs_status
              if (cv.patient.vers_status) {
                for (var i = 0; i < cv.versicherungsstatus.length; i++) {
                  if (cv.versicherungsstatus[i].id === cv.patient.vers_status) {
                    cv.vers_status = cv.versicherungsstatus[i];
                    break;
                  }
                }
              }

              // set ik_num for KK
              if (cv.patient.kt_typ == "KK") {
                KostentraegerService.get({
                  id: cv.patient.kostentraeger,
                })
                  .$promise.then(function (data) {
                    cv.kt = data;
                    cv.kk_ik_num = data.ik_num;
                    cv.kk_ik_exists = true;
                  })
                  .catch(function (error) {
                    // fix for "Possibly unhandled rejection"
                  });
              }
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      cv.deletePatienten = function () {
        PatientenService.delete({
          id: cv.patienten_id,
        })
          .$promise.then(function (data) {
            // show changed available Patienten
            cv.returnToPreviousState();
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cv.editPatienten = function () {
        // set the location.hash to the id of
        // the element you wish to scroll to.
        $location.hash("top");

        // call $anchorScroll()
        $anchorScroll();

        var stateChainEntry = {
          state: $state.current.name,
          params: { patientenId: cv.patienten_id },
        };

        var newParams = Object.assign({}, $stateParams, { patientenId: cv.patienten_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.patienten-edit", newParams);
      };

      cv.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      cv.getKostentraegerTypData = function () {
        KostentraegertypService.query()
          .$promise.then(function (data) {
            cv.kostentraegertyp = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cv.getKundenData = function () {
        KundenService.get({
          id: cv.kunden_id,
        })
          .$promise.then(function (data) {
            cv.kunde = data;
            // forcing to run after KundenService promise is resolved
            cv.getVersicherungsStatusData();
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cv.getVersicherungsStatusData = function () {
        if (["03", "12", "13", "14", "16"].indexOf(cv.kunde.tarif_region) != -1) {
          var bundesland = "alt";
        } else {
          var bundesland = "neu";
        }

        VersicherungsStatusService.query({
          bundesland: bundesland,
        })
          .$promise.then(function (data) {
            cv.versicherungsstatus = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cv.getKtRelatedData = function () {
        // reset data
        cv.patient.kostentraeger = "";
        cv.kt = null;
        cv.patient.vers_nummer = "";
        cv.patient.vers_status = "";
        cv.vers_status = null;
        cv.patient.unfalltag = null;
        cv.kk_ik_exists = null;
        cv.kk_ik_num = null;
        cv.unfalltag = null;

        if (["PBeaKK", "S", "BG"].indexOf(cv.kt_typ.id) != -1) {
          KostentraegerService.query({
            kt_typ: cv.kt_typ.id,
          })
            .$promise.then(function (data) {
              cv.kostentraeger = data;
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      cv.getKtRelatedData_nonreset = function () {
        if (["PBeaKK", "S", "BG"].indexOf(cv.kt_typ.id) != -1) {
          KostentraegerService.query({
            kt_typ: cv.kt_typ.id,
          })
            .$promise.then(function (data) {
              cv.kostentraeger = data;
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      cv.check_kk_ik_num = function () {
        KostentraegerService.query({
          ik_num: cv.kk_ik_num,
        })
          .$promise.then(function (data) {
            if (data.length == 1) {
              cv.kt = data[0];
              cv.kk_ik_exists = true;
            } else {
              cv.kk_ik_exists = false;
            }
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cv.getMitarbeiterData = function () {
        MitarbeiterService.query()
          .$promise.then(function (data) {
            cv.mitarbeiter = data;
            cv.mitarbeiter_selected = data.filter(function (e) {
              return e.id == cv.patient.mitarbeiter;
            })[0];
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cv.checkifready = function () {
        if (!cv.kostentraegertyp || !cv.versicherungsstatus || !cv.patient || !cv.mitarbeiter || !cv.befreiungen) {
          setTimeout(cv.checkifready, 50);
        } else {
          cv.isready = true;
          $scope.$apply();
        }
      };

      cv.init();
    },
  ],
});
