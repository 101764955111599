angular.
  module('core.diagnose_tarif').
  factory('DiagnoseTarifService', ['$resource', '__env',
    function($resource, __env) {
      return $resource(__env.apiUrl + 'diagnosetarif/\:id/',{id: "@id", id_key: "@id_key"},
      {
        'update': { method:'PUT' }
      }
    );
    }
  ]);