angular.module("rezepteOverviewNav").component("rezepteOverviewNav", {
  templateUrl: "static/angular_assets/rezepte/rezepte_overview/overview_nav/overview_nav.template.html",
  controller: [
    "$scope",
    "$state",
    "$stateParams",
    function RezepteOverviewNavController($scope, $state, $stateParams) {
      var ronc = this;

      // NAVIGATION ############################

      ronc.gotoPatientenSearch = function () {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams);
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-overview-patient", newParams);
      };

      ronc.gotoFilterSearch = function () {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams);
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-overview-filter", newParams);
      };

      // #######################################

      ronc.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.rezepte-nav");
        }
      };
    },
  ],
});
