angular.module("rezepteAbrechnen").component("rezepteAbrechnen", {
  templateUrl: "static/angular_assets/rezepte/abrechnen/abrechnen.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "store",
    "jwtHelper",
    "RezepteHeaderService",
    "RememberService",
    "Popeye",
    "AbrechnungService",
    "AbrechnungStatusService",
    "StoreService",
    "$stateParams",
    function RezepteOverviewFilterController(
      $scope,
      $rootScope,
      $state,
      store,
      jwtHelper,
      RezepteHeaderService,
      RememberService,
      Popeye,
      AbrechnungService,
      AbrechnungStatusService,
      StoreService,
      $stateParams
    ) {
      var rab = this;

      rab.init = function () {
        rab.tables = {
          filter_search_data: {},
        };

        rab.filter = {
          kt_typ: "NOPP",
          datum_von: null,
          datum_bis: null,
        };

        rab.filtered_rows = null;
        rab.filtered_rows_length = 0;

        rab.kunde = jwtHelper.decodeToken(store.get("access_token")).user_id;
        rab.fromState = $rootScope.fromState;
        rab.savedSelections = RememberService.AbrechnungFilterSelections;

        //modal
        rab.abrechnen_modal = null;

        rab.rezepte = null;
        rab.rezepte_cnt = 0;
        rab.isready = false;

        rab.awaiting_response = false;

        rab.abrechnung_status = null;

        // get related rezepte first
        rab.getRezeptHeaderData();

        // get current abrechnung-status
        rab.getAbrechnungStatus();

        // check if ready to be displayed
        rab.checkifready();
      };

      rab.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      rab.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      rab.showRezept = function (header_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { headerId: header_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-overview-view", newParams);
      };

      rab.openAbrechnenModal = function () {
        var snapshot = FooTable.get("#rezepte_table").use(FooTable.Snapshot).snapshot;
        rab.filtered_rows_length = snapshot.length;

        // only start abrechnung if items found
        if (snapshot.length != 0) {
          rab.abrechnen_modal = Popeye.openModal({
            templateUrl: "static/angular_assets/rezepte/abrechnen/modals/abrechnen_modal.template.html",
            scope: $scope,
            modalClass: "no_close normal-modal-container",
            click: false,
            keyboard: false,
          });
        }
      };

      rab.closeAbrechnenModal = function () {
        rab.abrechnen_modal.close();
        rab.abrechnen_modal = null;
        rab.awaiting_response = false;
      };

      rab.createAbrechnung = function () {
        rab.awaiting_response = true;
        var snapshot = FooTable.get("#rezepte_table").use(FooTable.Snapshot).snapshot;
        rab.abrechnung = new AbrechnungService();

        rab.abrechnung.positionen = snapshot.length;
        rab.abrechnung.kunde = rab.kunde;
        rab.abrechnung.datum = rab.date_to_str(new Date());
        rab.abrechnung.positionen_data = [];

        // create filter string
        var filter_list = [rab.filter.kt_typ];

        if (rab.filter.datum_von) {
          filter_list.push(">=" + rab.date_to_str(rab.filter.datum_von));
        }

        if (rab.filter.datum_bis) {
          filter_list.push("<=" + rab.date_to_str(rab.filter.datum_bis));
        }

        rab.abrechnung.filter_used = filter_list.join(" + ");

        for (var i = 0; i < snapshot.length; i++) {
          rab.abrechnung.positionen_data.push(parseInt(snapshot[i].value.id));
        }

        rab.abrechnung.$save().then(
          function (success) {
            rab.reloadRezeptHeaderData();
          },
          function (error) {
            console.log(error);
          }
        );
      };

      rab.reloadRezeptHeaderData = function () {
        RezepteHeaderService.query()
          .$promise.then(function (data) {
            // change list to dict
            var dict_data = data.results.reduce(function (map, obj) {
              map[obj.id] = obj;
              return map;
            }, {});

            StoreService.set("rezepte", dict_data);
            rab.closeAbrechnenModal();
            $state.reload();
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rab.getRezeptHeaderData = function () {
        var rezepte = StoreService.get("rezepte");

        rab.rezepte = {};
        rab.rezepte_cnt = 0;

        for (key in rezepte) {
          if (rezepte.hasOwnProperty(key)) {
            if (rezepte[key].status == "Neu") {
              rab.rezepte[key] = rezepte[key];
              rab.rezepte_cnt += 1;
            }
          }
        }
      };

      rab.getAbrechnungStatus = function () {
        AbrechnungStatusService.get({}).$promise.then(function (data) {
          rab.abrechnung_status = data;
        });
      };

      rab.checkifready = function () {
        if (!rab.rezepte || !rab.abrechnung_status) {
          setTimeout(rab.checkifready, 50);
        } else {
          rab.isready = true;
        }
      };

      rab.updateFilter = function () {
        var filtering = FooTable.get("#rezepte_table").use(FooTable.Filtering); // get the filtering component for the table
        filtering.filter();
        rab.filtered_rows_length = FooTable.get("#rezepte_table").use(FooTable.Snapshot).snapshot.length;
      };

      rab.modify_kt_typ_filter = function () {
        var filtering = FooTable.get("#rezepte_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = rab.filter.kt_typ; // get the value to filter by

        if (filter === "ALL") {
          // if the value is "none" remove the filter
          filtering.removeFilter("kt_typ");
        } else if (filter === "NOPP") {
          filter = "-PP";
          filtering.addFilter("kt_typ", filter, ["kt_typ"]);
        } else {
          // otherwise add/update the filter.
          filter = '"' + filter + '"';
          filtering.addFilter("kt_typ", filter, ["kt_typ"]);
        }
      };

      rab.modify_datum_von_filter = function () {
        var filtering = FooTable.get("#rezepte_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = rab.filter.datum_von; // get the value to filter by

        if (!filter) {
          // if the value is "none" remove the filter
          filtering.removeFilter("datum_von");
        } else {
          // otherwise add/update the filter.

          filter = ">" + rab.date_to_str(filter);
          filtering.addFilter("datum_von", filter, ["datum"]);
        }
      };

      rab.modify_datum_bis_filter = function () {
        var filtering = FooTable.get("#rezepte_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = rab.filter.datum_bis; // get the value to filter by

        if (!filter) {
          // if the value is "none" remove the filter
          filtering.removeFilter("datum_bis");
        } else {
          // otherwise add/update the filter.

          filter = "<" + rab.date_to_str(filter);
          filtering.addFilter("datum_bis", filter, ["datum"]);
        }
      };

      rab.on_change_kt_typ = function () {
        rab.modify_kt_typ_filter();
        rab.updateFilter();
      };

      rab.on_change_datum_von = function () {
        rab.modify_datum_von_filter();
        rab.updateFilter();
      };

      rab.on_change_datum_bis = function () {
        rab.modify_datum_bis_filter();
        rab.updateFilter();
      };

      rab.initFooTable = function () {
        // custom dropdown filter
        FooTable.MyFiltering = FooTable.Filtering.extend({
          construct: function (instance) {
            this._super(instance);
            this.$filter_status = null;
          },

          $create: function () {
            this._super();
            var self = this;
            this.$filter_status = $(".btn-primary").on(
              "click",
              {
                self: self,
              },
              this._onFilterStatusChanged
            );
          },
          _onFilterStatusChanged: function (e) {
            rab.filter = {
              kt_typ: "NOPP",
              datum_von: null,
              datum_bis: null,
            };
            rab.on_change_kt_typ();
          },
        });

        rab.table.footable({
          components: {
            filtering: FooTable.MyFiltering,
          },
          on: {
            "before.ft.filtering": function (e, ft, filters) {
              rab.modify_kt_typ_filter();
              rab.modify_datum_von_filter();
              rab.modify_datum_bis_filter();
            },
          },
        });

        rab.on_change_kt_typ();
      };

      rab.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      $scope.$watch(
        "rab.data",
        function () {
          rab.data = angular.copy(rab.data);
        },
        true
      );

      // INIT
      rab.init();
    },
  ],
});
