angular.
module('core.settings').
factory('SettingsService', ['$resource', '__env',
  function ($resource, __env) {
    return $resource(__env.apiUrl + 'settings/\.', {}, {
      'update': {
        method: 'PUT'
      }
    });
  }
]);