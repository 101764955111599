angular.
  module('core.scroller').
  factory('ScrollerService',function(){
    return {
             registerScrollFunction: function(){

          angular.element(document).on('focus',"input, select, textarea", function(){

                var height = $(window).outerHeight();
                var top = $(this).offset().top;
                var pos = $('body').scrollTop();
                var move = height/2;
            
                if (top + 100 > height) {
                  angular.element("html, body").animate({ scrollTop: pos+move }, 400);       
                }
          })

             }
         };
});
