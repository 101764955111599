angular.module("vollprivattarifeEdit").component("vollprivattarifeEdit", {
  templateUrl:
    "static/angular_assets/vollprivattarife/vollprivattarife_edit/vollprivattarife_edit.template.html",
  controller: [
    "$scope",
    "$state",
    "VollprivatTarifService",
    "Popeye",
    "$stateParams",

    function VollprivattarifeEditController(
      $scope,
      $state,
      VollprivatTarifService,
      Popeye,
      $stateParams
    ) {
      var vpte = this;

      vpte.init = function () {

        vpte.vpt_id = $stateParams.vptId;

        if (!vpte.vpt_id) {
          $state.go("menu.nav");
        }

        vpte.errors = {
          tarif: [],
          bezeichnung: [],
          preis: [],
          abkuerzung: [],
          zeiteinheit: [],
        };

        vpte.previousValues = {
          bezeichnung: null,
          preis: null,
        };

        vpte.modal = null;

        vpte.awaiting_response = false;

        vpte.tarif = null;

        vpte.isready = false;
        
        vpte.getVollprivatpreisDetails();

        // check if page is ready to be displayed
        vpte.checkifready();
      };

      vpte.getVollprivatpreisDetails = function () {
        VollprivatTarifService.get({
          id: vpte.vpt_id,
        })
          .$promise.then(function (data) {
            vpte.tarif = data;
            vpte.tarif.zeiteinheit = vpte.tarif.zeiteinheit.toString();

            // Save previous values for later comparison
            vpte.previousValues.bezeichnung = vpte.tarif.bezeichnung;
            vpte.previousValues.preis = vpte.tarif.preis;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      vpte.openUpdateModal = function () {
        vpte.modal = Popeye.openModal({
          templateUrl:
            "static/angular_assets/vollprivattarife/vollprivattarife_edit/modals/adjust_open_prescriptions.template.html",
          scope: $scope,
          modalClass: "no_close extended-modal-container",
          click: false,
          keyboard: false,
        });
      };

      vpte.editOrShowModal = function () {
        // First check if values are valid
        var valid = vpte.checkInput();

        if (valid) {
          if (
            vpte.tarif.bezeichnung != vpte.previousValues.bezeichnung ||
            vpte.tarif.preis != vpte.previousValues.preis
          ) {
            vpte.openUpdateModal();
          } else {
            vpte.editVollprivatpreisDetails();
          }
        }
      };

      vpte.closeModal = function () {
        if (vpte.modal) {
          vpte.modal.close();
          vpte.modal = null;
        }
      };

      vpte.editVollprivatpreisDetails = function (updateOpenPrescriptions) {
        updateOpenPrescriptions =
          updateOpenPrescriptions === undefined
            ? false
            : updateOpenPrescriptions;

        var params = {
          id: vpte.vpt_id,
          updateOpenPrescriptions: updateOpenPrescriptions,
        };

        // Close modal if open
        vpte.closeModal();

        // Block UI
        vpte.awaiting_response = true;

        VollprivatTarifService.update(params, vpte.tarif).$promise.then(
          function (data) {
            vpte.awaiting_response = false;
            vpte.returnToPreviousState();
          }
        );
      };

      vpte.checkInput = function () {
        // reset current errors
        for (var error in vpte.errors) {
          vpte.errors[error] = [];
        }

        if (!vpte.tarif.bezeichnung) {
          vpte.errors.bezeichnung.push("Es wurde keine Bezeichnung angegeben!");
        } else {
          if (vpte.tarif.bezeichnung.indexOf(";") != -1) {
            vpte.errors.bezeichnung.push(
              "Semikolons dürfen nicht verwendet werden!"
            );
          }
        }

        if (!vpte.tarif.abkuerzung) {
          vpte.errors.abkuerzung.push("Es wurde keine Abkürzung angegeben!");
        } else {
          if (vpte.tarif.abkuerzung.length > 7) {
            vpte.errors.abkuerzung.push(
              "Abkürzung ist zu lang! Maximal 7 Zeichen sind erlaubt!"
            );
          }

          if (vpte.tarif.bezeichnung.indexOf(";") > -1) {
            vpte.errors.abkuerzung.push(
              "Semikolons dürfen nicht verwendet werden!"
            );
          }
        }

        if (!vpte.tarif.zeiteinheit) {
          vpte.errors.zeiteinheit.push("Es wurde keine Zeiteinheit angegeben!");
        } else {
          if (
            vpte.tarif.zeiteinheit.indexOf(",") != -1 ||
            vpte.tarif.zeiteinheit.indexOf(".") != -1
          ) {
            vpte.errors.zeiteinheit.push("Nur ganzzahlige Werte sind erlaubt!");
          }
          if (!isNumber(vpte.tarif.zeiteinheit)) {
            vpte.errors.zeiteinheit.push("Zeiteinheit ist keine gültige Zahl!");
          } else {
            if (parseFloat(vpte.tarif.zeiteinheit) < 0) {
              vpte.errors.zeiteinheit.push(
                "Zeiteinheit muss eine positive Zahl sein!"
              );
            }
          }
        }

        if (!vpte.tarif.preis) {
          vpte.errors.preis.push("Es wurde keine Preis angegeben!");
        } else {
          // replace ',' with '.'
          vpte.tarif.preis = vpte.tarif.preis.replace(",", ".");
          if (!isNumber(vpte.tarif.preis)) {
            vpte.errors.preis.push("Preis ist keine gültige Zahl!");
          } else {
            if (parseFloat(vpte.tarif.preis) < 0) {
              vpte.errors.preis.push("Preis muss eine positive Zahl sein!");
            }
          }
        }

        return vpte.checkErrors();
      };

      vpte.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in vpte.errors) {
          if (!vpte.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (vpte.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      vpte.checkifready = function () {
        if (!vpte.tarif) {
          setTimeout(vpte.checkifready, 50);
        } else {
          vpte.isready = true;
          $scope.$apply();
        }
      };

      isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      vpte.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      vpte.init();
    },
  ],
});
