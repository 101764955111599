angular.module("rezepteArchive").component("rezepteArchive", {
  templateUrl: "static/angular_assets/rezepte/archiv/rezepte_archive.template.html",
  controller: [
    "$rootScope",
    "$state",
    "StoreService",
    "RezepteHeaderService",
    "DTColumnDefBuilder",
    "$stateParams",
    function RezepteArchiveController(
      $rootScope,
      $state,
      StoreService,
      RezepteHeaderService,
      DTColumnDefBuilder,
      $stateParams
    ) {
      var rac = this;

      rac.search_term = function () {
        rac.dtInstance.DataTable.page.len();
      };

      rac.init = function () {
        rac.rezepte = null;
        rac.rezepte_cnt = 0;

        // loading flag used to display a line-scale-pulse-out loader
        rac.is_loading = false;

        // filter and search options/terms
        rac.filter = {
          name: "",
          vorname: "",
          kt_typ: "ALL",
          datum_von: null,
          datum_bis: null,
          status: "ALL",
          search: "",
          page_length: 10,
          page_start: 0,
        };

        // DataTable instance - can be used to access original DataTable functions and attributes
        rac.dtInstance = {};

        // DataTable options
        rac.dtOptions = {
          paginationType: "full",
          displayLength: 10,
          displayStart: 0,
          responsive: true,
          search: {
            search: "",
          },

          language: {
            decimal: "",
            emptyTable: "Bisher wurden keine Daten geladen",
            info: "Zeige _START_ bis _END_ von _TOTAL_ Einträgen",
            infoEmpty: "Zeige 0 bis 0 von 0 Einträgen",
            infoFiltered: "(gefiltert von insg. _MAX_ Einträgen)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Zeige _MENU_ Einträge",
            loadingRecords: "Laden...",
            processing: "Verarbeitung...",
            search: "Suche:",
            zeroRecords: "Keine passenden Einträge gefunden!",
            paginate: {
              first: "Erste",
              last: "Letzte",
              next: ">",
              previous: "<",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        };

        // DataTable Column Definitions
        rac.dtColumnDefs = [
          DTColumnDefBuilder.newColumnDef(0).withOption("type", "date-eu"),
          DTColumnDefBuilder.newColumnDef(3).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(4).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(6).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(7).withClass("none"),
          DTColumnDefBuilder.newColumnDef(8).withClass("none"),
          DTColumnDefBuilder.newColumnDef(9).withClass("none"),
          DTColumnDefBuilder.newColumnDef(10).withClass("none"),
          DTColumnDefBuilder.newColumnDef(12).notVisible(),
          DTColumnDefBuilder.newColumnDef(13).notSortable(),
        ];

        // check from which state the current state was accessed from
        // a) fromState rezepte nav - start with clean filters and table - ignore
        // b) fromState rezepte overview view - user is returning from an accessed rezept - set previous filters and data

        rac.fromState = $rootScope.fromState;

        if (rac.fromState == "menu.rezepte-overview-view") {
          var savedArchive = StoreService.get("rezepteArchiveData");

          rac.filter = savedArchive.filter;
          rac.rezepte = JSON.parse(JSON.stringify(savedArchive.rezepte));
          rac.rezepte_cnt = rac.dict_length(rac.rezepte);

          rac.dtOptions.search.search = rac.filter.search;
          rac.dtOptions.displayLength = rac.filter.page_length;
          rac.dtOptions.displayStart = rac.filter.page_start;
        }
      };

      rac.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      rac.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      rac.showRezept = function (header_id) {
        // TODO: rewrite to pass the filter data using $stateParams
        rac.filter.search = rac.dtInstance.DataTable.search();
        rac.filter.page_length = rac.dtInstance.DataTable.page.len();
        rac.filter.page_start = rac.dtInstance.DataTable.page() * rac.filter.page_length;

        StoreService.set("rezepteArchiveData", {
          filter: rac.filter,
          rezepte: rac.rezepte,
        });

        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { headerId: header_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-overview-view", newParams);
      };

      rac.dict_length = function (dict) {
        cnt = 0;

        for (key in dict) {
          if (dict.hasOwnProperty(key)) {
            cnt += 1;
          }
        }

        return cnt;
      };

      rac.getRezeptHeaderData = function (pat) {
        rac.is_loading = true;
        rac.rezepte_cnt = 0;

        // RESET SAVED SEARCH TERM
        if (rac.dtInstance["DataTable"]) {
          rac.dtOptions.search.search = "";
          rac.dtOptions.displayStart = 0;
          rac.dtInstance.DataTable.search("");
          rac.dtInstance.DataTable.page(0);
        }

        var params = {
          age: "old",
        };

        if (rac.filter.name.trim()) {
          params.name = rac.filter.name.trim();
        }

        if (rac.filter.vorname.trim()) {
          params.vorname = rac.filter.vorname.trim();
        }

        if (rac.filter.status != "ALL") {
          params.status = rac.filter.status;
        }

        if (rac.filter.kt_typ != "ALL") {
          params.kt_typ = rac.filter.kt_typ;
        }

        if (rac.filter.datum_von) {
          params.datum_von = rac.date_to_str(rac.filter.datum_von);
        }

        if (rac.filter.datum_bis) {
          params.datum_bis = rac.date_to_str(rac.filter.datum_bis);
        }

        var alt_status_keys = {
          Abgerechnet: "OK",
          Abgelehnt: "REJ",
        };

        // get data from DB rather than StoreService
        RezepteHeaderService.query(params)
          .$promise.then(function (data) {
            rac.rezepte = null;

            // change list to dict
            rac.rezepte = data.results.reduce(function (map, obj) {
              map[obj.id] = obj;
              return map;
            }, {});

            for (key in rac.rezepte) {
              if (rac.rezepte.hasOwnProperty(key)) {
                rac.rezepte_cnt += 1;
              }

              // set alternative UNIQUE/NON-OVERLAPPING status
              rac.rezepte[key].status_alt = alt_status_keys[rac.rezepte[key].status];
            }

            if (rac.rezepte_cnt > 1000) {
              setTimeout(function () {
                //do what you need here
                rac.is_loading = false;
              }, 5);
            } else {
              setTimeout(function () {
                //do what you need here
                rac.is_loading = false;
              }, 2);
            }
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
            console.log(error);
          });
      };

      rac.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      // INIT
      rac.init();
    },
  ],
});
