angular.
module('adminMessages').
component('adminMessages', {
	templateUrl: 'static/angular_assets/admin/admin_messages/admin_messages.template.html',
	controller: [
		'$scope',
		'KundenService',
		'AdminNachrichtService',
		'Popeye',
		function AdminMessagesController(
			$scope,
			KundenService,
			AdminNachrichtService,
			Popeye
		) {

			var amc = this;

			amc.init = function (){
				amc.isready = false
				amc.recipients = []
				amc.betreff = ""
				amc.text = ""
				amc.kunden = null
				amc.awaiting_response = false;
	
				amc.modal = null
	
				amc.message = new AdminNachrichtService()

				amc.getKunden();

				amc.checkifready();
			}



			

			amc.loadItems = function (query) {
				query = query.toLowerCase()

				var matched = amc.kunden.filter(function (entry) {
					var name = entry.bezeichnung.toLowerCase()
					var ik = entry.ik_num.toLowerCase()
					var name_match = name.indexOf(query) != -1;
					var ik_match = ik.indexOf(query) != -1;

					return (name_match || ik_match)
				});

				return matched
			}

			amc.getKunden = function () {

				KundenService.query().$promise.then(
					function (data) {
						amc.kunden = [
							{ik_num: 'Alle', bezeichnung: 'Alle Kunden'},
							{ik_num: 'KT-Region # 01', bezeichnung:'KT-Region: Schleswig-Holstein', kt_region:'01'},
							{ik_num: 'KT-Region # 02', bezeichnung:'KT-Region: Hamburg', kt_region:'02'},
							{ik_num: 'KT-Region # 03', bezeichnung:'KT-Region: Niedersachsen', kt_region:'03'},
							{ik_num: 'KT-Region # 04', bezeichnung:'KT-Region: Bremen', kt_region:'04'},
							{ik_num: 'KT-Region # 05', bezeichnung:'KT-Region: Nordrhein-Westfalen', kt_region:'05'},
							{ik_num: 'KT-Region # 06', bezeichnung:'KT-Region: Hessen', kt_region:'06'},
							{ik_num: 'KT-Region # 07', bezeichnung:'KT-Region: Rheinland-Pfalz', kt_region:'07'},
							{ik_num: 'KT-Region # 08', bezeichnung:'KT-Region: Baden-Württemberg', kt_region:'08'},
							{ik_num: 'KT-Region # 09', bezeichnung:'KT-Region: Bayern', kt_region:'09'},
							{ik_num: 'KT-Region # 10', bezeichnung:'KT-Region: Saarland', kt_region:'10'},
							{ik_num: 'KT-Region # 11', bezeichnung:'KT-Region: Berlin', kt_region:'11'},
							{ik_num: 'KT-Region # 12', bezeichnung:'KT-Region: Brandenburg', kt_region:'12'},
							{ik_num: 'KT-Region # 13', bezeichnung:'KT-Region: Mecklenburg-Vorpommern', kt_region:'13'},
							{ik_num: 'KT-Region # 14', bezeichnung:'KT-Region: Sachsen', kt_region:'14'},
							{ik_num: 'KT-Region # 15', bezeichnung:'KT-Region: Sachsen-Anhalt', kt_region:'15'},
							{ik_num: 'KT-Region # 16', bezeichnung:'KT-Region: Thüringen', kt_region:'16'},
						]

						for (var i = 0; i < data.length; i++){
							var d = {
								ik_num: data[i].ik_num,
								bezeichnung: data[i].name + ", " + data[i].vorname
							}
							amc.kunden.push(d);
						}

					}
				).catch(function (error) {
					// fix for "Possibly unhandled rejection"
				});
			}

			amc.sendMessage = function(){
				amc.awaiting_response = true

				amc.message.$save().then(
					function (success) {
						amc.openSuccessModal()
					},
					function (error) {
						console.log(error)
					}
				);
			}

			amc.openInformationModal = function() {
				amc.modal = Popeye.openModal({
					templateUrl: 'static/angular_assets/admin/admin_messages/modals/information_modal.template.html',
					scope: $scope,
					modalClass: "normal-modal-container",
				});
			}


			amc.openSuccessModal = function() {
				amc.modal = Popeye.openModal({
					templateUrl: 'static/angular_assets/admin/admin_messages/modals/success_modal.template.html',
					scope: $scope,
					modalClass: "no_close normal-modal-container",
					click: false,
					keyboard: false,
				});
			}


			amc.closeInformationModal = function () {
				amc.modal.close();
				amc.modal = null;
			}

			amc.closeSuccessModal = function () {
				amc.modal.close();
				amc.modal = null;

				amc.awaiting_response = false;

				// reset data
				amc.recipients = []
				amc.betreff = ""
				amc.text = ""
				amc.message = new AdminNachrichtService()
				
			}


			amc.createMessage = function() {

				var all = false
				var recipients = []
				var kt_regionen = []

				for (var i = 0; i < amc.recipients.length; i++){
					if (amc.recipients[i].ik_num == 'Alle'){
						all = true
						recipients = []
						break
					}

					else if (amc.recipients[i].ik_num.indexOf('KT-Region') != -1){
						kt_regionen.push(amc.recipients[i].kt_region)
					}

					else {
						recipients.push(amc.recipients[i].ik_num)
					}

					
				}

				amc.message.recipients = recipients;
				amc.message.betreff = amc.betreff;
				amc.message.text = amc.text;
				amc.message.all = all
				amc.message.kt_regionen = kt_regionen

				if (all || kt_regionen.length != 0){
					amc.openInformationModal();
				}
				else {
					amc.sendMessage();
				}

			}

			amc.checkifready = function () {
				if (!amc.kunden) {
					setTimeout(amc.checkifready, 50);
				} else {
					amc.isready = true;
				}
			}
			


			amc.init();


		}
	],
});