angular.module("patientenAdd").component("patientenAdd", {
  templateUrl: "static/angular_assets/patienten/patienten_add/patienten_add.template.html",
  controller: [
    "$scope",
    "$state",
    "$window",
    "$anchorScroll",
    "$timeout",
    "store",
    "PatientenService",
    "KostentraegertypService",
    "VersicherungsStatusService",
    "jwtHelper",
    "KundenService",
    "KostentraegerService",
    "MitarbeiterService",
    "$stateParams",

    function PatientenAddController(
      $scope,
      $state,
      $window,
      $anchorScroll,
      $timeout,
      store,
      PatientenService,
      KostentraegertypService,
      VersicherungsStatusService,
      jwtHelper,
      KundenService,
      KostentraegerService,
      MitarbeiterService,
      $stateParams
    ) {
      var cadd = this;

      cadd.regexp_text = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+']/g;
      cadd.regexp_text_num = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+'0-9]/g;
      cadd.regexp_text_all = /[^A-Za-zßöÖäÄüÜéÉèÈ.,:\\\*\-_#@+§&%$€~=| <>°'!?\/()\[\]\{\}\"0-9]/g;

      get_unique = function (arr) {
        return arr
          ? arr.reduce(function (a, b) {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, [])
          : null;
      };

      // functions

      cadd.init = function () {
        // holds errors
        cadd.errors = {
          anrede: [],
          name: [],
          vorname: [],
          geburtsdatum: [],
          strasse: [],
          plz: [],
          ort: [],
          kt_typ: [],
          unfalltag: [],
          bg_kt: [],
          kk_kt: [],
          pbs_kt: [],
          vers_status: [],
          vers_nummer: [],
          patient: [],
          alt_anrede: [],
          alt_name: [],
          alt_vorname: [],
          alt_strasse: [],
          alt_plz: [],
          alt_ort: [],
          zuzahlung_von: [],
          zuzahlung_bis: [],
          titel: [],
          alt_titel: [],
          info: [],
        };

        // focus first input element
        $window.document.getElementById("titel_input").focus();

        // init forms
        $scope.forms = {};

        // used for anchor scroll to first error
        cadd.error_scroll = null;
        $anchorScroll.yOffset = 200;

        cadd.kunden_id = jwtHelper.decodeToken(store.get("access_token")).user_id;
        cadd.kunde = null;
        cadd.getKundenData();

        cadd.anrede = ["Herr", "Frau", "-----"];
        cadd.kostentraegertyp = null;
        cadd.prev_kostentraegertyp = null;
        cadd.getKostentraegerTypData();

        cadd.kostentraeger = null;

        cadd.versicherungsstatus = null;
        cadd.vers_status = null;

        cadd.geburtsdatum = null;
        cadd.unfalltag = null;

        // prepare objects for Patient input
        cadd.patient = new PatientenService();

        // possible/mandatory data
        cadd.patient.kunde = "";
        cadd.patient.kt_typ = "";
        cadd.kt_typ = null;
        cadd.kt = null;
        cadd.patient.titel = "";
        cadd.patient.anrede = "-----";

        cadd.patient.name = "";
        cadd.patient.vorname = "";
        cadd.patient.geburtsdatum = "";
        cadd.patient.plz = "";
        cadd.patient.ort = "";
        cadd.patient.strasse = "";
        cadd.patient.telefon_priv = "";
        cadd.patient.telefon_mob = "";
        cadd.patient.telefon_arb = "";
        cadd.patient.info = "";

        // KK, S, PBeaKK related
        cadd.patient.vers_status = "";
        cadd.patient.vers_nummer = "";
        cadd.patient.kostentraeger = "";
        cadd.kk_ik_num = null;
        cadd.kk_ik_exists = null;

        // KK related
        cadd.patient.zuzahlung_v = null;
        cadd.patient.zuzahlung_b = null;
        cadd.zuzahlung_bis = null;
        cadd.zuzahlung_von = null;

        // BG related
        cadd.patient.unfalltag = null;

        // PP related
        cadd.patient.alt_rechnungsadresse = false;
        cadd.patient.alt_titel = "";
        cadd.patient.alt_anrede = "-----";
        cadd.patient.alt_name = "";
        cadd.patient.alt_vorname = "";
        cadd.patient.alt_plz = "";
        cadd.patient.alt_ort = "";
        cadd.patient.alt_strasse = "";
      };

      cadd.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      cadd.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      cadd.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in cadd.errors) {
          if (!cadd.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (cadd.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      cadd.set_error_scroll = function (loc) {
        if (!cadd.error_scroll) {
          cadd.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      cadd.checkInput = function () {
        // reset current errors
        for (var error in cadd.errors) {
          cadd.errors[error] = [];
        }

        // reset anchor scroll position
        cadd.error_scroll = null;

        // GENERIC data
        if (cadd.patient.anrede != "Herr" && cadd.patient.anrede != "Frau") {
          cadd.errors.anrede.push("Es wurde keine Auswahl getroffen!");
          cadd.set_error_scroll("anrede_loc");
        }

        //titel
        if (cadd.patient.titel) {
          var matches = get_unique(cadd.patient.titel.match(cadd.regexp_text));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            cadd.errors.titel.push(err_msg);
            cadd.set_error_scroll("titel_loc");
          }
        }

        //name
        if (!cadd.patient.name) {
          cadd.errors.name.push("Es wurde kein Name angegeben!");
          cadd.set_error_scroll("name_loc");
        } else {
          var matches = get_unique(cadd.patient.name.match(cadd.regexp_text));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            cadd.errors.name.push(err_msg);
            cadd.set_error_scroll("name_loc");
          }
        }

        //vorname
        if (!cadd.patient.vorname) {
          cadd.errors.vorname.push("Es wurde kein Vorname angegeben!");
          cadd.set_error_scroll("vorname_loc");
        } else {
          var matches = get_unique(cadd.patient.vorname.match(cadd.regexp_text));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            cadd.errors.vorname.push(err_msg);
            cadd.set_error_scroll("vorname_loc");
          }
        }

        //geburtsdatum
        if (!cadd.geburtsdatum) {
          cadd.errors.geburtsdatum.push("Es wurde kein Geburtsdatum angegeben!");
          cadd.set_error_scroll("geburtsdatum_loc");
        } else {
          lim_past = 1900;
          lim_future = parseInt(new Date().getFullYear());
          lim_month = parseInt(new Date().getMonth()) + 1;
          lim_day = parseInt(new Date().getDate());

          given_date = $scope.forms.geburtsdatum_form.geburtsdatum_input.$viewValue;
          given_year = parseInt(given_date.slice(0, 4));
          given_month = parseInt(given_date.slice(5, 7));
          given_day = parseInt(given_date.slice(8, 10));

          if (lim_past <= given_year && lim_future >= given_year) {
            if (
              given_year != lim_future ||
              (given_year == lim_future && given_month < lim_month) ||
              (given_year == lim_future && given_month == lim_month && given_day < lim_day)
            ) {
              cadd.patient.geburtsdatum = cadd.date_to_str(cadd.geburtsdatum);
            } else {
              cadd.errors.geburtsdatum.push("Geburtsdatum liegt in der Zukunft!");
              cadd.set_error_scroll("geburtsdatum_loc");
            }
          } else {
            cadd.errors.geburtsdatum.push("Ungültiges Datum angegeben!");
            cadd.set_error_scroll("geburtsdatum_loc");
          }
        }

        //strasse
        if (!cadd.patient.strasse) {
          cadd.errors.strasse.push("Es wurde keine Strasse angegeben!");
          cadd.set_error_scroll("strasse_loc");
        } else {
          var matches = get_unique(cadd.patient.strasse.match(cadd.regexp_text_num));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            cadd.errors.strasse.push(err_msg);
            cadd.set_error_scroll("strasse_loc");
          }
        }

        //plz
        if (!cadd.patient.plz) {
          cadd.errors.plz.push("Es wurde keine PLZ angegeben!");
          cadd.set_error_scroll("plz_loc");
        } else if (!cadd.isNumber(cadd.patient.plz)) {
          cadd.errors.plz.push("Ungültige PLZ! (nur Zahlen sind erlaubt)");
          cadd.set_error_scroll("plz_loc");
        } else if (cadd.patient.plz.length != 5) {
          cadd.errors.plz.push("PLZ ist nicht 5 Stellen lang!");
          cadd.set_error_scroll("plz_loc");
        }

        //ort
        if (!cadd.patient.ort) {
          cadd.errors.ort.push("Es wurde kein Ort angegeben!");
          cadd.set_error_scroll("ort_loc");
        } else {
          var matches = get_unique(cadd.patient.ort.match(cadd.regexp_text_num));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            cadd.errors.ort.push(err_msg);
            cadd.set_error_scroll("ort_loc");
          }
        }

        //info field
        if (cadd.patient.info) {
          var matches = get_unique(cadd.patient.info.match(cadd.regexp_text_all));
          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            cadd.errors.info.push(err_msg);
            cadd.set_error_scroll("info_loc");
          }
        }

        //kt_typ
        if (!cadd.kt_typ) {
          cadd.errors.kt_typ.push("Es wurde kein Kostenträger-Typ ausgewählt!");
          cadd.set_error_scroll("kt_typ_loc");
        } else {
          if (cadd.kt_typ.id == "BG") {
            if (!cadd.unfalltag) {
              cadd.errors.unfalltag.push("Es wurde kein Unfalltag ausgewählt!");
              cadd.set_error_scroll("unfalltag_loc");
            } else {
              lim_past = 1900;
              lim_future = parseInt(new Date().getFullYear());
              given_year = parseInt($scope.forms.unfalltag_form.unfalltag_input.$viewValue.slice(0, 4));

              if (lim_past <= given_year && lim_future >= given_year) {
                cadd.patient.unfalltag = cadd.date_to_str(cadd.unfalltag);
              } else {
                cadd.errors.unfalltag.push("Ungültiges Datum angegeben!");
                cadd.set_error_scroll("unfalltag_loc");
              }
            }

            if (!cadd.kt) {
              cadd.errors.bg_kt.push("Es wurde kein Kostenträger ausgewählt!");
              cadd.set_error_scroll("bg_kt_loc");
            }
          }

          if (cadd.kt_typ.id == "KK" || cadd.kt_typ.id == "PBeaKK" || cadd.kt_typ.id == "S") {
            if (cadd.kt_typ.id == "KK") {
              if (!cadd.kk_ik_num) {
                cadd.errors.kk_kt.push("Es wurde kein Kostenträger angegeben!");
                cadd.set_error_scroll("kk_kt_loc");
              } else {
                if (cadd.kk_ik_exists == null) {
                  cadd.errors.kk_kt.push("Kostenträger wurde nicht überprüft!");
                  cadd.set_error_scroll("kk_kt_loc");
                } else if (!cadd.kk_ik_exists) {
                  cadd.errors.kk_kt.push("Kostenträger existiert nicht!");
                  cadd.set_error_scroll("kk_kt_loc");
                }
              }

              if (cadd.zuzahlung_bis) {
                cadd.patient.zuzahlung_b = cadd.date_to_str(cadd.zuzahlung_bis);
              }

              if (cadd.zuzahlung_von) {
                cadd.patient.zuzahlung_v = cadd.date_to_str(cadd.zuzahlung_von);
              }

              if (cadd.zuzahlung_von && !cadd.zuzahlung_bis) {
                cadd.errors.zuzahlung_bis.push("Beide Zuzahlungsbefreiungs-Daten müssen angegeben werden!");
                cadd.set_error_scroll("kk_zzb_loc");
              }

              if (!cadd.zuzahlung_von && cadd.zuzahlung_bis) {
                cadd.errors.zuzahlung_bis.push("Beide Zuzahlungsbefreiungs-Daten müssen angegeben werden!");
                cadd.set_error_scroll("kk_zzv_loc");
              }

              if (cadd.zuzahlung_von && cadd.zuzahlung_bis) {
                var zzv = moment(cadd.zuzahlung_von);
                var zzb = moment(cadd.zuzahlung_bis);

                if (zzb.isBefore(zzv)) {
                  cadd.errors.zuzahlung_bis.push("Zuzahlung-Bis liegt vor Zuzahlung-Von!");
                }
              }
            }

            if (cadd.kt_typ.id == "PBeaKK" || cadd.kt_typ.id == "S") {
              if (!cadd.kt) {
                cadd.errors.pbs_kt.push("Es wurde kein Kostenträger ausgewählt!");
                cadd.set_error_scroll("pbs_kt_loc");
              }
            }

            if (!cadd.vers_status) {
              cadd.errors.vers_status.push("Es wurde kein Versicherungsstatus ausgewählt!");
              cadd.set_error_scroll("vers_status_loc");
            }

            if (!cadd.patient.vers_nummer) {
              cadd.errors.vers_nummer.push("Es wurde keine Versicherungsnummer angegeben!");
              cadd.set_error_scroll("vers_nummer_loc");
            }
          }

          //new additions to PP
          if (cadd.kt_typ.id == "PP" && cadd.patient.alt_rechnungsadresse) {
            // alt GENERIC data
            if (cadd.patient.alt_anrede != "Herr" && cadd.patient.alt_anrede != "Frau") {
              cadd.errors.alt_anrede.push("Es wurde keine Auswahl getroffen!");
              cadd.set_error_scroll("alt_anrede_loc");
            }

            //titel
            if (cadd.patient.alt_titel) {
              var matches = get_unique(cadd.patient.alt_titel.match(cadd.regexp_text));

              if (matches) {
                var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
                cadd.errors.alt_titel.push(err_msg);
                cadd.set_error_scroll("alt_titel_loc");
              }
            }

            //name
            if (!cadd.patient.alt_name) {
              cadd.errors.alt_name.push("Es wurde kein Name angegeben!");
              cadd.set_error_scroll("alt_name_loc");
            } else {
              var matches = get_unique(cadd.patient.alt_name.match(cadd.regexp_text));

              if (matches) {
                var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
                cadd.errors.alt_name.push(err_msg);
                cadd.set_error_scroll("alt_name_loc");
              }
            }

            //vorname
            if (!cadd.patient.alt_vorname) {
              cadd.errors.alt_vorname.push("Es wurde kein Vorname angegeben!");
              cadd.set_error_scroll("alt_vorname_loc");
            } else {
              var matches = get_unique(cadd.patient.alt_vorname.match(cadd.regexp_text));

              if (matches) {
                var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
                cadd.errors.alt_vorname.push(err_msg);
                cadd.set_error_scroll("alt_vorname_loc");
              }
            }

            //strasse
            if (!cadd.patient.alt_strasse) {
              cadd.errors.alt_strasse.push("Es wurde keine Strasse angegeben!");
              cadd.set_error_scroll("alt_strasse_loc");
            } else {
              var matches = get_unique(cadd.patient.alt_strasse.match(cadd.regexp_text_num));

              if (matches) {
                var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
                cadd.errors.alt_strasse.push(err_msg);
                cadd.set_error_scroll("alt_strasse_loc");
              }
            }

            //plz
            if (!cadd.patient.alt_plz) {
              cadd.errors.alt_plz.push("Es wurde keine PLZ angegeben!");
              cadd.set_error_scroll("alt_plz_loc");
            } else if (!cadd.isNumber(cadd.patient.alt_plz)) {
              cadd.errors.alt_plz.push("Ungültige PLZ! (nur Zahlen sind erlaubt)");
              cadd.set_error_scroll("alt_plz_loc");
            } else if (cadd.patient.alt_plz.length != 5) {
              cadd.errors.alt_plz.push("PLZ ist nicht 5 Stellen lang!");
              cadd.set_error_scroll("alt_plz_loc");
            }

            //ort
            if (!cadd.patient.alt_ort) {
              cadd.errors.alt_ort.push("Es wurde kein Ort angegeben!");
              cadd.set_error_scroll("alt_ort_loc");
            } else {
              var matches = get_unique(cadd.patient.alt_ort.match(cadd.regexp_text_num));

              if (matches) {
                var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
                cadd.errors.alt_ort.push(err_msg);
                cadd.set_error_scroll("alt_ort_loc");
              }
            }
          }
        }

        return cadd.checkErrors();
      };

      cadd.getKostentraegerTypData = function () {
        KostentraegertypService.query()
          .$promise.then(function (data) {
            cadd.kostentraegertyp = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cadd.getKundenData = function () {
        KundenService.get({
          id: cadd.kunden_id,
        })
          .$promise.then(function (data) {
            cadd.kunde = data;
            // forcing to run after KundenService promise is resolved
            cadd.getVersicherungsStatusData();
            cadd.getMitarbeiterData();
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cadd.getMitarbeiterData = function () {
        MitarbeiterService.query()
          .$promise.then(function (data) {
            cadd.mitarbeiter = data;
            cadd.mitarbeiter_selected = data[0];
            cadd.mitarbeiter_cnt = cadd.mitarbeiter.length;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cadd.getVersicherungsStatusData = function () {
        if (["03", "12", "13", "14", "16"].indexOf(cadd.kunde.tarif_region) != -1) {
          var bundesland = "alt";
        } else {
          var bundesland = "neu";
        }

        VersicherungsStatusService.query({
          bundesland: bundesland,
        })
          .$promise.then(function (data) {
            cadd.versicherungsstatus = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      cadd.getKtRelatedData = function () {
        // reset data
        cadd.patient.kostentraeger = "";
        cadd.kt = null;
        cadd.patient.vers_nummer = null;
        cadd.patient.vers_status = null;
        cadd.vers_status = null;
        cadd.patient.zuzahlung_b = null;
        cadd.zuzahlung_bis = null;
        cadd.patient.zuzahlung_v = null;
        cadd.zuzahlung_von = null;
        cadd.patient.unfalltag = null;
        cadd.kk_ik_exists = null;
        cadd.kk_ik_num = null;
        cadd.unfalltag = null;

        if (["PBeaKK", "S", "BG"].indexOf(cadd.kt_typ.id) != -1) {
          //cadd.prev_kostentraegertyp = cadd.patient.kt_typ.id
          KostentraegerService.query({
            kt_typ: cadd.kt_typ.id,
          })
            .$promise.then(function (data) {
              cadd.kostentraeger = data;
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      cadd.check_kk_ik_num = function () {
        cadd.errors.kk_kt = [];

        if (cadd.kk_ik_num) {
          if (cadd.kk_ik_num.length == 7) {
            cadd.kk_ik_num = "10" + cadd.kk_ik_num;
          }
          KostentraegerService.query({
            ik_num: cadd.kk_ik_num,
            kt_typ: "KK",
          })
            .$promise.then(function (data) {
              if (data.length == 1) {
                cadd.kt = data[0];
                cadd.kk_ik_exists = true;
                cadd.errors.kk_kt = [];
              } else {
                cadd.kk_ik_exists = false;
                cadd.errors.kk_kt.push("Kostenträger existiert nicht!");
              }
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      cadd.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      cadd.savePatienten = function () {
        if (cadd.checkInput()) {
          cadd.patient.kunde = cadd.kunden_id;
          cadd.patient.kt_typ = cadd.kt_typ.id;
          cadd.patient.mitarbeiter = cadd.mitarbeiter_selected.id;

          if (cadd.kt) {
            cadd.patient.kostentraeger = cadd.kt.id;
          }
          if (cadd.vers_status) {
            cadd.patient.vers_status = cadd.vers_status.id;
          }

          if (!cadd.patient.alt_rechnungsadresse) {
            //reset data to null in case user typed something in before
            cadd.patient.alt_titel = "";
            cadd.patient.alt_anrede = "-----";
            cadd.patient.alt_name = "";
            cadd.patient.alt_vorname = "";
            cadd.patient.alt_plz = "";
            cadd.patient.alt_ort = "";
            cadd.patient.alt_strasse = "";
          }

          cadd.patient.$save().then(
            function (success_response) {
              cadd.returnToPreviousState();
            },
            function (error_response) {
              var errors = error_response.data.errors;
              
              for (var error of errors) {
                if (error.id == "PatientExists"){
                  cadd.errors.name.push(error.msg)
                  cadd.errors.vorname.push("");
                  cadd.errors.geburtsdatum.push("");
                  cadd.set_error_scroll("name_loc");
                }
                else if (error.id == "VersnrExists"){
                  cadd.errors.vers_nummer.push(error.msg)
                  cadd.set_error_scroll("vers_nummer_loc");
                }
                else if (error.id == "BGPatientExists"){
                  cadd.errors.name.push(error.msg)
                  cadd.errors.vorname.push("");
                  cadd.errors.geburtsdatum.push("");
                  cadd.errors.unfalltag.push("");
                  cadd.set_error_scroll("name_loc");
                }
                else if (error.id == "CombinationError"){
                  cadd.errors.kt_typ.push(error.msg)
                  cadd.errors.name.push("");
                  cadd.errors.vorname.push("");
                  cadd.errors.geburtsdatum.push("");
                  cadd.set_error_scroll("kt_typ_loc");
                }
              }
            }
          );
        }
      };

      cadd.init();
    },
  ],
});
