angular.module("rezepteAddHeader").component("rezepteAddHeader", {
  templateUrl: "static/angular_assets/rezepte/rezepte_add_new/rezepte_add_header/rezepte_add_header.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "store",
    "jwtHelper",
    "$anchorScroll",
    "$timeout",
    "RezepteHeaderService",
    "PatientenService",
    "AerzteService",
    "IndikationsschluesselService",
    "VerordnungsartService",
    "RememberService",
    "SettingsService",
    "BefreiungenService",
    "Popeye",
    "StoreService",
    "DiagnoseService",
    "$stateParams",
    function RezepteAddController(
      $scope,
      $rootScope,
      $state,
      store,
      jwtHelper,
      $anchorScroll,
      $timeout,
      RezepteHeaderService,
      PatientenService,
      AerzteService,
      IndikationsschluesselService,
      VerordnungsartService,
      RememberService,
      SettingsService,
      BefreiungenService,
      Popeye,
      StoreService,
      DiagnoseService,
      $stateParams
    ) {
      var rah = this;

      rah.regexp_text = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+']/g;
      rah.regexp_text_num = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+'0-9]/g;
      rah.regexp_text_all = /[^A-Za-zßöÖäÄüÜéÉèÈ.,:\\\*\-_#@+§&%$€~=| <>°'!?\/()\[\]\{\}\"0-9\n]/g;
      rah.regexp_diagnose = /[^A-Za-zßöÖäÄüÜéÉèÈ.\*\-_#@§&%$€~=| <>!\/()\[\]\{\}0-9\n]/g;

      // !############ GENERIC FUNCTIONS ############

      get_unique = function (arr) {
        return arr
          ? arr.reduce(function (a, b) {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, [])
          : null;
      };

      rah.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      rah.icd_to_str = function (icd) {
        icd_str = "";
        for (i = 0; i < icd.length; i++) {
          icd_str += icd[i].id + ";";
        }
        return icd_str;
      };

      // returns written weekday based on provided date
      rah.getweekday = function (dt) {
        var days = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
        return days[dt.getDay()];
      };

      rah.dateDiffInDays = function (a, b) {
        // Discard the time and time-zone information.
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      };

      rah.getAge = function (bd_str) {
        var today = new Date();
        var birthDate = new Date(bd_str);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      };

      rah.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      // ! ###########################################
      // ! ARZT MODAL RELATED DATA ##################################

      rah.init_arzt_modal_data = function () {
        // new arzt modal
        rah.modal = null;

        rah.am_data = {};
        rah.am_data.errors = {
          arzt_num: [],
          betriebs_num: [],
          arzt_name: [],
          strasse: [],
          plz: [],
          ort: [],
        };

        rah.am_data.arzt = new AerzteService();

        rah.am_data.arzt.kunde = rah.rezeptHeader.kunde;
        rah.am_data.arzt.arzt_num = "";
        rah.am_data.arzt.betriebs_num = "";
        rah.am_data.arzt.name = "";
        rah.am_data.arzt.strasse = "";
        rah.am_data.arzt.plz = "";
        rah.am_data.arzt.ort = "";
        rah.am_data.arzt.telefon = "";
        rah.am_data.arzt.fax = "";
      };

      rah.checkArztErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in rah.am_data.errors) {
          if (!rah.am_data.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (rah.am_data.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      rah.checkArztInput = function () {
        // reset current errors
        for (var error in rah.am_data.errors) {
          rah.am_data.errors[error] = [];
        }

        // reset anchor scroll position
        rah.am_data.error_scroll = null;

        // arzt_num
        if (!rah.am_data.arzt.arzt_num) {
          rah.am_data.errors.arzt_num.push("Es wurde keine Arzt-Nummer angegeben!");
        } else {
          if (!rah.isNumber(rah.am_data.arzt.arzt_num)) {
            rah.am_data.errors.arzt_num.push("Eingabe ist keine gültige Nummer!");
          }

          if (rah.am_data.arzt.arzt_num.length != 9) {
            rah.am_data.errors.arzt_num.push("Arzt-Nummer muss eine 9-stellige Nummer sein!");
          }
        }

        // betriebs_num
        if (!rah.am_data.arzt.betriebs_num) {
          rah.am_data.errors.betriebs_num.push("Es wurde keine Betriebsnummer angegeben!");
        } else {
          if (!rah.isNumber(rah.am_data.arzt.betriebs_num)) {
            rah.am_data.errors.betriebs_num.push("Eingabe ist keine gültige Nummer!");
          }

          if (rah.am_data.arzt.betriebs_num.length != 9) {
            rah.am_data.errors.betriebs_num.push("Betriebsnummer muss eine 9-stellige Nummer sein!");
          }
          if (rah.am_data.arzt.betriebs_num == "999999999") {
            rah.am_data.errors.betriebs_num.push("Betriebsnummer 999999999 darf nicht verwendet werden!");
          }
        }

        // name
        if (!rah.am_data.arzt.name) {
          rah.am_data.errors.arzt_name.push("Es wurde kein Name angegeben!");
        } else {
          var matches = get_unique(rah.am_data.arzt.name.match(rah.regexp_text));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            rah.am_data.errors.arzt_name.push(err_msg);
          }
          if (rah.am_data.arzt.name.length > 90) {
            rah.am_data.errors.arzt_name.push("Name des Arztes ist zu lang! (> 90 Zeichen)");
          }
        }

        // strasse

        if (rah.am_data.arzt.strasse) {
          var matches = get_unique(rah.am_data.arzt.strasse.match(rah.regexp_text_num));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            rah.am_data.errors.strasse.push(err_msg);
          }
        }

        // plz
        if (rah.am_data.arzt.plz && rah.am_data.arzt.plz.length > 0) {
          //rah.am_data.errors.plz.push("Es wurde keine PLZ angegeben!");
          //rah.set_error_scroll('plz_loc');
          if (!rah.isNumber(rah.am_data.arzt.plz)) {
            rah.am_data.errors.plz.push("Ungültige PLZ! (nur Zahlen sind erlaubt)");
          } else if (rah.am_data.arzt.plz.length != 5) {
            rah.am_data.errors.plz.push("PLZ ist nicht 5 Stellen lang!");
          }
        }

        // ort

        if (rah.am_data.arzt.ort) {
          var matches = get_unique(rah.am_data.arzt.ort.match(rah.regexp_text_num));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            rah.am_data.errors.ort.push(err_msg);
          }
        }

        return rah.checkArztErrors();
      };

      rah.saveArzt = function () {
        if (rah.checkArztInput()) {
          rah.am_data.arzt.$save().then(
            function (success_response) {
              // set newly created arzt_num
              rah.general.arzt_num = rah.am_data.arzt.arzt_num;

              // check num
              rah.check_arzt_num();

              // delete data and close modal
              rah.closeModal();
            },
            function (error_response) {
              if (error_response["data"]["non_field_errors"][0] == "ArztExists") {
                rah.am_data.errors.arzt_num.push("Arzt mit dieser Arztnummer exisitert bereits!");
              }
            }
          );
        }
      };

      rah.openNewArztModal = function (information) {
        if (rah.general.arzt_num) {
          rah.am_data.arzt.arzt_num = rah.general.arzt_num;
        }

        rah.modal = Popeye.openModal({
          templateUrl: "static/angular_assets/rezepte/rezepte_add_new/rezepte_add_header/modals/arzt_add_modal.html",
          scope: $scope,
          modalClass: "extended-modal-container",
          click: false,
          keyboard: true,
        });
      };

      rah.closeModal = function () {
        rah.modal.close();
        rah.modal = null;
        rah.init_arzt_modal_data();
      };
      // ! ############################################################

      rah.getPatientenDetails = function () {
        // previously visited state
        rah.fromState = $rootScope.fromState;

        // if no parameters were provided return to main menu
        if (!$stateParams.patientenId && rah.fromState != "menu.rezepte-add-lines") {
          $state.go("menu.nav");
          return;
        }

        if ($stateParams.patientenId) {
          PatientenService.get({
            id: $stateParams.patientenId,
          })
            .$promise.then(function (data) {
              rah.patient = data;
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      rah.init = function () {
        // HACK: anti-pattern - using .then on the promise returned from PatientenService.get does not seem to work
        if (!rah.patient && rah.fromState != "menu.rezepte-add-lines") {
          setTimeout(rah.init, 50);
          return;
        }

        // holds errors
        rah.errors = {
          gebuehrenpflichtig: [],
          arzt: [],
          rezeptdatum: [],
          erfassungsdatum: [],
          verordnungsart: [],
          hausbesuch: [],
          therapiebericht: [],
          indikationsschluessel: [],
          genehmigungsnummer: [],
          genehmigungsdatum: [],
          icd10: [],
          privat_info_text: [],
          logo_erstbefund: [],
          diagnose: [],
          ls: [],
          ls_text: [],
          diagnosetext: [],
          frequenz: [],
          physiotherapiebeginn: [],
          behandlungsbedarf: [],
        };

        // used for anchor scroll to first error
        rah.error_scroll = null;
        $anchorScroll.yOffset = 200;

        rah.error_checking_flag = false;

        // token data
        token_data = jwtHelper.decodeToken(store.get("access_token"));

        // needed for access to date forms
        $scope.forms = {};

        // rezeptHeader object
        rah.rezeptHeader = null;

        // general object that holds data related to patient/rezeptHeader
        rah.general = {
          arzt_exists: null,
          ids_exists: null,

          // icd10 data
          icd10_data: null,

          // verordnugsart data
          verordnungsart: null,
          verordnungsart_data: null,
          verordnungsart_selected: null,

          // genehmigung
          genehmigung: false,
          genehmigungsnummer: null,
          genehmigungsart: null,
          genehmigungsdatum: null,

          // settings
          settings_data: null,

          // VPP and LOGOPÄDIE needed data
          le_typ: token_data.le_typ,
          verband: token_data.verband,
          kt_region: token_data.kt_region,
          tarif_region: token_data.tarif_region,

          //befreiungen
          befreiungen: null,
          aktive_befreiung: null,

          // heilmittelbereich
          heilmittelbereich: null,

          // frequenz
          frequenz: null,

          // blanko
          blankoDisabled: true,
          blankoLightCyclePhysio: false,
          blankoLightCyclePhysioVorrangig: 0,
          blankoLightCyclePhysioErgaenzend: 0,
          blankoLightCycleErgo: false,
          blankoLightCycleErgoGreenLimit: 0,
          blankoLightCycleErgoYellowLimit: 0,
        };

        // object that holds information whether a given input field is irrelevant and or disabled
        rah.is_irrelevant = {};
        rah.is_disabled = {};

        rah.isready = false;

        if (rah.fromState == "menu.rezepte-add-lines") {
          rah.rezeptHeader = RememberService.rezeptAddHeaderData.rezeptHeader;
          rah.patient = RememberService.rezeptAddHeaderData.patient;
          rah.general = RememberService.rezeptAddHeaderData.general;
        } else {
          // INFO: patient data set by getPatientenDetails() function

          // prepare objects for Header-Information input
          rah.rezeptHeader = new RezepteHeaderService();
          rah.rezeptHeader.kunde = token_data.user_id;

          rah.rezeptHeader.rezeptversion = "Neu";

          rah.rezeptHeader.patient = rah.patient.id;
          rah.rezeptHeader.name = rah.patient.name;
          rah.rezeptHeader.vorname = rah.patient.vorname;

          var from = rah.patient.geburtsdatum.split("-");
          rah.rezeptHeader.geburtsdatum = rah.patient.geburtsdatum;
          rah.general.geburtsdatum = from[2] + "." + from[1] + "." + from[0];

          rah.rezeptHeader.plz = rah.patient.plz;
          rah.rezeptHeader.ort = rah.patient.ort;
          rah.rezeptHeader.strasse = rah.patient.strasse;
          rah.rezeptHeader.vers_nummer = rah.patient.vers_nummer;
          rah.rezeptHeader.vers_status = rah.patient.vers_status;
          rah.rezeptHeader.unfalltag = rah.patient.unfalltag;

          rah.rezeptHeader.kostentraeger = rah.patient.kostentraeger;
          rah.general.kostentraeger_name = rah.patient.kostentraeger_name;
          rah.general.kostentraeger_ik_num = rah.patient.kostentraeger_ik_num;

          rah.rezeptHeader.gebuehrenpflichtig = null;
          rah.rezeptHeader.unfall = false;
          rah.rezeptHeader.bvg = false;
          rah.rezeptHeader.hausbesuch = null;
          rah.rezeptHeader.therapiebericht = null;
          rah.rezeptHeader.bemerkung = "";
          rah.rezeptHeader.privat_info_text = "";

          rah.rezeptHeader.discharge_management = false;

          rah.rezeptHeader.icd_code = "";
          rah.general.icdcodes = [];

          // arzt logic
          rah.general.arzt_num = null;
          rah.general.arzt = null;
          rah.rezeptHeader.arzt = null;

          // genehmigung logic
          rah.rezeptHeader.genehmigung = null;
          rah.rezeptHeader.genehmigungsnummer = null;
          rah.rezeptHeader.genehmigungsart = null;
          rah.rezeptHeader.genehmigungsdatum = null;

          // daten
          rah.general.rezeptdatum = null;
          rah.general.erfassungsdatum = null;
          rah.general.physiotherapiebeginn = null;
          rah.rezeptHeader.rezeptdatum = null;
          rah.rezeptHeader.erfassungsdatum = null;
          rah.rezeptHeader.physiotherapiebeginn = null;

          // indikationsschleussel
          rah.rezeptHeader.indikationsschluessel = null;
          rah.general.indikationsschluessel = null;
          rah.general.ids_key = null;

          // verordnungsart
          rah.rezeptHeader.verordnungsart = null;
          rah.general.verordnungsart_selected = null;

          // logopädie - erstbefund
          rah.rezeptHeader.logo_erstbefund = null;

          // heilmittelbereich
          rah.general.heilmittelbereich = StoreService.get("kunde").heilmittelbereich;

          //diagnose
          rah.rezeptHeader.diagnose = null;
          rah.general.diagnose = null;
          rah.general.diagnose_key = null;
          rah.general.diagnose_exists = null;

          //frequenz
          rah.rezeptHeader.frequenz = null;

          // dringender behandlungsbedarf
          rah.rezeptHeader.behandlungsbedarf = null;

          // Langfristiger Heilmittelbedarf  / Besonderer Verordnungsbedarf
          rah.rezeptHeader.langf = false;
          rah.rezeptHeader.besond = false;

          // leitsymptomatik
          rah.rezeptHeader.ls_a = false;
          rah.rezeptHeader.ls_b = false;
          rah.rezeptHeader.ls_c = false;
          rah.rezeptHeader.ls_x = false;
          rah.rezeptHeader.ls_x_text = "";

          // Blanko Verordnung
          rah.rezeptHeader.blanko = false;
          rah.general.blakoDisabled = true;

          rah.rezeptHeader.rezeptart = null;

          // get Settings data
          rah.getSettingsData();

          // get ICD-10 Codes
          rah.getICD10Data();

          // get Zuzahlungsbefreiungen
          rah.getBefreiungenData();

          // based on kt_typ omit certain irrelevant objects from being displayed
          //rah.setIrrelevant(rah.patient.kt_typ);

          rah.general.rezeptarten = null;
          rah.general.rezeptart_selected = null;
          rah.general.is_disabled = {};
          rah.general.is_irrelevant = {};

          // get related verordnungsart data first
          rah.getVerordnungsartData();
        }

        // init arzt modal related stuff
        rah.init_arzt_modal_data();

        // check if ready to be displayed
        rah.checkifready();
      };

      rah.check_zulassungsbefreiung = function () {
        rah.general.aktive_befreiung = null;

        if (rah.general.rezeptdatum) {
          var rzd = moment(rah.general.rezeptdatum);

          if (rzd.isValid()) {
            for (var i = 0; i < rah.general.befreiungen.length; i++) {
              var b = rah.general.befreiungen[i];
              var zzv = moment(b.befreit_von);
              var zzb = moment(b.befreit_bis);

              if (rzd.isSameOrAfter(zzv) && rzd.isSameOrBefore(zzb)) {
                rah.general.aktive_befreiung =
                  "Aktive Zulassungsbefreiung: " + zzv.format("DD.MM.YYYY") + " - " + zzb.format("DD.MM.YYYY");

                break;
              }
            }
          }
        }
      };

      // functions that will only be executed when previous state is not menu.rezepte-add-lines

      rah.set_rezeptart_defaults = function (kt_typ) {
        if (kt_typ == "KK") {
          rah.general.rezeptarten = [
            {
              id: "KK",
              bezeichnung: "Krankenkasse",
            },
            {
              id: "ZA",
              bezeichnung: "Zahnarzt",
            },
            {
              id: "KR",
              bezeichnung: "Kur",
            },
          ];
        } else if (kt_typ == "PP") {
          rah.general.rezeptarten = [
            {
              id: "PP",
              bezeichnung: "Privat",
            },
            {
              id: "VPP",
              bezeichnung: "Vollprivat",
            },
          ];
        } else if (kt_typ == "BG") {
          rah.general.rezeptarten = [
            {
              id: "BG",
              bezeichnung: "Berufsgenossenschaft",
            },
          ];
        } else if (kt_typ == "S") {
          rah.general.rezeptarten = [
            {
              id: "KK",
              bezeichnung: "Krankenkasse",
            },
            {
              id: "BW",
              bezeichnung: "Bundeswehr",
            },
            {
              id: "ZA",
              bezeichnung: "Zahnarzt",
            },
            {
              id: "KR",
              bezeichnung: "Kur",
            },
          ];
        } else if (kt_typ == "PBeaKK") {
          rah.general.rezeptarten = [
            {
              id: "KK",
              bezeichnung: "Krankenkasse",
            },
            {
              id: "ZA",
              bezeichnung: "Zahnarzt",
            },
            {
              id: "KR",
              bezeichnung: "Kur",
            },
          ];
        }

        // set default value
        rah.general.rezeptart_selected = rah.general.rezeptarten[0];

        rah.setRules();
      };

      // ? OK but could be improved
      rah.setRules = function () {
        var rezeptart = rah.general.rezeptart_selected.id;
        var kttyp = rah.patient.kt_typ;

        // reset veordnungsart_data
        rah.general.verordnungsart_data = null;

        // set rezeptart for header
        rah.rezeptHeader.rezeptart = rezeptart;

        // reset current errors first
        for (var error in rah.errors) {
          rah.errors[error] = [];
        }

        // remove icd10 red error-border
        try {
          angular
            .element(document.querySelector("#icd10_loc").querySelector("div > div"))
            .removeClass("is-error-border");
        } catch (error) {}

        // ZA diagnosetext
        rah.rezeptHeader.diagnosetext = null;

        if (rezeptart == "KK") {
          rah.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: false,
            indikationsschluessel: false,
            verordnungsart: false,
            diagnose: true,
          };

          rah.general.is_irrelevant = {
            rezeptversion: false,
            rezeptart: false,
            gebuehrenpflichtig: false,
            kk_info: false,
            vers_nummer_info: false,
            vers_status_info: false,
            unfalltag_info: true,
            unfall: false,
            bvg: false,
            therapiebericht: false,
            verordnungsart: false,
            indikationsschluessel: false,
            icdcodes: false,
            genehmigung: false,
            arzt: false,
            privat_zusatztext: true,
            hausbesuch: false,
            logo_erstbefund: true,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: false,
            physiotherapiebeginn: true,
            discharge_management: false,
            blanko: true,
          };

          // for PBeaKK disable gebuehrenpflichtig and set it to "Nein"
          if (kttyp == "PBeaKK") {
            rah.general.is_disabled.gebuehrenpflichtig = true;
            rah.general.is_irrelevant.gebuehrenpflichtig = true;
            rah.rezeptHeader.gebuehrenpflichtig = "Nein";
            rah.general.is_irrelevant.genehmigung = true;
            rah.general.is_irrelevant.icdcodes = true;
          } else if (kttyp == "KK") {
            rah.general.is_irrelevant.genehmigung = false;
          } else if (kttyp == "S") {
            rah.general.is_irrelevant.genehmigung = true;
            rah.general.is_irrelevant.icdcodes = true;
          }

          if (rah.rezeptHeader.rezeptversion == "Alt") {
            rah.general.is_irrelevant.frequenz = true;

            // Logopädie logic
            if (rah.general.le_typ == "23") {
              rah.general.is_irrelevant.logo_erstbefund = false;
            }

            rah.general.verordnungsart_data = rah.general.verordnungsart.filter(function (d) {
              return d.id != "99" || (d.id == "99" && kttyp == "S" && rezeptart == "KK");
            });

            // reset only if no data or if previous id was 99 (empty)
            if (!rah.rezeptHeader.verordnungsart || rah.rezeptHeader.verordnungsart == "99") {
              rah.general.verordnungsart_selected = null;
              rah.rezeptHeader.verordnungsart = null;
            }

            // reset ids_key
            rah.general.indikationsschluessel = null;
            rah.general.ids_exists = null;
            rah.general.ids_key = null;
            rah.rezeptHeader.indikationsschluessel = null;
          } else {

              if (["22", "26"].includes(rah.general.le_typ)) {
                rah.general.is_irrelevant.blanko = false;
              }

              rah.general.is_disabled.diagnose = false;
              rah.general.is_irrelevant.diagnose = false;
              rah.general.is_irrelevant.behandlungsbedarf = false;

              rah.general.is_irrelevant.verordnungsart = true;
              rah.general.is_irrelevant.indikationsschluessel = true;

              rah.general.diagnose = null;
              rah.general.diagnose_exists = null;
              rah.general.diagnose_key = null;
              rah.rezeptHeader.diagnose = null;

              rah.general.verordnungsart_selected = null;
              rah.rezeptHeader.verordnungsart = null;
          }
        } else if (rezeptart == "PP") {
          rah.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: true,
            indikationsschluessel: true,
            verordnungsart: true,
            diagnose: true,
          };

          rah.general.is_irrelevant = {
            rezeptversion: false,
            rezeptart: false,
            gebuehrenpflichtig: true,
            kk_info: true,
            vers_nummer_info: true,
            vers_status_info: true,
            unfalltag_info: true,
            unfall: true,
            bvg: true,
            therapiebericht: true,
            verordnungsart: true,
            indikationsschluessel: true,
            icdcodes: true,
            genehmigung: true,
            arzt: true,
            privat_zusatztext: false,
            hausbesuch: false,
            logo_erstbefund: true,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: true,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          // set unchangeable defaults
          rah.rezeptHeader.gebuehrenpflichtig = "Nein";
          rah.rezeptHeader.unfall = null;
          rah.rezeptHeader.bvg = null;
          rah.rezeptHeader.therapiebericht = null;

          rah.general.arzt_num = "999999999";
          rah.check_arzt_num();

          if (rah.rezeptHeader.rezeptversion == "Alt") {
            // Logopädie logic
            if (rah.general.le_typ == "23") {
              rah.general.is_irrelevant.logo_erstbefund = false;
            }

            rah.general.verordnungsart_data = rah.general.verordnungsart;
            rah.general.verordnungsart_selected = rah.general.verordnungsart.filter(function (a) {
              return a.id == "99";
            })[0];
            rah.rezeptHeader.verordnungsart = "99"; // 99 - Empty

            rah.general.ids_key = "PPPP";
            rah.check_indikationsschluessel();

            rah.general.diagnose = null;
            rah.general.diagnose_exists = null;
            rah.general.diagnose_key = null;
            rah.rezeptHeader.diagnose = null;
          } else {
            // reset ids_key
            rah.general.indikationsschluessel = null;
            rah.general.ids_exists = null;
            rah.general.ids_key = null;
            rah.rezeptHeader.indikationsschluessel = null;

            rah.general.verordnungsart_selected = null;
            rah.rezeptHeader.verordnungsart = null;

            rah.general.diagnose_key = "PPPP";
            rah.check_diagnose();
          }
        } else if (rezeptart == "VPP") {
          rah.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: true,
            indikationsschluessel: true,
            verordnungsart: true,
            diagnose: true,
          };

          rah.general.is_irrelevant = {
            rezeptversion: false,
            rezeptart: false,
            gebuehrenpflichtig: true,
            kk_info: true,
            vers_nummer_info: true,
            vers_status_info: true,
            unfalltag_info: true,
            unfall: true,
            bvg: true,
            therapiebericht: true,
            verordnungsart: true,
            indikationsschluessel: true,
            icdcodes: true,
            genehmigung: true,
            arzt: true,
            privat_zusatztext: false,
            hausbesuch: false,
            logo_erstbefund: true,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: true,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          // set unchangeable defaults
          rah.rezeptHeader.gebuehrenpflichtig = "Nein";
          rah.rezeptHeader.unfall = null;
          rah.rezeptHeader.bvg = null;
          rah.rezeptHeader.therapiebericht = null;

          rah.general.arzt_num = "999999999";
          rah.check_arzt_num();

          if (rah.rezeptHeader.rezeptversion == "Alt") {
            // Logopädie logic
            if (rah.general.le_typ == "23") {
              rah.general.is_irrelevant.logo_erstbefund = false;
            }

            rah.general.verordnungsart_data = rah.general.verordnungsart;
            rah.general.verordnungsart_selected = rah.general.verordnungsart.filter(function (a) {
              return a.id == "99";
            })[0];
            rah.rezeptHeader.verordnungsart = "99"; // 99 - Empty

            rah.general.ids_key = "PPPP";
            rah.check_indikationsschluessel();

            rah.general.diagnose = null;
            rah.general.diagnose_exists = null;
            rah.general.diagnose_key = null;
            rah.rezeptHeader.diagnose = null;
          } else {
            // reset ids_key
            rah.general.indikationsschluessel = null;
            rah.general.ids_exists = null;
            rah.general.ids_key = null;
            rah.rezeptHeader.indikationsschluessel = null;

            rah.general.verordnungsart_selected = null;
            rah.rezeptHeader.verordnungsart = null;

            rah.general.diagnose_key = "PPPP";
            rah.check_diagnose();
          }
        } else if (rezeptart == "BG") {
          rah.general.is_disabled = {
            rezeptart: true,
            gebuehrenpflichtig: true,
            indikationsschluessel: true,
            verordnungsart: true,
            diagnose: true,
          };

          rah.general.is_irrelevant = {
            rezeptversion: false,
            rezeptart: true,
            gebuehrenpflichtig: true,
            kk_info: false,
            vers_nummer_info: true,
            vers_status_info: true,
            unfalltag_info: false,
            unfall: true,
            bvg: true,
            therapiebericht: true,
            verordnungsart: true,
            indikationsschluessel: true,
            icdcodes: true,
            genehmigung: true,
            arzt: true,
            privat_zusatztext: true,
            hausbesuch: false,
            logo_erstbefund: true,
            diagnose: true,
            behandlungsbedarf: false,
            frequenz: false,
            physiotherapiebeginn: false,
            discharge_management: true,
            blanko: true,
          };

          rah.rezeptHeader.gebuehrenpflichtig = "Nein";
          rah.rezeptHeader.unfall = null;
          rah.rezeptHeader.bvg = null;
          rah.rezeptHeader.therapiebericht = null;

          rah.general.arzt_num = "999999999";
          rah.check_arzt_num();

          if (rah.rezeptHeader.rezeptversion == "Alt") {
            rah.general.is_irrelevant.frequenz = true;

            // Logopädie logic
            if (rah.general.le_typ == "23") {
              rah.general.is_irrelevant.logo_erstbefund = false;
            }

            rah.general.verordnungsart_data = rah.general.verordnungsart;
            rah.general.verordnungsart_selected = rah.general.verordnungsart.filter(function (a) {
              return a.id == "99";
            })[0];
            rah.rezeptHeader.verordnungsart = "99"; // 99 - Empty

            rah.general.ids_key = "BGBG";
            rah.check_indikationsschluessel();

            rah.general.diagnose = null;
            rah.general.diagnose_exists = null;
            rah.general.diagnose_key = null;
            rah.rezeptHeader.diagnose = null;
          } else {
            // reset ids_key
            rah.general.indikationsschluessel = null;
            rah.general.ids_exists = null;
            rah.general.ids_key = null;
            rah.rezeptHeader.indikationsschluessel = null;

            rah.general.verordnungsart_selected = null;
            rah.rezeptHeader.verordnungsart = null;

            rah.general.diagnose_key = "BGBG";
            rah.check_diagnose();
          }
        } else if (rezeptart == "BW") {
          rah.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: true,
            indikationsschluessel: true,
            verordnungsart: true,
            diagnose: true,
          };

          rah.general.is_irrelevant = {
            rezeptversion: false,
            rezeptart: false,
            gebuehrenpflichtig: true,
            kk_info: false,
            vers_nummer_info: false,
            vers_status_info: false,
            unfalltag_info: true,
            unfall: false,
            bvg: false,
            therapiebericht: false,
            verordnungsart: true,
            indikationsschluessel: true,
            icdcodes: true,
            genehmigung: true,
            arzt: true,
            privat_zusatztext: true,
            hausbesuch: false,
            logo_erstbefund: true,
            diagnose: true,
            behandlungsbedarf: false,
            frequenz: false,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          // set unchangeable defaults
          rah.rezeptHeader.gebuehrenpflichtig = "Nein";

          rah.general.arzt_num = "999999999";
          rah.check_arzt_num();

          if (rah.rezeptHeader.rezeptversion == "Alt") {
            rah.general.is_irrelevant.frequenz = true;

            // Logopädie logic
            if (rah.general.le_typ == "23") {
              rah.general.is_irrelevant.logo_erstbefund = false;
            }

            rah.general.verordnungsart_data = rah.general.verordnungsart;
            rah.general.verordnungsart_selected = rah.general.verordnungsart.filter(function (a) {
              return a.id == "99";
            })[0];
            rah.rezeptHeader.verordnungsart = "99"; // 99 - Empty

            rah.general.ids_key = "BWBW";
            rah.check_indikationsschluessel();

            rah.general.diagnose = null;
            rah.general.diagnose_exists = null;
            rah.general.diagnose_key = null;
            rah.rezeptHeader.diagnose = null;
          } else {
            // reset ids_key
            rah.general.indikationsschluessel = null;
            rah.general.ids_exists = null;
            rah.general.ids_key = null;
            rah.rezeptHeader.indikationsschluessel = null;

            rah.general.verordnungsart_selected = null;
            rah.rezeptHeader.verordnungsart = null;

            rah.general.diagnose_key = "BWBW";
            rah.check_diagnose();
          }
        } else if (rezeptart == "KR") {
          rah.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: false,
            indikationsschluessel: true,
            verordnungsart: true,
            diagnose: true,
          };

          rah.general.is_irrelevant = {
            rezeptversion: false,
            rezeptart: false,
            gebuehrenpflichtig: false,
            kk_info: false,
            vers_nummer_info: false,
            vers_status_info: false,
            unfalltag_info: true,
            unfall: false,
            bvg: false,
            therapiebericht: false,
            verordnungsart: true,
            indikationsschluessel: true,
            icdcodes: true,
            genehmigung: true,
            arzt: false,
            privat_zusatztext: true,
            hausbesuch: false,
            logo_erstbefund: true,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: false,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          if (kttyp == "PBeaKK") {
            rah.general.is_disabled.gebuehrenpflichtig = true;
            rah.general.is_irrelevant.gebuehrenpflichtig = true;
            rah.rezeptHeader.gebuehrenpflichtig = "Nein";
            rah.general.is_irrelevant.genehmigung = true;
            rah.general.is_irrelevant.icdcodes = true;
          }

          if (rah.rezeptHeader.rezeptversion == "Alt") {
            rah.general.is_irrelevant.frequenz = true;

            // Logopädie logic
            if (rah.general.le_typ == "23") {
              rah.general.is_irrelevant.logo_erstbefund = false;
            }

            rah.general.verordnungsart_data = rah.general.verordnungsart;
            rah.general.verordnungsart_selected = rah.general.verordnungsart.filter(function (a) {
              return a.id == "99";
            })[0];
            rah.rezeptHeader.verordnungsart = "99"; // 99 - Empty

            // set default ids_key
            rah.general.ids_key = "KURE";
            rah.check_indikationsschluessel();

            rah.general.diagnose = null;
            rah.general.diagnose_exists = null;
            rah.general.diagnose_key = null;
            rah.rezeptHeader.diagnose = null;
          } else {
            // * NEW / NEU

            rah.general.indikationsschluessel = null;
            rah.general.ids_exists = null;
            rah.general.ids_key = null;
            rah.rezeptHeader.indikationsschluessel = null;

            rah.general.is_irrelevant.behandlungsbedarf = false;

            rah.general.diagnose_key = "KURE";
            rah.check_diagnose();
          }
        } else if (rezeptart == "ZA") {
          rah.general.is_disabled = {
            rezeptart: false,
            gebuehrenpflichtig: false,
            indikationsschluessel: false,
            verordnungsart: false,
            diagnose: true,
          };

          rah.general.is_irrelevant = {
            rezeptversion: false,
            rezeptart: false,
            gebuehrenpflichtig: false,
            kk_info: false,
            vers_nummer_info: false,
            vers_status_info: false,
            unfalltag_info: true,
            unfall: false,
            bvg: false,
            therapiebericht: false,
            verordnungsart: false,
            indikationsschluessel: false,
            icdcodes: true,
            genehmigung: true,
            arzt: false,
            privat_zusatztext: true,
            hausbesuch: false,
            logo_erstbefund: true,
            diagnose: true,
            behandlungsbedarf: true,
            frequenz: false,
            physiotherapiebeginn: true,
            discharge_management: true,
            blanko: true,
          };

          // reset ids_key
          rah.general.indikationsschluessel = null;
          rah.general.ids_exists = null;
          rah.general.ids_key = null;
          rah.rezeptHeader.indikationsschluessel = null;
          rah.general.diagnose = null;
          rah.general.diagnose_exists = null;
          rah.general.diagnose_key = null;
          rah.rezeptHeader.diagnose = null;

          if (kttyp == "PBeaKK") {
            rah.general.is_disabled.gebuehrenpflichtig = true;
            rah.general.is_irrelevant.gebuehrenpflichtig = true;
            rah.rezeptHeader.gebuehrenpflichtig = "Nein";
            rah.general.is_irrelevant.genehmigung = true;
            rah.general.is_irrelevant.icdcodes = true;
          }

          if (rah.rezeptHeader.rezeptversion == "Alt") {
            rah.general.is_irrelevant.frequenz = true;

            // Logopädie logic
            if (rah.general.le_typ == "23") {
              rah.general.is_irrelevant.logo_erstbefund = false;
            }

            // remove '99'-empty from selectable verordnungsart_data
            rah.general.verordnungsart_data = rah.general.verordnungsart.filter(function (d) {
              return d.id != "99";
            });
            rah.general.verordnungsart_selected = null;
            rah.rezeptHeader.verordnungsart = null;
          } else {

              if (["22", "26"].includes(rah.general.le_typ)) {
                rah.general.is_irrelevant.blanko = false;
              }

              rah.general.is_disabled.diagnose = false;
              rah.general.is_irrelevant.diagnose = false;
              rah.general.is_irrelevant.behandlungsbedarf = false;

              rah.general.is_irrelevant.genehmigung = true;
              rah.general.is_irrelevant.verordnungsart = true;
              rah.general.is_irrelevant.indikationsschluessel = true;
          }
        }
      };

      rah.set_default_genehmigungsart = function () {
        if (!rah.general.genehmigungsart) {
          rah.general.genehmigungsart = "Einzel (B1)";
        }
        // reset genehmigungsart if false
        if (!rah.general.genehmigung) {
          rah.general.genehmigungsart = null;
        }
      };

      rah.set_error_scroll = function (loc) {
        if (!rah.error_scroll) {
          rah.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      rah.loadItems = function (query) {
        query = query.toUpperCase();

        var matched = rah.general.icd10_data.filter(function (code) {
          return code.id.indexOf(query) != -1;
        });
        return matched;
      };

      rah.check_arzt_num = function () {
        rah.errors.arzt = [];
        if (rah.general.arzt_num) {
          // '999999999' only valid for BG, PP
          if (
            rah.general.arzt_num == "999999999" &&
            !["BG", "PP", "VPP", "BW"].includes(rah.general.rezeptart_selected.id) &&
            rah.patient.kt_typ != "S"
          ) {
            rah.general.arzt_exists = false;
            rah.errors.arzt.push("Angegebener Arzt darf nicht verwendet werden!");
          } else {
            AerzteService.get({
              arztNum: rah.general.arzt_num,
            })
              .$promise.then(function (data) {
                rah.general.arzt = data;
                rah.general.arzt_exists = true;
              })
              .catch(function (error) {
                rah.general.arzt_exists = false;
                rah.errors.arzt.push("Angegebener Arzt existiert nicht!");
              });
          }
        } else {
          rah.general.arzt_exists = false;
          rah.errors.arzt.push("Es wurde kein Arzt angegeben!");
        }
      };

      // TODO: Remove this function
      rah.verordnungsart_check_ids = function () {
        if (rah.general.ids_key) {
          rah.check_indikationsschluessel();
        }

        if (rah.general.verordnungsart_selected) {
          if (rah.general.verordnungsart_selected.id != "10") {
            rah.general.genehmigung = false;
            rah.general.genehmigungsnummer = null;
            rah.general.genehmigungsart = null;
            rah.general.genehmigungsdatum = null;
          }
        }
      };


      rah.resetLightCycle = function () {
        rah.general.blankoLightCyclePhysio = false;
        rah.general.blankoLightCyclePhysioVorrangig = 0;
        rah.general.blankoLightCyclePhysioErgaenzend = 0;

        rah.general.blankoLightCycleErgo = false;
        rah.general.blankoLightCycleErgoGreenLimit = 0;
        rah.general.blankoLightCycleErgoYellowLimit = 0;
      }

      rah.setLightCycleErgo = function (greenLimit, yellowLimit){
        // Red light cycle phase starts after the yellow limit
        rah.general.blankoLightCycleErgo = true;
        rah.general.blankoLightCycleErgoGreenLimit = greenLimit;
        rah.general.blankoLightCycleErgoYellowLimit = yellowLimit;
      }

      rah.setLightCyclePhysio = function (enable, vorrangigLimit, ergaenzendLimit) {
        rah.general.blankoLightCyclePhysio = enable;
        rah.general.blankoLightCyclePhysioVorrangig = vorrangigLimit;
        rah.general.blankoLightCyclePhysioErgaenzend = ergaenzendLimit;
      }

      rah.check_blanko = function (autoSet) {

        // Default value for autoSet is true
        var autoSet = (autoSet === undefined) ? true : autoSet;

        const leTyp = rah.general.le_typ;
        const leTypDiagnosisLkp = {
          "22": ["EX"],
          "26": ["SB1", "PS3", "PS4"]
        };

        // Only responsible for enabling/disabling blanko field
        if (rah.general.is_irrelevant.blanko || !rah.general.diagnose_exists || rah.rezeptHeader.discharge_management) {
          rah.rezeptHeader.blanko = false;
          rah.general.blankoDisabled = true;
          rah.resetLightCycle();
          return;
        }

        // Two cases where blanko is enabled
        // Requires at least the Diagnosis to be set
        const diagnosis = rah.general.diagnose.id;

        if (leTypDiagnosisLkp[leTyp] && leTypDiagnosisLkp[leTyp].includes(diagnosis)) {
          // Check "blankoEntries" property on the diagnosis to see if there are any ICD10 code entries required
          var blankoEntries = rah.general.diagnose.blankoEntries;

          if (blankoEntries.length == 0) {
            // No ICD10 codes required, enable blanko
            rah.general.blankoDisabled = false;

            // Automatically set blanko to true
            if (autoSet) {
              rah.rezeptHeader.blanko = true;
            }

            // Light cycle is relevant for Ergo (26) but only if langf or besond is false
            if (leTyp == "26" && !rah.rezeptHeader.langf && !rah.rezeptHeader.besond){
              if (diagnosis == "SB1") {
                rah.setLightCycleErgo(128, 176);
              }
              else {
                rah.setLightCycleErgo(176, 200);
              }
            }
            else {
              // Otherwise light cycle is not relevant
              rah.resetLightCycle();
            }

          } else {
            // We need to check if the required ICD10 codes are set
            if (!rah.general.icdcodes || rah.general.icdcodes.length === 0) {
              rah.general.blankoDisabled = true;
              rah.rezeptHeader.blanko = false;
              rah.resetLightCycle();
            }
            else {
              var matches = [];
              var icdCodeExists = function(code) {
                return rah.general.icdcodes.some(function(icd) {
                  return icd.id === code;
                });
              };

              for (var i = 0; i < blankoEntries.length; i++) {
                var entry = blankoEntries[i];
                
                if (
                  (entry.icd10_b === null && icdCodeExists(entry.icd10_a)) || 
                  (entry.icd10_b !== null && icdCodeExists(entry.icd10_a) && icdCodeExists(entry.icd10_b))
                ) {
                  matches.push(entry);
                }
              }

              var hasMatch = matches.length > 0;
          
              rah.general.blankoDisabled = !hasMatch;
              if (autoSet) {
                rah.rezeptHeader.blanko = hasMatch
              }

              if (rah.rezeptHeader.blanko) {
                // Select matched entry with max lc_vorrangig_limit
                var matchedEntry = matches.reduce(function(prev, current) {
                  return (prev.lc_vorrangig_limit > current.lc_vorrangig_limit) ? prev : current
                });
                
                rah.setLightCyclePhysio(matchedEntry.light_cycle, matchedEntry.lc_vorrangig_limit, matchedEntry.lc_ergaenzend_limit);
              } else {
                rah.resetLightCycle();
              }

            }
          }
        } else {
          // Diagnosis does not match the required diagnosis for blanko
          rah.rezeptHeader.blanko = false;
          rah.general.blankoDisabled = true;
          rah.resetLightCycle();
        }
      };


      rah.check_diagnose = function () {
        rah.errors.diagnose = [];

        if (rah.general.diagnose_key) {
          var same = false;
          if (rah.general.diagnose) {
            if (rah.general.diagnose.id == rah.general.diagnose_key) {
              same = true;
            }
          }

          if (!same) {
            rah.rezeptHeader.ls_a = false;
            rah.rezeptHeader.ls_b = false;
            rah.rezeptHeader.ls_c = false;
            rah.rezeptHeader.ls_x = false;
            rah.rezeptHeader.ls_x_text = "";

            DiagnoseService.get({
              id: rah.general.diagnose_key,
              rezeptart: rah.general.rezeptart_selected.id,
              bereich: rah.general.heilmittelbereich,
            })
              .$promise.then(function (data) {
                if (rah.general.diagnose_key == "9999" && rah.patient.kt_typ != "S") {
                  rah.general.diagnose_exists = false;
                  if (rah.general.rezeptart_selected.id == "ZA") {
                    rah.errors.diagnose.push("Angegebener Indikationsschlüssel ist ungültig!");
                  } else {
                    rah.errors.diagnose.push("Angegebene Diagnosegruppe ist ungültig!");
                  }
                  return;
                }

                rah.general.diagnose = data;
                rah.general.diagnose_exists = true;
                rah.general.diagnose_key = data.id;
                rah.rezeptHeader.diagnose = data.id;
                rah.check_lb_behandlungsbedarf();
                rah.check_blanko();
              })
              .catch(function (error) {
                rah.general.diagnose_exists = false;
                rah.rezeptHeader.diagnose = null;
                rah.general.diagnose = null;
                rah.errors.diagnose.push(error.data.detail);
                rah.check_lb_behandlungsbedarf();
                rah.check_blanko();
              });
          }
        } else {
          rah.general.diagnose_exists = false;
          rah.rezeptHeader.diagnose = null;
          rah.general.diagnose = null;

          rah.rezeptHeader.ls_a = false;
          rah.rezeptHeader.ls_b = false;
          rah.rezeptHeader.ls_c = false;
          rah.rezeptHeader.ls_x = false;
          rah.rezeptHeader.ls_x_text = "";

          if (rah.general.rezeptart_selected.id == "ZA") {
            rah.errors.diagnose.push("Es wurde kein Indikationsschlüssel angegeben!");
          } else {
            rah.errors.diagnose.push("Es wurde keine Diagnosegruppe angegeben!");
          }
          rah.check_lb_behandlungsbedarf();
          rah.check_blanko();
          
        }
      };

      rah.check_indikationsschluessel = function () {
        rah.errors.indikationsschluessel = [];

        if (!rah.general.verordnungsart_selected) {
          rah.general.ids_exists = false;
          rah.errors.indikationsschluessel.push(
            "Indikationsschlüssel kann ohne Verordnungsart nicht überprüft werden!"
          );
        } else {
          if (rah.general.ids_key) {
            IndikationsschluesselService.get({
              id: rah.general.ids_key,
              rezeptart: rah.general.rezeptart_selected.id,
            })
              .$promise.then(function (data) {
                rah.general.ids_key = data.id;

                // check if age needs to be < 18
                if (["ZN1a", "ZN1b", "ZN1c", "EN1"].includes(data.id)) {
                  var age = rah.getAge(rah.patient.geburtsdatum);
                  if (age >= 18) {
                    rah.general.ids_exists = false;
                    rah.errors.indikationsschluessel.push(
                      "Angegebener Indikationsschlüssel ist nur für Personen unter 18 Jahre gültig!"
                    );
                    return;
                  }
                }

                // check if age needs to be >= 18
                if (["ZN2a", "ZN2b", "ZN2c", "EN2"].includes(data.id)) {
                  var age = rah.getAge(rah.patient.geburtsdatum);
                  if (age < 18) {
                    rah.general.ids_exists = false;
                    rah.errors.indikationsschluessel.push(
                      "Angegebener Indikationsschlüssel ist nur für Personen über 18 Jahre gültig!"
                    );
                    return;
                  }
                }

                if (!data.langf && !data.mittelf && rah.general.verordnungsart_selected.id == "10") {
                  rah.general.ids_exists = false;
                  rah.errors.indikationsschluessel.push(
                    "Angegebener Indikationsschlüssel erlaubt keine Verordnung außerhalb des Regelfalls!"
                  );
                  return;
                }

                if (rah.general.ids_key == "9999" && rah.patient.kt_typ != "S") {
                  rah.general.ids_exists = false;
                  rah.errors.indikationsschluessel.push("Angegebener Indikationsschlüssel ist ungültig!");
                  return;
                }

                rah.general.indikationsschluessel = data;
                rah.general.ids_exists = true;
                rah.general.ids_key = data.id;
                rah.rezeptHeader.indikationsschluessel = rah.general.indikationsschluessel.id;
              })
              .catch(function (error) {
                rah.general.ids_exists = false;
                rah.errors.indikationsschluessel.push("Angegebener Indikationsschlüssel ist ungültig!");
              });
          } else {
            rah.general.ids_exists = false;
            rah.errors.indikationsschluessel.push("Es wurde kein Indikationsschlüssel angegeben!");
          }
        }
      };

      rah.getSettingsData = function () {
        SettingsService.get()
          .$promise.then(function (data) {
            rah.general.settings_data = data;
            if (rah.patient.kt_typ == "PP") {
              rah.rezeptHeader.privat_info_text = rah.general.settings_data.privat_info_text;
            } else {
              rah.rezeptHeader.privat_info_text = "";
            }
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rah.getVerordnungsartData = function () {
        VerordnungsartService.query()
          .$promise.then(function (data) {
            rah.general.verordnungsart = data;
            rah.general.verordnungsart_data = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rah.getICD10Data = function () {
        rah.general.icd10_data = StoreService.get("icd10_codes");
      };

      rah.checkifready = function () {
        if (
          !rah.general.verordnungsart ||
          !rah.general.icd10_data ||
          !rah.general.settings_data ||
          !rah.general.befreiungen
        ) {
          setTimeout(rah.checkifready, 50);
        } else {
          if (rah.fromState != "menu.rezepte-add-lines") {
            rah.set_rezeptart_defaults(rah.patient.kt_typ);
          }
          rah.isready = true;
        }
      };

      rah.getBefreiungenData = function () {
        BefreiungenService.query({ patient: rah.patient.id })
          .$promise.then(function (data) {
            rah.general.befreiungen = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rah.check_lb_behandlungsbedarf = function () {
        // reset langf and besond to false first
        rah.rezeptHeader.langf = false;
        rah.rezeptHeader.besond = false;

        if (rah.rezeptHeader.diagnose && rah.general.icdcodes.length > 0) {
          for (var i = 0; i < rah.general.icdcodes.length; i++) {
            var keys = [];

            if (rah.general.heilmittelbereich == "PHYS" && rah.general.icdcodes[i].phys) {
              keys = rah.general.icdcodes[i].phys.split("/");
            } else if (rah.general.heilmittelbereich == "ERGO" && rah.general.icdcodes[i].ergo) {
              keys = rah.general.icdcodes[i].ergo.split("/");
            } else if (rah.general.heilmittelbereich == "LOGO" && rah.general.icdcodes[i].logo) {
              keys = rah.general.icdcodes[i].logo.split("/");
            }

            if (keys.length != 0) {
              var diag_match = false;
              var comb_match = false;

              // try to match icd-10 combination match
              if (rah.general.icdcodes[i].icd_comb) {
                rah.general.icdcodes.forEach(function (icd, index) {
                  if (index != i) {
                    if (icd.id == rah.general.icdcodes[i].icd_comb) {
                      comb_match = true;
                    }
                  }
                });
              } else {
                comb_match = true;
              }

              // try to match diagnose gruppe
              keys.forEach(function (key, index) {
                if (key == rah.rezeptHeader.diagnose.substring(0, key.length)) {
                  diag_match = true;
                }
              });

              // if both diagnose and icd10 combination key are matched
              if (diag_match && comb_match) {
                if (rah.general.icdcodes[i].langf) {
                  rah.rezeptHeader.langf = true;
                }
                if (rah.general.icdcodes[i].besond) {
                  rah.rezeptHeader.besond = true;
                }
                break;
              }
            }
          }
        } else {
          // either diagnose or icdcode missing for testing
        }
      };

      // ? NEW
      rah.update_rezept_version = function () {
        // rezeptdatum
        if (rah.general.rezeptdatum != null && rah.general.rezeptdatum != "") {
          given_year = parseInt($scope.forms.daten_forms.rd_input.$viewValue.slice(0, 4));

          version = "Neu";

          if (version != rah.rezeptHeader.rezeptversion) {
            rah.rezeptHeader.rezeptversion = version;
            rah.setRules();
          }
        }
      };

      rah.checkInput = function () {
        // reset current errors
        for (var error in rah.errors) {
          if (["indikationsschluessel", "diagnose"].indexOf(error) == -1) {
            rah.errors[error] = [];
          }
        }

        // reset anchor scroll position
        rah.error_scroll = null;

        // date limits
        lim_past = 1900;
        lim_future = parseInt(new Date().getFullYear());

        rah.error_checking_flag = true;

        // gebuehrenpflichtig
        if (rah.rezeptHeader.gebuehrenpflichtig === null) {
          rah.errors.gebuehrenpflichtig.push("Es wurde keine Auswahl getroffen!");
          rah.set_error_scroll("gebuehrenpflichtig_loc");
        }

        // arzt
        if (rah.general.arzt_num) {
          if (!rah.general.arzt_exists) {
            rah.errors.arzt.push("Der angegebene Arzt existiert nicht!");
            rah.set_error_scroll("arzt_loc");
          } else {
            rah.rezeptHeader.arzt = rah.general.arzt.id;
          }
        } else {
          rah.errors.arzt.push("Es wurde kein Arzt angegeben!");
          rah.set_error_scroll("arzt_loc");
        }

        // rezeptdatum
        if (rah.general.rezeptdatum == null || rah.general.rezeptdatum == "") {
          rah.errors.rezeptdatum.push("Es wurde kein Datum angegeben!");
          rah.set_error_scroll("rezeptdatum_loc");
        } else {
          given_year = parseInt($scope.forms.daten_forms.rd_input.$viewValue.slice(0, 4));

          if (lim_past <= given_year && lim_future >= given_year) {
            rah.rezeptHeader.rezeptdatum = rah.date_to_str(rah.general.rezeptdatum);
            rah.general.rezeptdatum.setHours(0, 0, 0, 0);
          } else {
            rah.errors.rezeptdatum.push("Ungültiges Datum angegeben!");
            rah.set_error_scroll("rezeptdatum_loc");
          }
        }

        // erfassungsdatum
        if (rah.general.erfassungsdatum == null || rah.general.erfassungsdatum == "") {
          rah.errors.erfassungsdatum.push("Es wurde kein Datum angegeben!");
          rah.set_error_scroll("erfassungsdatum_loc");
        } else {
          given_year = parseInt($scope.forms.daten_forms.ed_input.$viewValue.slice(0, 4));

          if (lim_past <= given_year) {
            if (rah.getweekday(rah.general.erfassungsdatum) != "Sonntag") {
              rah.rezeptHeader.erfassungsdatum = rah.date_to_str(rah.general.erfassungsdatum);
              rah.general.erfassungsdatum.setHours(0, 0, 0, 0);
            } else {
              rah.errors.erfassungsdatum.push("Behandlung an Sonntagen sind nicht erlaubt!");
              rah.set_error_scroll("erfassungsdatum_loc");
            }
          } else {
            rah.errors.erfassungsdatum.push("Ungültiges Datum angegeben!");
            rah.set_error_scroll("erfassungsdatum_loc");
          }
        }

        // physiotherapiebeginn
        if (!rah.general.is_irrelevant.physiotherapiebeginn) {
          if (rah.general.physiotherapiebeginn != null) {
            given_year = parseInt($scope.forms.daten_forms.pt_input.$viewValue.slice(0, 4));

            if (lim_past <= given_year && lim_future >= given_year) {
              rah.rezeptHeader.physiotherapiebeginn = rah.date_to_str(rah.general.physiotherapiebeginn);
              rah.general.physiotherapiebeginn.setHours(0, 0, 0, 0);
            } else {
              rah.errors.physiotherapiebeginn.push("Ungültiges Datum angegeben!");
              rah.set_error_scroll("physiotherapiebeginn_loc");
            }
          } else {
            // needed when rah.general.physiotherapiebeginn entry is cleared
            rah.rezeptHeader.physiotherapiebeginn = null;
          }
        }

        // check only if erfassungsdatum and rezeptdatum available
        if (
          rah.general.erfassungsdatum &&
          rah.general.rezeptdatum &&
          !["PP", "VPP"].includes(rah.general.rezeptart_selected.id)
        ) {
          // default remainingDays is the difference between rezeptdatum and erfassungsdatum (first treatment)
          var remainingDays = rah.dateDiffInDays(rah.general.rezeptdatum, rah.general.erfassungsdatum);

          var grace = null;
          var msg = null;

          if (rah.general.rezeptart_selected.id == "BW") {
            if (rah.rezeptHeader.behandlungsbedarf) {
              grace = 14;
              msg =
                "Erste Behandlung überschreitet Frist von " + grace + " Tagen (Bundeswehr) (dring. Behandlungsbedarf)!";
            } else {
              grace = 28;
              msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen (Bundeswehr)";
            }
          } else if (rah.general.rezeptart_selected.id == "BG") {
            if (rah.general.physiotherapiebeginn) {
              remainingDays = rah.dateDiffInDays(rah.general.physiotherapiebeginn, rah.general.erfassungsdatum);
            }

            if (rah.rezeptHeader.behandlungsbedarf) {
              grace = 7;
              msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen (BG) (dring. Behandlungsbedarf)!";
            } else {
              grace = 14;
              msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen (BG)";
            }
          } else if (rah.rezeptHeader.behandlungsbedarf) {
            grace = 14;
            msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen (dring. Behandlungsbedarf)!";
          } else {
            grace = 28;
            msg = "Erste Behandlung überschreitet Frist von " + grace + " Tagen!";
          }

          if (remainingDays > grace) {
            rah.errors.erfassungsdatum.push(msg);
            rah.set_error_scroll("erfassungsdatum_loc");
          }

          if (remainingDays < 0) {
            msg = !rah.general.physiotherapiebeginn
              ? "Erste Behandlung liegt vor Rezeptdatum!"
              : "Erste Behandlung liegt vor Physiotherapiebeginn!";
            rah.errors.erfassungsdatum.push(msg);
            rah.set_error_scroll("erfassungsdatum_loc");
          }

          // Entlassmanagement
          if (!rah.general.is_irrelevant.discharge_management && rah.rezeptHeader.discharge_management) {
            if (remainingDays > 7) {
              rah.errors.erfassungsdatum.push("Erste Behandlung überschreitet Frist von 7 Tagen (Entlassmanagement)!");
              rah.set_error_scroll("erfassungsdatum_loc");
            }
          }
        }

        // indikationsschlüssel
        if (!rah.general.is_irrelevant.indikationsschluessel && rah.rezeptHeader.rezeptversion == "Alt") {
          if (rah.errors.indikationsschluessel.length != 0) {
            rah.set_error_scroll("indikationsschluessel_loc");
          }

          if (!rah.general.indikationsschluessel) {
            rah.errors.indikationsschluessel.push("Es wurde kein Indikationsschlüssel angegeben!");
            rah.set_error_scroll("indikationsschluessel_loc");
          }
        }

        // diagnose + leitsymptomatik
        if (!rah.general.is_irrelevant.diagnose && rah.rezeptHeader.rezeptversion == "Neu") {
          if (rah.errors.diagnose.length != 0) {
            rah.set_error_scroll("diagnose_loc");
          } else {
            if (!rah.general.diagnose) {
              if (rah.general.rezeptart_selected.id == "ZA") {
                rah.errors.diagnose.push("Es wurde kein Indikationsschlüssel angegeben!");
              } else {
                rah.errors.diagnose.push("Es wurde keine Diagnosegruppe angegeben!");
              }
              rah.set_error_scroll("diagnose_loc");
            } else {
              if (
                !rah.rezeptHeader.ls_a &&
                !rah.rezeptHeader.ls_b &&
                !rah.rezeptHeader.ls_c &&
                !rah.rezeptHeader.ls_x &&
                (rah.general.diagnose.ls_a ||
                  rah.general.diagnose.ls_b ||
                  rah.general.diagnose.ls_c ||
                  rah.general.diagnose.ls_x)
              ) {
                rah.errors.ls.push("Es wurde keine Leitsymptomatik ausgewählt!");
                rah.set_error_scroll("leitsympt_loc");
              }

              if (rah.rezeptHeader.ls_x) {
                if (rah.rezeptHeader.ls_x_text.length == 0) {
                  rah.errors.ls_text.push("Beschreibung der patientenindividuellen Leitsymptomatik fehlt!");
                  rah.set_error_scroll("leitsympt_loc");
                }

                if (rah.rezeptHeader.ls_x_text.length > 70) {
                  rah.errors.ls_text.push(
                    "Beschreibung der patientenindividuellen Leitsymptomatik ist zu lang (max. 70 Zeichen erlaubt)!"
                  );
                  rah.set_error_scroll("leitsympt_loc");
                }

                var matches = get_unique(rah.rezeptHeader.ls_x_text.match(rah.regexp_diagnose));
                if (matches) {
                  var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
                  rah.errors.ls_text.push(err_msg);
                  rah.set_error_scroll("leitsympt_loc");
                }
              }
            }
          }
        }

        // frequenz
        if (!rah.general.is_irrelevant.frequenz && rah.rezeptHeader.rezeptversion == "Neu") {
          if (!rah.general.frequenz) {
            rah.errors.frequenz.push("Es wurde keine gültige Therapiefrequenz angegeben!");
          } else {
            rah.rezeptHeader.frequenz = rah.general.frequenz;
          }
        }

        // verordnungsart
        if (!rah.general.is_irrelevant.verordnungsart && rah.rezeptHeader.rezeptversion == "Alt") {
          if (!rah.general.verordnungsart_selected) {
            rah.errors.verordnungsart.push("Es wurde keine Verordnungsart ausgewählt!");

            if (!rah.general.is_irrelevant.logo_erstbefund) {
              rah.errors.logo_erstbefund.push("Erstbefundung kann ohne Verordnungsart nicht überprüft werden!");
            }

            rah.set_error_scroll("verordnungsart_loc");
          } else {
            if (rah.general.indikationsschluessel) {
              if (
                rah.general.verordnungsart_selected.name == "Folgeverordnung" &&
                rah.general.indikationsschluessel.max_folgev == 0
              ) {
                rah.errors.verordnungsart.push("Indikationsschlüssel erlaubt keine Folgeverordnungen!");
                rah.set_error_scroll("verordnungsart_loc");
              } else if (
                rah.general.verordnungsart_selected.name == "Erstverordnung" &&
                rah.general.indikationsschluessel.max_erstv == 0
              ) {
                rah.errors.verordnungsart.push("Indikationsschlüssel erlaubt keine Erstverordnungen!");
                rah.set_error_scroll("verordnungsart_loc");
              } else {
                rah.rezeptHeader.verordnungsart = rah.general.verordnungsart_selected.id;
              }
            }

            // logo - erstbefund
            if (!rah.general.is_irrelevant.logo_erstbefund) {
              if (rah.rezeptHeader.logo_erstbefund === null) {
                rah.errors.logo_erstbefund.push("Es wurde keine Auswahl getroffen!");
                rah.set_error_scroll("logo_erstbefund_loc");
              } else {
                if (rah.rezeptHeader.logo_erstbefund && rah.general.verordnungsart_selected.name != "Erstverordnung") {
                  rah.errors.logo_erstbefund.push("Erstbefundung nur bei Erstverordnung möglich!");
                  rah.set_error_scroll("logo_erstbefund_loc");
                }
              }
            }
          }
        }

        // privat_info_text
        if (!rah.general.is_irrelevant.privat_zusatztext) {
          if (rah.rezeptHeader.privat_info_text) {
            if (rah.rezeptHeader.privat_info_text.length > 200) {
              rah.errors.privat_info_text.push("Zusatztext ist zu lang! Nur 200 Zeichen sind erlaubt!");
              rah.set_error_scroll("privat_zusatztext_loc");
            }

            var matches = get_unique(rah.rezeptHeader.privat_info_text.match(rah.regexp_text_all));
            if (matches) {
              var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
              rah.errors.privat_info_text.push(err_msg);
              rah.set_error_scroll("privat_zusatztext_loc");
            }
          }
        }

        // dringlicher behandlungsbedarf
        if (!rah.general.is_irrelevant.behandlungsbedarf && rah.rezeptHeader.behandlungsbedarf === null) {
          rah.errors.behandlungsbedarf.push("Es wurde keine Auswahl getroffen!");
          rah.set_error_scroll("behandlungsbedarf_loc");
        }

        // hausbesuch
        if (rah.rezeptHeader.hausbesuch === null) {
          rah.errors.hausbesuch.push("Es wurde keine Auswahl getroffen!");
          rah.set_error_scroll("hausbesuch_loc");
        }

        // therapiebericht
        if (!rah.general.is_irrelevant.therapiebericht) {
          if (rah.rezeptHeader.therapiebericht === null) {
            rah.errors.therapiebericht.push("Es wurde keine Auswahl getroffen!");
            rah.set_error_scroll("therapiebericht_loc");
          }
        }

        // icd10
        if (!rah.general.is_irrelevant.icdcodes) {
          if (!rah.general.icdcodes || rah.general.icdcodes.length == 0) {
            rah.errors.icd10.push("Es wurde kein ICD-10 Code angegeben!");
            rah.set_error_scroll("icd10_loc");
            var entry = angular.element(document.querySelector("#icd10_loc").querySelector("div > div"));
            entry.addClass("is-error-border");
          } else {
            rah.rezeptHeader.icd_code = rah.icd_to_str(rah.general.icdcodes);
            var entry = angular.element(document.querySelector("#icd10_loc").querySelector("div > div"));
            entry.removeClass("is-error-border");
          }
        } else {
          // if user wants to provide icd-10 even though it is not needed
          if (
            rah.general.icdcodes &&
            !(rah.rezeptHeader.rezeptversion == "Neu" && rah.general.rezeptart_selected.id == "ZA")
          ) {
            rah.rezeptHeader.icd_code = rah.icd_to_str(rah.general.icdcodes);
            var entry = angular.element(document.querySelector("#icd10_loc").querySelector("div > div"));
            entry.removeClass("is-error-border");
          }
        }

        if (rah.rezeptHeader.rezeptversion == "Neu" && rah.general.rezeptart_selected.id == "ZA") {
          if (rah.rezeptHeader.diagnosetext) {
            if (rah.rezeptHeader.diagnosetext.length == 0) {
              rah.errors.diagnosetext.push("Zahnarzt Diagnosetext fehlt!");
              rah.set_error_scroll("diagnosetext_loc");
            }

            if (rah.rezeptHeader.diagnosetext.length > 70) {
              rah.errors.diagnosetext.push("Zahnarzt Diagnosetext ist zu lang (max. 70 Zeichen erlaubt)!");
              rah.set_error_scroll("diagnosetext_loc");
            }

            var matches = get_unique(rah.rezeptHeader.diagnosetext.match(rah.regexp_diagnose));
            if (matches) {
              var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
              rah.errors.diagnosetext.push(err_msg);
              rah.set_error_scroll("diagnosetext_loc");
            }
          } else {
            rah.errors.diagnosetext.push("Zahnarzt Diagnosetext fehlt!");
            rah.set_error_scroll("diagnosetext_loc");
          }
        }

        // genehmigung
        // let's assume that genehmigung is false by default
        rah.rezeptHeader.genehmigung = false;
        rah.rezeptHeader.genehmigungsnummer = null;
        rah.rezeptHeader.genehmigungsart = null;
        rah.rezeptHeader.genehmigungsdatum = null;

        if (!rah.general.is_irrelevant.genehmigung) {
          if (rah.general.genehmigung) {
            // check genehmigungsdatum
            if (rah.general.genehmigungsdatum == null || rah.general.genehmigungsdatum == "") {
              rah.errors.genehmigungsdatum.push("Es wurde kein Datum angegeben!");
              rah.set_error_scroll("genehmigungsdatum_loc");
            }

            // check genehmigungsnummer
            if (rah.general.genehmigungsnummer) {
              if (rah.general.genehmigungsnummer.length > 20) {
                rah.errors.genehmigungsnummer.push("Genehmigungsnummer ist zu lang!");
                rah.set_error_scroll("genehmigungsnummer_loc");
              }
            } else {
              rah.errors.genehmigungsnummer.push("Genehmigungsnummer fehlt!");
              rah.set_error_scroll("genehmigungsnummer_loc");
            }

            if (
              [rah.errors.genehmigungsdatum, rah.errors.genehmigungsnummer].every(function (arr) {
                return arr.length === 0;
              })
            ) {
              rah.rezeptHeader.genehmigung = true;
              rah.rezeptHeader.genehmigungsnummer = rah.general.genehmigungsnummer;
              rah.rezeptHeader.genehmigungsart = rah.general.genehmigungsart;
              rah.rezeptHeader.genehmigungsdatum = rah.date_to_str(rah.general.genehmigungsdatum);
            }
          }
        }

        return rah.checkErrors();
      };

      rah.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null

        for (var error in rah.errors) {
          if (!rah.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (rah.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        rah.error_checking_flag = false;
        return true;
      };

      rah.clean_ls_x_text = function () {
        rah.rezeptHeader.ls_x_text = "";
      };

      rah.cleanData = function () {
        if (rah.rezeptHeader.rezeptversion == "Alt") {
          //diagnose
          rah.rezeptHeader.diagnose = null;
          rah.general.diagnose = null;
          rah.general.diagnose_key = null;
          rah.general.diagnose_exists = null;

          // behandlungsbedarf
          rah.rezeptHeader.behandlungsbedarf = false;

          // leitsymptomatik
          rah.rezeptHeader.ls_a = false;
          rah.rezeptHeader.ls_b = false;
          rah.rezeptHeader.ls_c = false;
          rah.rezeptHeader.ls_x = false;
          rah.rezeptHeader.ls_x_text = "";

          // blanko
          rah.rezeptHeader.blanko = false;
        } else {
          rah.rezeptHeader.indikationsschluessel = null;
          rah.general.indikationsschluessel = null;
          rah.general.ids_key = null;
          rah.general.ids_exists = null;

          rah.rezeptHeader.verordnungsart = null;
          rah.general.verordnungsart_selected = null;
        }
      };

      rah.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      rah.gotoPositionen = function () {
        rah.check_zulassungsbefreiung();

        if (rah.checkInput()) {
          rah.cleanData();

          RememberService.rezeptAddHeaderData = {
            rezeptHeader: rah.rezeptHeader,
            patient: rah.patient,
            general: rah.general,
          };

          var params = {
            rezeptHeader: rah.rezeptHeader,
            patient: rah.patient,
            general: rah.general,
          };

          var stateChainEntry = {
            state: $state.current.name,
            params: {},
          };

          var newParams = Object.assign({}, $stateParams, { params: params });
          if (!newParams.stateChain) {
            newParams.stateChain = [];
          }
          newParams.stateChain.push(stateChainEntry);

          $state.go("menu.rezepte-add-lines", newParams);
        }
      };

      rah.getPatientenDetails();
      rah.init();
    },
  ],
});
