angular.
module('abrechnungUpload').
component('abrechnungUpload', {
	templateUrl: 'static/angular_assets/admin/abrechnung_upload/abrechnung_upload.template.html',
	controller: [
		'$rootScope',
		'FileUploader',
		'store',
		'__env',
		function AbrechnungUploadController(
			$rootScope,
			FileUploader,
			store,
			__env
		) {

			var apc = this;

			apc.upload_success = false;
			apc.upload_error = false;
			apc.upload_msg = ""


			apc.fromState = $rootScope.fromState;

			apc.uploader = new FileUploader({
				queueLimit: 2,
				url: __env.apiUrl + "upload/abrechnung/",
				headers: {
					'Authorization': 'Bearer ' + store.get('access_token')
				},
				method: "PUT",
				removeAfterUpload: true,
			});

			// clear queue before adding (new) file
			apc.uploader.onAfterAddingFile = function (item) {
				if (apc.uploader.queue.length > 1) {
					apc.uploader.removeFromQueue(0);
				}
				// reset upload_success and upload_error status
				apc.upload_success = false;
				apc.upload_error = false;

			};

			apc.upload_file = function () {
				apc.uploader.uploadAll()
			}

			apc.uploader.onSuccessItem = function (item, response, status, headers) {
				apc.upload_success = true;
				apc.response_data = response.data
			}

			apc.uploader.onErrorItem = function (item, response, status, headers) {
				apc.upload_error = true;
				apc.upload_msg = response.msg;
			}

		}
	],
});