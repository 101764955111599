angular.module("aerzteEdit").component("aerzteEdit", {
  templateUrl:
    "static/angular_assets/aerzte/aerzte_edit/aerzte_edit.template.html",
  controller: [
    "$state",
    "$anchorScroll",
    "$timeout",
    "$uiRouterGlobals",
    "AerzteService",
    "$stateParams",

    function AerzteEditController(
      $state,
      $anchorScroll,
      $timeout,
      $uiRouterGlobals,
      AerzteService,
      $stateParams
    ) {
      var aedit = this;

      aedit.regexp_text = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+']/g;
      aedit.regexp_text_num = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+'0-9]/g;

      get_unique = function (arr) {
        return arr
          ? arr.reduce(function (a, b) {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, [])
          : null;
      };

      aedit.getArzt = function () {
        AerzteService.get({
          arztNum: aedit.arzt_num,
        })
          .$promise.then(function (data) {
            aedit.arzt = data;
            aedit.isready = true;
          })
          .catch(function (error) {});
      };

      aedit.init = function () {
        // holds errors
        aedit.errors = {
          arzt_num: [],
          betriebs_num: [],
          arzt_name: [],
          strasse: [],
          plz: [],
          ort: [],
        };

        aedit.isready = false;

        // read provided parameters
        aedit.transparam = $uiRouterGlobals.params;
        aedit.arzt_num = aedit.transparam.arztNum;
        aedit.arzt = {};

        aedit.getArzt();
      };

      // generic functions ################### //
      aedit.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };
      // ##################################### //

      aedit.set_error_scroll = function (loc) {
        if (!aedit.error_scroll) {
          aedit.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      aedit.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in aedit.errors) {
          if (!aedit.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (aedit.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      aedit.checkArztInput = function () {
        // reset current errors
        for (var error in aedit.errors) {
          aedit.errors[error] = [];
        }

        // reset anchor scroll position
        aedit.error_scroll = null;

        // arzt_num
        if (!aedit.arzt.arzt_num) {
          aedit.errors.arzt_num.push("Es wurde keine Arzt-Nummer angegeben!");
          aedit.set_error_scroll("arzt_num_loc");
        } else {
          if (!aedit.isNumber(aedit.arzt.arzt_num)) {
            aedit.errors.arzt_num.push("Eingabe ist keine gültige Nummer!");
            aedit.set_error_scroll("arzt_num_loc");
          }

          if (aedit.arzt.arzt_num.length != 9) {
            aedit.errors.arzt_num.push(
              "Arzt-Nummer muss eine 9-stellige Nummer sein!"
            );
            aedit.set_error_scroll("arzt_num_loc");
          }
        }

        // betriebs_num
        if (!aedit.arzt.betriebs_num) {
          aedit.errors.betriebs_num.push(
            "Es wurde keine Betriebsnummer angegeben!"
          );
          aedit.set_error_scroll("betriebs_num_loc");
        } else {
          if (!aedit.isNumber(aedit.arzt.betriebs_num)) {
            aedit.errors.betriebs_num.push("Eingabe ist keine gültige Nummer!");
            aedit.set_error_scroll("betriebs_num_loc");
          }

          if (aedit.arzt.betriebs_num.length != 9) {
            aedit.errors.betriebs_num.push(
              "Betriebsnummer muss eine 9-stellige Nummer sein!"
            );
            aedit.set_error_scroll("betriebs_num_loc");
          }
          if (aedit.arzt.betriebs_num == "999999999") {
            aedit.errors.betriebs_num.push(
              "Betriebsnummer 999999999 darf nicht verwendet werden!"
            );
            aedit.set_error_scroll("betriebs_num_loc");
          }
        }

        // name
        if (!aedit.arzt.name) {
          aedit.errors.arzt_name.push("Es wurde kein Name angegeben!");
          aedit.set_error_scroll("name_loc");
        } else {
          var matches = get_unique(aedit.arzt.name.match(aedit.regexp_text));

          if (matches) {
            var err_msg =
              "Folgende Zeichen dürfen nicht verwendet werden: " +
              matches.join(" ");
            aedit.errors.arzt_name.push(err_msg);
            aedit.set_error_scroll("name_loc");
          }
          if (aedit.arzt.name.length > 90) {
            aedit.errors.arzt_name.push(
              "Name des Arztes ist zu lang! (> 90 Zeichen)"
            );
            aedit.set_error_scroll("name_loc");
          }
        }

        // strasse
        if (aedit.arzt.strasse) {
          var matches = get_unique(
            aedit.arzt.strasse.match(aedit.regexp_text_num)
          );

          if (matches) {
            var err_msg =
              "Folgende Zeichen dürfen nicht verwendet werden: " +
              matches.join(" ");
            aedit.errors.strasse.push(err_msg);
            aedit.set_error_scroll("strasse_loc");
          }
        }

        // plz
        if (aedit.arzt.plz && aedit.arzt.plz.length > 0) {
          //aedit.errors.plz.push("Es wurde keine PLZ angegeben!");
          //aedit.set_error_scroll('plz_loc');
          if (!aedit.isNumber(aedit.arzt.plz)) {
            aedit.errors.plz.push("Ungültige PLZ! (nur Zahlen sind erlaubt)");
            aedit.set_error_scroll("plz_loc");
          } else if (aedit.arzt.plz.length != 5) {
            aedit.errors.plz.push("PLZ ist nicht 5 Stellen lang!");
            aedit.set_error_scroll("plz_loc");
          }
        }

        // ort
        if (aedit.arzt.ort) {
          var matches = get_unique(aedit.arzt.ort.match(aedit.regexp_text_num));

          if (matches) {
            var err_msg =
              "Folgende Zeichen dürfen nicht verwendet werden: " +
              matches.join(" ");
            aedit.errors.ort.push(err_msg);
            aedit.set_error_scroll("ort_loc");
          }
        }

        return aedit.checkErrors();
      };

      aedit.editArzt = function () {
        if (aedit.checkArztInput()) {
          AerzteService.update(
            {
              arztNum: aedit.arzt_num,
            },
            aedit.arzt
          ).$promise.then(
            function (success_response) {
              aedit.returnToPreviousState();
            },
            function (error_response) {
              if (error_response.data["non_field_errors"]) {
                var err_rep = error_response.data["non_field_errors"];
                for (i = 0; i < err_rep.length; i++) {
                  if (err_rep[i] == "ArztExists") {
                    aedit.errors.arzt_exists = true;
                  }
                }
              }
            }
          );
        }
      };

      // Navigation
      aedit.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      aedit.init();
    },
  ],
});
