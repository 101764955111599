angular.
  module('core.mahnung').
  factory('MahnungService', ['$resource', 'Blob', '__env',
    function($resource, Blob, __env) {
      return $resource(__env.apiUrl + 'mahnung/:id/\.',{},
      {
        'pdf': {
          method: 'GET',
          headers: {
              accept: 'application/pdf'
          },
          responseType: 'arraybuffer',
          cache: false,
          transformResponse: function (data, headers) {
            var pdf;
            var hdrs = headers()            
            if (data) {
                pdf = new Blob([data], {
                    type: 'application/pdf'
                });
            }
            return {
                response: pdf,
                filename: hdrs["content-disposition"].slice(22,hdrs["content-disposition"].length - 1),

            };
        }
        }
      }
    );
    }
  ]);
