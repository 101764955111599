angular.module("mitarbeiterView").component("mitarbeiterView", {
  templateUrl: "static/angular_assets/mitarbeiter/mitarbeiter_view/mitarbeiter_view.template.html",
  controller: [
    "$state",
    "StoreService",
    "MitarbeiterService",
    "$stateParams",

    function MitarbeiterViewController($state, StoreService, MitarbeiterService, $stateParams) {
      // add proper CSS classes

      var mv = this;
      mv.mitarbeiter_data = StoreService.get("mitarbeiter");
      mv.mitarbeiter_id = $stateParams.mitarbeiterId;

      // functions

      mv.getMitarbeiterDetails = function () {
        mv.mitarbeiter = mv.mitarbeiter_data.find(function (x) {
          return x.id == mv.mitarbeiter_id;
        });
      };

      mv.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      mv.deleteMitarbeiter = function () {
        MitarbeiterService.delete({
          id: mv.mitarbeiter_id,
        })
          .$promise.then(function (data) {
            var id_to_remove = mv.mitarbeiter_id;
            var index = mv.mitarbeiter_data
              .map(function (item) {
                return item.id;
              })
              .indexOf(id_to_remove);

            mv.mitarbeiter_data.splice(index, 1);

            StoreService.set("mitarbeiter", mv.mitarbeiter_data);

            // show changed available Mitarbeiter
            mv.returnToPreviousState();
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      mv.editMitarbeiter = function () {
        var stateChainEntry = {
          state: $state.current.name,
          params: { mitarbeiterId: mv.mitarbeiter_id },
        };

        var newParams = Object.assign({}, $stateParams, { mitarbeiterId: mv.mitarbeiter_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.mitarbeiter-edit", newParams);
      };

      mv.mitarbeiter = null;
      mv.getMitarbeiterDetails();
    },
  ],
});
