angular.
  module('core.heilmittel_old').
  factory('HeilmittelOldService', ['$resource', '__env',
    function($resource, __env) {
      return $resource(__env.apiUrl + 'heilmittel_old/\:id/',{id: "@id", patient_id: "@patient_id", tarif_typ: "@tarif_typ", diagnose_key: "@diagnose_key", preistyp: "@preistyp"},
      {
        'update': { method:'PUT' }
      }
    );
    }
  ]);