angular.
  module('core.password').
  factory('ChangePasswordService', ['$resource', '__env',
    function($resource, __env) {
      return $resource(__env.apiUrl + 'changepassword/',
      {
        'update': { method:'PUT' }
      }
    );
    }
  ]);