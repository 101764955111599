angular.
module('core.vollprivat_tarif').
factory('VollprivatTarifService', ['$resource', '__env',
  function ($resource, __env) {
    return $resource(__env.apiUrl + 'vollprivattarif/\:id/', {
      id: "@id"
    }, {
      'update': {
        method: 'PUT'
      }
    });
  }
]);