angular.module("core", [
  "core.register",
  "core.login",
  "core.username",
  "core.leistungserbringer",
  "core.verbaende",
  "core.kostentraegerregionen",
  "core.tarifregionen",
  "core.tokenrefresh",
  "core.mitarbeiter",
  "core.aerzte",
  "core.patienten",
  "core.kostentraegertyp",
  "core.versicherungsstatus",
  "core.kunden",
  "core.kostentraeger",
  "core.rezepte_header",
  "core.rezepte_daten",
  "core.rezepte_positionen",
  "core.indikationsschluessel",
  "core.verordnungsart",
  "core.heilmittel",
  "core.password",
  "core.quittung",
  "core.behandlungsbescheinigung",
  "core.rechnung",
  "core.ik_num",
  "core.offeneposten",
  "core.kassenbuch_daten",
  "core.kassenbuch_create",
  "core.kassenbuch",
  "core.remember_choices",
  "core.scroller",
  "core.preislisten_check",
  "core.icd10",
  "core.abrechnung",
  "core.download-abrechnung",
  "core.messages",
  "core.messages_mark",
  "core.messages_delete",
  "core.ids_tarif",
  "core.abrechnung_status",
  "core.preiscalculate",
  "core.settings",
  "core.vollprivat_tarif",
  "core.version",
  "core.mka_store",
  "core.statistics",
  "core.befreiungen",
  "core.admin_nachricht",
  "core.mahnung_preview",
  "core.mahnung",
  "core.diagnose",
  "core.diagnose_tarif",
  "core.heilmittel_old",
  "core.admin_reset_testaccount",
  "core.medicalReport",
]);
