angular.
  module('core.register').
  factory('RegisterService', ['$resource', '__env',
    function($resource, __env) {
    	var r = $resource(__env.apiUrl + 'register/\.');
    	delete r.prototype.$get;
    	delete r.prototype.$query;
  		delete r.prototype.$delete;
  		delete r.prototype.$remove;
      return r
    }
  ]);
