angular.module("termineRezepte").component("termineRezepte", {
  templateUrl: "static/angular_assets/rezepte/termine/termine.template.html",
  controller: [
    "$rootScope",
    "$state",
    "StoreService",
    "RezepteHeaderService",
    "DTColumnDefBuilder",
    "$stateParams",
    function TermineRezepteController(
      $rootScope,
      $state,
      StoreService,
      RezepteHeaderService,
      DTColumnDefBuilder,
      $stateParams
    ) {
      var trc = this;

      // TODO: REMOVE
      trc.search_term = function () {
        trc.dtInstance.DataTable.page.len();
      };

      trc.init = function () {
        trc.rezepte = null;
        trc.rezepte_cnt = 0;

        // loading flag used to display a line-scale-pulse-out loader
        trc.is_loading = false;

        // filter and search options/terms
        trc.filter = {
          name: "",
          vorname: "",
          kt_typ: "ALL",
          datum_von: null,
          datum_bis: null,
          status: "ALL",
          search: "",
          page_length: 10,
          page_start: 0,
        };

        // DataTable instance - can be used to access original DataTable functions and attributes
        trc.dtInstance = {};

        // DataTable options
        trc.dtOptions = {
          paginationType: "full",
          displayLength: 10,
          displayStart: 0,
          responsive: true,
          search: {
            search: "",
          },

          language: {
            decimal: "",
            emptyTable: "Bisher wurden keine Daten geladen",
            info: "Zeige _START_ bis _END_ von _TOTAL_ Einträgen",
            infoEmpty: "Zeige 0 bis 0 von 0 Einträgen",
            infoFiltered: "(gefiltert von insg. _MAX_ Einträgen)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "Zeige _MENU_ Einträge",
            loadingRecords: "Laden...",
            processing: "Verarbeitung...",
            search: "Suche:",
            zeroRecords: "Keine passenden Einträge gefunden!",
            paginate: {
              first: "Erste",
              last: "Letzte",
              next: ">",
              previous: "<",
            },
            aria: {
              sortAscending: ": activate to sort column ascending",
              sortDescending: ": activate to sort column descending",
            },
          },
        };

        // DataTable Column Definitions
        trc.dtColumnDefs = [
          DTColumnDefBuilder.newColumnDef(0).withOption("type", "date-eu"),
          DTColumnDefBuilder.newColumnDef(3).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(4).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(6).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(7).withClass("none"),
          DTColumnDefBuilder.newColumnDef(8).withClass("none"),
          DTColumnDefBuilder.newColumnDef(9).withClass("none"),
          DTColumnDefBuilder.newColumnDef(10).withClass("none"),
          DTColumnDefBuilder.newColumnDef(11).withClass("min-desktop"),
          DTColumnDefBuilder.newColumnDef(12).notSortable(),
        ];

        trc.fromState = $rootScope.fromState;

        trc.getTermineRezeptData();
      };

      trc.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      trc.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      trc.validateRezept = function (header_id) {
        // TODO: This is currently saved but not used upon return to this state
        trc.filter.search = trc.dtInstance.DataTable.search();
        trc.filter.page_length = trc.dtInstance.DataTable.page.len();
        trc.filter.page_start = trc.dtInstance.DataTable.page() * trc.filter.page_length;

        StoreService.set("rezepteArchiveData", {
          filter: trc.filter,
          rezepte: trc.rezepte,
        });

        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, {headerId: header_id});
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-edit", newParams);
      };

      trc.dict_length = function (dict) {
        cnt = 0;

        for (key in dict) {
          if (dict.hasOwnProperty(key)) {
            cnt += 1;
          }
        }

        return cnt;
      };

      trc.getTermineRezeptData = function () {
        trc.is_loading = true;
        trc.rezepte_cnt = 0;

        // RESET SAVED SEARCH TERM
        if (trc.dtInstance["DataTable"]) {
          trc.dtOptions.search.search = "";
          trc.dtOptions.displayStart = 0;
          trc.dtInstance.DataTable.search("");
          trc.dtInstance.DataTable.page(0);
        }

        var params = {
          incomplete: "y",
        };

        if (trc.filter.name.trim()) {
          params.name = trc.filter.name.trim();
        }

        if (trc.filter.vorname.trim()) {
          params.vorname = trc.filter.vorname.trim();
        }

        if (trc.filter.kt_typ != "ALL") {
          params.kt_typ = trc.filter.kt_typ;
        }

        if (trc.filter.datum_von) {
          params.datum_von = trc.date_to_str(trc.filter.datum_von);
        }

        if (trc.filter.datum_bis) {
          params.datum_bis = trc.date_to_str(trc.filter.datum_bis);
        }

        // get data from DB rather than StoreService
        RezepteHeaderService.query(params)
          .$promise.then(function (data) {
            trc.rezepte = null;

            // change list to dict
            trc.rezepte = data.results.reduce(function (map, obj) {
              map[obj.id] = obj;
              return map;
            }, {});

            for (key in trc.rezepte) {
              if (trc.rezepte.hasOwnProperty(key)) {
                trc.rezepte_cnt += 1;
              }
            }

            if (trc.rezepte_cnt > 1000) {
              setTimeout(function () {
                //do what you need here
                trc.is_loading = false;
              }, 5);
            } else {
              setTimeout(function () {
                //do what you need here
                trc.is_loading = false;
              }, 2);
            }
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
            console.log(error);
          });
      };

      trc.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      // INIT
      trc.init();
    },
  ],
});
