angular.
  module('core.mitarbeiter').
  factory('MitarbeiterService', ['$resource', '__env',
    function($resource, __env) {
      return $resource(__env.apiUrl + 'mitarbeiter/\:id/',{id: "@id"},
      {
        'update': { method:'PUT' }
      }
    );
    }
  ]);