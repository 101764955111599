angular.module("nachrichtenView").component("nachrichtenView", {
  templateUrl: "static/angular_assets/nachrichten_center/nachrichten_view/nachrichten_view.template.html",
  controller: [
    "$state",
    "$rootScope",
    "MessagesService",
    "$stateParams",
    function NachrichtenViewController($state, $rootScope, MessagesService, $stateParams) {
      var nv = this;

      nv.init = function () {
        // get nachricht from passed params
        nv.nachricht = $stateParams.message;

        if (!nv.nachricht) {
          $state.go("menu.nachrichten");
        } else {
          // set nachricht to read (gelesen)
          nv.updateNachricht();

          nv.isready = true;
        }
      };

      nv.updateNachricht = function () {
        nv.nachricht.gelesen = true;
        MessagesService.update(
          {
            id: nv.nachricht.id,
          },
          nv.nachricht
        ).$promise.then(
          function (success_response) {
            $rootScope.getUnreadMessagesCount(true);
          },
          function (error) {
            console.log(error);
          }
        );
      };

      nv.showRezept = function (header_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: { message: nv.nachricht },
        };

        var newParams = Object.assign({}, $stateParams, { headerId: header_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-overview-view", newParams);
      };

      nv.showOffenenPosten = function (offener_posten_id) {
        var stateChainEntry = {
          state: $state.current.name,
          params: { message: nv.nachricht },
        };

        var newParams = Object.assign({}, $stateParams, { offenerPostenId: offener_posten_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.offene-posten-edit", newParams);
      };

      nv.deleteNachricht = function () {
        MessagesService.delete({
          id: nv.nachricht.id,
        }).$promise.then(
          function (success_response) {
            $state.go("menu.nachrichten");
          },
          function (error) {
            console.log(error);
          }
        );
      };

      nv.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      nv.init();
    },
  ],
});
