angular.
module('mainNav').
component('mainNav', {
	templateUrl: 'static/angular_assets/main_nav/main_nav.template.html',
	controller: ['$state','store', 'jwtHelper', '$window', '$stateParams',
		function MainMenuController($state, store, jwtHelper, $window, $stateParams) {

			// add proper CSS classes

			var mmc = this;
			// try to get token
			var token = store.get('access_token');

			// get activated modules info
			mmc.modules = store.get('modules');

			// ensure token was found
			if (token) {
				token = jwtHelper.decodeToken(token);
				mmc.admin = token.admin;
			} else {
				$window.sessionStorage.clear();
				$state.go('login');
			}


			// Navigation
			mmc.goToState = function (stateName){
				var stateChainEntry = {
					'state': $state.current.name,
					'params': {}
				};

				var newParams = Object.assign({}, $stateParams);
				if (!newParams.stateChain){
					newParams.stateChain = [];
				}

				newParams.stateChain.push(stateChainEntry);
				$state.go(stateName, newParams)
			}




		}
	]












});