angular.
  module('core.kunden').
  factory('KundenService', ['$resource', '__env',
    function($resource, __env) {
      return $resource(__env.apiUrl + 'kunden/\:id/',{id: "@id"},
      {
        'update': { method:'PUT' }
      }
    );
    }
  ]);