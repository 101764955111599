angular.
  module('core.heilmittel').
  factory('HeilmittelService', ['$resource', '__env',
    function($resource, __env) {
      return $resource(__env.apiUrl + 'heilmittel/\:id/',{id: "@id", id_key: "@id_key", patient_id: "@patient_id", tarif_typ: "@tarif_typ", diagnose_key: "@diagnose_key", preistyp: "@preistyp", blanko: "@blanko"},
      {
        'update': { method:'PUT' }
      }
    );
    }
  ]);