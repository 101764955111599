angular.module("aerzteAdd").component("aerzteAdd", {
  templateUrl: "static/angular_assets/aerzte/aerzte_add/aerzte_add.template.html",
  controller: [
    "$state",
    "$anchorScroll",
    "$timeout",
    "store",
    "AerzteService",
    "jwtHelper",
    "$stateParams",

    function AerzteDetailsController(
      $state,
      $anchorScroll,
      $timeout,
      store,
      AerzteService,
      jwtHelper,
      $stateParams
    ) {
      var aedd = this;

      aedd.regexp_text = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+']/g;
      aedd.regexp_text_num = /[^A-Za-zßöÖäÄüÜéÉèÈ\/\. \-&+'0-9]/g;

      get_unique = function (arr) {
        return arr
          ? arr.reduce(function (a, b) {
              if (a.indexOf(b) < 0) a.push(b);
              return a;
            }, [])
          : null;
      };

      aedd.init = function () {
        // holds errors
        aedd.errors = {
          arzt_num: [],
          betriebs_num: [],
          arzt_name: [],
          strasse: [],
          plz: [],
          ort: [],
        };

        // used for anchor scroll to first error
        aedd.error_scroll = null;
        $anchorScroll.yOffset = 200;

        // prepare objects for Arzt input
        aedd.arzt = new AerzteService();

        // possible/mandatory data
        aedd.arzt.kunde = jwtHelper.decodeToken(store.get("access_token")).user_id;
        aedd.arzt.betriebs_num = "";
        aedd.arzt.name = "";
        aedd.arzt.strasse = "";
        aedd.arzt.plz = "";
        aedd.arzt.ort = "";
        aedd.arzt.telefon = "";
        aedd.arzt.fax = "";
      };

      aedd.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      aedd.set_error_scroll = function (loc) {
        if (!aedd.error_scroll) {
          aedd.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      aedd.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in aedd.errors) {
          if (!aedd.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (aedd.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      aedd.checkArztInput = function () {
        // reset current errors
        for (var error in aedd.errors) {
          aedd.errors[error] = [];
        }

        // reset anchor scroll position
        aedd.error_scroll = null;

        // arzt_num
        if (!aedd.arzt.arzt_num) {
          aedd.errors.arzt_num.push("Es wurde keine Arzt-Nummer angegeben!");
          aedd.set_error_scroll("arzt_num_loc");
        } else {
          if (!aedd.isNumber(aedd.arzt.arzt_num)) {
            aedd.errors.arzt_num.push("Eingabe ist keine gültige Nummer!");
            aedd.set_error_scroll("arzt_num_loc");
          }

          if (aedd.arzt.arzt_num.length != 9) {
            aedd.errors.arzt_num.push("Arzt-Nummer muss eine 9-stellige Nummer sein!");
            aedd.set_error_scroll("arzt_num_loc");
          }
        }

        // betriebs_num
        if (!aedd.arzt.betriebs_num) {
          aedd.errors.betriebs_num.push("Es wurde keine Betriebsnummer angegeben!");
          aedd.set_error_scroll("betriebs_num_loc");
        } else {
          if (!aedd.isNumber(aedd.arzt.betriebs_num)) {
            aedd.errors.betriebs_num.push("Eingabe ist keine gültige Nummer!");
            aedd.set_error_scroll("betriebs_num_loc");
          }

          if (aedd.arzt.betriebs_num.length != 9) {
            aedd.errors.betriebs_num.push("Betriebsnummer muss eine 9-stellige Nummer sein!");
            aedd.set_error_scroll("betriebs_num_loc");
          }
          if (aedd.arzt.betriebs_num == "999999999") {
            aedd.errors.betriebs_num.push("Betriebsnummer 999999999 darf nicht verwendet werden!");
            aedd.set_error_scroll("betriebs_num_loc");
          }
        }

        // name
        if (!aedd.arzt.name) {
          aedd.errors.arzt_name.push("Es wurde kein Name angegeben!");
          aedd.set_error_scroll("name_loc");
        } else {
          var matches = get_unique(aedd.arzt.name.match(aedd.regexp_text));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            aedd.errors.arzt_name.push(err_msg);
            aedd.set_error_scroll("name_loc");
          }
          if (aedd.arzt.name.length > 90) {
            aedd.errors.arzt_name.push("Name des Arztes ist zu lang! (> 90 Zeichen)");
            aedd.set_error_scroll("name_loc");
          }
        }

        // strasse

        if (aedd.arzt.strasse) {
          var matches = get_unique(aedd.arzt.strasse.match(aedd.regexp_text_num));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            aedd.errors.strasse.push(err_msg);
            aedd.set_error_scroll("strasse_loc");
          }
        }

        // plz
        if (aedd.arzt.plz && aedd.arzt.plz.length > 0) {
          //aedd.errors.plz.push("Es wurde keine PLZ angegeben!");
          //aedd.set_error_scroll('plz_loc');
          if (!aedd.isNumber(aedd.arzt.plz)) {
            aedd.errors.plz.push("Ungültige PLZ! (nur Zahlen sind erlaubt)");
            aedd.set_error_scroll("plz_loc");
          } else if (aedd.arzt.plz.length != 5) {
            aedd.errors.plz.push("PLZ ist nicht 5 Stellen lang!");
            aedd.set_error_scroll("plz_loc");
          }
        }

        // ort

        if (aedd.arzt.ort) {
          var matches = get_unique(aedd.arzt.ort.match(aedd.regexp_text_num));

          if (matches) {
            var err_msg = "Folgende Zeichen dürfen nicht verwendet werden: " + matches.join(" ");
            aedd.errors.ort.push(err_msg);
            aedd.set_error_scroll("ort_loc");
          }
        }

        return aedd.checkErrors();
      };

      aedd.saveArzt = function () {
        if (aedd.checkArztInput()) {
          aedd.arzt.$save().then(
            function (success_response) {
              aedd.returnToPreviousState();
            },
            function (error_response) {
              if (error_response["data"]["non_field_errors"][0] == "ArztExists") {
                aedd.errors.arzt_num.push("Arzt mit dieser Arztnummer exisitert bereits!");
                aedd.set_error_scroll("arzt_num_loc");
              }
            }
          );
        }
      };

      aedd.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      aedd.init();
    },
  ],
});
