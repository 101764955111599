angular.
  module('core.mka_store').
  factory('StoreService', function ( MitarbeiterService, RezepteHeaderService, ICD10Service, KundenService) {

    var store = {};

    function get (key) {
      return store[key]
    }

    function set (key, value) {
      store[key] = value;
    }

    function load_mitarbeiter () {
      MitarbeiterService.query().$promise.then(function (data) {
        store['mitarbeiter'] = data
      }).catch(function (error) {
        // fix for "Possibly unhandled rejection"
      });
    }

    function load_rezeptHeader (params) {
      RezepteHeaderService.query(params).$promise.then(function (data) {
        // change list to dict
        var dict_data = data.results.reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {});

        store['rezepte'] = dict_data;

      }).catch(function (error) {
        // fix for "Possibly unhandled rejection"
      });
    }

    function load_icd10 () {
      ICD10Service.query().$promise.then(function (data) {
        store['icd10_codes'] = data
      }).catch(function (error) {
        // fix for "Possibly unhandled rejection"
      });
    }

    function load_kunde () {
      KundenService.query().$promise.then(function(data){
        store['kunde'] = data[0]
      }).catch(function (error) {
        // fix for "Possibly unhandled rejection"
      })
    }

    return {
      get: get,
      set: set,
      load_mitarbeiter: load_mitarbeiter,
      load_rezeptHeader: load_rezeptHeader,
      load_icd10: load_icd10,
      load_kunde: load_kunde
    };

  });