angular.module("rezepteOverviewView").component("rezepteOverviewView", {
  templateUrl: "static/angular_assets/rezepte/rezepte_overview/overview_view/overview_view.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "RezepteHeaderService",
    "RezepteDatenService",
    "RezeptePositionenService",
    "QuittungService",
    "BehandlungsbescheinigungService",
    "RechnungService",
    "MedicalReportService",
    "FileSaver",
    "Popeye",
    "RememberService",
    "AerzteService",
    "PreisCalculateService",
    "store",
    "jwtHelper",
    "StoreService",
    "$stateParams",
    function RezepteOverviewViewController(
      $scope,
      $rootScope,
      $state,
      RezepteHeaderService,
      RezepteDatenService,
      RezeptePositionenService,
      QuittungService,
      BehandlungsbescheinigungService,
      RechnungService,
      MedicalReportService,
      FileSaver,
      Popeye,
      RememberService,
      AerzteService,
      PreisCalculateService,
      store,
      jwtHelper,
      StoreService,
      $stateParams
    ) {
      var rovc = this;

      rovc.init = function () {
        rovc.header_id = $stateParams.headerId;
        rovc.redirectFromNachricht = $stateParams.redirectFromNachricht;
        rovc.savedData = RememberService.rezeptViewData;

        // probably not needed anymore
        if (!rovc.header_id) {
          $state.go("menu.nav");
        }

        rovc.prevState = $rootScope.fromState;

        rovc.quittung_modal = null;
        rovc.delete_modal = null;

        rovc.isready = false;
        rovc.rezeptHeader = null;
        rovc.rezeptPositionen = null;
        rovc.rezeptDaten = null;
        rovc.totals = null;

        rovc.le_typ = jwtHelper.decodeToken(store.get("access_token")).le_typ;

        rovc.getRezeptHeaderDataFromDB(rovc.header_id);
        rovc.checkifready();
      };

      rovc.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      rovc.openQuittungModal = function (id) {
        // reset
        rovc.zahlart = null;
        rovc.quittung_id = null;

        // if quittung was previously created
        if (rovc.rezeptHeader.quittung) {
          rovc.getQuittung(id, null);
        }
        // first time quittung generation
        else {
          rovc.quittung_id = id;
          rovc.quittung_modal = Popeye.openModal({
            templateUrl:
              "static/angular_assets/rezepte/rezepte_overview/overview_view/modals/quittung_modal.template.html",
            scope: $scope,
            modalClass: "normal-modal-container",
          });
        }
      };

      rovc.closeQuittungModal = function () {
        rovc.quittung_modal.close();
      };

      rovc.openRechnungModal = function (id) {
        // reset
        rovc.zahlart = null;
        rovc.rechnung_id = null;

        // if quittung was previously created
        if (rovc.rezeptHeader.rechnung) {
          rovc.getRechnung(id, null);
        }
        // first time quittung generation
        else {
          rovc.rechnung_id = id;
          rovc.rechnung_modal = Popeye.openModal({
            templateUrl:
              "static/angular_assets/rezepte/rezepte_overview/overview_view/modals/rechnung_modal.template.html",
            scope: $scope,
            modalClass: "normal-modal-container",
          });
        }
      };

      rovc.closeRechnungModal = function () {
        rovc.rechnung_modal.close();
      };

      rovc.openDeleteModal = function () {
        // if quittung was previously created ignore this
        if (!rovc.rezeptHeader.quittung) {
          rovc.delete_modal = Popeye.openModal({
            templateUrl:
              "static/angular_assets/rezepte/rezepte_overview/overview_view/modals/delete_modal.template.html",
            scope: $scope,
            modalClass: "normal-modal-container",
          });
        }
      };

      rovc.closeDeleteModal = function () {
        rovc.delete_modal.close();
      };

      rovc.deleteRezept = function () {
        RezepteHeaderService.delete({
          id: rovc.header_id,
        })
          .$promise.then(function (data) {
            var rezepte = StoreService.get("rezepte");
            delete rezepte[rovc.header_id];
            StoreService.set("rezepte", rezepte);

            rovc.delete_modal.close();
            rovc.returnToPreviousState();
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      rovc.editRezept = function () {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams, { headerId: rovc.header_id });
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);

        $state.go("menu.rezepte-edit", newParams);
      };

      rovc.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      rovc.date_to_str_ger = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return (ddChars[1] ? dd : "0" + ddChars[0]) + "." + (mmChars[1] ? mm : "0" + mmChars[0]) + "." + yyyy;
      };

      // ########## GET DATA FUNCTIONS ##########
      // ########################################

      rovc.getRezeptHeaderDataFromDB = function (header_id) {
        RezepteHeaderService.get({
          id: header_id,
        })
          .$promise.then(function (data) {
            // save returned data
            rovc.rezeptHeader = data;
            rovc.getArzt(rovc.rezeptHeader.arzt_num);
            rovc.kostentraeger_typ = rovc.rezeptHeader.kostentraeger_typ;

            rovc.getRezeptDatenData(header_id);
            rovc.getRezeptPositionenData(header_id);
            rovc.getTotals(header_id);
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rovc.getArzt = function (arztNum) {
        AerzteService.get({
          arztNum: arztNum,
        })
          .$promise.then(function (data) {
            rovc.arzt_num = data.arzt_num;
            rovc.betr_num = data.betriebs_num;
            rovc.arzt_name = data.name;
          })
          .catch(function (error) {
            rovc.arzt_num = "999999999";
          });
      };

      rovc.getRezeptDatenData = function (header_id) {
        RezepteDatenService.query({
          header: header_id,
        })
          .$promise.then(function (data) {
            rovc.rezeptDaten = data;

            for (var i = 0; i < data.length; i++) {
              rovc.rezeptDaten[i].date = rovc.str_to_date(rovc.rezeptDaten[i].datum);
              rovc.rezeptDaten[i].weekday = rovc.getweekday(rovc.rezeptDaten[i].date);
            }
          })
          .catch(function (error) {});
      };

      rovc.getRezeptPositionenData = function (header_id) {
        RezeptePositionenService.query({
          header: header_id,
        })
          .$promise.then(function (data) {
            rovc.rezeptPositionen = data;
          })
          .catch(function (error) {});
      };

      rovc.getTotals = function (header_id) {
        PreisCalculateService.get({
          header_id: header_id,
        })
          .$promise.then(function (data) {
            rovc.totals = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rovc.getQuittung = function (id, zahlart) {
        rovc.rezeptHeader.quittung = true;

        // update in store
        var rezepte = StoreService.get("rezepte");
        rezepte[id] = rovc.rezeptHeader;
        StoreService.set("rezepte", rezepte);

        if (rovc.quittung_modal) {
          rovc.quittung_modal.close();
        }
        QuittungService.pdf({
          id: id,
          zahlart: zahlart,
        })
          .$promise.then(function (data) {
            FileSaver.saveAs(data.response, data.filename);
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rovc.getBescheinigung = function (id) {
        rovc.rezeptHeader.bescheinigung = true;

        // update in store
        var rezepte = StoreService.get("rezepte");
        rezepte[id] = rovc.rezeptHeader;
        StoreService.set("rezepte", rezepte);

        BehandlungsbescheinigungService.pdf({
          id: id,
        })
          .$promise.then(function (data) {
            FileSaver.saveAs(data.response, data.filename);
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rovc.getMedicalReport = function (id) {
        MedicalReportService.pdf({
          id: id,
        })
          .$promise.then(function (data) {
            FileSaver.saveAs(data.response, data.filename);
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      rovc.getRechnung = function (id, zahlart) {
        rovc.rezeptHeader.rechnung = true;
        rovc.rezeptHeader.status = "Abgerechnet";

        // update in store
        var rezepte = StoreService.get("rezepte");
        rezepte[id] = rovc.rezeptHeader;
        StoreService.set("rezepte", rezepte);

        if (rovc.rechnung_modal) {
          rovc.rechnung_modal.close();
        }

        RechnungService.pdf({
          id: id,
          zahlart: zahlart,
        })
          .$promise.then(function (data) {
            FileSaver.saveAs(data.response, data.filename);

            // update rezept in store
            if (!rovc.rezeptHeader.rechnung) {
              RezepteHeaderService.get({
                id: id,
              })
                .$promise.then(function (success_response) {
                  var rezepte = StoreService.get("rezepte");
                  rezepte[id] = success_response;
                  StoreService.set("rezepte", rezepte);

                  rovc.getRezeptHeaderDataFromStore(id);
                })
                .catch(function (error) {
                  // fix for "Possibly unhandled rejection"
                });
            }
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      // returns written weekday based on provided date
      rovc.getweekday = function (dt) {
        var days = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
        return days[dt.getDay()];
      };

      rovc.checkifready = function () {
        if (!rovc.rezeptHeader || !rovc.rezeptPositionen || !rovc.rezeptDaten || !rovc.totals) {
          setTimeout(rovc.checkifready, 50);
        } else {
          rovc.isready = true;
          $scope.$apply();
        }
      };

      rovc.initFooTable = function () {
        rovc.table.footable();
      };

      rovc.init();
    },
  ],
});
