angular.
  module('core.rezepte_header').
  factory('RezepteHeaderService', ['$resource', '__env',
    function($resource, __env) {
      return $resource(__env.apiUrl + 'rezeptheader/\:id/',{id: "@id"},
      {
        'query': { method:'GET', isArray:false },
        'update': { method:'PUT' }
      }
    );
    }
  ]);