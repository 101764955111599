angular.module("offenepostenEdit").component("offenepostenEdit", {
  templateUrl: "static/angular_assets/offene_posten/offene_posten_edit/offene_posten_edit.template.html",
  controller: [
    "$scope",
    "$rootScope",
    "$state",
    "$anchorScroll",
    "$timeout",
    "OffenePostenService",
    "Popeye",
    "KassenbuchCreateService",
    "SettingsService",
    "MahnungService",
    "FileSaver",
    "$stateParams",
    function OffenePostenEditController(
      $scope,
      $rootScope,
      $state,
      $anchorScroll,
      $timeout,
      OffenePostenService,
      Popeye,
      KassenbuchCreateService,
      SettingsService,
      MahnungService,
      FileSaver,
      $stateParams
    ) {
      var ope = this;
      ope.offenerposten_id = $stateParams.offenerPostenId;

      ope.modal = null;

      ope.openKassenbuchModal = function () {
        // reset
        ope.zahlarten = [];
        ope.zahlungen = [ope.betrag1, ope.betrag2, ope.betrag3].filter(function (betrag) {
          return betrag > 0;
        });

        ope.modal = Popeye.openModal({
          templateUrl: "static/angular_assets/offene_posten/offene_posten_edit/modals/kassenbuch_modal.template.html",
          scope: $scope,
          click: false,
          keyboard: false,
          modalClass: "no_close normal-modal-container",
        });
      };

      ope.openMahnungDeleteModal = function (stufe) {
        ope.deleteStufe = stufe;
        ope.modal = Popeye.openModal({
          templateUrl:
            "static/angular_assets/offene_posten/offene_posten_edit/modals/mahnung_delete_modal.template.html",
          scope: $scope,
          click: false,
          keyboard: false,
          modalClass: "no_close extended-modal-container",
        });
      };

      ope.closeModal = function () {
        ope.disable_mahndatum1 = true;
        ope.disable_mahndatum2 = true;
        ope.disable_mahndatum3 = true;

        if (ope.modal) {
          ope.modal.close();
          ope.modal = null;
        }
      };

      ope.getSettingsData = function () {
        SettingsService.get()
          .$promise.then(function (data) {
            ope.settings = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      ope.init = function () {
        // holds errors
        ope.errors = {
          betrag1: [],
          betrag2: [],
          betrag3: [],
          total: [],
        };

        // used for anchor scroll to first error
        ope.error_scroll = null;
        $anchorScroll.yOffset = 200;

        ope.isready = false;

        ope.offenerposten = null;
        ope.re_text = null;

        ope.datum1 = null;
        ope.datum2 = null;
        ope.datum3 = null;

        ope.betrag1 = "0.00";
        ope.betrag2 = "0.00";
        ope.betrag3 = "0.00";

        ope.mahndatum1 = null;
        ope.mahndatum2 = null;
        ope.mahndatum3 = null;
        ope.mahnwert1 = "0.00";
        ope.mahnwert2 = "0.00";
        ope.mahnwert3 = "0.00";

        ope.settings = null;

        ope.disable_mahndatum1 = true;
        ope.disable_mahndatum2 = true;
        ope.disable_mahndatum3 = true;

        ope.mahnung_used_flag = false;

        ope.zahlungen_changed = false;
        ope.mahnungen_changed = false;

        ope.getOffenerPostenDetails();
        ope.getSettingsData();

        ope.checkifready();
      };

      ope.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
      };

      ope.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      ope.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      ope.getMahnungPDF = function (stufe) {
        MahnungService.pdf({
          id: ope.offenerposten_id,
          stufe: stufe,
        })
          .$promise.then(function (data) {
            FileSaver.saveAs(data.response, data.filename);
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      ope.deleteMahnungOption = function (stufe) {
        // if automatic mahnpruefung is active show popup with information
        ope["disable_mahndatum" + stufe] = false;

        ope.openMahnungDeleteModal(stufe);
      };

      ope.resetMahnungStufe = function (stufe) {
        // disable automatic mahnpruefung
        ope.offenerposten.mahnpruefung = false;

        ope.offenerposten["mahndatum" + stufe] = null;
        ope.offenerposten["mahnwert" + stufe] = "0.00";

        // reset values
        ope["mahndatum" + stufe] = null;
        angular.element(document.querySelector("#mahndatum" + stufe))[0].value = null;
        ope["mahnwert" + stufe] = "0.00";

        ope.mahnungen_changed = true;

        //recalculate offenen wert und mahnungswert
        ope.recalculateValues();

        ope.closeModal();
      };

      ope.setdefaults = function () {
        ope.re_text =
          ope.offenerposten.name1 +
          ", " +
          ope.offenerposten.name2 +
          "\n" +
          ope.offenerposten.strasse +
          "\n" +
          ope.offenerposten.plz +
          " " +
          ope.offenerposten.ort;

        if (ope.offenerposten.zahldatum1) {
          ope.datum1 = ope.str_to_date(ope.offenerposten.zahldatum1);
        }

        if (ope.offenerposten.zahldatum2) {
          ope.datum2 = ope.str_to_date(ope.offenerposten.zahldatum2);
        }

        if (ope.offenerposten.zahldatum3) {
          ope.datum3 = ope.str_to_date(ope.offenerposten.zahldatum3);
        }

        ope.betrag1 = ope.offenerposten.gezahlter_wert1;
        ope.betrag2 = ope.offenerposten.gezahlter_wert2;
        ope.betrag3 = ope.offenerposten.gezahlter_wert3;

        ope.mahnwert1 = ope.offenerposten.mahnwert1;
        ope.mahnwert2 = ope.offenerposten.mahnwert2;
        ope.mahnwert3 = ope.offenerposten.mahnwert3;

        ope.summe_mahnkosten = (
          Math.round((Number(ope.mahnwert1) + Number(ope.mahnwert2) + Number(ope.mahnwert3)) * 1e12) / 1e12
        )
          .toFixed(2)
          .toString();

        if (ope.offenerposten.mahndatum1) {
          ope.mahndatum1 = ope.str_to_date(ope.offenerposten.mahndatum1);
          ope.mahnung_used_flag = true;
        }

        if (ope.offenerposten.mahndatum2) {
          ope.mahndatum2 = ope.str_to_date(ope.offenerposten.mahndatum2);
        }

        if (ope.offenerposten.mahndatum3) {
          ope.mahndatum3 = ope.str_to_date(ope.offenerposten.mahndatum3);
        }

        ope.kassenbuch_eintrag = ope.offenerposten.kassenbuch_eintrag;
      };

      ope.getOffenerPostenDetails = function () {
        OffenePostenService.get({
          id: ope.offenerposten_id,
        })
          .$promise.then(function (data) {
            ope.offenerposten = data;
            ope.setdefaults();
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
            console.log(error);
          });
      };

      ope.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in ope.errors) {
          if (!ope.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (ope.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      ope.set_error_scroll = function (loc) {
        if (!ope.error_scroll) {
          ope.error_scroll = true;
          $timeout(function () {
            $anchorScroll(loc);
          }, 0);
        }
      };

      ope.checkInput = function () {
        // reset current errors
        for (var error in ope.errors) {
          ope.errors[error] = [];
        }

        // reset anchor scroll position
        ope.error_scroll = null;

        ope.zahldaten = [];

        for (var i = 1; i <= 3; i++) {
          if (!ope.isNumber(ope["betrag" + i])) {
            ope.errors["betrag" + i].push("Betrag ist keine gültige Nummer!");
            ope.set_error_scroll("betrag" + i + "1_loc");
          } else {
            if (Number(ope["betrag" + i]) == 0) {
              // betrag was modified
              if (ope["betrag" + i] != ope.offenerposten["gezahlter_wert" + i]) {
                ope.zahlungen_changed = true;
              }

              ope.offenerposten["zahldatum" + i] = null;
              ope.offenerposten["gezahlter_wert" + i] = "0.00";
            } else {
              if (!ope["datum" + i]) {
                ope.errors["betrag" + i].push("Zahlungsdatum fehlt!");
                ope.set_error_scroll("betrag" + i + "_loc");
              } else {
                // betrag was modified
                if (ope["betrag" + i] != ope.offenerposten["gezahlter_wert" + i]) {
                  ope.zahlungen_changed = true;
                }

                ope.offenerposten["gezahlter_wert" + i] = Number(ope["betrag" + i])
                  .toFixed(2)
                  .toString();
                if (ope["datum" + i]) {
                  // date was modified
                  if (ope.offenerposten["zahldatum" + i] != ope.date_to_str(ope["datum" + i])) {
                    ope.zahlungen_changed = true;
                  }

                  ope.offenerposten["zahldatum" + i] = ope.date_to_str(ope["datum" + i]);
                  ope.zahldaten.push(ope.date_to_str(ope["datum" + i]));
                }
              }
            }
          }
        }

        return ope.checkErrors();
      };

      ope.updateOffenerPosten = function () {
        if (!ope.offenerposten.mahnpruefung) {
          if (ope.mahndatum1) {
            ope.offenerposten.mahnwert1 = ope.mahnwert1;
            ope.offenerposten.mahndatum1 = ope.date_to_str(ope.mahndatum1);
          }
          if (ope.mahndatum2) {
            ope.offenerposten.mahnwert2 = ope.mahnwert2;
            ope.offenerposten.mahndatum2 = ope.date_to_str(ope.mahndatum2);
          }
          if (ope.mahndatum3) {
            ope.offenerposten.mahnwert3 = ope.mahnwert3;
            ope.offenerposten.mahndatum3 = ope.date_to_str(ope.mahndatum3);
          }
        }

        OffenePostenService.update(
          {
            id: ope.offenerposten_id,
          },
          ope.offenerposten
        ).$promise.then(function (data) {
          $state.reload();
        });
      };

      ope.recalculateValues = function () {
        var ow = ope.offenerposten.original_wert;
        var gw1 = ope.betrag1;
        var gw2 = ope.betrag2;
        var gw3 = ope.betrag3;
        var mb1 = ope.mahnwert1;
        var mb2 = ope.mahnwert2;
        var mb3 = ope.mahnwert3;

        ope.offenerposten.offener_wert = (
          Math.round(
            (Number(ow) - Number(gw1) - Number(gw2) - Number(gw3) + Number(mb1) + Number(mb2) + Number(mb3)) * 1e12
          ) / 1e12
        )
          .toFixed(2)
          .toString();
        ope.summe_mahnkosten = (Math.round((Number(mb1) + Number(mb2) + Number(mb3)) * 1e12) / 1e12)
          .toFixed(2)
          .toString();
      };

      ope.check_mahnungen_change = function () {
        if (
          ope.mahnwert1 != ope.offenerposten.mahnwert1 ||
          ope.mahnwert2 != ope.offenerposten.mahnwert2 ||
          ope.mahnwert3 != ope.offenerposten.mahnwert3
        ) {
          return true;
        }

        if (ope.mahndatum1) {
          if (ope.offenerposten.mahndatum1 != ope.date_to_str(ope.mahndatum1)) {
            return true;
          }
        } else {
          if (ope.offenerposten.mahndatum1) {
            return true;
          }
        }

        if (ope.mahndatum2) {
          if (ope.offenerposten.mahndatum2 != ope.date_to_str(ope.mahndatum2)) {
            return true;
          }
        } else {
          if (ope.offenerposten.mahndatum2) {
            return true;
          }
        }

        if (ope.mahndatum3) {
          if (ope.offenerposten.mahndatum3 != ope.date_to_str(ope.mahndatum3)) {
            return true;
          }
        } else {
          if (ope.offenerposten.mahndatum3) {
            return true;
          }
        }

        return false;
      };

      ope.openStornoModal = function () {
        // reset
        ope.zahlarten = [];
        ope.zahlungen = [ope.betrag1, ope.betrag2, ope.betrag3].filter(function (betrag) {
          return betrag > 0;
        });

        ope.modal = Popeye.openModal({
          templateUrl: "static/angular_assets/offene_posten/offene_posten_edit/modals/storno_modal.template.html",
          scope: $scope,
          click: false,
          keyboard: false,
          modalClass: "no_close extended-modal-container",
        });
      };

      ope.editOffenerPostenDetails = function () {
        if (ope.checkInput()) {
          ope.recalculateValues();

          // UPDATE KASSENBUCH_EINTRAG SETTING
          ope.offenerposten.kassenbuch_eintrag = ope.kassenbuch_eintrag;

          // SET Status
          if (Number(ope.offenerposten.offener_wert) == 0) {
            ope.offenerposten.status = "Bezahlt";
          } else {
            if (Number(ope.offenerposten.offener_wert) < 0) {
              ope.errors.total.push(
                "Summe aller gezahlter Beträge überschreitet den original geforderten Wert inkl. Mahngebühren!"
              );
              ope.set_error_scroll("total_loc");
            }
            ope.offenerposten.status = "Offen";
          }

          // CHECK MAHNUNGEN FOR CHANGES
          if (!ope.offenerposten.mahnpruefung && !ope.mahnungen_changed) {
            ope.mahnungen_changed = ope.check_mahnungen_change();
          }

          if (ope.checkErrors()) {
            // kassenbuch eintrag already created
            // ! THIS IS CURRENTLY DISABLED
            // ! There is no way to edit a kassenbuch eintrag once it is completed (e.g. status is PAID or CANCELED)
            if (ope.offenerposten.kassenbuch_eintrag_generiert) {
              if (ope.mahnungen_changed || ope.zahlungen_changed || !ope.offenerposten.kassenbuch_eintrag) {
                ope.openStornoModal();
              } else {
                ope.updateOffenerPosten();
              }
            }
            // kassenbuch eintrag not yet created
            else {
              // should a kassenbuch eintrag be generated?  - only if both kassenbuch_eintrag is set to true and status is PAID/Bezahlt
              if (ope.kassenbuch_eintrag && ope.offenerposten.status == 'Bezahlt') {
                ope.openKassenbuchModal();
              }
              // kassenbuch eintrag should not be created if status is still OPEN/Offen
              // only update offenen Posten
              else {
                ope.updateOffenerPosten();
              }
            }
          } else {
            //errors
          }
        } else {
          //errors
        }
      };

      ope.createKassenbuchEintrag = function () {
        ope.KassenbuchEntries = new KassenbuchCreateService();
        ope.KassenbuchEntries.posten_id = ope.offenerposten_id;

        if (ope.offenerposten.status == 'Bezahlt' && ope.offenerposten.kassenbuch_eintrag) {
          ope.KassenbuchEntries.zahlungsart = ope.zahlarten;
          ope.KassenbuchEntries.zahlungen = ope.zahlungen;
          ope.KassenbuchEntries.zahldaten = ope.zahldaten;
        } else {
          ope.KassenbuchEntries.zahlungsart = [];
          ope.KassenbuchEntries.zahlungen = [];
          ope.KassenbuchEntries.zahldaten = [];
        }

        if (ope.offenerposten.kassenbuch_eintrag_generiert) {
          ope.KassenbuchEntries.storno = true;
        } else {
          ope.KassenbuchEntries.storno = false;
        }

        ope.KassenbuchEntries.$save().then(
          function (success_response) {
            if (ope.offenerposten.status == 'Bezahlt' && ope.offenerposten.kassenbuch_eintrag) {
              ope.offenerposten.kassenbuch_eintrag_generiert = true;
            } else {
              ope.offenerposten.kassenbuch_eintrag_generiert = false;
            }
            ope.updateOffenerPosten();
            ope.closeModal();
          },
          function (error) {
            console.log(error);
          }
        );
      };

      ope.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      ope.checkifready = function () {
        if (!ope.offenerposten || !ope.settings) {
          setTimeout(ope.checkifready, 50);
          return;
        }
        ope.isready = true;
      };

      ope.init();
    },
  ],
});
