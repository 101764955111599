angular.
module('abrechnungDownload').
component('abrechnungDownload', {
	templateUrl: 'static/angular_assets/admin/abrechnung_download/abrechnung_download.template.html',
	controller: ['$scope',
		'$rootScope',
		'$state',
		'$filter',
		'store',
		'jwtHelper',
		'AbrechnungService',
		'FileSaver',
		'Blob',
		'DownloadAbrechnungService',
		function AbrechnungDownloadController($scope,
			$rootScope,
			$state,
			$filter,
			store,
			jwtHelper,
			AbrechnungService,
			FileSaver,
			Blob,
			DownloadAbrechnungService) {

			var rab = this;

			rab.init = function () {
				rab.tables = {
					filter_search_data: {},
				}

				rab.kunde = jwtHelper.decodeToken(store.get('access_token')).user_id
				rab.fromState = $rootScope.fromState

				rab.tables.filter_search_data = {
					query: '',
					select_filter: '',
					sort: {
						sortingOrder: 'rezeptdatum',
						reverse: true
					},
					gap: 3,
					filteredItems: [],
					itemsPerPage: 20,
					pagedItems: [],
					currentPage: 1,
					kunden_filter: null,
					status_filter: 'Neu',
				}

				rab.abrechnungen = null;
				rab.abrechnungen_cnt = 0;
				rab.isready = false;


				// get related rezepte first
				rab.getAbrechnungData()

				// check if ready to be displayed
				rab.checkifready();
			}

			rab.str_to_date = function (str) {
				var parts = str.split('-');
				var dt = new Date(parts[0], parts[1] - 1, parts[2]);
				return dt
			}

			rab.date_to_str = function (dt) {
				yyyy = dt.getFullYear().toString();
				mm = (dt.getMonth() + 1).toString();
				dd = dt.getDate().toString();

				// CONVERT mm AND dd INTO chars
				var mmChars = mm.split('');
				var ddChars = dd.split('');
				return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);

			}


			// ############ TABLE FUNCTIONS ############
			// #########################################

			// function to find a given string (needle) in given attribute (haystack)
			var searchMatch = function (haystack, needle) {
				if (!needle) {
					return true;
				}
				if (!haystack) {
					return false;
				}
				return haystack.toString().toLowerCase().indexOf(needle.toLowerCase()) !== -1;
			};

			// TODO
			// search-filter to filter out only relevant data; based on query term and a filter-dropdown (rab.select_filter)
			rab.filter_abrechnungen = function (data) {
				var sp = rab.tables.filter_search_data

				sp.filteredItems = $filter('filter')(data, function (item) {
					if (sp.kunden_filter) {
						if (item['kunden_ik_num'] != sp.kunden_filter.ik_num) {
							return false
						}
					}

					if (item['status'] != sp.status_filter) {
						return false
					}

					// all checks were successful - item is relevant so return true
					return true;
				});

				// take care of the sorting order
				if (sp.sort.sortingOrder !== '') {
					sp.filteredItems = $filter('orderBy')(sp.filteredItems, sp.sort.sortingOrder, sp.sort.reverse);
				}
				sp.currentPage = 1;

				// now group by pages
				rab.groupToPages(rab.tables.filter_search_data);
			};

			// calculate and group filteredItems into pages with itemsPerPage 
			rab.groupToPages = function (cnt) {
				var sp = cnt
				sp.pagedItems = []

				for (var i = 0; i < sp.filteredItems.length; i++) {
					if (i % sp.itemsPerPage === 0) {
						sp.pagedItems[Math.floor(i / sp.itemsPerPage)] = [sp.filteredItems[i]];
					} else {
						sp.pagedItems[Math.floor(i / sp.itemsPerPage)].push(sp.filteredItems[i]);
					}
				}
			};

			// pagination range
			rab.range = function (size, curr) {
				var ret = [];

				if (size == 1) {
					return ret
				} else if (size <= 5) {
					for (var i = 2; i <= size - 1; i++) {
						ret.push(i);
					}
					return ret
				} else {
					// left end
					if (curr == 1) {
						ret.push(curr + 1)
						ret.push(curr + 2)
						ret.push(curr + 3)
					} else if (curr == size) {
						ret.push(curr - 3)
						ret.push(curr - 2)
						ret.push(curr - 1)

					} else if (curr - 1 == 1) {
						ret.push(curr)
						ret.push(curr + 1)
						ret.push(curr + 2)
					} else if (curr + 1 == size) {
						ret.push(curr - 2)
						ret.push(curr - 1)
						ret.push(curr)

					} else {
						ret.push(curr - 1)
						ret.push(curr)
						ret.push(curr + 1)
					}
					return ret
				}
			};

			// pagination back-button function 
			rab.prevPage = function (cnt) {
				var d = rab.tables[cnt]

				if (d.currentPage > 1) {
					d.currentPage--;
				}
			};

			// pagination forward-button function 
			rab.nextPage = function (cnt) {
				var d = rab.tables[cnt]
				if (d.currentPage < d.pagedItems.length) {
					d.currentPage++;
				}
			};

			// set currently viewed page to n
			rab.setPage = function (cnt, n) {
				var d = rab.tables[cnt]
				d.currentPage = n;
			};

			//functions to sort data (for tables) based on multiple attributes/properties
			function dynamicSort(property) {
				var sortOrder = 1;
				if (property[0] === "-") {
					sortOrder = -1;
					property = property.substr(1);
				}
				return function (a, b) {
					var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
					return result * sortOrder;
				}
			}

			rab.dynamicSortMultiple = function () {
				var props = arguments;
				return function (obj1, obj2) {
					var i = 0,
						result = 0,
						numberOfProperties = props.length;
					/* try getting a different result from 0 (equal)
					 * as long as we have extra properties to compare
					 */
					while (result === 0 && i < numberOfProperties) {
						result = dynamicSort(props[i])(obj1, obj2);
						i++;
					}
					return result;
				}
			}
			// #########################################



			rab.getAbrechnungData = function (pat) {

				AbrechnungService.query({
					status: ['Neu', 'Bearbeitung']
				}).$promise.then(function (data) {
					rab.abrechnungen = data;
					rab.abrechnungen_cnt = rab.abrechnungen.length;

					rab.kunden = {}
					for (var i = 0; i < rab.abrechnungen.length; i++) {
						var entry = rab.abrechnungen[i]
						if (!(entry.kunden_ik_num in rab.kunden)) {
							rab.kunden[entry.kunden_ik_num] = {
								nachname: entry.kunden_nachname,
								vorname: entry.kunden_vorname,
								ik_num: entry.kunden_ik_num
							}
						}
					}



				}).catch(function (error) {
					console.log(error);
					// fix for "Possibly unhandled rejection"
				});
			}



			rab.getAbrechnung = function () {
				var ik_num = rab.tables.filter_search_data.kunden_filter.ik_num

				DownloadAbrechnungService.zip({
					ik_num: ik_num
				}).$promise.then(function (data) {
					FileSaver.saveAs(data.response, data.filename);
					// reload page to update data
					$state.reload();
				}).catch(function (error) {
					console.log(error)
				});
			}








			rab.checkifready = function () {
				if (!rab.abrechnungen) {
					setTimeout(rab.checkifready, 50);
				} else {
					rab.filter_abrechnungen(rab.abrechnungen);
					rab.isready = true;
					$scope.$apply()
				}
			}

			// INIT
			rab.init()
		}
	],
});