angular.module("rezepteNav").component("rezepteNav", {
  templateUrl: "static/angular_assets/rezepte_nav/rezepte_nav.template.html",
  controller: [
    "$state",
    "$stateParams",
    function RezepteNavController($state, $stateParams) {
      var rnc = this;

      // Navigation
      rnc.goToState = function (stateName) {
        var stateChainEntry = {
          state: $state.current.name,
          params: {},
        };

        var newParams = Object.assign({}, $stateParams);
        if (!newParams.stateChain) {
          newParams.stateChain = [];
        }
        newParams.stateChain.push(stateChainEntry);
        $state.go(stateName, newParams);
      };

      rnc.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };
    },
  ],
});
