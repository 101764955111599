angular.module("rezepteAddSummary").component("rezepteAddSummary", {
  templateUrl: "static/angular_assets/rezepte/rezepte_add_new/rezepte_add_summary/rezepte_add_summary.template.html",
  controller: [
    "$scope",
    "$state",
    "Popeye",
    "StoreService",
    "BefreiungenService",
    "$stateParams",
    function RezepteAddController($scope, $state, Popeye, StoreService, BefreiungenService, $stateParams) {
      var ras = this;

      ras.init = function () {
        // if no parameters were provided return to rezepte_add_selection
        if (!$stateParams.params) {
          ras.returnToPreviousState();
          return;
        }

        // modal object
        ras.modal = null;

        ras.awaiting_response = false;

        // rezeptHeader object
        ras.rezeptHeader = $stateParams.params.rezeptHeader;

        // patient object
        ras.patient = $stateParams.params.patient;

        // general object that holds data related to patient/rezeptHeader
        ras.general = $stateParams.params.general;

        // mitarbeiter object
        ras.mitarbeiter = $stateParams.params.mitarbeiter;

        // positions object
        ras.positions = $stateParams.params.positions;

        // dates object
        ras.dates = $stateParams.params.dates;

        ras.befreiungen = null;

        if (ras.patient.kt_typ == "KK") {
          ras.getRelevantBefreiungenData();
          ras.checkifready();
        } else {
          ras.isready = true;
        }
      };

      ras.checkifready = function () {
        if (!ras.befreiungen) {
          setTimeout(ras.checkifready, 50);
        } else {
          ras.isready = true;
          // $scope.$apply();
        }
      };

      ras.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      ras.date_to_str_ger = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return (ddChars[1] ? dd : "0" + ddChars[0]) + "." + (mmChars[1] ? mm : "0" + mmChars[0]) + "." + yyyy;
      };

      ras.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      // ########## MODAL NAVIGATION ###########
      // #######################################

      ras.returnNewPatient = function () {
        ras.closeModal();

        // pop states until rezepte-add-selection is found
        while ($stateParams.stateChain.length > 0) {
          // pop only if state is not rezepte-add-selection
          if ($stateParams.stateChain.slice(-1)[0].state != "menu.rezepte-add-selection") {
            $stateParams.stateChain.pop();
          } else {
            break;
          }
        }

        ras.returnToPreviousState();
      };

      ras.returnSamePatient = function () {
        ras.closeModal();

        // pop states until rezepte-add-header is found
        while ($stateParams.stateChain.length > 0) {
          // pop only if state is not rezepte-add-header
          if ($stateParams.stateChain.slice(-1)[0].state != "menu.rezepte-add-header") {
            $stateParams.stateChain.pop();
          } else {
            break;
          }
        }

        // make sure that patientenId is set otherwise rezepte-add-header will redirect to menu.nav
        $stateParams.stateChain[$stateParams.stateChain.length - 1].params.patientenId = ras.patient.id;

        ras.returnToPreviousState();
      };

      ras.returnMainmenu = function () {
        ras.closeModal();
        $state.go("menu.nav");
      };
      // #######################################

      ras.openSuccessModal = function () {
        ras.awaiting_response = false;
        ras.modal = Popeye.openModal({
          templateUrl:
            "static/angular_assets/rezepte/rezepte_add_new/rezepte_add_summary/modals/success_modal.template.html",
          scope: $scope,
          click: false,
          keyboard: false,
          modalClass: "normal-modal-container",
        });
      };

      ras.closeModal = function () {
        ras.modal.close();
      };

      ras.lockRezept = function () {
        if (ras.rezeptHeader.status == "Vordatiert") {
          ras.rezeptHeader.status = "Vordatiert-Gesperrt";
        } else {
          ras.rezeptHeader.status = "Neu-Gesperrt";
        }

        ras.saveRezept();
      };

      ras.getRelevantBefreiungenData = function () {
        var from_date = ras.date_to_str(ras.dates.daten_entries[0].datum);
        var to_date = ras.date_to_str(ras.dates.daten_entries.slice(-1)[0].datum);

        var date_from_to = from_date + "+" + to_date;

        BefreiungenService.query({ patient: ras.patient.id, datum_von_bis: date_from_to })
          .$promise.then(function (data) {
            ras.befreiungen = data;
          })
          .catch(function (error) {
            // fix for "Possibly unhandled rejection"
          });
      };

      ras.saveRezept = function () {
        ras.awaiting_response = true;
        ras.rezeptHeader.$save().then(
          function (success_response) {
            var rezepte = StoreService.get("rezepte");
            rezepte[success_response.id] = success_response;
            StoreService.set("rezepte", rezepte);

            ras.openSuccessModal();
          },
          function (error) {
            console.log(error);
          }
        );
      };

      ras.init();
    },
  ],
});
