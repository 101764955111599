angular.module("kassenbuchOverview").component("kassenbuchOverview", {
  templateUrl: "static/angular_assets/kassenbuch/kassenbuch_overview/kassenbuch_overview.template.html",
  controller: [
    "$state",
    "$scope",
    "$filter",
    "KassenbuchDatenService",
    "KassenbuchService",
    "FileSaver",
    "$stateParams",
    function KassenbuchOverviewController(
      $state,
      $scope,
      $filter,
      KassenbuchDatenService,
      KassenbuchService,
      FileSaver,
      $stateParams
    ) {
      var koc = this;

      koc.total_geb = 0;
      koc.total_aus = 0;

      koc.filter = {
        vorgangsart: "ALL",
        zahlungsart: "ALL",
        datum_von: null,
        datum_bis: null,
      };

      koc.filtered_rows = null;
      koc.filtered_rows_length = 0;

      //TBC <<<--------------------

      koc.str_to_date = function (str) {
        var parts = str.split("-");
        var dt = new Date(parts[0], parts[1] - 1, parts[2]);
        return dt;
      };

      koc.date_to_str = function (dt) {
        yyyy = dt.getFullYear().toString();
        mm = (dt.getMonth() + 1).toString();
        dd = dt.getDate().toString();

        // CONVERT mm AND dd INTO chars
        var mmChars = mm.split("");
        var ddChars = dd.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
      };

      // ############ TABLE DATA/FUNCTIONS ############
      // #########################################

      // table object information
      koc.tables = {
        kassenbuch_search_data: {},
      };

      koc.tables.kassenbuch_search_data = {
        query: "",
        select_filter: "",
        sort: {
          sortingOrder: "id",
          reverse: true,
        },
        gap: 3,
        filteredItems: [],
        itemsPerPage: 10,
        pagedItems: [],
        currentPage: 1,
        erstellungsdatum_von_filter: "",
        erstellungsdatum_bis_filter: "",
        vorgangsart_filter: "ALL",
        zahlungsart_filter: "ALL",
      };

      var searchMatch = function (haystack, needle) {
        if (!needle) {
          return true;
        }
        if (!haystack) {
          return false;
        }
        return haystack.toString().toLowerCase().indexOf(needle.toLowerCase()) !== -1;
      };

      koc.search_kassenbuch_data = function (data) {
        var sp = koc.tables.kassenbuch_search_data;

        sp.filteredItems = $filter("filter")(data, function (item) {
          // 1. check erstellungsdatum
          var erstellungsdatum = koc.str_to_date(item["erstellungsdatum"]);
          if (sp.erstellungsdatum_von_filter && sp.erstellungsdatum_bis_filter) {
            if (
              erstellungsdatum < sp.erstellungsdatum_von_filter ||
              erstellungsdatum > sp.erstellungsdatum_bis_filter
            ) {
              return false;
            }
          } else if (sp.erstellungsdatum_von_filter) {
            if (erstellungsdatum < sp.erstellungsdatum_von_filter) {
              return false;
            }
          } else if (sp.erstellungsdatum_bis_filter) {
            if (erstellungsdatum > sp.erstellungsdatum_bis_filter) {
              return false;
            }
          }

          // 2. check vorgangsart
          if (sp.vorgangsart_filter != "ALL") {
            if (item["vorgangsart"] != sp.vorgangsart_filter) {
              return false;
            }
          }

          // 3. check zahlungsart
          if (sp.zahlungsart_filter != "ALL") {
            if (item["zahlungsart"] != sp.zahlungsart_filter) {
              return false;
            }
          }

          // all checks were successful - item is relevant so return true
          return true;
        });

        // take care of the sorting order
        if (sp.sort.sortingOrder !== "") {
          sp.filteredItems = $filter("orderBy")(sp.filteredItems, sp.sort.sortingOrder, sp.sort.reverse);
        }
        sp.currentPage = 1;

        // calculate totals
        koc.total_geb = 0;
        koc.total_aus = 0;
        for (var i = 0; i < sp.filteredItems.length; i++) {
          koc.total_geb += Math.round(Number(sp.filteredItems[i]["gebuehr"]) * 1e12) / 1e12;
          koc.total_aus += Math.round(Number(sp.filteredItems[i]["ausgaben"]) * 1e12) / 1e12;
        }
        koc.total_geb = koc.total_geb.toFixed(2);
        koc.total_aus = koc.total_aus.toFixed(2);

        // now group by pages
        koc.groupToPages(koc.tables.kassenbuch_search_data);
      };

      // calculate and group filteredItems into pages with itemsPerPage
      koc.groupToPages = function (cnt) {
        var sp = cnt;
        sp.pagedItems = [];

        for (var i = 0; i < sp.filteredItems.length; i++) {
          if (i % sp.itemsPerPage === 0) {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)] = [sp.filteredItems[i]];
          } else {
            sp.pagedItems[Math.floor(i / sp.itemsPerPage)].push(sp.filteredItems[i]);
          }
        }
      };

      // pagination range
      koc.range = function (size, curr) {
        var ret = [];

        if (size == 1) {
          return ret;
        } else if (size <= 5) {
          for (var i = 2; i <= size - 1; i++) {
            ret.push(i);
          }
          return ret;
        } else {
          // left end
          if (curr == 1) {
            ret.push(curr + 1);
            ret.push(curr + 2);
            ret.push(curr + 3);
          } else if (curr == size) {
            ret.push(curr - 3);
            ret.push(curr - 2);
            ret.push(curr - 1);
          } else if (curr - 1 == 1) {
            ret.push(curr);
            ret.push(curr + 1);
            ret.push(curr + 2);
          } else if (curr + 1 == size) {
            ret.push(curr - 2);
            ret.push(curr - 1);
            ret.push(curr);
          } else {
            ret.push(curr - 1);
            ret.push(curr);
            ret.push(curr + 1);
          }
          return ret;
        }
      };

      // pagination back-button function
      koc.prevPage = function (cnt) {
        var d = koc.tables[cnt];

        if (d.currentPage > 1) {
          d.currentPage--;
        }
      };

      // pagination forward-button function
      koc.nextPage = function (cnt) {
        var d = koc.tables[cnt];
        if (d.currentPage < d.pagedItems.length) {
          d.currentPage++;
        }
      };

      // set currently viewed page to n
      koc.setPage = function (cnt, n) {
        var d = koc.tables[cnt];
        d.currentPage = n;
      };

      //functions to sort data (for tables) based on multiple attributes/properties
      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      koc.dynamicSortMultiple = function () {
        var props = arguments;
        return function (obj1, obj2) {
          var i = 0,
            result = 0,
            numberOfProperties = props.length;
          /* try getting a different result from 0 (equal)
           * as long as we have extra properties to compare
           */
          while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
          }
          return result;
        };
      };
      // #######################################

      // ########## GET DATA FUNCTIONS #########
      // #######################################
      koc.getKassenbuchData = function () {
        KassenbuchDatenService.query()
          .$promise.then(function (data) {
            koc.kassenbuch_data = data;
            koc.kassenbuch_data_cnt = koc.kassenbuch_data.length;
          })
          .catch(function (error) {
            console.log(error);
            // fix for "Possibly unhandled rejection"
          });
      };

      koc.getKassenbuch = function () {
        params = {};

        var snapshot = FooTable.get("#kassenbuch_table").use(FooTable.Snapshot).snapshot;
        koc.filtered_rows_length = snapshot.length;

        // only get Kassenbuch PDF if data exists
        if (koc.filtered_rows_length != 0) {
          if (koc.filter.datum_von) {
            params["from_date"] = koc.date_to_str(koc.filter.datum_von);
          }

          if (koc.filter.datum_bis) {
            params["to_date"] = koc.date_to_str(koc.filter.datum_bis);
          }

          if (koc.filter.vorgangsart != "ALL") {
            params["vorgangsart"] = koc.filter.vorgangsart;
          }

          if (koc.filter.zahlungsart != "ALL") {
            params["zahlungsart"] = koc.filter.zahlungsart;
          }

          KassenbuchService.pdf(params)
            .$promise.then(function (data) {
              FileSaver.saveAs(data.response, data.filename);
            })
            .catch(function (error) {
              // fix for "Possibly unhandled rejection"
            });
        }
      };

      koc.get_totals = function () {
        var snapshot = FooTable.get("#kassenbuch_table").use(FooTable.Snapshot).snapshot;

        // only calculate if data is present, otherwise its 0.00
        if (snapshot.length != 0) {
          koc.total_geb = 0;
          koc.total_aus = 0;
          for (var i = 0; i < snapshot.length; i++) {
            koc.total_geb += Math.round(Number(snapshot[i].value["gebuehr"]) * 1e12) / 1e12;
            koc.total_aus += Math.round(Number(snapshot[i].value["ausgaben"]) * 1e12) / 1e12;
          }
          koc.total_geb = koc.total_geb.toFixed(2);
          koc.total_aus = koc.total_aus.toFixed(2);
        } else {
          koc.total_geb = "0.00";
          koc.total_aus = "0.00";
        }
      };

      koc.on_change_vorgangsart = function () {
        var filtering = FooTable.get("#kassenbuch_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = koc.filter.vorgangsart; // get the value to filter by

        if (filter === "ALL") {
          // if the value is "none" remove the filter
          filtering.removeFilter("vorgangsart");
        } else {
          // otherwise add/update the filter.
          filter = '"' + filter + '"';
          filtering.addFilter("vorgangsart", filter, ["vorgangsart"]);
        }
        filtering.filter();

        koc.filtered_rows_length = FooTable.get("#kassenbuch_table").use(FooTable.Snapshot).snapshot.length;
        koc.get_totals();
      };

      koc.on_change_zahlungsart = function () {
        var filtering = FooTable.get("#kassenbuch_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = koc.filter.zahlungsart; // get the value to filter by

        if (filter === "ALL") {
          // if the value is "none" remove the filter
          filtering.removeFilter("zahlungsart");
        } else {
          // otherwise add/update the filter.
          filter = '"' + filter + '"';
          filtering.addFilter("zahlungsart", filter, ["zahlungsart"]);
        }
        filtering.filter();

        koc.filtered_rows_length = FooTable.get("#kassenbuch_table").use(FooTable.Snapshot).snapshot.length;
        koc.get_totals();
      };

      koc.on_change_datum_von = function () {
        var filtering = FooTable.get("#kassenbuch_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = koc.filter.datum_von; // get the value to filter by

        if (!filter) {
          // if the value is "none" remove the filter
          filtering.removeFilter("datum_von");
        } else {
          // otherwise add/update the filter.

          filter = ">" + koc.date_to_str(filter);
          filtering.addFilter("datum_von", filter, ["datum"]);
        }
        filtering.filter();

        koc.filtered_rows_length = FooTable.get("#kassenbuch_table").use(FooTable.Snapshot).snapshot.length;
        koc.get_totals();
      };

      koc.on_change_datum_bis = function () {
        var filtering = FooTable.get("#kassenbuch_table").use(FooTable.Filtering); // get the filtering component for the table
        var filter = koc.filter.datum_bis; // get the value to filter by

        if (!filter) {
          // if the value is "none" remove the filter
          filtering.removeFilter("datum_bis");
        } else {
          // otherwise add/update the filter.

          filter = "<" + koc.date_to_str(filter);
          filtering.addFilter("datum_bis", filter, ["datum"]);
        }
        filtering.filter();

        koc.filtered_rows_length = FooTable.get("#kassenbuch_table").use(FooTable.Snapshot).snapshot.length;
        koc.get_totals();
      };

      koc.initFooTable = function () {
        // custom dropdown filter
        FooTable.MyFiltering = FooTable.Filtering.extend({
          construct: function (instance) {
            this._super(instance);
            this.$filter_status = null;
          },

          $create: function () {
            this._super();
            var self = this;
            this.$filter_status = $(".btn-primary").on(
              "click",
              {
                self: self,
              },
              self._onFilterStatusChanged
            );
          },
          _onFilterStatusChanged: function (e) {
            koc.filter = {
              vorgangsart: "ALL",
              zahlungsart: "ALL",
              datum_von: null,
              datum_bis: null,
            };
          },
        });

        koc.table.footable({
          components: {
            filtering: FooTable.MyFiltering,
          },
        });

        // hide footable search input
        var footable_filtering = $("tr[class='footable-filtering']");
        if (footable_filtering) {
          footable_filtering.css("display", "none");
        }

        // get current count
        var snapshot = FooTable.get("#kassenbuch_table").use(FooTable.Snapshot).snapshot;
        koc.filtered_rows_length = snapshot.length;
        koc.get_totals();
      };

      koc.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      // #######################################

      // ########### CHECK FUNCTIONS ###########
      // #######################################
      koc.checkifready = function () {
        if (!koc.kassenbuch_data) {
          setTimeout(koc.checkifready, 50);
        } else {
          koc.isready = true;
          $scope.$apply();
        }
      };
      // #######################################

      koc.kassenbuch_data = null;
      koc.isready = false;

      //get all relevant KassenbuchData
      koc.getKassenbuchData();
      koc.checkifready();
    },
  ],
});
