angular.module("statistics").component("statistics", {
  templateUrl: "static/angular_assets/statistics/statistics.template.html",
  controller: [
    "StatisticsService",
    "FileSaver",
    "$timeout",
    "$stateParams",
    "$state",

    function StatisticsController(StatisticsService, FileSaver, $timeout, $stateParams, $state) {
      var stat = this;

      stat.init = function () {
        // holds errors
        stat.errors = {
          empty: [],
          start: [],
          end: [],
        };

        stat.filter = {
          datum_von: null,
          datum_bis: null,
          stat_typ: "UM",
          export_typ: "PDF",
          kt_typ: "NOPP",
        };

        stat.status = {
          loading: false,
          disabled: false,
        };

        stat.modal = null;
      };

      stat.adjust_stat_typ = function () {
        if (stat.filter.kt_typ == "PP") {
          stat.filter.stat_typ = "UM";
        }
      };

      stat.checkErrors = function () {
        // iterate over relevant object (error) elements and check if all errors are set to null
        for (var error in stat.errors) {
          if (!stat.errors.hasOwnProperty(error)) {
            //The current error is not a direct property of p
            continue;
          }
          if (stat.errors[error].length != 0) {
            return false;
          }
        }

        // no errors were found
        return true;
      };

      stat.getStatistics = function () {
        // reset errors
        for (var error in stat.errors) {
          stat.errors[error] = [];
        }

        stat.status.disabled = true;

        params = {
          datum_von: null,
          datum_bis: null,
          stat_typ: stat.filter.stat_typ,
          export_typ: stat.filter.export_typ,
          kt_typ: stat.filter.kt_typ,
        };

        datum_von = moment(stat.filter.datum_von);
        datum_bis = moment(stat.filter.datum_bis);

        if (datum_von.isValid()) {
          if (datum_von.year() < 2000) {
            stat.errors.start.push("Ungültiges Start-Datum!");
          }
          params.datum_von = datum_von.format("DDMMYYYY");
        }

        if (datum_bis.isValid()) {
          if (datum_bis.year() < 2000) {
            stat.errors.end.push("Ungültiges End-Datum!");
          }
          params.datum_bis = datum_bis.format("DDMMYYYY");
        }

        if (datum_von.isValid() && datum_bis.isValid()) {
          if (datum_bis.isBefore(datum_von)) {
            stat.errors.end.push("End-Datum liegt vor Start-Datum!");
          }
        }

        if (stat.checkErrors()) {
          stat.status.loading = true;

          $timeout(function () {
            if (stat.filter.stat_typ == "ALL") {
              StatisticsService.zip(params)
                .$promise.then(function (data) {
                  FileSaver.saveAs(data.response, data.filename);
                })
                .catch(function (error) {
                  stat.errors.empty.push(error.msg);
                });
            } else {
              if (stat.filter.export_typ == "PDF") {
                StatisticsService.pdf(params)
                  .$promise.then(function (data) {
                    FileSaver.saveAs(data.response, data.filename);
                  })
                  .catch(function (error) {
                    stat.errors.empty.push(error.msg);
                  });
              } else if (stat.filter.export_typ == "CSV") {
                StatisticsService.csv(params)
                  .$promise.then(function (data) {
                    FileSaver.saveAs(data.response, data.filename);
                  })
                  .catch(function (error) {
                    stat.errors.empty.push(error.msg);
                  });
              }
            }

            stat.status.loading = false;
          }, 1000);
        } else {
          stat.status.disabled = false;
        }
      };

      stat.returnToPreviousState = function () {
        if ($stateParams.stateChain && $stateParams.stateChain.length != 0) {
          var lastStateEntry = $stateParams.stateChain.pop();
          var state = lastStateEntry.state;
          var params = lastStateEntry.params ? lastStateEntry.params : {};

          var newParams = Object.assign({}, $stateParams, params);
          $state.go(state, newParams);
        } else {
          $state.go("menu.nav");
        }
      };

      stat.init();
    },
  ],
});
